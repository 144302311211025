<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  <InLoader class="inloader" />

  <div class="page-wrapper">
    <div class="content settings-content">
      <div class="page-header settings-pg-header">
        <div class="add-item d-flex">
          <div class="page-title">
            <h4>Settings</h4>
            <h6>Manage your settings on portal</h6>
          </div>
        </div>
        <ul class="table-top-head">
          <li>
            <a data-bs-toggle="tooltip" data-bs-placement="top" title="Refresh"
              ><i data-feather="rotate-ccw" class="feather-rotate-ccw"></i
            ></a>
          </li>
          <li>
            <a
              ref="collapseHeader"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Collapse"
              @click="toggleCollapse"
            >
              <i data-feather="chevron-up" class="feather-chevron-up"></i>
            </a>
          </li>
        </ul>
      </div>
      <div class="row">
        <div class="col-xl-12">
          <div class="settings-wrapper d-flex">
            <!-- <div
              class="sidebars settings-sidebar theiaStickySidebar"
              id="sidebar2"
            >
              <settings-sidebar></settings-sidebar>
            </div> -->
            <div class="settings-page-wrap w-50">
              <div class="setting-title">
                <h4>Bank Account</h4>
              </div>
              <div class="page-header bank-settings justify-content-end">
                <router-link
                  to="bank-settings-list"
                  class="btn-list me-2"
                  hidden
                  ><vue-feather type="list" class="list"></vue-feather
                ></router-link>
                <router-link
                  to="bank-settings-grid"
                  class="btn-grid active"
                  hidden
                  ><vue-feather type="grid" class="grid"></vue-feather
                ></router-link>
                <div class="page-btn">
                  <a
                    href="javascript:void(0);"
                    class="btn btn-added"
                    data-bs-toggle="modal"
                    data-bs-target="#add-account"
                    ><vue-feather type="plus-circle" class="me-2"></vue-feather
                    >Add New Account</a
                  >
                </div>
              </div>
              <div class="row">
                <div
                  v-for="(bank, index) in bank_list"
                  :key="index"
                  class="col-xxl-4 col-xl-6 col-lg-12 col-sm-6"
                >
                  <div :class="{ 'bank-box': true, active: bank?.is_verified }">
                    <div class="bank-header">
                      <div class="bank-name">
                        <h6>{{ bank?.bank }}</h6>
                        <p>{{ bank?.account_number }}</p>
                      </div>
                    </div>
                    <div
                      class="d-flex align-items-center justify-content-between"
                    >
                      <div class="bank-info">
                        <span>Holder Name</span>
                        <h6>{{ bank?.surname }} {{ bank?.other_names }}</h6>
                      </div>
                      <div class="edit-delete-action bank-action-btn">
                        <a
                          class="me-2 p-2"
                          href="javascript:void(0);"
                          data-bs-toggle="modal"
                          data-bs-target="#edit-account"
                          @click="assignForEdit(bank)"
                        >
                          <i data-feather="edit" class="feather-edit"></i>
                        </a>
                        <a
                          class="confirm-text p-2"
                          @click="showConfirmation(bank?.id)"
                          href="javascript:void(0);"
                        >
                          <i data-feather="trash-2" class="feather-trash-2"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <bank-settings-grid-modal></bank-settings-grid-modal>
</template>
<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      bank_list: [],
    };
  },
  methods: {
    ...mapActions({
      _get_banks_accounts: "general/get_banks_accounts",
      _delete_bank_account: "general/delete_bank_account",
    }),

    get_banks_accounts() {
      this._get_banks_accounts()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.bank_list = this.response.data;
          }
        })
        .catch((error) => {
          console.log("Error logging in", error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;
            if (
              status == 400 ||
              status == 422 ||
              status == 401 ||
              status == 404
            ) {
            } else if (status === 500) {
            }
          }
        });
    },
    assignForEdit(data){
      console.log(data)
localStorage.setItem('Edit_data',JSON.stringify(data))
    },
    // makeActive(index) {
    //   this.banks.forEach((bank, i) => {
    //     bank?.active = i === index;
    //   });
    // },    @click="makeActive(index)"

    showConfirmation(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: false,
      }).then((result) => {
        this._delete_bank_account(id)
          .then((response) => {
            $(".inloader").hide();
            // toast.success("Product Edited", {
            //   position: toast.POSITION.TOP_RIGHT,
            // });
            this.response = response.data;
            //   this.response
            if (this.response["status"] == true) {
              var data = this.response.data;

              window.location.reload();
            }
          })
          .catch((error) => {
            $(".inloader").hide();

            console.log("Error logging in", error);
            if (error.response) {
              const status = error.response.status;
              const data = error.response.data;
              if (
                status == 400 ||
                status == 422 ||
                status == 401 ||
                status == 404
              ) {
                toast.error(error.response.data.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              } else if (status === 500) {
                toast.error("Network Error, Check your internet conectivity.", {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }
            }
          });

        if (result.value) {
          Swal.fire({
            icon: "success",
            title: "Deleted!",
            text: "Your bank details has been deleted.",
            confirmButtonClass: "btn btn-success",
          });
        }
      });
    },
   

    toggleCollapse() {
      const collapseHeader = this.$refs.collapseHeader;

      if (collapseHeader) {
        collapseHeader.classList.toggle("active");
        document.body.classList.toggle("header-collapse");
      }
    },
  },
  mounted() {
    this.get_banks_accounts();
    $(".modal-backdrop.show").hide();
  },
};
</script>

<style scoped>
.modal-backdrop.show {
  display: none;
}
</style>
