<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  <InLoader class="inloader" />

  <div class="page-wrapper">
    <div class="content">
      <div class="page-header">
        <div class="page-title">
          <h4>#{{ returned_data?.public_ref }}</h4>
          <h6>Order detail of #{{ returned_data?.public_ref }}</h6>
        </div>
      </div>
      <!-- /add -->

      <div class="row">
        <div class="col-lg-8 col-sm-12">
          <div class="row">
            <div class="col-lg-4 col-sm-12">
              <div class="card">
                <div class="card-body">
                  <h2 class="shipping_info">Shipping Information</h2>
                  <br />
                  <p class="name">
                    Customer Name: {{ returned_data?.customer?.name }}
                  </p>
                  <p class="address">
                    Customer Address:
                    {{ returned_data?.deliveryAddress?.address }},
                    {{ returned_data?.deliveryAddress?.city }}
                    {{ returned_data?.deliveryAddress?.state }}
                  </p>
                  <p class="phone">
                    Phone No: {{ returned_data?.customer?.phone }}
                  </p>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-sm-12">
              <div class="card">
                <div class="card-body">
                  <h2 class="shipping_info">Billing Information</h2>
                  <br />
                  <p class="name">Payment Type:: -</p>
                  <p class="address">Provider: **** **** **** ****</p>
                  <p class="phone">Valid Date: **-****</p>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-sm-12">
              <div class="card">
                <div class="card-body">
                  <h2 class="shipping_info">Shipping Information</h2>
                  <br />
                  <p class="name">{{ returned_data?.customer?.name }}</p>
                  <p class="address">
                    Address: {{ returned_data?.deliveryAddress?.address }},
                    {{ returned_data?.deliveryAddress?.city }}
                    {{ returned_data?.deliveryAddress?.state }}
                  </p>
                  <p class="phone">
                    Phone No: {{ returned_data?.customer?.phone }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- other rows -->

          <div class="tcont">
            <!-- <h4>{{ returned_data?.public_ref }}</h4> -->
            <div class="card-body">
              <div class="table-responsive dataview table-stripped table">
                <table class="table dashboard-expired-products">
                  <thead>
                    <tr>
                      <th>Product</th>
                      <th>Quantity</th>
                      <th>Price</th>
                      <th>Total</th>
                      <!-- <th class="no-sort">Action</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="p in returned_data.products" :key="p.id">
                      <td>
                        {{ p?.name }}
                      </td>
                      <td>{{ p?.quantity }}</td>
                      <td>₦ {{ p?.price }}</td>
                      <td>
                        ₦
                        {{ splitNumbersWithComma((getprice = p.price)) }}
                      </td>
                    </tr>

                    <tr class="s_tr">
                      <td>Sub Total</td>
                      <td>-</td>
                      <td>-</td>
                      <td>{{ returned_data.amount }}</td>
                    </tr>
                    <tr class="s_tr">
                      <td>Commission</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-{{ commission }}</td>
                    </tr>
                    <tr class="s_tr">
                      <td>Bonus</td>
                      <td>-</td>
                      <td>-</td>
                      <td>0.00</td>
                    </tr>
                    <tr class="s_tr">
                      <td>Total amount receivable</td>
                      <td>-</td>
                      <td>-</td>
                      <td>
                        ₦
                        {{
                          splitNumbersWithComma(returned_data.amount_receivable)
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!-- table2 -->

          <div class="tcont">
            <h4>Shipping Details</h4>
            <div class="card-body">
              <div class="table-responsive dataview">
                <table class="table dashboard-expired-products">
                  <thead>
                    <tr>
                      <th>Product</th>
                      <!-- <th>Order ID</th> -->
                      <th>Buyer</th>
                      <th>Delivery Method</th>
                      <th>Order Status</th>
                      <!-- <th>Tracking Number</th> -->
                      <!-- <th>Delivery Type</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="p in returned_data.products" :key="p.id">
                      <td>
                        {{ p?.name }}
                      </td>
                      <td>{{ returned_data?.customer?.name }}</td>

                      <!-- <td>{{ returned_data?.public_ref }}</td> -->
                      <!-- <td>{{ returned_data?.company }}</td> -->
                      <td>{{ p?.status }}</td>
                      <td>{{ returned_data?.delivery_type }}</td>

                      <!-- <td>-</td> -->
                      <td hidden>
                        {{ (commission = p?.vendor_commission_fee) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <br />
          <div
            class="btn btn-primary w-40"
            data-bs-toggle="modal"
            href="#view-invoice"
            style="margin-right: 2%"
          >
            GENERATE SALES INVOICE
          </div>
          <div
            class="btn btn-primary w-40"
            data-bs-toggle="modal"
            href="#shipping-invoice"
          >
            GENERATE SHIPPING INVOICE
          </div>
        </div>

        <div class="col-lg-4 col-sm-12 trackcont">
          <div class="innertrackcont">
            <h1 class="order_header">Track Order</h1>
            <div class="my_prgress cont">
              <div class="my_progress" style="display: inline-block">
                <!-- <div class="in_my_progress"></div> -->
                <div
                  class="in_my_progress in_my_progress1"
                  style="height: 20%"
                ></div>
                <div
                  class="in_my_progress in_my_progress2"
                  v-if="
                    returned_data?.packed_at !== null &&
                    returned_data?.packed_at !== undefined
                  "
                  style="height: 34%"
                ></div>
                <div
                  class="in_my_progress in_my_progress3"
                  v-if="
                    returned_data?.shipped_at !== null &&
                    returned_data?.shipped_at !== undefined
                  "
                  style="height: 17%"
                ></div>
                <div
                  class="in_my_progress in_my_progress1"
                  v-if="
                    returned_data?.delivered_at !== null &&
                    returned_data?.delivered_at !== undefined
                  "
                  style="height: 16%"
                ></div>
                <div
                  class="in_my_progress in_my_progress1"
                  v-if="
                    returned_data?.fulfilled_at !== null &&
                    returned_data?.fulfilled_at !== undefined
                  "
                  style="height: 13%"
                ></div>

                <!-- circles -->
                <div
                  class="circles circle0"
                  style="background-color: #0095c78e"
                ></div>
                <div
                  class="circles circle1"
                  style="background-color: #0095c78e"
                ></div>
                <div
                  class="circles circle2"
                  :style="{
                    backgroundColor:
                      returned_data?.packed_at !== null &&
                      returned_data?.packed_at !== undefined
                        ? '#0095c78e'
                        : '',
                  }"
                ></div>
                <div
                  class="circles circle3"
                  :style="{
                    backgroundColor:
                      returned_data?.shipped_at !== null &&
                      returned_data?.shipped_at !== undefined
                        ? '#0095c78e'
                        : '',
                  }"
                ></div>
                <div
                  class="circles circle4"
                  :style="{
                    backgroundColor:
                      returned_data?.delivered_at !== null &&
                      returned_data?.delivered_at !== undefined
                        ? '#0095c78e'
                        : '',
                  }"
                ></div>
                <div
                  class="circles circle5"
                  :style="{
                    backgroundColor:
                      returned_data?.fulfilled_at !== null &&
                      returned_data?.fulfilled_at !== undefined
                        ? '#0095c78e'
                        : '',
                  }"
                ></div>
              </div>

              <div style="display: inline-block; margin-left: 5%">
                <div class="track_info">
                  <span style="float: left">
                    <span
                      style="font-weight: bolder; font-size: 20px; color: black"
                      ><b>Order ID</b></span
                    ><br />
                    {{ returned_data?.public_ref }}</span
                  >

                  <span style="float: right">
                    <span
                      style="font-weight: bolder; font-size: 20px; color: black"
                      ><b>Tracking ID</b></span
                    ><br />
                    <!-- #0CABNMKCB3E -->
                  </span>
                </div>
                <br /><br />
                <div class="track" style="clear: both; margin-top: 50px">
                  <figure class="active">
                    <figcaption>Order Placed</figcaption>
                    <p>{{ getDatetime(returned_data?.timestamp) }}</p>
                  </figure>
                  <figure class="">
                    <figcaption>Packed</figcaption>
                    <!---->
                    <p v-if="returned_data?.packed_at == null">
                      Waiting for the order to be packed
                    </p>
                    <p v-else>{{ getDatetime(returned_data?.packed_at) }}</p>
                  </figure>
                  <figure class="">
                    <figcaption>Shipped</figcaption>
                    <!---->
                    <p v-if="returned_data?.shipped_at == null">
                      Waiting for the order to be shipped
                    </p>
                    <p v-else>{{ getDatetime(returned_data?.shipped_at) }}</p>
                  </figure>
                  <figure class="">
                    <figcaption>Delivered</figcaption>
                    <!---->
                    <p v-if="returned_data?.delivered_at == null">
                      Estimated delivery within 1 day
                    </p>
                    <p v-else>{{ getDatetime(returned_data?.delivered_at) }}</p>
                  </figure>
                  <figure class="">
                    <figcaption>Completed</figcaption>
                    <!----><!---->
                    <p v-if="returned_data?.fulfilled_at == null">
                      Waiting for user to confirm Recipient of goods
                    </p>
                    <p v-else>{{ getDatetime(returned_data?.fulfilled_at) }}</p>
                  </figure>
                </div>

                <!-- code for shipping options -->
                <div
                  v-if="returned_data?.packed_at == null"
                  class="btn btn-primary w-100"
                  @click="change_status('Packed')"
                >
                  PACK
                </div>
                <div
                  v-else-if="
                    returned_data?.shipped_at == null &&
                    returned_data?.packed_at !== null
                  "
                  data-bs-toggle="modal"
                  data-bs-target="#TrackingId"
                  class="btn btn-primary w-100"
                >
                  SHIP
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- /add -->
    </div>
  </div>
  <!-- not in use for now -->
  <div class="modal fade" id="view-invoice">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="content">
          <div class="modal-header border-0 custom-modal-header">
            <h5 class="modal-title"></h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              ×
            </button>
          </div>

          <div class="modal-body custom-modal-body">
            <!--  -->
            <div class="vue-html2pdf">
              <section class="layout-container show-layout">
                <section class="content-wrapper" style="width: 100%">
                  <div class="invoice-container">
                    <header class="sender-header">
                      <div class="logo">
                        <img
                          src="@/assets/img/logo.png"
                          width="200px"
                          alt="Naija Kobo Market"
                          class="fl"
                        />
                      </div>
                      <div class="fr tn">
                        <h4>Tracking Number:</h4>
                        <p>#{{ returned_data?.public_ref }}</p>
                      </div>
                    </header>
                    <br />
                    <br />
                    <br />
                    <div class="r1">
                      <div class="ps-section__header">
                        <h4 class="invoiceHeader">Sender Details</h4>
                        <div class="">
                          <div>
                            <p class="dtl_cont">
                              <span class="dtl_box">Name:</span>
                              <span class="dtl_box" style="text-align: right">{{
                                returned_data?.seller?.name
                              }}</span>
                            </p>
                            <p class="dtl_cont">
                              <span class="dtl_box">Email:</span>
                              <span class="dtl_box" style="text-align: right">{{
                                returned_data?.seller?.email
                              }}</span>
                            </p>

                            <p class="dtl_cont">
                              <span class="dtl_box">Order Date:</span>
                              <span class="dtl_box" style="text-align: right">
                                {{
                                  getDatetime(returned_data?.timestamp)
                                }}</span
                              >
                            </p>
                            <p class="dtl_cont" hidden>
                              <span class="dtl_box">Order Number:</span>
                              <span class="dtl_box" style="text-align: right">
                                {{ returned_data?.public_ref }}</span
                              >
                            </p>
                            <p class="dtl_cont" hidden>
                              <span class="dtl_box">Weight (kg):</span>
                              <span
                                class="dtl_box"
                                style="text-align: right"
                              ></span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="ps-section__header secH_special">
                        <h4 class="invoiceHeader">Recipient Details</h4>
                        <div>
                          <p class="dtl_cont">
                            <span class="dtl_box">Name:</span>
                            <span class="dtl_box" style="text-align: right">
                              {{ returned_data?.customer?.name }}</span
                            >
                          </p>
                          <p class="dtl_cont">
                            <span class="dtl_box">Phone Number:</span>
                            <span class="dtl_box" style="text-align: right">
                              {{ returned_data?.customer?.phone }}</span
                            >
                          </p>
                          <p class="dtl_cont">
                            <span class="dtl_box">Address:</span>
                            <span class="dtl_box" style="text-align: right">
                              {{ returned_data?.trackingInfo?.origin }}</span
                            >
                          </p>
                        </div>
                      </div>
                      <div class="invoiceDetails text-center">
                        Product Information
                      </div>
                      <div class="table-responsive dataview">
                        <table class="table table-stripped" width="70%">
                          <thead>
                            <tr>
                              <th>
                                <span>Serial Number</span>
                              </th>
                              <th>
                                <span>Product Name</span>
                              </th>

                              <th>
                                <span>Quantity</span>
                              </th>
                              <th>
                                <span>Price</span>
                              </th>
                              <th>
                                <span>Amount</span>
                              </th>
                              <th>
                                <span>Commission</span>
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr
                              v-for="(p, index) in returned_data?.products"
                              v-bind:key="p"
                            >
                              <td>
                                <span>{{ index + 1 }}</span>
                              </td>
                              <td>
                                <a class="cut"></a><span>{{ p.name }}</span>
                              </td>

                              <td>
                                <span>{{ p.quantity }}</span>
                              </td>

                              <td>
                                <span>₦ </span
                                ><span>{{
                                  splitNumbersWithComma(p.price)
                                }}</span>
                              </td>
                              <td>
                                <span>₦ </span
                                ><span>{{
                                  splitNumbersWithComma(p.amount)
                                }}</span>
                              </td>
                              <td>
                                <span>{{ p.vendor_commission_fee }}</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="balance fr">
                        <span
                          >Total: ₦
                          {{
                            splitNumbersWithComma(returned_data?.amount)
                          }}</span
                        >
                      </div>
                      <br /><br />
                      <div class="invoice-user-info">
                        <p>
                          Dear {{ returned_data?.customer?.name }}, <br />
                          Thank you for shopping with us at Naija Kobo Market.
                          We are delighted to have you as a customer.
                          <br />
                        </p>
                      </div>
                    </div>
                  </div>
                </section>
              </section>
              <!---->
            </div>
          </div>
        </div>
        <footer id="modal_footer" class="modal-footer">
          <!-- <div type="button" class="btn btn-primary">Download</div> -->
          <div
            class="btn btn-primary"
            data-bs-toggle="modal"
            href="#shipping-invoice"
            id="shipping_invoice_btn"
          >
            Download
          </div>
          <!-- <div type="button" class="btn btn-primary" @click="downloadPdf()">Download</div> -->
        </footer>
      </div>
    </div>
  </div>
  <!-- not in use for now -->

  <div class="modal fade" id="shipping-invoice">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="content">
          <div class="modal-header border-0 custom-modal-header">
            <h5 class="modal-title"></h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              ×
            </button>
          </div>

          <div class="modal-body custom-modal-body">
            <!--  -->
            <div class="vue-html2pdf">
              <section class="layout-container show-layout">
                <section class="content-wrapper" style="width: 100%">
                  <div class="invoice-container">
                    <header class="sender-header">
                      <div class="logo">
                        <img
                          src="@/assets/img/logo.png"
                          width="200px"
                          alt="Naija Kobo Market"
                          class="fl"
                        />
                      </div>
                      <div class="fr tn tn1">
                        <h4>Tracking Number:</h4>
                        <p>#{{ returned_data?.public_ref }}</p>
                      </div>
                    </header>
                    <br />
                    <br />
                    <br />
                    <div class="row r1 r11">
                      <div class="col-lg-8 col-sm-12">
                        <h4 class="invoiceHeader">Sender Details</h4>
                        <div>
                          <p class="dtl_cont">
                            <span class="dtl_box">Name:</span>
                            <span class="dtl_box" style="text-align: right">{{
                              returned_data?.seller?.name
                            }}</span>
                          </p>
                          <p class="dtl_cont">
                            <span class="dtl_box">Email:</span>
                            <span class="dtl_box" style="text-align: right">{{
                              returned_data?.seller?.email
                            }}</span>
                          </p>

                          <p class="dtl_cont">
                            <span class="dtl_box">Order Date:</span>
                            <span class="dtl_box" style="text-align: right">
                              Mon Apr 22 2024</span
                            >
                          </p>
                          <p class="dtl_cont">
                            <span class="dtl_box">Order Number:</span>
                            <span class="dtl_box" style="text-align: right">
                              {{ returned_data?.public_ref }}</span
                            >
                          </p>
                          <!-- <p class="dtl_cont">
                            <span class="dtl_box">Weight (kg):</span>
                            <span
                              class="dtl_box"
                              style="text-align: right"
                            ></span>
                          </p> -->
                        </div>
                      </div>
                      <div class="col-lg-4 col-sm-12">
                        <h4 class="invoiceHeader">Order Details</h4>
                        <p class="dtl_cont">
                          <span class="dtl_box"> Order Date:</span>
                          <span class="dtl_box" style="text-align: right">
                            {{ getDatetime(returned_data?.created_at) }}</span
                          >
                        </p>
                        <p class="dtl_cont">
                          <span class="dtl_box">Order Number:</span>
                          <span class="dtl_box" style="text-align: right">
                            #{{ returned_data?.public_ref }}</span
                          >
                        </p>
                        <!-- <p class="dtl_cont">
                          <span class="dtl_box">Weight (kg):</span>
                          <span class="dtl_box" style="text-align: right">
                            3</span
                          >
                        </p> -->
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-8 col-sm-12">
                        <h4 class="invoiceHeader">Recipient Details</h4>
                        <div>
                          <p class="dtl_cont">
                            <span class="dtl_box">Name:</span>
                            <span class="dtl_box" style="text-align: right">
                              {{ returned_data?.customer?.name }}</span
                            >
                          </p>
                          <p class="dtl_cont">
                            <span class="dtl_box">Phone Number:</span>
                            <span class="dtl_box" style="text-align: right">
                              {{ returned_data?.customer?.phone }}</span
                            >
                          </p>
                          <p class="dtl_cont">
                            <span class="dtl_box">Address:</span>
                            <span class="dtl_box" style="text-align: right">
                              {{ returned_data?.trackingInfo?.origin }}</span
                            >
                          </p>
                        </div>
                      </div>

                      <div class="col-lg-4 col-sm-12">
                        <div>
                          <h4 class="invoiceHeader">Account Details</h4>
                          <p class="dtl_cont">
                            <span class="dtl_box">Account Number:</span>
                            <span class="dtl_box" style="text-align: right">
                              ECO022654</span
                            >
                          </p>
                          <p class="dtl_cont">
                            <span class="dtl_box">Account Name:</span>
                            <span class="dtl_box" style="text-align: right">
                              Naija Kobo Market</span
                            >
                          </p>
                          <p class="dtl_cont">
                            <span class="dtl_box">Shipping Company:</span>
                            <span class="dtl_box" style="text-align: right">
                              GIG Logistics</span
                            >
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="invoiceDetails text-center">
                      Product Information
                    </div>

                    <div class="table-responsive dataview">
                      <table class="table table-stripped" width="70%">
                        <thead>
                          <tr>
                            <th>
                              <span>Serial Number</span>
                            </th>
                            <th>
                              <span>Product Name</span>
                            </th>

                            <th>
                              <span>Quantity</span>
                            </th>
                            <th>
                              <span>Price</span>
                            </th>
                            <th>
                              <span>Amount</span>
                            </th>
                            <th>
                              <span>Commission</span>
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr
                            v-for="(p, index) in returned_data?.products"
                            v-bind:key="p"
                          >
                            <td>
                              <span>{{ index + 1 }}</span>
                            </td>
                            <td>
                              <a class="cut"></a><span>{{ p.name }}</span>
                            </td>

                            <td>
                              <span>{{ p.quantity }}</span>
                            </td>

                            <td>
                              <span>₦ </span
                              ><span>{{ splitNumbersWithComma(p.price) }}</span>
                            </td>
                            <td>
                              <span>₦ </span
                              ><span>{{
                                splitNumbersWithComma(p.amount)
                              }}</span>
                            </td>
                            <td>
                              <span>{{ p.vendor_commission_fee }}</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="balance fr">
                      <span
                        >Total: ₦
                        {{ splitNumbersWithComma(returned_data?.amount) }}</span
                      >
                    </div>
                    <br />
                    <div class="row r1 r1btn" hidden>
                      <div class="col-lg-8 col-sm-12">
                        <h4 class="invoiceHeader">POD</h4>
                        <p>Name:</p>
                        <p>IC:</p>
                        <p>Signature:</p>
                      </div>

                      <div class="col-lg-4 col-sm-12">
                        <h4 class="invoiceHeader">Batch Code</h4>
                      </div>
                    </div>
                  </div>
                </section>
              </section>
              <!---->
            </div>
          </div>
        </div>
        <footer id="modal_footer" class="modal-footer">
          <div
            class="btn btn-primary"
            data-bs-toggle="modal"
            href="#shipping-invoice"
            id="shipping_invoice_btn"
          >
            Download
          </div>
          <!-- <div type="button" class="btn btn-primary dbtn">Download</div> -->
          <!-- <div type="button" class="btn btn-primary" @click="downloadPdf()">Download</div> -->
        </footer>
      </div>
    </div>
  </div>

  <!-- Toggle Between Modals -->
  <div class="col-xl-4">
    <!-- Modal -->
    <div
      class="modal fade"
      id="TrackingId"
      aria-hidden="true"
      aria-labelledby="TrackingIdLabel"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="TrackingIdLabel">
              Enter Tracking Code
            </h4>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <form class="">
              <div id="input-group-1" role="group" class="form-group">
                <!-- <label id="" for="input-1" class="d-block"
                  >Enter Tracking code</label
                > -->
                <div>
                  <input
                    id="input-1"
                    type="text"
                    placeholder="Enter Amount"
                    required="required"
                    aria-required="true"
                    class="form-control"
                    v-model="tracking_id"
                  /><!----><!----><!---->
                </div>
              </div>
            </form>
          </div>

          <div class="modal-footer">
            <button
              class="btn btn-primary"
              data-bs-dismiss="modal"
              style="width: 100%"
              @click="change_status('Shipped')"
            >
              CONFIRM
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- /Modal -->
  </div>
  <!-- /Toggle Between Modals -->
</template>
<script>
// import html2pdf from "html2pdf.js";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      data: JSON.parse(localStorage.getItem("product_data")),
      id: localStorage.getItem("invoice_id"),
      returned_data: "",
      getprice: "",
      commission: "",
      completed: false,
      tracking_id: 1013335300,
    };
  },
  methods: {
    // downloadPdf(){
    //   html2pdf(document.getElementById("vue-html2pdf"), {
    //     margin: 5,
    //     filename: this.id + ".pdf",
    //     enableLinks: true,

    //     image: {
    //       type: "png",
    //       quality: 50.98,
    //     },
    //   });
    // },
    ...mapActions({
      _get_purchase_details: "purchase/get_purchase_details",
      _change_status: "product/change_status",
    }),
    get_purchase_details() {
      // let id = (this.id !== null) ? this.id : localStorage.getItem("purchase_id");
      this._get_purchase_details(this.id)
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.returned_data = this.response.data;
            console.log(this.returned_data);
          }
        })
        .catch((error) => {
          console.log("Error logging in", error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;
            if (
              status == 400 ||
              status == 422 ||
              status == 401 ||
              status == 404
            ) {
            } else if (status === 500) {
            }
          }
        });
    },
    change_status(status) {
      $(".inloader").show();

      this._change_status({
        id: this.id,
        payload: {
          status: status,
          company: "Gigl",
          tracking_code: this.tracking_id,
        },
      })
        .then((response) => {
          $(".inloader").hide();
          this.get_purchase_details();
          // window.reload();
          this.response = response.data;
          if (this.response["status"] == true) {
            // this.returned_data = this.response.data;
            // console.log(this.returned_data);
          }
        })
        .catch((error) => {
          $(".inloader").hide();

          console.log("Error logging in", error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;
            if (
              status == 400 ||
              status == 422 ||
              status == 401 ||
              status == 404
            ) {
            } else if (status === 500) {
            }
          }
        });
    },
    getDatetime(date) {
      if (date) {
        let datetime = new Date(date);
        // Format date as YYYY-MM-DD and time as HH:MM:SS
        const formattedDate = datetime.toISOString().split("T")[0]; // YYYY-MM-DD
        const formattedTime = datetime.toTimeString().split(" ")[0]; // HH:MM:SS
        return `${formattedDate} ${formattedTime}`;
      } else {
        return (
          new Date().toISOString().split("T")[0] +
          " " +
          new Date().toTimeString().split(" ")[0]
        );
      }
    },
    splitNumbersWithComma(number) {
      // Convert number to string to handle cases like decimal numbers
      if (number) {
        let numStr = number.toString();

        // Split the integer and decimal parts if it's a decimal number
        let parts = numStr.split(".");
        let integerPart = parts[0];
        let decimalPart = parts.length > 1 ? "." + parts[1] : "";

        // Insert commas for thousands separator in the integer part
        let integerWithCommas = integerPart.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );

        // Combine integer part with decimal part (if any)
        let formattedNumber = integerWithCommas + decimalPart;

        return formattedNumber;
      }
    },
  },
  mounted() {
    this.get_purchase_details();
  },
};
</script>
<style scoped>
.card {
  border: none;
  box-shadow: 2px 2px 2px 2px #0095c78e;
  height: 220px;
}
.tcont {
  margin-top: 40px;
}
.s_tr {
  font-weight: bolder;
  background-color: rgba(128, 128, 128, 0.329);
}
.trackcont {
  padding-left: 1%;
}
.innertrackcont {
  height: auto;
  border-left: 1px solid rgba(0, 0, 0, 0.082);
  padding-left: 20px;
  padding-bottom: 40px;
}
figcaption {
  font-weight: bolder;
  margin-bottom: 10px;
  color: black;
}
figure p {
  margin-bottom: 30px;
}
.order_header {
  margin-bottom: 30px;
}
@media (max-width: 1800px) {
  .card {
    height: auto;
  }
}
@media (max-width: 853px) {
  .trackcont {
    margin-top: 80px;
  }
}

/* my progress bar */

.my_progress {
  height: 500px;
  width: 3px;
  background-color: rgba(102, 102, 102, 0.404);
  display: inline-block;
  position: absolute;
}
.circles {
  width: 12px;
  height: 12px;
  background-color: rgba(102, 102, 102, 0.938);
  border-radius: 50%;
  position: absolute;
  left: -150%;
}
.circle0 {
  top: -1%;
}
.circle1 {
  top: 20%;
}
.circle2 {
  top: 36%;
}
.circle3 {
  top: 52%;
}
.circle4 {
  top: 69%;
}
.circle5 {
  top: 85%;
}
.in_my_progress {
  background-color: #0095c78e;
}
.table {
  width: 100%;
  overflow-x: scroll;
}

.invoiceHeader {
  background: #00afef;
  color: #fff;
  padding: 10px;
}
.invoiceDetails {
  color: #00afef;
  margin-top: 30px;
  font-size: 23px;
}
p {
  font-size: 14px;
  line-height: 1.6em;
  font-weight: 500;
}

.fl {
  float: left;
}
.fr {
  float: right;
}
.sender-header {
  margin-top: 0px;
}

.dtl_cont {
  display: flex;
}

.dtl_box {
  margin-top: 10px;
  flex-grow: 1;
  padding: 0px;
  text-align: left;
  width: 50%;
  display: inline-block;
  /* border: 1px solid #ccc; */
  /* text-align: center; */
}
.balance {
  font-size: 17px;
  margin-bottom: 20px;
}
#shipping_invoice_btn {
  width: 40%;
}

.r1btn {
  margin-top: 60px;
}

@media (max-width: 500px) {
  .r1 {
    margin-top: 50px;
  }
  .dbtn {
    float: right;
  }
  #shipping_invoice_btn {
    width: 100%;
  }
  .tn {
    display: block;
    margin-top: 15px;
    float: left;
  }
  .r11 {
    margin-top: 60px;
  }
  .dtl_box {
    margin-bottom: -20px;
    font-size: 12px;
  }
  .invoiceHeader {
    padding: 5px;
    margin-top: 20px;
  }

  .secH_special {
    margin-top: 30px;
  }

  .fl {
    width: 150px;
  }
  .table-stripped,
  table,
  td {
    font-size: 12px;
  }
}
</style>
