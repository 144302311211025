<template>
  <div
    class="modal fade"
    id="previewModal"
    tabindex="-1"
    aria-labelledby="previewModalLabel"
    aria-hidden="true"
    style="display: none"
  >
    <div class="modal-dialog modal-fullscreen">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="previewModalLabel">Product Preview </h4>

          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="container">
            <div class="product-image">
              <div class="image-container" style="width: 600px; height: 600px">
                <!-- <div class="product-image"> -->
                <div class="featured-banner">FEATURED</div>
                <img
                  :src="preview?.main_product?.image"
                  id="preview_image"
                  style="width: 100%; height: 100%"
                  class="zoom-image"
                />
                <div class="zoom-lens"></div>
              </div>

              <div class="thumbnail-images">
                 <img
                  :src="preview?.main_product?.image"
                  @click="changeimg($event)"
                  @pointerenter="changeimg($event)"
                />
                <img
                  v-for="(image, index) in preview?.main_product?.imagePreviews"
                  :key="index"
                  :src="image.url"
                  @click="changeimg($event)"
                  @pointerenter="changeimg($event)"
                />
              </div>
            </div>
            <div class="product-detail">
              <h1>{{ preview?.main_product?.name }}</h1>
              <p class="price">
                <span class="discounted-price">{{
                  preview?.main_product?.selling_price
                }}</span>
                <span class="original-price">{{
                  preview?.main_product?.price
                }}</span>
                <!-- <span class="discount">9.00% off</span> -->
              </p>
              <p class="description">
                {{ preview?.main_product?.description }}
              </p>
              <div class="order-section">
                <div class="stock-info">
                  <p>
                    Please hurry! only
                    <span class="stock-quantity">7</span> left in stock
                  </p>
                </div>
                <div class="product-info">
                  <ul>
                    <li><strong>SKU:</strong> FRUIT09</li>
                    <li><strong>SKU:</strong> 4 KG</li>
                    <li>
                      <strong>Weight:</strong>
                      {{ preview?.main_product?.width }} KM
                    </li>
                    <li><strong>Stock Status:</strong> In Stock</li>
                    <li>
                      <strong>Quantity:</strong>
                      {{ preview?.main_product?.quantity }}
                    </li>
                  </ul>
                </div>
                <div class="buttons">
                  <button class="add-to-cart">ADD TO CART</button>
                  <button class="buy-now">BUY NOW</button>
                </div>
              </div>
              <div class="seller-info">
                <div
                  class="product-selection"
                  v-if="
                    this.preview &&
                    this.preview?.color_images &&
                    this.preview?.color_images?.length > 0
                  "
                >
                  <div class="color-selection" hidden>
                    <p>Color: Black</p>
                    <div class="colors">
                      <div
                        class="color-option"
                        v-for="(image, index) in preview?.main_product
                          ?.imagePreviews"
                        :key="index"
                      >
                        <img :src="image.url" alt="Black" />
                        <p>Black</p>
                      </div>
                    </div>
                  </div>
<!-- {{ preview }} -->
                  <div class="color-selection">
                    <p>Color: {{ selected_varaint_color }}</p>
                    <div class="colors">
                      <div
                        class="color-option"
                        v-for="(variant, index) in preview?.color_images"
                        :key="index"
                        @click="change_varaint_product(variant)"
                      >
                        <img :src="image_procesor(variant.image)" alt="Black" />
                        <p>{{ preview?.color_images?.color }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="size-selection">
                    <p>Size:</p>
                    <div class="sizes">
                      <div class="size-option">S</div>
                      <div class="size-option almost-sold-out">
                        M <span>Almost sold out</span>
                      </div>
                      <div class="size-option almost-sold-out">
                        L <span>Almost sold out</span>
                      </div>
                      <div class="size-option">XL</div>
                      <div class="size-option">XXL</div>
                    </div>
                  </div>
                </div>
                <div class="contacts">
                  <p class="contact"><strong>Contact:</strong> +1 4088023009</p>
                  <p class="contact">
                    <strong>Email:</strong> irene.clark@example.com
                  </p>
                </div>
              </div>
              <!-- <div class="guarantee-section">
              <p>GUARANTEED FRESHNESS, ALL DAY, EVERY DAY.</p>
              <button class="shop-now">Shop Now</button>
            </div> -->
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  components: {},
 
  props: {
    preview: {
      type: Array,
      required: true,
    },
  },
   data() {
    return { selected_varaint_color: "None",};
  },
  methods: {
    changeimg(E) {
      // const img = document.getElementById("preview_image");
      // img.src = src;

      var previewer = document.getElementById("preview_image");

      var imgg = E.target;
      var img = imgg.src;
      previewer.src = img;
    },
    image_procesor(image) {
      if (image) {
        const imageURL = URL.createObjectURL(image);
        // console.log(image);
        return imageURL;
      }
    },
    change_varaint_product(data) {
      this.backup_preview=this.preview
      this.selected_varaint_color = data.values.Color.value;
      this.preview.main_product.image = this.image_procesor(data.image);
      // console.log(this.preview.main_product.image)

      this.preview.main_product.selling_price = data.price
      this.preview.main_product.price = data.price
      this.preview.main_product.quantity=data.quantity
    },
    normal_product() {
      this.selected_varaint_color = 'None';
      this.preview.main_product.image = this.backup_preview.image;
      this.preview.main_product.selling_price = this.backup_preview.main_product.selling_price
      this.preview.main_product.price = this.backup_preview.main_product.price
      this.preview.main_product.quantity=this.backup_preview.main_product.quantity
    }
  },
  mounted() {
    const imageContainer = document.querySelector(".image-container");
    const zoomImage = document.querySelector(".zoom-image");
    const zoomLens = document.querySelector(".zoom-lens");

    imageContainer.addEventListener("mousemove", moveLens);
    imageContainer.addEventListener("mouseout", resetZoom);

    function moveLens(e) {
      // Get cursor position
      const pos = getCursorPos(e);

      // Calculate lens position
      let lensX = pos.x - zoomLens.offsetWidth / 2;
      let lensY = pos.y - zoomLens.offsetHeight / 2;

      // Prevent the lens from being positioned outside the image
      if (lensX > imageContainer.offsetWidth - zoomLens.offsetWidth) {
        lensX = imageContainer.offsetWidth - zoomLens.offsetWidth;
      }
      if (lensX < 0) {
        lensX = 0;
      }
      if (lensY > imageContainer.offsetHeight - zoomLens.offsetHeight) {
        lensY = imageContainer.offsetHeight - zoomLens.offsetHeight;
      }
      if (lensY < 0) {
        lensY = 0;
      }

      // Set lens position
      zoomLens.style.left = lensX + "px";
      zoomLens.style.top = lensY + "px";

      // Zoom effect
      zoomImage.style.transform = `scale(2)`;
      zoomImage.style.transformOrigin = `${
        ((lensX + zoomLens.offsetWidth / 2) / imageContainer.offsetWidth) * 100
      }% ${
        ((lensY + zoomLens.offsetHeight / 2) / imageContainer.offsetHeight) *
        100
      }%`;
    }

    function getCursorPos(e) {
      const rect = imageContainer.getBoundingClientRect();
      return {
        x: e.clientX - rect.left,
        y: e.clientY - rect.top,
      };
    }

    function resetZoom() {
      zoomLens.style.display = "none";
      zoomImage.style.transform = `scale(1)`;
      zoomImage.style.transformOrigin = "center center";
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  max-width: 1200px;
  margin: 50px auto;
  margin-top: 10px;
  background-color: #fff;
  padding: 50px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.product-image {
  position: relative;
  text-align: center;
}

.featured-banner {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #ffc107;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
}

.product-image img {
  max-width: 100%;
  border-radius: 8px;
}

.thumbnail-images {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.thumbnail-images img {
  max-width: 60px;
  width:60px;
  margin: 0 8px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.product-detail {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.product-detail h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

.price {
  font-size: 20px;
  color: #ff5722;
  margin-bottom: 10px;
}

.discounted-price {
  font-weight: bold;
}

.original-price {
  text-decoration: line-through;
  color: #888;
  margin-left: 10px;
}

.discount {
  color: #4caf50;
  margin-left: 10px;
}

.description {
  margin-bottom: 20px;
}

.order-section {
  margin-bottom: 0px;
}

.stock-info {
  margin-bottom: 10px;
}

.stock-quantity {
  font-weight: bold;
}

.product-info ul {
  list-style: none;
  padding: 0;
  margin: 0 0 20px 0;
}

.product-info li {
  margin-bottom: 5px;
}

.buttons {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.add-to-cart,
.buy-now {
  flex: 1;
  padding: 10px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.add-to-cart {
  background-color: #4caf50;
  color: #fff;
}

.buy-now {
  background-color: #ff5722;
  color: #fff;
}

.seller-info {
  margin-bottom: 10px;
}

.seller-info h2 {
  font-size: 20px;
  margin-bottom: 10px;
}

.guarantee-section {
  background-color: #e0f7fa;
  padding: 15px;
  border-radius: 8px;
  text-align: center;
  margin-bottom: 0px;
}

.shop-now {
  background-color: #0288d1;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.checkout-security {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.checkout-security img {
  max-width: 50px;
}

.product-selection {
  max-width: 600px;
  margin: auto;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.color-selection,
.size-selection,
.quantity-selection {
  margin-bottom: 20px;
}

.color-selection p,
.size-selection p,
.quantity-selection p {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.colors,
.sizes {
  display: flex;
  gap: 10px;
}

.color-option,
.size-option {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}

.color-option:hover,
.size-option.almost-sold-out {
  border-color: #ff5722;
}

.color-option img {
  width: 60px;
  height: 60px;
  /* max-width: 60px; */
  display: block;
  margin: auto;
  margin-bottom: 5px;
}

.color-option p {
  margin: 0;
}

.size-option.almost-sold-out {
  position: relative;
}

.size-option.almost-sold-out::after {
  content: "Almost sold out";
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: #ff5722;
  color: #fff;
  padding: 2px 5px;
  border-radius: 5px;
  font-size: 10px;
}

.size-guide {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  color: #0275d8;
  cursor: pointer;
}

.size-guide img {
  width: 16px;
}

.contacts {
  margin-top: 20px;
  display: none;
}

.image-container {
  position: relative;
  width: 400px;
  height: 300px;
  overflow: hidden;
  border: 2px solid #ccc;
  border-radius: 10px;
}

.zoom-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
  cursor: zoom-in;
}

.zoom-lens {
  position: absolute;
  border: 2px solid #000;
  width: 100px; /* Size of the zoom lens */
  height: 100px; /* Size of the zoom lens */
  background-color: rgba(255, 255, 255, 0.5);
  cursor: none;
  display: none;
}

.image-container:hover .zoom-lens {
  display: block;
}
</style>
