/* eslint-disable no-unused-vars */
import { router } from "@/router";
import { api, kycUrl, url, url2, redirectUrl_account } from "../api";
import googleApi from "../api/google";
import twitterApi from "../api/twiiter";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export const general = {
  namespaced: true,
  actions: {
    login({ context }, payload) {
      localStorage.clear();
      return api.post(url("login"), payload);
    },
    verify({ context }, payload) {
      return api.post(url2("verify_login"), payload);
    },
    change_password({ context }, payload) {
      return api.post(url("users/password"), { ...payload, _method: "PATCH" });
    },

    get_invoices({ context }) {
      return api.get(url("vendor/invoices"));
    },

    get_withdrawls({ context }) {
      return api.get(url("users/withdrawals"));
    },
    get_banks({ context }) {
      return api.get(url("kyc/banks"));
    },
    get_banks_accounts({ context }) {
      return api.get(url("users/bank_accounts"));
    },
    add_bank_account({ context }, payload) {
      return api.post(url("vendor/bank-details"), payload);
    },
    delete_bank_account({ context }, id) {
      return api.post(url(`vendor/bank-details/${id}`), { _method: "DELETE" });
    },
    edit_bank_account({ context }, { payload, id }) {
      return api.post(url(`vendor/bank-details/${id}`), {
        ...payload,
        _method: "PATCH",
      });
    },
    confirm_withdrawal({ context }, payload) {
      return api.post(url("users/withdrawals"), payload);
    },
    verify_withdraw({ context }, { id, payload }) {
      return api.post(url(`users/withdrawals/${id}`), payload);
    },
    get_variant_keys({ context }) {
      return api.get(url2("products/variant-keys"));
    },
    get_cancelled_order({ context }) {
      return api.get(url("orders/cancel/requests"));
    },
    cancellation_action({ context }, payload) {
      return api.post(url2(`orders/cancel/process`), {
        ...payload,
        _method: "PATCH",
      });
    },
    refund_action({ context }, { refundRequestID,payload }) {
      return api.post(
        url2(`vendor/orders/refund/requests/${refundRequestID}`),
        {
          ...payload,
          _method: "PATCH",
        }
      );
    },
    get_refund({ context }) {
      return api.get(url("vendor/orders/refund/requests"));
    },
    // ------------------------------------------//
    // global_category({ context }, payload) {
    //   return api.get(url("global/category"), { params: payload });
    // },

    // Transfer_OTP_call({ context }, { id, payload }) {
    //   return api.post(
    //     url(`send/transferOut/patient/hospital/otp/${id}`),
    //     payload
    //   );
    // },
  },
};
export const dashboard = {
  namespaced: true,
  actions: {
    get_sales_dashboard({ context }) {
      return api.get(url("vendor/dashboard"));
    },
  },
};
export const product = {
  namespaced: true,
  actions: {
    add_product({ context }, payload) {
      return api.post(url2("products"), payload);
    },
    edit_product({ context }, { slug, payload }) {
      return api.post(url(`products/${slug}`), {
        ...payload,
        _method: "PATCH",
      });
    },
    delete_product({ context }, productId) {
      return api.post(url(`products/${productId}`), { _method: "DELETE" });
    },
    // get_categories({ context }) {
    //     return api.get(url("categories"));
    //   },
    get_subcategories({ context }, params) {
      return api.get(url("subcategories"), { params: params });
    },
    get_subproducts({ context }) {
      return api.get(url("vendor/products"));
    },
    get_states({ context }) {
      return api.get(url("states"));
    },
    get_gig({ context }) {
      return api.get(url("gigl_pickup_addresses"));
    },
    get_brands({ context }) {
      return api.get(url("brands"));
    },
    // change_status({ context }, payload) {
    //   return api.post(url(`products/status`), { ...payload, _method: "PATCH" });
    // },

    // for changing status
    change_status({ context }, { id, payload }) {
      return api.post(url2(`vendor/invoices/${id}`), {
        ...payload,
        _method: "PATCH",
      });
    },
    delete_online_img({ context }, { productId, imageId }) {
      return api.post(url2(`products/${productId}/images/${imageId}`), {
        // ...payload,
        _method: "DELETE",
      });
    },
    send_new_img({ context }, { productId, payload }) {
      return api.post(url2(`products/${productId}/images`), payload);
    },
    edit_img({ context }, { productId, imageId,payload }) {
      return api.post(url2(`products/${productId}/images/${imageId}`), {
        ...payload,
        _method: "PATCH",
      });
    },
  },
};
export const coupon = {
  namespaced: true,
  actions: {
    add_coupon({ context }, payload) {
      return api.post(url("coupons"), payload);
    },
    get_coupons({ context }) {
      return api.get(url("coupons"));
    },
  },
};

export const purchase = {
  namespaced: true,
  actions: {
    get_purchase_details({ context }, id) {
      return api.get(url(`vendor/invoices/${id}`));
    },
    create_payment({ context }, { orderId,payload }) {
      return api.post(url(`vendor/orders/${orderId}/payment`), payload);
    },
  },
};

export const notification = {
  namespaced: true,
  actions: {
    get_notifications({ context }) {
      return api.get(url("vendor/notifications"));
    },
    read_notification({ context }, id) {
      return api.get(url(`vendor/notifications/${id}`));
    },
    get_notification_details({ context }, id) {
      return api.get(url(`vendor/invoices/${id}`));
    },
  },
};

export const admin = {
  namespaced: true,
  actions: {
    get_code({ context }, payload) {
      return api.post(url("store-admin/code"), payload);
    },
    add_vendor({ context }, payload) {
      return api.post(url("store-admin"), payload);
    },
    get_vendor({ context }) {
      return api.get(url("store-admin"));
    },
    edit_vendor({ context }, { payload, id }) {
      return api.post(url(`store-admin/${id}`), {
        ...payload,
        _method: "PATCH",
      });
    },
    delete_vendor({ context }, id) {
      return api.post(url(`store-admin/${id}`), { _method: "DELETE" });
    },

    add_user({ context }, payload) {
      return api.post(url("vendor/users"), payload);
    },
    get_user({ context }) {
      return api.get(url("vendor/users"));
    },
    // edit_user({ context }, { payload, id }) {
    //   return api.post(url(`store-admin/${id}`), {
    //     ...payload,
    //     _method: "PATCH",
    //   });
    // },
    // delete_user({ context }, id) {
    //   return api.post(url(`store-admin/${id}`), { _method: "DELETE" });
    // },
  },
};

export const message = {
  namespaced: true,
  actions: {
    get_messages({ context }) {
      return api.get(url("vendor/products/question"));
    },
    answer_question({ context }, { payload, id }) {
      return api.post(url(`product/question/${id}`), {
        ...payload,
        _method: "PATCH",
      });
    },
  },
};

export const authentication = {
  namespaced: true,
  actions: {
    loginGoogle: () => {
      googleApi.loginWithGoogle();
    },

    continueOAuth: async ({ commit }, location) => {
      const code = location.href.match(/(?:code)\=([\S]*?)\&/)[1];
      const res = await googleApi.requestToken(code);
      const token = res.data.access_token;
      commit("SET_TOKEN", token);
      window.localStorage.setItem("token", token);
      const user = await googleApi.getUserInfo(token);
      commit("SET_USER", user.data);
      router.push("/verify-phone");
    },

    signUpGoogle({ commit }, urlDetails) {
      localStorage.clear();
      api
        .post(url2("signup_with_google"), urlDetails)
        .then((res) => {
          localStorage.setItem("accessToken", res.data.token);
          localStorage.setItem("data", JSON.stringify(res.data.data));
          if (
            res.data.data.phone_number == null ||
            res.data.data.phone_number == ""
          ) {
            window.location = redirectUrl_account();
          } else {
            if (res.data.data.kyc_verified == false) {
              window.location = kycUrl(res.data.token);
            } else {
              router.push("/dashboard/sales-dashboard");
            }
          }
        })
        .catch((error) => {
          // Vue.$toast.error(error);
          // console.clear();
          // console.error(error);
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          if (error.response.data.code == "__only_vendor__") {
            //3 environments
            window.location = redirectUrl_account;
          }
        });
    },

    // signUpApple({ commit }, appleDetails) {
    //   api
    //     .post("/signup_with_apple", appleDetails)
    //     .then((res) => {
    //       persistToken(res.data.token);
    //       commit("SET_TOKEN", res.data.token);

    //       if (!res.data.profile.email_verified) {
    //         router.push("/register-verify-email");
    //       } else {
    //         router.push("/verify-phone");
    //       }
    //     })
    //     .catch((error) => {
    //       Vue.$toast.error(error);
    //       router.push("/");
    //       console.clear();
    //     });
    // },

    loginApple({ commit }, appleLoginDetails) {
      api
        .post(url2("signup_with_apple"), appleLoginDetails, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          localStorage.setItem("accessToken", res.data.token);
          localStorage.setItem("data", JSON.stringify(res.data.data));
          if (
            res.data.data.phone_number == null ||
            res.data.data.phone_number == ""
          ) {
            window.location = redirectUrl_account();
          } else {
            if (res.data.data.kyc_verified == false) {
              window.location = kycUrl(res.data.token);
            } else {
              router.push("/dashboard/sales-dashboard");
            }
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          // if (error.response.data.code == "__only_vendor__") {
          //   //3 environments
          //   window.location = redirectUrl_account();
          // }
        });
    },

    loginTwitter: () => {
      twitterApi.loginWithTwitter();
    },

    authTwitter({ commit }, tokenDetails) {
      localStorage.clear();
      api
        .post(url2("signup_with_twitter"), tokenDetails)
        .then((res) => {
          localStorage.setItem("accessToken", res.data.token);
          localStorage.setItem("data", JSON.stringify(res.data.data));
          alert(res.data.code);
          if (res.data.data.kyc_verified == false) {
            window.location = kycUrl(res.data.token);
          } else {
            router.push("/dashboard/sales-dashboard");
          }
        })
        .catch((error) => {
          console.log(error);

          if (error.response.data.code == "__only_vendor__") {
            //3 environments
            window.location = redirectUrl_account;
          }
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          console.error(error);
        });
    },
  },
};
