import { Object } from "core-js";

let REDIRECT_URL;
// demo.gtcomnet.com or front.dev

function objectToQueryString(obj) {
  return Object.entries(obj).reduce((total, [key, value]) => {
    return total + (total ? "&" : "") + `${key}=${value}`;
  }, "");
}
const data = {
  response_type: "code",
  client_id: "enVZZ3JGZVBlb2dEemRMM0xvOUg6MTpjaQ",
  redirect_uri: "https://front.dev/twitter_signup",
  scope: "tweet.read users.read",
  state: "state",
  code_challenge: "challenge",
  code_challenge_method: "plain",
};
const params = objectToQueryString(data)


if (process.env.NODE_ENV === "production") {
  REDIRECT_URL =
    "https://twitter.com/i/oauth2/authorize?response_type=code&client_id=enVZZ3JGZVBlb2dEemRMM0xvOUg6MTpjaQ&redirect_uri=https://vendor.naijakobomarket.com/twitter_signup&scope=tweet.read%20users.read&state=state&code_challenge=challenge&code_challenge_method=plain";
} else if (process.env.NODE_ENV === "demo") {
  REDIRECT_URL =
    "https://twitter.com/i/oauth2/authorize?response_type=code&client_id=enVZZ3JGZVBlb2dEemRMM0xvOUg6MTpjaQ&redirect_uri=https://nkmvendor.gtcomnet.com/twitter_signup&scope=tweet.read%20users.read&state=state&code_challenge=challenge&code_challenge_method=plain";
} else {
   REDIRECT_URL =
    "https://twitter.com/i/oauth2/authorize?response_type=code&client_id=enVZZ3JGZVBlb2dEemRMM0xvOUg6MTpjaQ&redirect_uri=https://vendor.dev/twitter_signup&scope=tweet.read%20users.read&state=state&code_challenge=challenge&code_challenge_method=plain";
}


if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  
   // REDIRECT_URL = `https://twitter.com/i/oauth2/authorize?${params}`;
} else {
  }

function loginWithTwitter() {
  window.location = REDIRECT_URL;
}
export default { loginWithTwitter };
