import axios from "axios";

let baseURL;

if (process.env.NODE_ENV === "production") {
  baseURL = "https://npanel.naijakobomarket.com/api/v2/";
} else if (process.env.NODE_ENV === "demo") {
  baseURL = "https://nkmdash.gtcomnet.com/api/v2/";
} else {
  // baseURL = "http://192.168.1.113:9005/api/v2/";
  baseURL = "https://nkmdash.gtcomnet.com/api/v2/";
}

const kycUrl = (token) => {
  if (process.env.NODE_ENV === "production") {
    return `https://kyc.naijakobomarket.com/auth/${token}`;
  } else if (process.env.NODE_ENV === "demo") {
    return `https://nkmkyc.gtcomnet.com/auth/${token}`;
  } else {
    return `https://nkmkyc.gtcomnet.com/auth/${token}`;
    // return `http://localhost:8001/auth/${token}`;
  }
};

const redirectUrl_account = () => {
  if (process.env.NODE_ENV === "production") {
    return `https://www.naijakobomarket.com/account`;
  } else if (process.env.NODE_ENV === "demo") {
    return `https://nkmuser.gtcomnet.com/account`;
  } else {
    return `http://front.dev/account`;
  }
};

const url = (path) => {
  return baseURL + path;
};

let baseURL2;
if (process.env.NODE_ENV === "production") {
  baseURL2 = "https://npanel.naijakobomarket.com/api/v2/";
} else if (process.env.NODE_ENV === "demo") {
  baseURL2 = "https://nkmdash.gtcomnet.com/api/v2/";
} else {
  // baseURL2 = "http://192.168.1.113:9005/api/v2/";
  baseURL2 = "https://nkmdash.gtcomnet.com/api/v2/";
}
const url2 = (path) => {
  return baseURL2 + path;
};

const api = axios.create();
api.interceptors.request.use((config) => {
  // alert("A Request was Processed");
  const bearerToken = localStorage.getItem("accessToken");
  config.headers.Authorization = `Bearer ${bearerToken}`;
  config.headers["Content-Type"] = "multipart/form-data";

  const store_id = localStorage.getItem("store_id");
  config.params = { ...config.params, is_vendor: 1, store_id };
  return config;
});
export { baseURL, url, url2, api, kycUrl, redirectUrl_account };
