// generalSearch.js

export function searchList(data, search, fields) {
  if (search == "") {
    return data;
  }

  let searchLetter = search.toLowerCase();
  let searchResult = [];

  for (let i = 0; i < data.length; i++) {
    for (let field of fields) {
      let fieldValue = data[i][field];
      
      // Check if the field exists and if it's a string, then convert it to lowercase
      if (fieldValue && typeof fieldValue === 'string') {
        fieldValue = fieldValue.toLowerCase();
      }

      // Check if the field value contains the search string
      if (fieldValue && fieldValue.includes(searchLetter)) {
        searchResult.push(data[i]);
        break; // Stop searching other fields for this item if one match is found
      }
    }
  }

  return searchResult;
}