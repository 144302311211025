<template>
  <div class="cont">
    <layout-header></layout-header>
    <layout-sidebar></layout-sidebar>
    <InLoader class="inloader" />

    <div class="page-wrapper">
      <div class="content">
        <div class="page-header">
          <div class="add-item d-flex">
            <div class="page-title">
              <h4>New Product</h4>
              <h6>Create new product</h6>
            </div>
          </div>
          <ul class="table-top-head">
            <li>
              <div class="page-btn">
                <router-link
                  to="/inventory/product-list"
                  class="btn btn-secondary"
                  ><vue-feather type="arrow-left" class="me-2"></vue-feather
                  >Back to Product</router-link
                >
              </div>
            </li>
            <li>
              <a
                ref="collapseHeader"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Collapse"
                @click="toggleCollapse"
              >
                <i data-feather="chevron-up" class="feather-chevron-up"></i>
              </a>
            </li>
          </ul>
        </div>
        <!-- /add -->
        <form @submit.prevent="submitForm">
          <div class="card">
            <div class="card-body add-product pb-0">
              <div class="accordion-card-one accordion" id="accordionExample">
                <div class="accordion-item">
                  <div class="accordion-header" id="headingOne">
                    <div
                      class="accordion-button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-controls="collapseOne"
                    >
                      <div class="addproduct-icon">
                        <h5>
                          <vue-feather
                            type="info"
                            class="add-info"
                          ></vue-feather
                          ><span>Product Information</span>
                        </h5>
                        <a href="javascript:void(0);"
                          ><vue-feather
                            type="chevron-down"
                            class="chevron-down-add"
                          ></vue-feather
                        ></a>
                      </div>
                    </div>
                  </div>
                  <div
                    id="collapseOne"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <div class="row">
                        <div class="col-lg-4 col-sm-6 col-12" hidden>
                          <div class="mb-3 add-product">
                            <label class="form-label">Store</label>
                            <vue-select
                              :options="ThomasStore"
                              id="thomasstore"
                              placeholder="Choose"
                            />
                          </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-12" hidden>
                          <div class="mb-3 add-product">
                            <label class="form-label">Warehouse</label>
                            <vue-select
                              :options="WarhouseStore"
                              id="warehousestore"
                              placeholder="Choose"
                            />
                          </div>
                        </div>

                        <div class="col-lg-4 col-sm-6 col-12">
                          <div class="mb-3 add-product">
                            <label class="form-label">Product Name</label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="name"
                              requ="yes"
                            />
                          </div>
                        </div>

                        <div class="col-lg-4 col-sm-6 col-12" hidden>
                          <div class="input-blocks add-product list">
                            <label>SKU</label>
                            <input
                              type="text"
                              class="form-control list"
                              placeholder="Enter SKU"
                            />
                            <button type="submit" class="btn btn-primaryadd">
                              Generate Code
                            </button>
                          </div>
                        </div>

                        <div class="col-lg-4 col-sm-6 col-12">
                          <div class="mb-3 add-product">
                            <div class="add-newplus">
                              <label class="form-label">Brand</label>
                            </div>
                            <vue-select
                              :options="brands"
                              id="brands"
                              placeholder="Choose"
                              v-model="brand_id"
                              requS="yes"
                            />
                          </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-12" hidden>
                          <div class="mb-3 add-product">
                            <label class="form-label">Selling Type</label>
                            <vue-select
                              :options="SellingType"
                              id="sellingtype"
                              placeholder="Choose"
                              requS="yes"
                            />
                          </div>
                        </div>

                        <div class="col-lg-4 col-sm-6 col-12">
                          <div class="mb-3 add-product">
                            <div class="add-newplus">
                              <label class="form-label">Category</label>
                            </div>
                            <vue-select
                              :options="Category"
                              id="Category"
                              placeholder="Choose"
                              v-model="category_id"
                              requS="yes"
                            />
                          </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-12">
                          <div class="mb-3 add-product">
                            <label class="form-label">Sub Category </label>
                            <vue-select
                              :options="SubCategory"
                              id="subcategory"
                              placeholder="Choose"
                              v-model="subcategory_id"
                              requS="yes"
                            />
                          </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-12">
                          <div class="mb-3 add-product">
                            <label class="form-label">Condition</label>
                            <vue-select
                              :options="Choosecondition"
                              id="choosecode"
                              placeholder="Choose"
                              v-model="condition"
                              requS="yes"
                            />
                          </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-12">
                          <div class="mb-3 add-product">
                            <label class="form-label">Negotiable</label>
                            <vue-select
                              :options="Negotiable"
                              id="subsubcategory"
                              placeholder="Choose"
                              v-model="negotiable"
                            />
                          </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-12">
                          <div class="mb-3 add-product">
                            <label class="form-label"
                              >Are you shipping from abroad?</label
                            >
                            <vue-select
                              :options="Local"
                              id="local"
                              placeholder="Choose"
                              v-model="is_local"
                              requS="yes"
                            />
                          </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-12">
                          <div class="mb-3 add-product">
                            <label class="form-label">Preorder</label>
                            <vue-select
                              :options="Preorder"
                              id="preorder"
                              placeholder="Choose"
                              v-model="preorder_val"
                              requS="yes"
                            />
                          </div>
                        </div>
                        <!--                        
                        <div class="col-lg-4 col-sm-6 col-12">
                          <div class="mb-3 add-product">
                            <div class="add-newplus">
                              <label class="form-label">Unit</label>
                              <a
                                href="javascript:void(0);"
                                data-bs-toggle="modal"
                                data-bs-target="#add-unit"
                                ><vue-feather
                                  type="plus-circle"
                                  class="plus-down-add"
                                ></vue-feather
                                ><span>Add New</span></a
                              >
                            </div>
                            <vue-select
                              :options="brandsNew"
                              id="brandsnew"
                              placeholder="Choose"
                            />
                          </div>
                        </div> -->
                      </div>
                    </div>
                    <!-- <div class="row"></div> -->

                    <!-- Editor -->
                    <div class="col-lg-12">
                      <div class="input-blocks add-product list" id="item_code">
                        <button type="submit" class="btn btn-primaryadd">
                          Generate with Stella
                        </button>
                      </div>

                      <div
                        class="input-blocks summer-description-box transfer mb-3"
                      >
                        <label>Description</label>
                        <textarea
                          class="form-control h-100"
                          rows="5"
                          v-model="description"
                          requ="yes"
                        ></textarea>
                        <p class="mt-1">Maximum 60 Characters</p>
                      </div>
                    </div>
                    <!-- /Editor -->
                  </div>
                </div>
              </div>
              <div class="accordion-card-one accordion" id="accordionExample2">
                <div class="accordion-item">
                  <div class="accordion-header" id="headingTwo">
                    <div
                      class="accordion-button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-controls="collapseTwo"
                    >
                      <div class="text-editor add-list">
                        <div class="addproduct-icon list icon">
                          <h5>
                            <vue-feather
                              type="life-buoy"
                              class="add-info"
                            ></vue-feather
                            ><span>Pricing & Stocks</span>
                          </h5>
                          <a href="javascript:void(0);"
                            ><vue-feather
                              type="chevron-down"
                              class="chevron-down-add"
                            ></vue-feather
                          ></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="collapseTwo"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample2"
                  >
                    <div class="accordion-body">
                      <div class="input-blocks add-products">
                        <label class="d-block">Product Type</label>
                        <div class="single-pill-product">
                          <ul
                            class="nav nav-pills"
                            id="pills-tab1"
                            role="tablist"
                          >
                            <li class="nav-item" role="presentation">
                              <span
                                class="custom_radio me-4 mb-0 active"
                                id="pills-home-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-home"
                                role="tab"
                                aria-controls="pills-home"
                                aria-selected="true"
                              >
                                <input
                                  type="radio"
                                  class="form-control"
                                  name="payment"
                                  requ="yes"
                                />
                                <span class="checkmark"></span> Single
                                Product</span
                              >
                            </li>
                            <li class="nav-item" role="presentation">
                              <span
                                class="custom_radio me-2 mb-0"
                                id="pills-profile-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-profile"
                                role="tab"
                                aria-controls="pills-profile"
                                aria-selected="false"
                              >
                                <input
                                  type="radio"
                                  class="form-control"
                                  name="sign"
                                  requ="yes"
                                />
                                <span class="checkmark"></span> Variable
                                Product</span
                              >
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="tab-content" id="pills-tabContent">
                        <div
                          class="tab-pane fade show active"
                          id="pills-home"
                          role="tabpanel"
                          aria-labelledby="pills-home-tab"
                        >
                          <div class="row">
                            <div class="col-lg-4 col-sm-6 col-12">
                              <div class="input-blocks add-product">
                                <label>Price</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="price"
                                  @keydown="preventNonNumeric"
                                />
                              </div>
                            </div>
                            <div class="col-lg-4 col-sm-6 col-12">
                              <div class="input-blocks add-product">
                                <label>Seling Price</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="selling_price"
                                  @keydown="preventNonNumeric"
                                />
                              </div>
                            </div>
                            <div class="col-lg-4 col-sm-6 col-12">
                              <div class="input-blocks add-product">
                                <label>Quantity</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="quantity"
                                  @keydown="preventNonNumeric"
                                />
                              </div>
                            </div>
                            <div class="col-lg-4 col-sm-6 col-12" hidden>
                              <div class="input-blocks add-product">
                                <label>Quantity Alert</label>
                                <input type="text" class="form-control" />
                              </div>
                            </div>

                            <div class="col-lg-4 col-sm-6 col-12" hidden>
                              <div class="input-blocks add-product">
                                <label>Product Voucher</label>
                                <vue-select
                                  :options="ChooseTypeDis"
                                  id="choosetypedis"
                                  placeholder="Choose"
                                />
                              </div>
                            </div>
                            <div class="col-lg-4 col-sm-6 col-12" hidden>
                              <div class="input-blocks add-product">
                                <label>Discount Value</label>
                                <input type="text" placeholder="Choose" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="tab-pane fade"
                          id="pills-profile"
                          role="tabpanel"
                          aria-labelledby="pills-profile-tab"
                        >
                          <div class="row select-color-add">
                            <div class="col-lg-6 col-sm-6 col-12">
                              <div class="input-blocks add-product">
                                <label>Variant Attribute</label>
                                <div class="row">
                                  <!-- <div class="col-lg-10 col-sm-10 col-10">
                                  <vue-select
                                    :options="VariantChoose"
                                    id="varianchoose"
                                    placeholder="Choose"
                                  />
                                </div> -->
                                  <div class="col-lg-2 col-sm-2 col-2 ps-0">
                                    <div class="add-icon tab">
                                      <a
                                        class="btn btn-filter"
                                        data-bs-toggle="modal"
                                        data-bs-target="#add-units"
                                        ><i
                                          class="feather feather-plus-circle"
                                        ></i
                                      ></a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <!-- {{ variantArray }} -->
                          <div class="modal-body-table" id="variant-table">
                            <div class="table-responsive">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th></th>
                                    <th>Variation</th>
                                    <th>Variant Value</th>
                                    <!-- <th>SKU</th> -->
                                    <th>Quantity</th>
                                    <th>Price</th>
                                    <th>Selling Price</th>
                                    <th class="no-sort">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="added_varaints in variantArray"
                                    :key="added_varaints"
                                  >
                                    <td>
                                      <div>
                                        <span
                                          class="select_img_input"
                                          @click="
                                            listimg(
                                              added_varaints.values.Color.value
                                            )
                                          "
                                          :id="`selector${added_varaints?.values?.Color?.value}`"
                                          >Add Image</span
                                        >

                                        <!-- <span
                                          class="select_img_input"
                                          @click="
                                            select_img_input(
                                              added_varaints.values.Color.value
                                            )
                                          "
                                          :id="`selector${added_varaints.values.Color.value}`"
                                          >Add Image</span
                                        > -->
                                        <!-- <input hidden
                                          type="file"
                                          :id="`upload_variant${added_varaints.values.Color.value}`"
                                          class="upload_variant"
                                          @change="
                                            push_into_array(
                                              $event,
                                              added_varaints.values.Color.value
                                            )
                                          "
                                          accept="image/*"
                                          style="display: none"
                                        /> -->
                                      </div>
                                      <!-- id="variant_pre_img" -->

                                      <img
                                        src=""
                                        alt=""
                                        :class="`variant_thumbnail${added_varaints?.values?.Color?.value} variant_pre_img_add`"
                                        @click="
                                          listimg(
                                            added_varaints.values.Color.value
                                          )
                                        "
                                      />
                                    </td>
                                    <td>
                                      <div
                                        class="add-product"
                                        v-for="v in added_varaints.values"
                                        :key="v"
                                      >
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="v.key"
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      <div
                                        class="add-product"
                                        v-for="v in added_varaints.values"
                                        :key="v"
                                      >
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="v.value"
                                        />
                                      </div>
                                    </td>
                                    <!-- <td>
                                    <div class="add-product">
                                      <input
                                        type="text"
                                        class="form-control"
                                        value="1234"
                                      />
                                    </div>
                                  </td> -->
                                    <td>
                                      <div class="product-quantity">
                                        <span class="quantity-btn"
                                          ><i
                                            data-feather="minus-circle"
                                            class="feather-search"
                                          ></i
                                        ></span>
                                        <input
                                          type="text"
                                          class="quntity-input"
                                          :value="added_varaints.quantity"
                                        />
                                        <span class="quantity-btn"
                                          >+<i
                                            data-feather="plus-circle"
                                            class="plus-circle"
                                          ></i
                                        ></span>
                                      </div>
                                    </td>
                                    <td>
                                      <div class="add-product">
                                        <input
                                          type="text"
                                          class="form-control"
                                          :value="added_varaints.original_price"
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      <div class="add-product">
                                        <input
                                          type="text"
                                          class="form-control"
                                          :value="added_varaints.selling_price"
                                        />
                                      </div>
                                    </td>
                                    <td class="action-table-data">
                                      <div
                                        class="edit-delete-action add-product"
                                        style="
                                          padding-bottom: 40px;
                                          padding-top: 40px;
                                        "
                                      >
                                        <!-- <div class="input-block add-lists">
                                          <label class="checkboxs">
                                            <input type="checkbox" checked />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <a
                                          class="me-2 p-2"
                                          href="javascript:void(0);"
                                          data-bs-toggle="modal"
                                          data-bs-target="#add-variation"
                                        >
                                          <i
                                            data-feather="plus"
                                            class="feather-edit"
                                          ></i>
                                        </a> -->
                                        <a
                                          class="confirm-text p-2"
                                          @click="
                                            showConfirmation(added_varaints.id)
                                          "
                                          href="javascript:void(0);"
                                        >
                                          <i
                                            data-feather="trash-2"
                                            class="feather-trash-2"
                                          ></i>
                                          <!-- {{ added_varaints.id }} -->
                                        </a>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="accordion-card-one accordion"
                        id="accordionExample3"
                      >
                        <div class="accordion-item">
                          <div class="accordion-header" id="headingThree">
                            <div
                              class="accordion-button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseThree"
                              aria-controls="collapseThree"
                            >
                              <div class="addproduct-icon list">
                                <h5>
                                  <vue-feather
                                    type="image"
                                    class="add-info"
                                  ></vue-feather
                                  ><span>Images</span>
                                </h5>
                                <a href="javascript:void(0);"
                                  ><vue-feather
                                    type="chevron-down"
                                    class="chevron-down-add"
                                  ></vue-feather
                                ></a>
                              </div>
                            </div>
                          </div>
                          <div
                            id="collapseThree"
                            class="accordion-collapse collapse show"
                            aria-labelledby="headingThree"
                            data-bs-parent="#accordionExample3"
                          >
                            <div class="accordion-body">
                              <div class="text-editor add-list add">
                                <div class="col-lg-12">
                                  <div class="add-choosen">
                                    <div class="input-blocks">
                                      <button
                                        type="button"
                                        @click="cropper_opener('single')"
                                      >
                                        <div class="image-upload">
                                          <!-- <input
                                            type="file"
                                            id="upload_Thumbnail"
                                            class="upload_Thumbnail"
                                            k="single"
                                          /> -->
                                          <div class="image-uploads">
                                            <i
                                              data-feather="plus-circle"
                                              class="plus-down-add me-0"
                                            ></i>
                                            <h4>Add thumbnail</h4>
                                          </div>
                                        </div>
                                      </button>
                                    </div>

                                    <div class="phone-img" id="uploadd">
                                      <img
                                        src=""
                                        alt="image"
                                        id="preview_thumbnail"
                                        style="height: 100%; width: 100%"
                                      />
                                      <a
                                        href="javascript:void(0);"
                                        @click="clearImageInput1()"
                                        ><vue-feather
                                          type="x"
                                          class="x-square-add remove-product"
                                        ></vue-feather
                                      ></a>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-lg-12">
                                  <div class="add-choosen">
                                    <div class="input-blocks">
                                      <button
                                        type="button"
                                        @click="cropper_opener('multi')"
                                      >
                                        <div class="image-upload">
                                          <!-- <input
                                            type="file"
                                            id="upload_images"
                                            class="upload_images"
                                            @change="handleImagePick($event)"
                                            multiple
                                            accept="image/*"
                                            k="multiple"
                                          /> -->
                                          <div class="image-uploads">
                                            <i
                                              data-feather="plus-circle"
                                              class="plus-down-add me-0"
                                            ></i>
                                            <h4>Add (5) Images</h4>
                                          </div>
                                        </div>
                                      </button>
                                    </div>
                                    <div
                                      class="phone-img"
                                      id="img_upload_prview"
                                      v-for="(image, index) in imagePreviews"
                                      :key="index"
                                    >
                                      <img
                                        :src="image.url"
                                        alt="image"
                                        id="preview"
                                        class="multi-image-display"
                                      />
                                      <a
                                        href="javascript:void(0);"
                                        @click="deleteAvatar(index)"
                                        ><vue-feather
                                          type="x"
                                          class="x-square-add remove-product"
                                        ></vue-feather
                                      ></a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- copied -->
              <div class="accordion-card-one accordion" id="accordionExample5">
                <div class="accordion-item">
                  <div class="accordion-header" id="headingFive">
                    <div
                      class="accordion-button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-controls="collapseFive"
                    >
                      <div class="text-editor add-list">
                        <div class="addproduct-icon list icon">
                          <h5>
                            <vue-feather
                              type="box"
                              class="add-info"
                            ></vue-feather
                            ><span>Package and Shipping</span>
                          </h5>
                          <a href="javascript:void(0);"
                            ><vue-feather
                              type="chevron-down"
                              class="chevron-down-add"
                            ></vue-feather
                          ></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="collapseFive"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingFive"
                    data-bs-parent="#accordionExample5"
                  >
                    <div class="accordion-body">
                      <div class="input-blocks add-products">
                        <label class="d-block">Shippment Type</label>
                        <div class="single-pill-product">
                          <ul
                            class="nav nav-pills"
                            id="pills-tab1"
                            role="tablist"
                          >
                            <li class="nav-item" role="presentation">
                              <span
                                class="custom_radio me-4 mb-0 active gig"
                                id="pills-home-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-home"
                                role="tab"
                                aria-controls="pills-home"
                                aria-selected="true"
                                @click="hide_shipping(false)"
                              >
                                <input
                                  type="radio"
                                  class="form-control"
                                  name="payment"
                                  requ="yes"
                                />
                                <span class="checkmark"></span> GIG</span
                              >
                            </li>
                            <!-- <li class="nav-item" role="presentation">
                              <span
                                class="custom_radio me-2 mb-0 bus_driver"
                                id="bus-driver-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#bus-driver"
                                role="tab"
                                aria-controls=""
                                aria-selected="true"
                                @click="hide_shipping(false)"
                              >
                                <input
                                  type="radio"
                                  class="form-control"
                                  name="sign"
                                  requ="yes"
                                />
                                <span class="checkmark"></span> Bus Driver</span
                              >
                            </li> -->
                            <li class="nav-item" role="presentation">
                              <span
                                class="custom_radio me-4 mb-0 shipping"
                                id="shipping-tab"
                                data-bs-toggle="pill"
                                role="tab"
                                @click="hide_shipping(true)"
                              >
                                <input
                                  type="radio"
                                  class="form-control"
                                  name="sign"
                                  v-model="free_shipping"
                                  value="true"
                                  requ="yes"
                                />
                                <span class="checkmark"></span> Free
                                Shpping</span
                              >
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div
                        class="tab-content"
                        id="pills-tabContent"
                        v-if="!free_shipping"
                      >
                        <div
                          class="tab-pane fade show active"
                          id="pills-home"
                          role="tabpanel"
                          aria-labelledby="pills-home-tab"
                          ref="pills-home"
                        >
                          <div class="row">
                            <div class="col-lg-4 col-sm-6 col-12">
                              <div class="input-blocks add-product">
                                <label>State</label>
                                <vue-select
                                  :options="state_list"
                                  id="chooseState"
                                  placeholder="Choose"
                                  v-model="stateId"
                                  @select="select_Sate_Station()"
                                />
                              </div>
                            </div>
                            <div class="col-lg-4 col-sm-6 col-12">
                              <div class="input-blocks add-product">
                                <label class="station_label">GIG Station</label>
                                <vue-select
                                  :options="New_Choose_GIG_station2"
                                  id="station"
                                  placeholder="Choose"
                                  class="station"
                                  v-model="gigl_station_id"
                                />
                              </div>
                            </div>

                            <div class="col-lg-4 col-sm-6 col-12">
                              <div class="input-blocks add-product">
                                <label>Weight</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="weight"
                                  @keydown="preventNonNumeric"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-4 col-sm-6 col-12">
                              <div class="input-blocks add-product">
                                <label>Height</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="height"
                                  @keydown="preventNonNumeric"
                                />
                              </div>
                            </div>
                            <div class="col-lg-4 col-sm-6 col-12">
                              <div class="input-blocks add-product">
                                <label>Width</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="width"
                                  @keydown="preventNonNumeric"
                                />
                              </div>
                            </div>
                            <div class="col-lg-4 col-sm-6 col-12">
                              <div class="input-blocks add-product">
                                <label>Length</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="length"
                                  @keydown="preventNonNumeric"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- copied -->
              <div
                class="accordion-card-one accordion"
                id="accordionExample4"
                hidden
              >
                <div class="accordion-item">
                  <div class="accordion-header" id="headingFour">
                    <div
                      class="accordion-button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-controls="collapseFour"
                    >
                      <div class="text-editor add-list">
                        <div class="addproduct-icon list">
                          <h5>
                            <vue-feather
                              type="list"
                              class="add-info"
                            ></vue-feather
                            ><span>Specificattion</span>
                          </h5>
                          <a href="javascript:void(0);"
                            ><vue-feather
                              type="chevron-down"
                              class="chevron-down-add"
                            ></vue-feather
                          ></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="collapseFour"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample4"
                  >
                    <div class="accordion-body">
                      <div class="text-editor add-list add">
                        <div class="row rl">
                          <div class="col-lg-2 col-sm-2 col-2 ps-0">
                            <div class="add-icon tab">
                              <a
                                class="btn btn-filter"
                                data-bs-toggle="modal"
                                data-bs-target="#add-units"
                                ><i class="feather feather-plus-circle"></i
                              ></a>
                            </div>
                          </div>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="btn-addproduct mb-4">
              <button type="button" class="btn btn-cancel me-2">Cancel</button>

              <!-- <div class="btn btn-submit" @click="add_product('Draft')">
                Save as Draft
              </div> -->
              <div
                class="btn btn-submit btn-success"
                @click="add_product('Pending')"
                v-if="allFilled"
              >
                Publish Product
              </div>

              <div
                @click="preview()"
                class="btn btn-primary mb-1"
                v-if="imagePreviews.length > 0"
              >
                Preview
              </div>
            </div>
          </div>
        </form>
        <!-- /add -->
      </div>
    </div>
    <add-product-modal
      @Emitter="P_emmiter"
      :variant_imgs_child="variant_imgs"
    ></add-product-modal>

    <ImageCropper />
    <ImageCropperMulti
      @CroppedImageMulti="getMultiimg"
      @CroppedImage="getimg"
      :crop_state="crop_state"
    />
    <ProductPreview :preview="product_preview_object" />

    <!-- <ImageCropperVariant @CroppedImageVariant="getvariant" /> -->

    <!-- <List_images :added_images="{ full_img, color_images, images,imagePreviews,current_colorValue }" /> -->
    <List_images
      :added_images="{ full_img, color_images, current_colorValue }"
      @list_images="get_list_images"
    />
  </div>
</template>
<script>
import { ref } from "vue";
const currentDate = ref(new Date());
const currentDateOne = ref(new Date());
import Vue3TagsInput from "vue3-tags-input";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import { mapActions } from "vuex";
import state from "@/store/state";

export default {
  components: {
    Vue3TagsInput,
  },
  data() {
    return {
      isVisible: true,
      isVisible1: true,
      // Distype: ["Choose", "Percentage", "Cash"],
      VariantChoose: ["Choose", "Color", "Red", "Black"],
      ChooseTypeDis: ["Choose", "Free Shippment", "Discount"],
      ExclusiveType: ["Exclusive", "Sales Tax"],
      ChooseCode: ["Choose", "Code34", "Code35", "Code36"],
      SellingType: ["Choose", "Transactional selling", "Solution selling"],
      brandsNew: ["Choose", "Kg", "Pc"],
      SubSubCategory: ["Choose", "Fruits", "Computers", "Shoes"],
      WarhouseStore: ["Choose", "Legendary", "Determined", "Sincere"],
      ThomasStore: ["Choose", "Thomas", "Rasmussen", "Fred john"],
      tags: ["red", "black"],
      state_list: ["Choose"],
      Choose_GIG_station: ["Choose"],
      startdate: currentDate,
      startdateOne: currentDateOne,
      dateFormat: "dd-MM-yyyy",
      // my edited data set
      SubCategory: ["Choose"],
      Category: ["Choose"],
      Choosecondition: ["New", "Used", "None"],
      Negotiable: [
        { id: 1, text: "Yes" },
        { id: 0, text: "No" },
      ],
      Local: [
        { id: 1, text: "Yes" },
        { id: 0, text: "No" },
      ],
      Preorder: [
        { id: 1, text: "Yes" },
        { id: 0, text: "No" },
      ],
      // my data
      condition: null,
      description: "",
      free_shipping: null,
      hasVariants: null,
      images: [],
      imagePreviews: [],
      name: "",
      negotiable: null,
      pre_order: null,
      price: null, //null
      selling_price: null,
      quantity: null,
      specification: null,
      // status: null,
      category_id: null,
      subcategory_id: null,
      thumbnail: null,
      variants: null,
      weight: null,
      height: null,
      width: null,
      length: null,
      video: null,
      gigl_station_id: "",
      response: "",
      subcats: [],
      stateId: null,
      New_Choose_GIG_station: [],
      New_Choose_GIG_station2: [],
      variantArray: [],
      product_preview_object: {},
      image_src: "",
      variant_imgs: {},
      color_images: [],
      is_local: null,
      brands: [],
      brand_id: null,
      free_shipping: null,
      preorder_val: null,
      allFilled: null,
      full_img: [],
      current_colorValue: "",
      crop_state: "",
    };
  },

  methods: {
    cropper_opener(state) {
      this.crop_state = state;
      // alert(this.crop_state);

      $("#EditMultiImage").modal("show");
    },
    get_list_images(message) {
      const { remainingBlobs, remainingimgpreview, added_color_images } =
        message;
      this.images = remainingBlobs;
      this.imagePreviews = remainingimgpreview;
      this.color_images = added_color_images;
    },
    validateForm() {
      const inputs = document.querySelectorAll('[requS="yes"],[requ="yes"]');
      this.allFilled = true;

      inputs.forEach((input) => {
        // console.log(inputs);
        if (input.type === "text") {
          if (input.value.trim() === "") {
            this.allFilled = false;
          }
        } else if (input.tagName === "SELECT") {
          if (input.value === "") {
            this.allFilled = false;
          }
        }
      });

      // submitButton.classList.toggle('hidden', !allFilled);
    },
    // push_into_array(event, colorValue) {
    //   var variant_preview = document.getElementById(
    //     `variant_thumbnail${colorValue}`
    //   );
    //   var selector = document.getElementById(`selector${colorValue}`);
    //   var file = event.target.files[0];
    //   var reader = new FileReader();

    //   reader.onload = function (e) {
    //     variant_preview.src = e.target.result;
    //     variant_preview.style = "display:inline-block";
    //     selector.style = "display:none";
    //   };

    //   if (file) {
    //     reader.readAsDataURL(file);
    //   }

    //   this.color_images.push({
    //     color: colorValue,
    //     image: file,
    //   });

    //   console.log(this.color_images);
    // },
    // select_img_input(colorValue) {
    //   document.getElementById(`upload_variant${colorValue}`).click();
    // },
    handleImagePick(event) {
      const files = event.target.files;
      for (const file of files) {
        this.images.push(file);
        const preview = URL.createObjectURL(file);
        this.imagePreviews.push({ img: preview });
      }
    },
    image_procesor(image) {
      if (image) {
        const imageURL = URL.createObjectURL(image);
        return imageURL;
      }
    },
    preview() {
      const array = {
        description: this.description,
        image: this.image_src,
        imagePreviews: this.imagePreviews,
        name: this.name,
        price: this.price,
        selling_price: this.selling_price,
        quantity: this.quantity,
        specification: this.specification,
        weight: this.weight,
      };

      this.product_preview_object = {
        main_product: array,
        color_images: this.color_images,
      };
      $("#previewModal").modal("show");
      console.log(this.product_preview_object);
    },
    listimg(colorValue) {
      if (this.imagePreviews.length != 0) {
        $("#List_images").modal("show");
        this.current_colorValue = colorValue;
      } else {
        toast.error("You have not added any image yet.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      // alert(this.current_colorValue)
    },
    getimg(img) {
      const { image, imageBlob } = img;
      this.thumbnail = imageBlob;
      this.image_src = image;
    },
    getMultiimg(payload) {
      const { croppedImg, blobImg, full_img } = payload;
      this.imagePreviews = croppedImg;
      this.images = blobImg;
      this.full_img = full_img;

      console.log(this.imagePreviews);
    },
    getvariant(message) {
      // const { croppedImg, blobImg } = message;
      this.variant_imgs = message;
    },
    P_emmiter(message) {
      console.log(message);
      this.variantArray = message;
    },
    ...mapActions({
      _add_product: "product/add_product",
      _get_subcategories: "product/get_subcategories",
      _get_states: "product/get_states",
      _get_brands: "product/get_brands",
      _get_gig: "product/get_gig",
      _get_variant_keys: "general/get_variant_keys",
    }),
    add_product(status) {
      this.validateForm();
      if (this.allFilled == true) {
        $(".inloader").show();

        this._add_product({
          condition: this.condition,
          description: this.description,
          hasVariants: this.hasVariants,
          images: this.images,
          name: this.name,
          negotiable: this.negotiable,
          pre_order: this.pre_order,
          price: this.price,
          selling_price: this.selling_price,
          quantity: this.quantity,
          specification: this.specification,
          status: status,
          subcategory_id: this.subcategory_id,
          thumbnail: this.thumbnail,
          variants: this.variants,
          weight: this.weight,
          height: this.height,
          width: this.width,
          length: this.length,
          video: this.video,
          gigl_station_id: this.gigl_station_id,
          variants: this.variantArray,
          color_images: this.color_images,
          brand_id: this.brand_id,
          free_shipping: this.free_shipping,
          preorder: this.preorder_val,
          is_local: this.is_local,
        })
          .then((response) => {
            $(".inloader").hide();
            toast.success("Product Added", {
              position: toast.POSITION.TOP_RIGHT,
            });
            this.response = response.data;
            //   this.response
            if (this.response["status"] == true) {
              var data = this.response.data;

              window.location = "/inventory/product-list";
            }
          })
          .catch((error) => {
            $(".inloader").hide();

            console.log("Error logging in", error);
            if (error.response) {
              const status = error.response.status;
              const data = error.response.data;
              if (
                status == 400 ||
                status == 422 ||
                status == 401 ||
                status == 404
              ) {
                toast.error(error.response.data.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              } else if (status === 500) {
                toast.error("Network Error, Check your internet conectivity.", {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }
            }
          });
      } else {
        toast.error("Inputs cannot be empty", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    },

    get_subcategories() {
      this._get_subcategories()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            const data = this.response.data;
            console.log(data);
            this.Category = data.map((item) => ({
              id: item.id,
              text: item.title,
              subcategories: item.subcategories.map((subcat) => ({
                id: subcat.id,
                text: subcat.title,
              })),
            }));
            console.log(this.Category, "lm");
          }
        })
        .catch((error) => {
          console.log("Error logging in", error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;
            if (
              status == 400 ||
              status == 422 ||
              status == 401 ||
              status == 404
            ) {
            } else if (status === 500) {
            }
          }
        });
    },
    get_states() {
      this._get_states()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            const data = this.response.data;
            console.log(data);
            this.state_list = data.map((item) => ({
              id: item.id,
              text: item.name,
            }));
            console.log(this.state_list, "lm");
          }
        })
        .catch((error) => {
          console.log("Error logging in", error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;
            if (
              status == 400 ||
              status == 422 ||
              status == 401 ||
              status == 404
            ) {
            } else if (status === 500) {
            }
          }
        });
    },
    get_gig() {
      this._get_gig()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            const data = this.response.data;

            this.Choose_GIG_station = data;
            // this.state_list = data.map((item) => ({
            //   id: item.id,
            //   text: item.title,
            // }));
          }
        })
        .catch((error) => {
          console.log("Error logging in", error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;
            if (
              status == 400 ||
              status == 422 ||
              status == 401 ||
              status == 404
            ) {
            } else if (status === 500) {
            }
          }
        });
    },
    select_Sate_Station() {
      this.New_Choose_GIG_station = this.Choose_GIG_station.filter(
        (item) => item.station.state_id == this.stateId
      );
      // alert(this.New_Choose_GIG_station);
      this.New_Choose_GIG_station2 = this.New_Choose_GIG_station.map(
        (item) => ({
          id: item.station_id,
          text: item.address,
        })
      );
    },

    deleteAvatar(index) {
      this.imagePreviews.splice(index, 1);
      // this.avatars[index].splice(index, 1);
      for (let i = 0; i < this.images.length; i++) {
        if (i === index) {
          this.images.splice(i, 1);
          break;
        }
      }
    },

    clearImageInput1() {
      // var input = document.getElementById("upload_Thumbnail");
      // var file = input.files[0];
      // this.thumbnail = file;
      // this.thumbnail = null;
      var dk = document.getElementById("uploadd");
      dk.style = "display:none";
    },

    hide_shipping(bo) {
      if (bo == true) {
        this.free_shipping = 1;
      } else if (bo == false) {
        this.free_shipping = 0;
      }
    },

    get_brands() {
      this._get_brands()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            const data = this.response.data;
            console.log(data);
            this.brands = data.map((item) => ({
              id: item.id,
              text: item.name,
            }));
            console.log(this.brands, "lm");
          }
        })
        .catch((error) => {
          console.log("Error logging in", error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;
            if (
              status == 400 ||
              status == 422 ||
              status == 401 ||
              status == 404
            ) {
            } else if (status === 500) {
            }
          }
        });
    },
    toggleCollapse() {
      const collapseHeader = this.$refs.collapseHeader;

      if (collapseHeader) {
        collapseHeader.classList.toggle("active");
        document.body.classList.toggle("header-collapse");
      }
    },
    preventNonNumeric(event) {
      // Allow only number keys (0-9) and special keys (Backspace, Delete, Arrow keys)
      const allowedKeys = [
        "Backspace",
        "Delete",
        "ArrowLeft",
        "ArrowRight",
        "Tab",
      ];

      if (
        (event.key < "0" || event.key > "9") &&
        !allowedKeys.includes(event.key)
      ) {
        event.preventDefault(); // Block non-numeric keys
      }
    },
    showConfirmation(id, type) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Aprrove",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value == true) {
          // Find the index of the item with the specified id
          const index = this.variantArray.findIndex(
            (product) => product.id === id
          );

          if (index !== -1) {
            // Remove the item from the array
            this.variantArray.splice(index, 1);
          }
          Swal.fire({
            icon: "success",
            title: "Aprroved",
            text: "Request Sent.",
            confirmButtonClass: "btn btn-success",
          });
        }
      });
    },
  },

  mounted() {
    this.get_states();
    this.get_subcategories();
    this.get_brands();
    // this.loadFileInputs();
    this.get_gig();

    $(".bus_driver").click(function () {
      $("#station").attr("disabled", "disabled");
      $(".station_label").text("Station");
    });
    $(".gig").click(function () {
      $("#station").removeAttr("disabled");
      $(".station_label").text("GIG Station");
    });

    $(".bus_driver").click(function () {
      $("#station").attr("disabled", "disabled");
    });

    const inputs = document.querySelectorAll("input, select");

    inputs.forEach((input) => {
      input.addEventListener("input", this.validateForm);
      input.addEventListener("change", this.validateForm);
    });

    // Initial validation check
    this.validateForm();

    //   $("input[k=single]").change(function () {
    //     var input = document.getElementById("upload_Thumbnail");
    //     var dk = document.getElementById("uploadd");
    //     var preview = document.getElementById("preview_thumbnail");
    //     var file = input.files[0];
    //     // this.thumbnail = file

    //     var reader = new FileReader();

    //     reader.onload = function (e) {
    //       preview.src = e.target.result;
    //       dk.style = "display:block";

    //       // alert(this.previewer);
    //     };

    //     if (file) {
    //       reader.readAsDataURL(file);
    //     }
    // });
  },
  watch: {
    category_id: {
      handler(newId) {
        this.SubCategory = this.Category.find(
          (category) => category.id == newId
        ).subcategories;
      },
    },
  },
};
</script>
<style>
#item_code {
  position: relative;
  z-index: 999;
  left: 87.2%;
  width: 200px;
  cursor: pointer;
}
.rl {
  padding-left: 10px;
}
#uploadd {
  display: none;
}
.btn-submit {
  margin-right: 5px;
}
.add-icon {
  margin-left: 10%;
}

button {
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: white;
}
.multi-image-display {
  height: 100%;
  width: 100%;
}

.variant_pre_img_add {
  width: 80px; /* Adjust the size as needed */
  height: 60px; /* Adjust the size as needed */
  object-fit: cover; /* Ensure the image covers the entire area without distortion */
  border-radius: 8px; /* Optional: round the corners of the images */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: add a subtle shadow for a nicer look */
  display: none;
  cursor: pointer;
}
/* #upload_variant {
  display: none;
} */
.select_img_input {
  cursor: pointer;
}
</style>
