<template>
  <!-- Add Bank Account -->
  <div class="modal fade" id="add-account">
    <div class="modal-dialog modal-dialog-centered custom-modal-two">
      <div class="modal-content">
        <div class="page-wrapper-new p-0">
          <div class="content">
            <div class="modal-header border-0 custom-modal-header">
              <div class="page-title">
                <h4>Add Bank Account</h4>
              </div>

              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body custom-modal-body">
              <form @submit.prevent="submitForm">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="mb-3">
                      <label class="form-label"
                        >Bank Name <span> *</span></label
                      >
                      <select
                        name=""
                        id=""
                        v-model="bank_code"
                        class="form-control select"
                      >
                        <option
                          :value="bank.code"
                          v-for="bank in banks"
                          :key="bank.id"
                        >
                          {{ bank.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="mb-3">
                      <label class="form-label"
                        >Account Number <span> *</span></label
                      >
                      <input
                        type="text"
                        class="form-control"
                        style="height: 50px"
                        v-model="account_number"
                      />
                    </div>
                  </div>

                  <div class="col-lg-12">
                    <div
                      class="status-toggle modal-status d-flex justify-content-between align-items-center"
                    >
                      <span class="status-label">Make as default</span>
                      <input
                        type="checkbox"
                        id="user3"
                        class="check"
                        checked=""
                      />
                      <label for="user3" class="checktoggle"></label>
                    </div>
                  </div>
                </div>
                <div class="modal-footer-btn">
                  <button
                    type="button"
                    class="btn btn-cancel me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <div class="btn btn-submit" @click="add_bank_account()">
                    Save Changes
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Bank Account -->
  <!-- Edit Bank Account -->
  <div class="modal fade" id="edit-account">
    <div class="modal-dialog modal-dialog-centered custom-modal-two">
      <div class="modal-content">
        <div class="page-wrapper-new p-0">
          <div class="content">
            <div class="modal-header border-0 custom-modal-header">
              <div class="page-title">
                <h4>Edit Bank Account</h4>
              </div>

              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body custom-modal-body">
              <form @submit.prevent="submitForm">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="mb-3">
                      <label class="form-label"
                        >Bank Name <span> *</span></label
                      >
                      <select
                        name=""
                        id=""
                        v-model="edit_bank_code"
                        class="form-control select"
                      >
                        <option
                          :value="bank.code"
                          v-for="bank in banks"
                          :key="bank.id"
                        >
                          {{ bank.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="mb-3">
                      <label class="form-label"
                        >Account Number <span> *</span></label
                      >
                      <input
                        type="text"
                        class="form-control"
                        style="height: 50px"
                        v-model="edit_account_number"
                      />
                    </div>
                  </div>

                  <div class="col-lg-12">
                    <div
                      class="status-toggle modal-status d-flex justify-content-between align-items-center"
                    >
                      <span class="status-label">Make as default</span>
                      <input type="checkbox" id="user6" class="check" />
                      <label for="user6" class="checktoggle"></label>
                    </div>
                  </div>
                </div>
                <div class="modal-footer-btn">
                  <button
                    type="button"
                    class="btn btn-cancel me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <div class="btn btn-submit" @click="edit_bank_account()">
                    Save Changes
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Edit Bank Account -->
</template>
<script>
import { mapActions } from "vuex";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
export default {
  data() {
    return {
      banks: [],
      account_number: "",
      bank_code: "",
      edit_data:JSON.parse(localStorage.getItem('Edit_data')),
      edit_account_number: JSON.parse(localStorage.getItem('Edit_data'))?.account_number,
      edit_bank_code: JSON.parse(localStorage.getItem('Edit_data'))?.bank_code,
      edit_id: JSON.parse(localStorage.getItem('Edit_data'))?.id,
      data: [],
    };
  },
  methods: {
    ...mapActions({
      _get_banks: "general/get_banks",
      _add_bank_account: "general/add_bank_account",
      _edit_bank_account: "general/edit_bank_account",
    }),

    get_banks() {
      this._get_banks()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.banks = this.response.data;
            console.log(data);
          }
        })
        .catch((error) => {
          console.log("Error logging in", error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;
            if (
              status == 400 ||
              status == 422 ||
              status == 401 ||
              status == 404
            ) {
            } else if (status === 500) {
            }
          }
        });
    },
    add_bank_account() {
      $(".inloader").show();

      this._add_bank_account({
        account_number: this.account_number,
        bank_code: this.bank_code,
      })
        .then((response) => {
          $(".inloader").hide();
          toast.success("Bank Account Added", {
            position: toast.POSITION.TOP_RIGHT,
          });
          window.location.reload();
          this.response = response.data;
          //   this.response
          if (this.response["status"] == true) {
            var data = this.response.data;

            // window.location = "/inventory/product-list";
          }
        })
        .catch((error) => {
          $(".inloader").hide();

          console.log("Error logging in", error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;
            if (
              status == 400 ||
              status == 422 ||
              status == 401 ||
              status == 404
            ) {
              toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            } else if (status === 500) {
              toast.error("Network Error, Check your internet conectivity.", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          }
        });
    },
    edit_bank_account() {
      $(".inloader").show();
      this._edit_bank_account({
        id: this.edit_id,
        payload: {
          account_number: this.edit_account_number,
          bank_code: this.edit_bank_code,
        },
      })
        .then((response) => {
          $(".inloader").hide();
          $("#edit-units").modal("hide");

          toast.success("Store Admin Updated", {
            position: toast.POSITION.TOP_RIGHT,
          });
          this.response = response.data;
          //   this.response
          if (this.response["status"] == true) {
            var data = this.response.data;
            // this.sent = true;
            this.get_user();
          }
        })
        .catch((error) => {
          $(".inloader").hide();

          console.log("Error logging in", error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;
            if (
              status == 400 ||
              status == 422 ||
              status == 401 ||
              status == 404
            ) {
              toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            } else if (status === 500) {
              toast.error("Network Error, Check your internet conectivity.", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          }
        });
    },
    submitForm() {
      this.$router.push("/bank-settings-grid");
    },
  },
  mounted() {
    this.get_banks();
  },
};
</script>
