<template>
  <!-- Main Wrapper -->
  
    <layout-header></layout-header>
    <layout-sidebar></layout-sidebar>

    <!-- Page Wrapper -->
    <div class="page-wrapper">
      <div class="content container-fluid">
        <div class="row">
          <!-- Chart -->
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">Flag Icons</h5>
              </div>
              <div class="card-body">
                <div class="icons-items">
                  <ul class="icons-list">
                    <li>
                      <flag iso="ad" data-bs-toggle="tooltip" title="flag flag-ad" />
                    </li>
                    <li>
                      <flag iso="ae" data-bs-toggle="tooltip" title="flag flag-ae" />
                    </li>
                    <li>
                      <flag iso="af" data-bs-toggle="tooltip" title="flag flag-af" />
                    </li>
                    <li>
                      <flag iso="ag" data-bs-toggle="tooltip" title="flag flag-ag" />
                    </li>
                    <li>
                      <flag iso="ai" data-bs-toggle="tooltip" title="flag flag-ai" />
                    </li>
                    <li>
                      <flag iso="al" data-bs-toggle="tooltip" title="flag flag-al" />
                    </li>
                    <li>
                      <flag iso="am" data-bs-toggle="tooltip" title="flag flag-am" />
                    </li>
                    <li>
                      <flag iso="ao" data-bs-toggle="tooltip" title="flag flag-ao" />
                    </li>
                    <li>
                      <flag iso="ar" data-bs-toggle="tooltip" title="flag flag-ar" />
                    </li>
                    <li>
                      <flag iso="as" data-bs-toggle="tooltip" title="flag flag-as" />
                    </li>
                    <li>
                      <flag iso="at" data-bs-toggle="tooltip" title="flag flag-at" />
                    </li>
                    <li>
                      <flag iso="au" data-bs-toggle="tooltip" title="flag flag-au" />
                    </li>
                    <li>
                      <flag iso="aw" data-bs-toggle="tooltip" title="flag flag-aw" />
                    </li>
                    <li>
                      <flag iso="ax" data-bs-toggle="tooltip" title="flag flag-ax" />
                    </li>
                    <li>
                      <flag iso="az" data-bs-toggle="tooltip" title="flag flag-az" />
                    </li>
                    <li>
                      <flag iso="ba" data-bs-toggle="tooltip" title="flag flag-ba" />
                    </li>
                    <li>
                      <flag iso="bb" data-bs-toggle="tooltip" title="flag flag-bb" />
                    </li>
                    <li>
                      <flag iso="bd" data-bs-toggle="tooltip" title="flag flag-bd" />
                    </li>
                    <li>
                      <flag iso="be" data-bs-toggle="tooltip" title="flag flag-be" />
                    </li>
                    <li>
                      <flag iso="bf" data-bs-toggle="tooltip" title="flag flag-bf" />
                    </li>
                    <li>
                      <flag iso="bg" data-bs-toggle="tooltip" title="flag flag-bg" />
                    </li>
                    <li>
                      <flag iso="bh" data-bs-toggle="tooltip" title="flag flag-bh" />
                    </li>
                    <li>
                      <flag iso="bi" data-bs-toggle="tooltip" title="flag flag-bi" />
                    </li>
                    <li>
                      <flag iso="bj" data-bs-toggle="tooltip" title="flag flag-bj" />
                    </li>
                    <li>
                      <flag iso="bl" data-bs-toggle="tooltip" title="flag flag-bl" />
                    </li>
                    <li>
                      <flag iso="bm" data-bs-toggle="tooltip" title="flag flag-bm" />
                    </li>
                    <li>
                      <flag iso="bn" data-bs-toggle="tooltip" title="flag flag-bn" />
                    </li>
                    <li>
                      <flag iso="bo" data-bs-toggle="tooltip" title="flag flag-bo" />
                    </li>
                    <li>
                      <flag iso="bq" data-bs-toggle="tooltip" title="flag flag-bq" />
                    </li>
                    <li>
                      <flag iso="br" data-bs-toggle="tooltip" title="flag flag-br" />
                    </li>
                    <li>
                      <flag iso="bs" data-bs-toggle="tooltip" title="flag flag-bs" />
                    </li>
                    <li>
                      <flag iso="bt" data-bs-toggle="tooltip" title="flag flag-bt" />
                    </li>
                    <li>
                      <flag iso="bv" data-bs-toggle="tooltip" title="flag flag-bv" />
                    </li>
                    <li>
                      <flag iso="bw" data-bs-toggle="tooltip" title="flag flag-bw" />
                    </li>
                    <li>
                      <flag iso="by" data-bs-toggle="tooltip" title="flag flag-by" />
                    </li>
                    <li>
                      <flag iso="bz" data-bs-toggle="tooltip" title="flag flag-bz" />
                    </li>
                    <li>
                      <flag iso="ca" data-bs-toggle="tooltip" title="flag flag-ca" />
                    </li>
                    <li>
                      <flag iso="cc" data-bs-toggle="tooltip" title="flag flag-cc" />
                    </li>
                    <li>
                      <flag iso="cd" data-bs-toggle="tooltip" title="flag flag-cd" />
                    </li>
                    <li>
                      <flag iso="cf" data-bs-toggle="tooltip" title="flag flag-cf" />
                    </li>
                    <li>
                      <flag iso="cg" data-bs-toggle="tooltip" title="flag flag-cg" />
                    </li>
                    <li>
                      <flag iso="ch" data-bs-toggle="tooltip" title="flag flag-ch" />
                    </li>
                    <li>
                      <flag iso="ci" data-bs-toggle="tooltip" title="flag flag-ci" />
                    </li>
                    <li>
                      <flag iso="ck" data-bs-toggle="tooltip" title="flag flag-ck" />
                    </li>
                    <li>
                      <flag iso="cl" data-bs-toggle="tooltip" title="flag flag-cl" />
                    </li>
                    <li>
                      <flag iso="cm" data-bs-toggle="tooltip" title="flag flag-cm" />
                    </li>
                    <li>
                      <flag iso="cn" data-bs-toggle="tooltip" title="flag flag-cn" />
                    </li>
                    <li>
                      <flag iso="co" data-bs-toggle="tooltip" title="flag flag-co" />
                    </li>
                    <li>
                      <flag iso="cr" data-bs-toggle="tooltip" title="flag flag-cr" />
                    </li>
                    <li>
                      <flag iso="cu" data-bs-toggle="tooltip" title="flag flag-cu" />
                    </li>
                    <li>
                      <flag iso="cv" data-bs-toggle="tooltip" title="flag flag-cv" />
                    </li>
                    <li>
                      <flag iso="cw" data-bs-toggle="tooltip" title="flag flag-cw" />
                    </li>
                    <li>
                      <flag iso="cx" data-bs-toggle="tooltip" title="flag flag-cx" />
                    </li>
                    <li>
                      <flag iso="cy" data-bs-toggle="tooltip" title="flag flag-cy" />
                    </li>
                    <li>
                      <flag iso="cz" data-bs-toggle="tooltip" title="flag flag-cz" />
                    </li>
                    <li>
                      <flag iso="de" data-bs-toggle="tooltip" title="flag flag-de" />
                    </li>
                    <li>
                      <flag iso="dj" data-bs-toggle="tooltip" title="flag flag-dj" />
                    </li>
                    <li>
                      <flag iso="dk" data-bs-toggle="tooltip" title="flag flag-dk" />
                    </li>
                    <li>
                      <flag iso="dm" data-bs-toggle="tooltip" title="flag flag-dm" />
                    </li>
                    <li>
                      <flag iso="do" data-bs-toggle="tooltip" title="flag flag-do" />
                    </li>
                    <li>
                      <flag iso="dz" data-bs-toggle="tooltip" title="flag flag-dz" />
                    </li>
                    <li>
                      <flag iso="ec" data-bs-toggle="tooltip" title="flag flag-ec" />
                    </li>
                    <li>
                      <flag iso="ee" data-bs-toggle="tooltip" title="flag flag-ee" />
                    </li>
                    <li>
                      <flag iso="eg" data-bs-toggle="tooltip" title="flag flag-eg" />
                    </li>
                    <li>
                      <flag iso="er" data-bs-toggle="tooltip" title="flag flag-er" />
                    </li>
                    <li>
                      <flag iso="es" data-bs-toggle="tooltip" title="flag flag-es" />
                    </li>
                    <li>
                      <flag iso="et" data-bs-toggle="tooltip" title="flag flag-et" />
                    </li>
                    <li>
                      <flag iso="fi" data-bs-toggle="tooltip" title="flag flag-fi" />
                    </li>
                    <li>
                      <flag iso="fj" data-bs-toggle="tooltip" title="flag flag-fj" />
                    </li>
                    <li>
                      <flag iso="fk" data-bs-toggle="tooltip" title="flag flag-fk" />
                    </li>
                    <li>
                      <flag iso="fm" data-bs-toggle="tooltip" title="flag flag-fm" />
                    </li>
                    <li>
                      <flag iso="fo" data-bs-toggle="tooltip" title="flag flag-fo" />
                    </li>
                    <li>
                      <flag iso="fr" data-bs-toggle="tooltip" title="flag flag-fr" />
                    </li>
                    <li>
                      <flag iso="ga" data-bs-toggle="tooltip" title="flag flag-ga" />
                    </li>
                    <li>
                      <flag iso="gb" data-bs-toggle="tooltip" title="flag flag-gb" />
                    </li>
                    <li>
                      <flag iso="gd" data-bs-toggle="tooltip" title="flag flag-gd" />
                    </li>
                    <li>
                      <flag iso="ge" data-bs-toggle="tooltip" title="flag flag-ge" />
                    </li>
                    <li>
                      <flag iso="gf" data-bs-toggle="tooltip" title="flag flag-gf" />
                    </li>
                    <li>
                      <flag iso="gg" data-bs-toggle="tooltip" title="flag flag-gg" />
                    </li>
                    <li>
                      <flag iso="gh" data-bs-toggle="tooltip" title="flag flag-gh" />
                    </li>
                    <li>
                      <flag iso="gi" data-bs-toggle="tooltip" title="flag flag-gi" />
                    </li>
                    <li>
                      <flag iso="gl" data-bs-toggle="tooltip" title="flag flag-gl" />
                    </li>
                    <li>
                      <flag iso="gm" data-bs-toggle="tooltip" title="flag flag-gm" />
                    </li>
                    <li>
                      <flag iso="gn" data-bs-toggle="tooltip" title="flag flag-gn" />
                    </li>
                    <li>
                      <flag iso="gp" data-bs-toggle="tooltip" title="flag flag-gp" />
                    </li>
                    <li>
                      <flag iso="gq" data-bs-toggle="tooltip" title="flag flag-gq" />
                    </li>
                    <li>
                      <flag iso="gr" data-bs-toggle="tooltip" title="flag flag-gr" />
                    </li>
                    <li>
                      <flag iso="gs" data-bs-toggle="tooltip" title="flag flag-gs" />
                    </li>
                    <li>
                      <flag iso="gt" data-bs-toggle="tooltip" title="flag flag-gt" />
                    </li>
                    <li>
                      <flag iso="gu" data-bs-toggle="tooltip" title="flag flag-gu" />
                    </li>
                    <li>
                      <flag iso="gw" data-bs-toggle="tooltip" title="flag flag-gw" />
                    </li>
                    <li>
                      <flag iso="gy" data-bs-toggle="tooltip" title="flag flag-gy" />
                    </li>
                    <li>
                      <flag iso="hk" data-bs-toggle="tooltip" title="flag flag-hk" />
                    </li>
                    <li>
                      <flag iso="hn" data-bs-toggle="tooltip" title="flag flag-hn" />
                    </li>
                    <li>
                      <flag iso="hr" data-bs-toggle="tooltip" title="flag flag-hr" />
                    </li>
                    <li>
                      <flag iso="ht" data-bs-toggle="tooltip" title="flag flag-ht" />
                    </li>
                    <li>
                      <flag iso="hu" data-bs-toggle="tooltip" title="flag flag-hu" />
                    </li>
                    <li>
                      <flag iso="id" data-bs-toggle="tooltip" title="flag flag-id" />
                    </li>
                    <li>
                      <flag iso="ie" data-bs-toggle="tooltip" title="flag flag-ie" />
                    </li>
                    <li>
                      <flag iso="il" data-bs-toggle="tooltip" title="flag flag-il" />
                    </li>
                    <li>
                      <flag iso="im" data-bs-toggle="tooltip" title="flag flag-im" />
                    </li>
                    <li>
                      <flag iso="in" data-bs-toggle="tooltip" title="flag flag-in" />
                    </li>
                    <li>
                      <flag iso="io" data-bs-toggle="tooltip" title="flag flag-io" />
                    </li>
                    <li>
                      <flag iso="iq" data-bs-toggle="tooltip" title="flag flag-iq" />
                    </li>
                    <li>
                      <flag iso="ir" data-bs-toggle="tooltip" title="flag flag-ir" />
                    </li>
                    <li>
                      <flag iso="is" data-bs-toggle="tooltip" title="flag flag-is" />
                    </li>
                    <li>
                      <flag iso="it" data-bs-toggle="tooltip" title="flag flag-it" />
                    </li>
                    <li>
                      <flag iso="je" data-bs-toggle="tooltip" title="flag flag-je" />
                    </li>
                    <li>
                      <flag iso="jm" data-bs-toggle="tooltip" title="flag flag-jm" />
                    </li>
                    <li>
                      <flag iso="jo" data-bs-toggle="tooltip" title="flag flag-jo" />
                    </li>
                    <li>
                      <flag iso="jp" data-bs-toggle="tooltip" title="flag flag-jp" />
                    </li>
                    <li>
                      <flag iso="ke" data-bs-toggle="tooltip" title="flag flag-ke" />
                    </li>
                    <li>
                      <flag iso="kg" data-bs-toggle="tooltip" title="flag flag-kg" />
                    </li>
                    <li>
                      <flag iso="kh" data-bs-toggle="tooltip" title="flag flag-kh" />
                    </li>
                    <li>
                      <flag iso="ki" data-bs-toggle="tooltip" title="flag flag-ki" />
                    </li>
                    <li>
                      <flag iso="km" data-bs-toggle="tooltip" title="flag flag-km" />
                    </li>
                    <li>
                      <flag iso="kn" data-bs-toggle="tooltip" title="flag flag-kn" />
                    </li>
                    <li>
                      <flag iso="kp" data-bs-toggle="tooltip" title="flag flag-kp" />
                    </li>
                    <li>
                      <flag iso="kr" data-bs-toggle="tooltip" title="flag flag-kr" />
                    </li>
                    <li>
                      <flag iso="kw" data-bs-toggle="tooltip" title="flag flag-kw" />
                    </li>
                    <li>
                      <flag iso="ky" data-bs-toggle="tooltip" title="flag flag-ky" />
                    </li>
                    <li>
                      <flag iso="kz" data-bs-toggle="tooltip" title="flag flag-kz" />
                    </li>
                    <li>
                      <flag iso="la" data-bs-toggle="tooltip" title="flag flag-la" />
                    </li>
                    <li>
                      <flag iso="lb" data-bs-toggle="tooltip" title="flag flag-lb" />
                    </li>
                    <li>
                      <flag iso="lc" data-bs-toggle="tooltip" title="flag flag-lc" />
                    </li>
                    <li>
                      <flag iso="li" data-bs-toggle="tooltip" title="flag flag-li" />
                    </li>
                    <li>
                      <flag iso="lk" data-bs-toggle="tooltip" title="flag flag-lk" />
                    </li>
                    <li>
                      <flag iso="lr" data-bs-toggle="tooltip" title="flag flag-lr" />
                    </li>
                    <li>
                      <flag iso="ls" data-bs-toggle="tooltip" title="flag flag-ls" />
                    </li>
                    <li>
                      <flag iso="lt" data-bs-toggle="tooltip" title="flag flag-lt" />
                    </li>
                    <li>
                      <flag iso="lu" data-bs-toggle="tooltip" title="flag flag-lu" />
                    </li>
                    <li>
                      <flag iso="lv" data-bs-toggle="tooltip" title="flag flag-lv" />
                    </li>
                    <li>
                      <flag iso="ly" data-bs-toggle="tooltip" title="flag flag-ly" />
                    </li>
                    <li>
                      <flag iso="ma" data-bs-toggle="tooltip" title="flag flag-ma" />
                    </li>
                    <li>
                      <flag iso="mc" data-bs-toggle="tooltip" title="flag flag-mc" />
                    </li>
                    <li>
                      <flag iso="md" data-bs-toggle="tooltip" title="flag flag-md" />
                    </li>
                    <li>
                      <flag iso="me" data-bs-toggle="tooltip" title="flag flag-me" />
                    </li>
                    <li>
                      <flag iso="mg" data-bs-toggle="tooltip" title="flag flag-mg" />
                    </li>
                    <li>
                      <flag iso="mh" data-bs-toggle="tooltip" title="flag flag-mh" />
                    </li>
                    <li>
                      <flag iso="mk" data-bs-toggle="tooltip" title="flag flag-mk" />
                    </li>
                    <li>
                      <flag iso="ml" data-bs-toggle="tooltip" title="flag flag-ml" />
                    </li>
                    <li>
                      <flag iso="mm" data-bs-toggle="tooltip" title="flag flag-mm" />
                    </li>
                    <li>
                      <flag iso="mn" data-bs-toggle="tooltip" title="flag flag-mn" />
                    </li>
                    <li>
                      <flag iso="mo" data-bs-toggle="tooltip" title="flag flag-mo" />
                    </li>
                    <li>
                      <flag iso="mp" data-bs-toggle="tooltip" title="flag flag-mp" />
                    </li>
                    <li>
                      <flag iso="mq" data-bs-toggle="tooltip" title="flag flag-mq" />
                    </li>
                    <li>
                      <flag iso="mr" data-bs-toggle="tooltip" title="flag flag-mr" />
                    </li>
                    <li>
                      <flag iso="ms" data-bs-toggle="tooltip" title="flag flag-ms" />
                    </li>
                    <li>
                      <flag iso="mt" data-bs-toggle="tooltip" title="flag flag-mt" />
                    </li>
                    <li>
                      <flag iso="mu" data-bs-toggle="tooltip" title="flag flag-mu" />
                    </li>
                    <li>
                      <flag iso="mv" data-bs-toggle="tooltip" title="flag flag-mv" />
                    </li>
                    <li>
                      <flag iso="mw" data-bs-toggle="tooltip" title="flag flag-mw" />
                    </li>
                    <li>
                      <flag iso="mx" data-bs-toggle="tooltip" title="flag flag-mx" />
                    </li>
                    <li>
                      <flag iso="my" data-bs-toggle="tooltip" title="flag flag-my" />
                    </li>
                    <li>
                      <flag iso="mz" data-bs-toggle="tooltip" title="flag flag-mz" />
                    </li>
                    <li>
                      <flag iso="na" data-bs-toggle="tooltip" title="flag flag-na" />
                    </li>
                    <li>
                      <flag iso="nc" data-bs-toggle="tooltip" title="flag flag-nc" />
                    </li>
                    <li>
                      <flag iso="ne" data-bs-toggle="tooltip" title="flag flag-ne" />
                    </li>
                    <li>
                      <flag iso="nf" data-bs-toggle="tooltip" title="flag flag-nf" />
                    </li>
                    <li>
                      <flag iso="ng" data-bs-toggle="tooltip" title="flag flag-ng" />
                    </li>
                    <li>
                      <flag iso="ni" data-bs-toggle="tooltip" title="flag flag-ni" />
                    </li>
                    <li>
                      <flag iso="nl" data-bs-toggle="tooltip" title="flag flag-nl" />
                    </li>
                    <li>
                      <flag iso="no" data-bs-toggle="tooltip" title="flag flag-no" />
                    </li>
                    <li>
                      <flag iso="np" data-bs-toggle="tooltip" title="flag flag-np" />
                    </li>
                    <li>
                      <flag iso="nr" data-bs-toggle="tooltip" title="flag flag-nr" />
                    </li>
                    <li>
                      <flag iso="nu" data-bs-toggle="tooltip" title="flag flag-nu" />
                    </li>
                    <li>
                      <flag iso="nz" data-bs-toggle="tooltip" title="flag flag-nz" />
                    </li>
                    <li>
                      <flag iso="om" data-bs-toggle="tooltip" title="flag flag-om" />
                    </li>
                    <li>
                      <flag iso="pa" data-bs-toggle="tooltip" title="flag flag-pa" />
                    </li>
                    <li>
                      <flag iso="pe" data-bs-toggle="tooltip" title="flag flag-pe" />
                    </li>
                    <li>
                      <flag iso="pf" data-bs-toggle="tooltip" title="flag flag-pf" />
                    </li>
                    <li>
                      <flag iso="pg" data-bs-toggle="tooltip" title="flag flag-pg" />
                    </li>
                    <li>
                      <flag iso="ph" data-bs-toggle="tooltip" title="flag flag-ph" />
                    </li>
                    <li>
                      <flag iso="pk" data-bs-toggle="tooltip" title="flag flag-pk" />
                    </li>
                    <li>
                      <flag iso="pl" data-bs-toggle="tooltip" title="flag flag-pl" />
                    </li>
                    <li>
                      <flag iso="pm" data-bs-toggle="tooltip" title="flag flag-pm" />
                    </li>
                    <li>
                      <flag iso="pn" data-bs-toggle="tooltip" title="flag flag-pn" />
                    </li>
                    <li>
                      <flag iso="pr" data-bs-toggle="tooltip" title="flag flag-pr" />
                    </li>
                    <li>
                      <flag iso="ps" data-bs-toggle="tooltip" title="flag flag-ps" />
                    </li>
                    <li>
                      <flag iso="pt" data-bs-toggle="tooltip" title="flag flag-pt" />
                    </li>
                    <li>
                      <flag iso="pw" data-bs-toggle="tooltip" title="flag flag-pw" />
                    </li>
                    <li>
                      <flag iso="py" data-bs-toggle="tooltip" title="flag flag-py" />
                    </li>
                    <li>
                      <flag iso="qa" data-bs-toggle="tooltip" title="flag flag-qa" />
                    </li>
                    <li>
                      <flag iso="re" data-bs-toggle="tooltip" title="flag flag-re" />
                    </li>
                    <li>
                      <flag iso="ro" data-bs-toggle="tooltip" title="flag flag-ro" />
                    </li>
                    <li>
                      <flag iso="rs" data-bs-toggle="tooltip" title="flag flag-rs" />
                    </li>
                    <li>
                      <flag iso="ru" data-bs-toggle="tooltip" title="flag flag-ru" />
                    </li>
                    <li>
                      <flag iso="rw" data-bs-toggle="tooltip" title="flag flag-rw" />
                    </li>
                    <li>
                      <flag iso="sa" data-bs-toggle="tooltip" title="flag flag-sa" />
                    </li>
                    <li>
                      <flag iso="sb" data-bs-toggle="tooltip" title="flag flag-sb" />
                    </li>
                    <li>
                      <flag iso="sc" data-bs-toggle="tooltip" title="flag flag-sc" />
                    </li>
                    <li>
                      <flag iso="sd" data-bs-toggle="tooltip" title="flag flag-sd" />
                    </li>
                    <li>
                      <flag iso="se" data-bs-toggle="tooltip" title="flag flag-se" />
                    </li>
                    <li>
                      <flag iso="sg" data-bs-toggle="tooltip" title="flag flag-sg" />
                    </li>
                    <li>
                      <flag iso="sh" data-bs-toggle="tooltip" title="flag flag-sh" />
                    </li>
                    <li>
                      <flag iso="si" data-bs-toggle="tooltip" title="flag flag-si" />
                    </li>
                    <li>
                      <flag iso="sk" data-bs-toggle="tooltip" title="flag flag-sk" />
                    </li>
                    <li>
                      <flag iso="sl" data-bs-toggle="tooltip" title="flag flag-sl" />
                    </li>
                    <li>
                      <flag iso="sm" data-bs-toggle="tooltip" title="flag flag-sm" />
                    </li>
                    <li>
                      <flag iso="sn" data-bs-toggle="tooltip" title="flag flag-sn" />
                    </li>
                    <li>
                      <flag iso="so" data-bs-toggle="tooltip" title="flag flag-so" />
                    </li>
                    <li>
                      <flag iso="sr" data-bs-toggle="tooltip" title="flag flag-sr" />
                    </li>
                    <li>
                      <flag iso="ss" data-bs-toggle="tooltip" title="flag flag-ss" />
                    </li>
                    <li>
                      <flag iso="st" data-bs-toggle="tooltip" title="flag flag-st" />
                    </li>
                    <li>
                      <flag iso="sv" data-bs-toggle="tooltip" title="flag flag-sv" />
                    </li>
                    <li>
                      <flag iso="sx" data-bs-toggle="tooltip" title="flag flag-sx" />
                    </li>
                    <li>
                      <flag iso="sy" data-bs-toggle="tooltip" title="flag flag-sy" />
                    </li>
                    <li>
                      <flag iso="sz" data-bs-toggle="tooltip" title="flag flag-sz" />
                    </li>
                    <li>
                      <flag iso="tc" data-bs-toggle="tooltip" title="flag flag-tc" />
                    </li>
                    <li>
                      <flag iso="td" data-bs-toggle="tooltip" title="flag flag-td" />
                    </li>
                    <li>
                      <flag iso="tf" data-bs-toggle="tooltip" title="flag flag-tf" />
                    </li>
                    <li>
                      <flag iso="tg" data-bs-toggle="tooltip" title="flag flag-tg" />
                    </li>
                    <li>
                      <flag iso="th" data-bs-toggle="tooltip" title="flag flag-th" />
                    </li>
                    <li>
                      <flag iso="tj" data-bs-toggle="tooltip" title="flag flag-tj" />
                    </li>
                    <li>
                      <flag iso="tk" data-bs-toggle="tooltip" title="flag flag-tk" />
                    </li>
                    <li>
                      <flag iso="tl" data-bs-toggle="tooltip" title="flag flag-tl" />
                    </li>
                    <li>
                      <flag iso="tm" data-bs-toggle="tooltip" title="flag flag-tm" />
                    </li>
                    <li>
                      <flag iso="tn" data-bs-toggle="tooltip" title="flag flag-tn" />
                    </li>
                    <li>
                      <flag iso="to" data-bs-toggle="tooltip" title="flag flag-to" />
                    </li>
                    <li>
                      <flag iso="tr" data-bs-toggle="tooltip" title="flag flag-tr" />
                    </li>
                    <li>
                      <flag iso="tt" data-bs-toggle="tooltip" title="flag flag-tt" />
                    </li>
                    <li>
                      <flag iso="tv" data-bs-toggle="tooltip" title="flag flag-tv" />
                    </li>
                    <li>
                      <flag iso="tw" data-bs-toggle="tooltip" title="flag flag-tw" />
                    </li>
                    <li>
                      <flag iso="tz" data-bs-toggle="tooltip" title="flag flag-tz" />
                    </li>
                    <li>
                      <flag iso="ua" data-bs-toggle="tooltip" title="flag flag-ua" />
                    </li>
                    <li>
                      <flag iso="ug" data-bs-toggle="tooltip" title="flag flag-ug" />
                    </li>
                    <li>
                      <flag iso="um" data-bs-toggle="tooltip" title="flag flag-um" />
                    </li>
                    <li>
                      <flag iso="us" data-bs-toggle="tooltip" title="flag flag-us" />
                    </li>
                    <li>
                      <flag iso="uy" data-bs-toggle="tooltip" title="flag flag-uy" />
                    </li>
                    <li>
                      <flag iso="uz" data-bs-toggle="tooltip" title="flag flag-uz" />
                    </li>
                    <li>
                      <flag iso="va" data-bs-toggle="tooltip" title="flag flag-va" />
                    </li>
                    <li>
                      <flag iso="vc" data-bs-toggle="tooltip" title="flag flag-vc" />
                    </li>
                    <li>
                      <flag iso="ve" data-bs-toggle="tooltip" title="flag flag-ve" />
                    </li>
                    <li>
                      <flag iso="vg" data-bs-toggle="tooltip" title="flag flag-vg" />
                    </li>
                    <li>
                      <flag iso="vi" data-bs-toggle="tooltip" title="flag flag-vi" />
                    </li>
                    <li>
                      <flag iso="vn" data-bs-toggle="tooltip" title="flag flag-vn" />
                    </li>
                    <li>
                      <flag iso="vu" data-bs-toggle="tooltip" title="flag flag-vu" />
                    </li>
                    <li>
                      <flag iso="wf" data-bs-toggle="tooltip" title="flag flag-wf" />
                    </li>
                    <li>
                      <flag iso="ws" data-bs-toggle="tooltip" title="flag flag-ws" />
                    </li>
                    <li>
                      <flag iso="ye" data-bs-toggle="tooltip" title="flag flag-ye" />
                    </li>
                    <li>
                      <flag iso="yt" data-bs-toggle="tooltip" title="flag flag-yt" />
                    </li>
                    <li>
                      <flag iso="za" data-bs-toggle="tooltip" title="flag flag-za" />
                    </li>
                    <li>
                      <flag iso="zm" data-bs-toggle="tooltip" title="flag flag-zm" />
                    </li>
                    <li>
                      <flag iso="zw" data-bs-toggle="tooltip" title="flag flag-zw" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!-- /Chart -->
        </div>
      </div>
    </div>
    <!-- /Page Wrapper -->
  
  <!-- /Main Wrapper -->
</template>
<script>
export default {
  data() {
    return {
      title: "Flag Icons",
    };
  },
};
</script>
