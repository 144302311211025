import { createRouter, createWebHistory } from "vue-router";
// import SignIn from "@/views/pages/pages/authentication/login/sign-in.vue";
import SignIn from "@/views/pages/pages/authentication/login/signin-2.vue";
// import SignIn_3 from "@/views/pages/pages/authentication/login/signin-3.vue";
import Email_Verification from "@/views/pages/pages/authentication/email-verification/email-verification.vue";
import Email_Verification_2 from "@/views/pages/pages/authentication/email-verification/email-verification-2.vue";
import Email_Verification_3 from "@/views/pages/pages/authentication/email-verification/email-verification-3.vue";
import Two_Step_Verification from "@/views/pages/pages/authentication/two-step-verification/two-step-verification.vue";
import Two_Step_Verification_2 from "@/views/pages/pages/authentication/two-step-verification/two-step-verification-2.vue";
import Two_Step_Verification_3 from "@/views/pages/pages/authentication/two-step-verification/two-step-verification-3.vue";
import Forgot_Password from "@/views/pages/pages/authentication/forgot-password/forgot-password.vue";
import Forgot_Password_2 from "@/views/pages/pages/authentication/forgot-password/forgot-password-2.vue";
import Forgot_Password_3 from "@/views/pages/pages/authentication/forgot-password/forgot-password-3.vue";
import Reset_Password from "@/views/pages/pages/authentication/reset-password/reset-password.vue";
import Reset_Password_2 from "@/views/pages/pages/authentication/reset-password/reset-password-2.vue";
import Reset_Password_3 from "@/views/pages/pages/authentication/reset-password/reset-password-3.vue";
import Lock_Screen from "@/views/pages/pages/authentication/lock-screen.vue";
import Coming_Soon from "@/views/pages/pages/coming-soon.vue";
import Under_Maintenance from "@/views/pages/pages/under-maintenance.vue";
import Error from "@/views/pages/pages/error/error.vue";
import Error_404 from "@/views/pages/pages/error/error-404.vue";
import Error_500 from "@/views/pages/pages/error/error-500.vue";
import Dashboard from "@/views/pages/dashboard/dashboard.vue";
import AdminDashboard from "@/views/pages/dashboard/admin-dashboard.vue";
import SalesDashboard from "@/views/pages/dashboard/sales-dashboard.vue";
import Chat from "@/views/pages/crm/chat/chat.vue";
import Call from "@/views/pages/crm/call/call.vue";
import VideoCall from "@/views/pages/crm/call/video-call.vue";
import AudioCall from "@/views/pages/crm/call/audio-call.vue";
import CallHistory from "@/views/pages/crm/call/call-history.vue";
import Calendar from "@/views/pages/crm/calendar.vue";
import Register from "@/views/pages/pages/authentication/register/register.vue";
import Register_2 from "@/views/pages/pages/authentication/register/register-2.vue";
import Register_3 from "@/views/pages/pages/authentication/register/register-3.vue";
import Blank_Page from "@/views/pages/pages/blank-page.vue";
import Tables from "@/views/pages/uiinterface/tables/tables";
import Tables_Basic from "@/views/pages/uiinterface/tables/tables-basic.vue";
import Data_Tables from "@/views/pages/uiinterface/tables/data-tables.vue";
import Chartapex from "@/views/pages/uiinterface/charts/apex/chart-apex.vue";
import Chartc3 from "@/views/pages/uiinterface/charts/c3/chart-c3.vue";
import Chartflot from "@/views/pages/uiinterface/charts/flot/chart-flot.vue";
import Chartjs from "@/views/pages/uiinterface/charts/js/chart-js.vue";
import Chartmorris from "@/views/pages/uiinterface/charts/morris/chart-morris.vue";
import Charts from "@/views/pages/uiinterface/charts/charts.vue";
import Forms from "@/views/pages/uiinterface/forms/forms.vue";
import Formbasicinput from "@/views/pages/uiinterface/forms/form-elements/formbasic/formbasicinput.vue";
import Formcheckboxradios from "@/views/pages/uiinterface/forms/form-elements/form-checkbox-radios.vue";
import Formgridgutters from "@/views/pages/uiinterface/forms/form-elements/form-grid-gutters.vue";
import Formselect from "@/views/pages/uiinterface/forms/form-elements/form-select.vue";
import FormInput from "@/views/pages/uiinterface/forms/form-elements/forminput/forminput.vue";
import Formmask from "@/views/pages/uiinterface/forms/form-elements/formmask/formmask.vue";
import Formfileupload from "@/views/pages/uiinterface/forms/form-elements/formfileupload.vue";
import FormHorizontal from "@/views/pages/uiinterface/forms/layouts/form-horizontal.vue";
import Formvertical from "@/views/pages/uiinterface/forms/layouts/form-vertical.vue";
import Formfloatinglabels from "@/views/pages/uiinterface/forms/layouts/form-floating-labels.vue";
import Formvalidation from "@/views/pages/uiinterface/forms/form-validation.vue";
import Formselect2 from "@/views/pages/uiinterface/forms/form-select2.vue";
import Formwizard from "@/views/pages/uiinterface/forms/form-wizard.vue";
import Advancedui from "@/views/pages/uiinterface/advancedUI/advancedui.vue";
import UI_Ribbon from "@/views/pages/uiinterface/advancedUI/ui-ribbon.vue";
import UI_Clipboard from "@/views/pages/uiinterface/advancedUI/ui-clipboard.vue";
import UI_Drag_Drop from "@/views/pages/uiinterface/advancedUI/ui-drag-drop.vue";
import UI_Text_Editor from "@/views/pages/uiinterface/advancedUI/ui-text-editor.vue";
import UI_Counter from "@/views/pages/uiinterface/advancedUI/ui-counter.vue";
import UI_Scrollbar from "@/views/pages/uiinterface/advancedUI/ui-scrollbar.vue";
import UI_Rating from "@/views/pages/uiinterface/advancedUI/ui-rating.vue";
import UI_Stickynote from "@/views/pages/uiinterface/advancedUI/ui-stickynote.vue";
import UI_Timeline from "@/views/pages/uiinterface/advancedUI/ui-timeline.vue";
import BaseUi from "@/views/pages/uiinterface/baseui/baseui.vue";
import UI_Alerts from "@/views/pages/uiinterface/baseui/ui-alerts.vue";
import UI_Accordion from "@/views/pages/uiinterface/baseui/ui-accordion.vue";
import UI_Avatar from "@/views/pages/uiinterface/baseui/ui-avatar.vue";
import UI_Badges from "@/views/pages/uiinterface/baseui/ui-badges.vue";
import UI_Borders from "@/views/pages/uiinterface/baseui/ui-borders.vue";
import UI_Buttons from "@/views/pages/uiinterface/baseui/ui-buttons.vue";
import UI_Buttons_group from "@/views/pages/uiinterface/baseui/ui-buttons-group.vue";
import UI_Breadcrumb from "@/views/pages/uiinterface/baseui/ui-breadcrumb.vue";
import UI_Cards from "@/views/pages/uiinterface/baseui/ui-cards.vue";
import UI_Carousel from "@/views/pages/uiinterface/baseui/carousel/ui-carousel.vue";
import UI_Colors from "@/views/pages/uiinterface/baseui/ui-colors.vue";
import UI_Dropdowns from "@/views/pages/uiinterface/baseui/ui-dropdowns.vue";
import UI_Grid from "@/views/pages/uiinterface/baseui/ui-grid.vue";
import UI_Images from "@/views/pages/uiinterface/baseui/ui-images.vue";
import UI_Lightbox from "@/views/pages/uiinterface/baseui/lightbox/ui-lightbox.vue";
import UI_Media from "@/views/pages/uiinterface/baseui/ui-media.vue";
import UI_Modals from "@/views/pages/uiinterface/baseui/ui-modals.vue";
import UI_Offcanvas from "@/views/pages/uiinterface/baseui/ui-offcanvas.vue";
import UI_Pagination from "@/views/pages/uiinterface/baseui/ui-pagination.vue";
import UI_Popovers from "@/views/pages/uiinterface/baseui/ui-popovers.vue";
import UI_Progress from "@/views/pages/uiinterface/baseui/ui-progress.vue";
import UI_Placeholders from "@/views/pages/uiinterface/baseui/ui-placeholders.vue";
import UI_Rangeslider from "@/views/pages/uiinterface/baseui/ui-rangeslider.vue";
import UI_Spinner from "@/views/pages/uiinterface/baseui/ui-spinner.vue";
import UI_Sweetalerts from "@/views/pages/uiinterface/baseui/ui-sweetalerts.vue";
import UI_Tooltips from "@/views/pages/uiinterface/baseui/ui-tooltips.vue";
import UI_Typography from "@/views/pages/uiinterface/baseui/ui-typography.vue";
import UI_Video from "@/views/pages/uiinterface/baseui/ui-video.vue";
import Icons from "@/views/pages/uiinterface/icons/icons.vue";
import UI_Iconfontawesome from "@/views/pages/uiinterface/icons/icon-fontawesome.vue";
import UI_Iconfeather from "@/views/pages/uiinterface/icons/icon-feather.vue";
import UI_Iconionic from "@/views/pages/uiinterface/icons/icon-ionic.vue";
import UI_Iconmaterial from "@/views/pages/uiinterface/icons/icon-material.vue";
import UI_Iconpe7 from "@/views/pages/uiinterface/icons/icon-pe7.vue";
import UI_Iconsimpleline from "@/views/pages/uiinterface/icons/icon-simpleline.vue";
import UI_Iconthemify from "@/views/pages/uiinterface/icons/icon-themify.vue";
import UI_Iconweather from "@/views/pages/uiinterface/icons/icon-weather.vue";
import UI_Icontypicon from "@/views/pages/uiinterface/icons/icon-typicon.vue";
import UI_Iconflag from "@/views/pages/uiinterface/icons/icon-flag.vue";
import Email from "@/views/pages/crm/email.vue";
import Todo from "@/views/pages/crm/todo.vue";
import Notes from "@/views/pages/crm/notes.vue";
import Stock from "@/views/pages/stock/stock.vue";
import ManageStocks from "@/views/pages/stock/manage-stocks.vue";
import StockAdjustment from "@/views/pages/stock/stock-adjustment.vue";
import Inventory from "@/views/pages/inventory/inventory.vue";
import Low_Stocks from "@/views/pages/inventory/low-stocks/low-stocks.vue";
import Category_List from "@/views/pages/inventory/category-list.vue";
import Sub_Categories from "@/views/pages/inventory/sub-categories.vue";
import Brand_List from "@/views/pages/inventory/brand-list.vue";
import Units from "@/views/pages/inventory/units.vue";
import Varriant_Attributes from "@/views/pages/inventory/varriant-attributes.vue";
import Warranty from "@/views/pages/inventory/warranty.vue";
import Barcode from "@/views/pages/inventory/barcode.vue";
import Qrcode from "@/views/pages/inventory/qrcode.vue";
import Product_Details from "@/views/pages/inventory/product-details.vue";
import Product_List from "@/views/pages/inventory/product-list/product-list.vue";
import StockTransfer from "@/views/pages/stock/stock-transfer.vue";
import Purchases from "@/views/pages/purchases/purchases.vue";
import PurchaseList from "@/views/pages/purchases/purchase-list.vue";
import CancelledOrder from "@/views/pages/purchases/cancelled-order.vue";
import PurchaseOrderReport from "@/views/pages/purchases/purchase-order-report.vue";
import PurchaseReturns from "@/views/pages/purchases/purchase-returns.vue";
import Add_Product from "@/views/pages/inventory/add-product.vue";
import Edit_Product from "@/views/pages/inventory/edit-product.vue";
import Expired_Products from "@/views/pages/inventory/expired-products.vue";
import Finance from "@/views/pages/finance/finance.vue";
import Expenses_List from "@/views/pages/finance/expenses-list.vue";
import Expenses_Category from "@/views/pages/finance/expenses-category.vue";
import People from "@/views/pages/people/people.vue";
import Customers from "@/views/pages/people/customers.vue";
import Suppliers from "@/views/pages/people/suppliers.vue";
import Store_List from "@/views/pages/people/store-list.vue";
import Warehouse from "@/views/pages/people/warehouse.vue";
import Reports from "@/views/pages/reports/reports.vue";
import Sales_Report from "@/views/pages/reports/sales-report.vue";
import Purchase_Report from "@/views/pages/reports/purchase-report.vue";
import Inventory_Report from "@/views/pages/reports/inventory-report.vue";
import Invoice_Report from "@/views/pages/reports/invoice-report.vue";
import Supplier_Report from "@/views/pages/reports/supplier-report/supplier-report.vue";
import Customer_Report from "@/views/pages/reports/customer-report.vue";
import Expense_Report from "@/views/pages/reports/expense-report.vue";
import Income_Report from "@/views/pages/reports/income-report.vue";
import Tax_Report from "@/views/pages/reports/tax-report/tax-report.vue";
import Profit_And_Loss from "@/views/pages/reports/profit-and-loss.vue";
import Vendors from "@/views/pages/users/vendors.vue";
import Vendors_List from "@/views/pages/users/vendors-list.vue";
import Users from "@/views/pages/users/users.vue";
import Users_List from "@/views/pages/users/users-list.vue";
import Roles_Permissions from "@/views/pages/users/roles-permissions.vue";
import Permissions from "@/views/pages/users/permissions.vue";
import Delete_Account from "@/views/pages/users/delete-account.vue";
import FileManager from "@/views/pages/crm/files/file-manager.vue";
import Profile from "@/views/pages/pages/profile.vue";
import Sales from "@/views/pages/sales/sales.vue";
import SalesList from "@/views/pages/sales/sales-list.vue";
import InvoiceReport from "@/views/pages/sales/invoice-report.vue";
import SalesReturns from "@/views/pages/sales/sales-returns.vue";
import Quotation_List from "@/views/pages/sales/quotation-list.vue";
import Places from "@/views/pages/pages/places/places.vue";
import Countries from "@/views/pages/pages/places/countries.vue";
import States from "@/views/pages/pages/places/states.vue";
import HRM from "@/views/pages/hrm/hrm.vue";
import Employees_Grid from "@/views/pages/hrm/employees-grid.vue";
import Employees_List from "@/views/pages/hrm/employees-list.vue";
import Add_Employee from "@/views/pages/hrm/add-employee.vue";
import Edit_Employee from "@/views/pages/hrm/edit-employee.vue";
import Department_Grid from "@/views/pages/hrm/department-grid.vue";
import Department_List from "@/views/pages/hrm/department-list.vue";
import Designation from "@/views/pages/hrm/designation.vue";
import Shift from "@/views/pages/hrm/shift.vue";
import Attendance_Employee from "@/views/pages/hrm/attendance-employee.vue";
import Attendance_Admin from "@/views/pages/hrm/attendance-admin.vue";
import Leaves_Admin from "@/views/pages/hrm/leaves/leaves-admin.vue";
import Leaves_Employee from "@/views/pages/hrm/leaves/leaves-employee.vue";
import Leave_Types from "@/views/pages/hrm/leaves/leave-types.vue";
import Holidays from "@/views/pages/hrm/holidays.vue";
import Payroll_List from "@/views/pages/hrm/payroll/payroll-list.vue";
import Payslip from "@/views/pages/hrm/payroll/payslip.vue";
import Success from "@/views/pages/pages/success.vue";
import SuccessTwo from "@/views/pages/pages/success-2.vue";
import SuccessThree from "@/views/pages/pages/success-3.vue";
import Activities from "@/views/pages/activities.vue";
import FileShared from "@/views/pages/crm/files/file-shared.vue";
import FileDocument from "@/views/pages/crm/files/file-document.vue";
import FileRecent from "@/views/pages/crm/files/file-recent.vue";
import FileFavourites from "@/views/pages/crm/files/file-favourites.vue";
import FileArchived from "@/views/pages/crm/files/file-archived.vue";
import Settings from "@/views/pages/settings/settings.vue";
import General_Settings from "@/views/pages/settings/general-settings/general-settings.vue";
import Security_Settings from "@/views/pages/settings/general-settings/security-settings.vue";
import Notification from "@/views/pages/settings/general-settings/notification.vue";
import Connected_Apps from "@/views/pages/settings/general-settings/connected-apps.vue";
import System_Settings from "@/views/pages/settings/website-settings/system-settings.vue";
import Company_Settings from "@/views/pages/settings/website-settings/company-settings.vue";
import Localization_Settings from "@/views/pages/settings/website-settings/localization-settings.vue";
import Prefixes from "@/views/pages/settings/website-settings/prefixes.vue";
import Preference from "@/views/pages/settings/website-settings/preference.vue";
import Appearance from "@/views/pages/settings/website-settings/appearance.vue";
import Social_Authentication from "@/views/pages/settings/website-settings/social-authentication.vue";
import Language_Settings from "@/views/pages/settings/website-settings/language-settings.vue";
import Language_Settings_Web from "@/views/pages/settings/website-settings/language-settings-web.vue";
import Invoice_Settings from "@/views/pages/settings/app-settings/invoice-settings.vue";
import Printer_Settings from "@/views/pages/settings/app-settings/printer-settings.vue";
import Pos_Settings from "@/views/pages/settings/app-settings/pos-settings.vue";
import Custom_Fields from "@/views/pages/settings/app-settings/custom-fields.vue";
import Email_Settings from "@/views/pages/settings/system-settings/email-settings.vue";
import Sms_Gateway from "@/views/pages/settings/system-settings/sms-gateway.vue";
import Otp_Settings from "@/views/pages/settings/system-settings/otp-settings.vue";
import Gdpr_Settings from "@/views/pages/settings/system-settings/gdpr-settings.vue";
import Payment_Gateway_Settings from "@/views/pages/settings/financal-settings/payment-gateway-settings.vue";
import Bank_Settings_Grid from "@/views/pages/settings/financal-settings/bank-settings-grid.vue";
import Bank_Settings_List from "@/views/pages/settings/financal-settings/bank-settings-list.vue";
import Tax_Rates from "@/views/pages/settings/financal-settings/tax-rates.vue";
import Currency_Settings from "@/views/pages/settings/financal-settings/currency-settings.vue";
import Storage_Settings from "@/views/pages/settings/other-settings/storage-settings.vue";
import Ban_Ip_Address from "@/views/pages/settings/other-settings/ban-ip-address.vue";
import FileManagerDeleted from "@/views/pages/crm/files/file-manager-deleted.vue";
import POS from "@/views/pages/sales/pos.vue";
import Coupons from "@/views/pages/promo/coupons.vue";
import Pages from "@/views/pages/pages/pages.vue";
import Application from "@/views/pages/crm/application.vue";
import Leaves from "@/views/pages/hrm/leaves/leaves.vue";
import Payroll from "@/views/pages/hrm/payroll/payroll.vue";

// my views
import Purchase_Details from "@/views/pages/inventory/purchase-details.vue";
import purchase_details_offline from "@/views/pages/inventory/purchase-details-offline.vue";
import Sales_Notification from "@/views/pages/inventory/notification/sales_notification.vue";
import Widthrawal from "@/views/pages/addedViews/Withdrawal.vue";
import Widthrawal_Details from "@/views/pages/addedViews/Withdrawal-details.vue";
import Message from "@/views/pages/addedViews/message.vue";
import Message_chat from "@/views/pages/addedViews/message-chat.vue";

import store from "@/store";
import { isAuth } from "@/utils";
import GoogleSignIn from "@/views/pages/pages/authentication/GoogleSignIn.vue";
import TwitterSignIn from "@/views/pages/pages/authentication/TwiitterSign.vue";
import AppleSignup from "@/views/pages/pages/authentication/AppleSignin.vue";
import { api, url2 } from "@/api";
const routes = [
  {
    path: "/apple_signup",
    name: "AppleSignup",
    component: AppleSignup,
    meta: {
      title: "Naijakobo Market | AppleSignup",
    },
  },
  {
    path: "/google_signup",
    name: "GoogleSignIn",
    component: GoogleSignIn,
    meta: {
      title: "Naijakobo Market | GoogleSignIn",
    },
  },
  {
    path: "/twitter_signup",
    name: "TwitterSignIn",
    component: TwitterSignIn,
    meta: {
      title: "Naijakobo Market | TwitterSignIn",
    },
  },
  {
    path: "/auth/:token",
    name: "token-login",

    meta: {
      requiresAuth: false,
      permissionsRequired: [],
    },
    beforeEnter: async (to, from, next) => {
      try {
        // Step 1: Save the token to localStorage
        const token = to.params.token;
        localStorage.setItem("accessToken", token);

        // Step 2: Make an API request to get the profile data
        const response = await api.get(url2("users/me"), {
          headers: {
            Authorization: `Bearer ${token}`, // Sending the token in the request header
          },
        });

        const profileData = response.data.data;
        localStorage.setItem("data", JSON.stringify(profileData)); // Step 3: Save profile data

        // Save other relevant data if needed
        localStorage.setItem("authorize", profileData.authorize);

        // Step 4: Redirect to the dashboard
        next({ path: "/dashboard/sales-dashboard" });
      } catch (error) {
        console.log('error')
        window.history.back();
      }
    },
  },
  {
    path: "/",
    name: "sign-in",

    meta: {
      requiresAuth: false,
      permissionsRequired: [],
    },
    component: SignIn,
  },
  {
    path: "/register",
    name: "register",

    meta: {
      requiresAuth: false,
      permissionsRequired: [],
    },
    component: Register,
  },
  {
    path: "/forgot-password",
    name: "forgot-password",

    meta: {
      requiresAuth: false,
      permissionsRequired: [],
    },
    component: Forgot_Password,
  },
  {
    path: "/reset-password",
    name: "reset-password",

    meta: {
      requiresAuth: false,
      permissionsRequired: [],
    },
    component: Reset_Password,
  },
  {
    path: "/email-verification",
    name: "email-verification",

    meta: {
      requiresAuth: false,
      permissionsRequired: [],
    },
    component: Email_Verification,
  },
  {
    path: "/two-step-verification",
    name: "two-step-verification",

    meta: {
      requiresAuth: false,
      permissionsRequired: [],
    },
    component: Two_Step_Verification,
  },
  {
    path: "/success",
    name: "success",

    meta: {
      requiresAuth: true,
      permissionsRequired: [],
    },
    component: Success,
  },
  {
    path: "/success-2",
    name: "success-2",

    meta: {
      requiresAuth: true,
      permissionsRequired: [],
    },
    component: SuccessTwo,
  },
  {
    path: "/success-3",
    name: "success-3",

    meta: {
      requiresAuth: true,
      permissionsRequired: [],
    },
    component: SuccessThree,
  },
  {
    path: "/activities",
    name: "activities",

    meta: {
      requiresAuth: true,
      permissionsRequired: [],
    },
    component: Activities,
  },
  {
    path: "/coupons",
    name: "coupons",

    meta: {
      requiresAuth: true,
      permissionsRequired: [],
    },
    component: Coupons,
  },
  {
    path: "/crm",

    meta: {
      requiresAuth: true,
      permissionsRequired: [],
    },
    component: Application,
    children: [
      { path: "", redirect: "/crm/chat" },
      {
        path: "chat",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Chat,
      },
      {
        path: "calendar",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Calendar,
      },
      {
        path: "product-question",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Message,
      },
      {
        path: "product-question-chat",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Message_chat,
      },
      {
        path: "todo",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Todo,
      },
      {
        path: "notes",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Notes,
      },
      {
        path: "file-manager",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: FileManager,
      },
      {
        path: "file-shared",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: FileShared,
      },
      {
        path: "file-recent",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: FileRecent,
      },
      {
        path: "file-document",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: FileDocument,
      },
      {
        path: "file-favourites",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: FileFavourites,
      },
      {
        path: "file-archived",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: FileArchived,
      },
      {
        path: "file-manager-deleted",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: FileManagerDeleted,
      },
    ],
  },
  {
    path: "/settings",

    meta: {
      requiresAuth: true,
      permissionsRequired: [],
    },
    component: Settings,
    children: [
      { path: "", redirect: "/settings/general-settings" },
      {
        path: "general-settings",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: General_Settings,
      },
      {
        path: "security-settings",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Security_Settings,
      },
      {
        path: "notification",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Notification,
      },
      {
        path: "connected-apps",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Connected_Apps,
      },
      {
        path: "system-settings",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: System_Settings,
      },
      {
        path: "company-settings",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Company_Settings,
      },
      {
        path: "localization-settings",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Localization_Settings,
      },
      {
        path: "prefixes",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Prefixes,
      },
      {
        path: "preference",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Preference,
      },
      {
        path: "appearance",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Appearance,
      },
      {
        path: "social-authentication",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Social_Authentication,
      },
      {
        path: "language-settings",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Language_Settings,
      },
      {
        path: "language-settings-web",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Language_Settings_Web,
      },
      {
        path: "invoice-settings",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Invoice_Settings,
      },
      {
        path: "printer-settings",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Printer_Settings,
      },
      {
        path: "pos-settings",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Pos_Settings,
      },
      {
        path: "custom-fields",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Custom_Fields,
      },
      {
        path: "email-settings",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Email_Settings,
      },
      {
        path: "sms-gateway",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Sms_Gateway,
      },
      {
        path: "otp-settings",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Otp_Settings,
      },
      {
        path: "gdpr-settings",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Gdpr_Settings,
      },
      {
        path: "payment-gateway-settings",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Payment_Gateway_Settings,
      },
      {
        path: "bank-settings-grid",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Bank_Settings_Grid,
      },
      {
        path: "bank-settings-list",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Bank_Settings_List,
      },
      {
        path: "tax-rates",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Tax_Rates,
      },
      {
        path: "currency-settings",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Currency_Settings,
      },
      {
        path: "storage-settings",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Storage_Settings,
      },
      {
        path: "ban-ip-address",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Ban_Ip_Address,
      },
    ],
  },
  {
    path: "/pages",

    meta: {
      requiresAuth: true,
      permissionsRequired: [],
    },
    component: Pages,
    children: [
      { path: "", redirect: "/pages/profile" },
      {
        path: "profile",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Profile,
      },
      {
        path: "coming-soon",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Coming_Soon,
      },
      {
        path: "under-maintenance",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Under_Maintenance,
      },
      {
        path: "blank-page",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Blank_Page,
      },
    ],
  },
  {
    path: "/error",

    meta: {
      requiresAuth: true,
      permissionsRequired: [],
    },
    component: Error,
    children: [
      { path: "", redirect: "/error/error-404" },
      {
        path: "error-404",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Error_404,
      },
      {
        path: "error-500",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Error_500,
      },
    ],
  },
  {
    path: "/authentication",

    meta: {
      requiresAuth: true,
      permissionsRequired: [],
    },
    component: Pages,
    children: [
      { path: "", redirect: "/authentication/signin-2" },
      // {
      //   path: "signin-2",
      //   meta: {
      //     requiresAuth: true,
      //     permissionsRequired: [],
      //   },
      //   component: SignIn_2,
      // },
      // {
      //   path: "signin-3",
      //   meta: {
      //     requiresAuth: true,
      //     permissionsRequired: [],
      //   },
      //   component: SignIn_3,
      // },
      {
        path: "register-2",
        meta: {
          requiresAuth: false,
          permissionsRequired: [],
        },
        component: Register_2,
      },
      {
        path: "register-3",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Register_3,
      },
      {
        path: "forgot-password-2",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Forgot_Password_2,
      },
      {
        path: "forgot-password-3",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Forgot_Password_3,
      },
      {
        path: "reset-password-2",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Reset_Password_2,
      },
      {
        path: "reset-password-3",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Reset_Password_3,
      },
      {
        path: "email-verification-2",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Email_Verification_2,
      },
      {
        path: "email-verification-3",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Email_Verification_3,
      },
      {
        path: "two-step-verification-2",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Two_Step_Verification_2,
      },
      {
        path: "two-step-verification-3",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Two_Step_Verification_3,
      },
      {
        path: "lock-screen",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Lock_Screen,
      },
    ],
  },
  {
    path: "/inventory",

    meta: {
      requiresAuth: true,
      permissionsRequired: [],
    },
    component: Inventory,
    children: [
      { path: "", redirect: "/inventory/product-list" },
      {
        path: "product-list",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Product_List,
      },
      {
        path: "add-product",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Add_Product,
      },
      {
        path: "edit-product",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Edit_Product,
      },
      {
        path: "expired-products",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Expired_Products,
      },
      {
        path: "low-stocks",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Low_Stocks,
      },
      {
        path: "category-list",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Category_List,
      },
      {
        path: "sub-categories",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Sub_Categories,
      },
      {
        path: "brand-list",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Brand_List,
      },
      {
        path: "units",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Units,
      },
      {
        path: "varriant-attributes",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Varriant_Attributes,
      },
      {
        path: "warranty",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Warranty,
      },
      {
        path: "barcode",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Barcode,
      },
      {
        path: "qrcode",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Qrcode,
      },
      {
        path: "product-details",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Product_Details,
      },
      {
        path: "purchase-details",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Purchase_Details,
      },
      {
        path: "purchase-details-offline",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: purchase_details_offline,
      },
    ],
  },
  {
    path: "/finance",

    meta: {
      requiresAuth: true,
      permissionsRequired: [],
    },
    component: Finance,
    children: [
      { path: "", redirect: "/finance/expenses-list" },
      {
        path: "expenses-list",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Expenses_List,
      },
      {
        path: "expenses-category",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Expenses_Category,
      },
    ],
  },
  {
    path: "/places",

    meta: {
      requiresAuth: true,
      permissionsRequired: [],
    },
    component: Places,
    children: [
      { path: "", redirect: "/places/countries" },
      {
        path: "countries",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Countries,
      },
      {
        path: "states",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: States,
      },
    ],
  },
  {
    path: "/users",

    meta: {
      requiresAuth: true,
      permissionsRequired: [],
    },
    component: Users,
    children: [
      { path: "", redirect: "/users/users-list" },
      {
        path: "users-list",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Users_List,
      },
      {
        path: "roles-permissions",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Roles_Permissions,
      },
      {
        path: "permissions",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Permissions,
      },
      {
        path: "delete-account",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Delete_Account,
      },
    ],
  },
  {
    path: "/Admins",

    meta: {
      requiresAuth: true,
      permissionsRequired: [],
    },
    component: Vendors,
    children: [
      { path: "", redirect: "/admins/admins-list" },
      {
        path: "admins-list",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Vendors_List,
      },
      {
        path: "roles-permissions",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Roles_Permissions,
      },
      {
        path: "permissions",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Permissions,
      },
      {
        path: "delete-account",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Delete_Account,
      },
    ],
  },
  {
    path: "/people",

    meta: {
      requiresAuth: true,
      permissionsRequired: [],
    },
    component: People,
    children: [
      { path: "", redirect: "/people/customers" },
      {
        path: "customers",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Customers,
      },
      {
        path: "suppliers",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Suppliers,
      },
      {
        path: "store-list",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Store_List,
      },
      {
        path: "warehouse",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Warehouse,
      },
    ],
  },
  {
    path: "/reports",

    meta: {
      requiresAuth: true,
      permissionsRequired: [],
    },
    component: Reports,
    children: [
      { path: "", redirect: "/reports/sales-report" },
      {
        path: "sales-report",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Sales_Report,
      },
      {
        path: "purchase-report",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Purchase_Report,
      },
      {
        path: "inventory-report",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Inventory_Report,
      },
      {
        path: "invoice-report",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Invoice_Report,
      },
      {
        path: "supplier-report",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Supplier_Report,
      },
      {
        path: "customer-report",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Customer_Report,
      },
      {
        path: "expense-report",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Expense_Report,
      },
      {
        path: "income-report",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Income_Report,
      },
      {
        path: "tax-report",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Tax_Report,
      },
      {
        path: "profit-and-loss",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Profit_And_Loss,
      },
    ],
  },
  {
    path: "/icons",

    meta: {
      requiresAuth: true,
      permissionsRequired: [],
    },
    component: Icons,
    children: [
      { path: "", redirect: "/icons/icon-fontawesome" },
      {
        path: "icon-fontawesome",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: UI_Iconfontawesome,
      },
      {
        path: "icon-feather",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: UI_Iconfeather,
      },
      {
        path: "icon-ionic",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: UI_Iconionic,
      },
      {
        path: "icon-material",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: UI_Iconmaterial,
      },
      {
        path: "icon-pe7",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: UI_Iconpe7,
      },
      {
        path: "icon-simpleline",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: UI_Iconsimpleline,
      },
      {
        path: "icon-themify",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: UI_Iconthemify,
      },
      {
        path: "icon-weather",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: UI_Iconweather,
      },
      {
        path: "icon-typicon",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: UI_Icontypicon,
      },
      {
        path: "icon-flag",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: UI_Iconflag,
      },
    ],
  },
  {
    path: "/baseui",

    meta: {
      requiresAuth: true,
      permissionsRequired: [],
    },
    component: BaseUi,
    children: [
      { path: "", redirect: "/baseui/ui-alerts" },
      {
        path: "ui-alerts",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: UI_Alerts,
      },
      {
        path: "ui-accordion",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: UI_Accordion,
      },
      {
        path: "ui-avatar",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: UI_Avatar,
      },
      {
        path: "ui-badges",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: UI_Badges,
      },
      {
        path: "ui-borders",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: UI_Borders,
      },
      {
        path: "ui-buttons",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: UI_Buttons,
      },
      {
        path: "ui-buttons-group",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: UI_Buttons_group,
      },
      {
        path: "ui-breadcrumb",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: UI_Breadcrumb,
      },
      {
        path: "ui-cards",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: UI_Cards,
      },
      {
        path: "ui-carousel",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: UI_Carousel,
      },
      {
        path: "ui-colors",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: UI_Colors,
      },
      {
        path: "ui-dropdowns",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: UI_Dropdowns,
      },
      {
        path: "ui-grid",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: UI_Grid,
      },
      {
        path: "ui-images",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: UI_Images,
      },
      {
        path: "ui-lightbox",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: UI_Lightbox,
      },
      {
        path: "ui-media",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: UI_Media,
      },
      {
        path: "ui-modals",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: UI_Modals,
      },
      {
        path: "ui-offcanvas",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: UI_Offcanvas,
      },
      {
        path: "ui-pagination",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: UI_Pagination,
      },
      {
        path: "ui-popovers",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: UI_Popovers,
      },
      {
        path: "ui-progress",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: UI_Progress,
      },
      {
        path: "ui-placeholders",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: UI_Placeholders,
      },
      {
        path: "ui-rangeslider",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: UI_Rangeslider,
      },
      {
        path: "ui-spinner",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: UI_Spinner,
      },
      {
        path: "ui-sweetalerts",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: UI_Sweetalerts,
      },
      {
        path: "ui-tooltips",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: UI_Tooltips,
      },
      {
        path: "ui-typography",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: UI_Typography,
      },
      {
        path: "ui-video",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: UI_Video,
      },
    ],
  },
  {
    path: "/advancedui",

    meta: {
      requiresAuth: true,
      permissionsRequired: [],
    },
    component: Advancedui,
    children: [
      { path: "", redirect: "/advancedui/ui-ribbon" },
      {
        path: "ui-ribbon",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: UI_Ribbon,
      },
      {
        path: "ui-clipboard",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: UI_Clipboard,
      },
      {
        path: "ui-drag-drop",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: UI_Drag_Drop,
      },
      {
        path: "ui-text-editor",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: UI_Text_Editor,
      },
      {
        path: "ui-counter",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: UI_Counter,
      },
      {
        path: "ui-scrollbar",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: UI_Scrollbar,
      },
      {
        path: "ui-rating",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: UI_Rating,
      },
      {
        path: "ui-stickynote",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: UI_Stickynote,
      },
      {
        path: "ui-timeline",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: UI_Timeline,
      },
    ],
  },
  {
    path: "/tables",

    meta: {
      requiresAuth: true,
      permissionsRequired: [],
    },
    component: Tables,
    children: [
      { path: "", redirect: "/tables/data-tables" },
      {
        path: "data-tables",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Data_Tables,
      },
      {
        path: "tables-basic",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Tables_Basic,
      },
    ],
  },
  {
    path: "/charts",

    meta: {
      requiresAuth: true,
      permissionsRequired: [],
    },
    component: Charts,
    children: [
      { path: "", redirect: "/charts/chart-apex" },
      {
        path: "chart-apex",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Chartapex,
      },
      {
        path: "chart-c3",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Chartc3,
      },
      {
        path: "chart-flot",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Chartflot,
      },
      {
        path: "chart-js",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Chartjs,
      },
      {
        path: "chart-morris",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Chartmorris,
      },
    ],
  },
  {
    path: "/forms",

    meta: {
      requiresAuth: true,
      permissionsRequired: [],
    },
    component: Forms,
    children: [
      { path: "", redirect: "/forms/form-basic-inputs" },
      {
        path: "form-basic-inputs",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Formbasicinput,
      },
      {
        path: "form-checkbox-radios",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Formcheckboxradios,
      },
      {
        path: "form-grid-gutters",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Formgridgutters,
      },
      {
        path: "form-input-groups",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: FormInput,
      },
      {
        path: "form-select",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Formselect,
      },
      {
        path: "form-mask",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Formmask,
      },
      {
        path: "form-fileupload",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Formfileupload,
      },
      {
        path: "form-horizontal",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: FormHorizontal,
      },
      {
        path: "form-vertical",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Formvertical,
      },
      {
        path: "form-floating-labels",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Formfloatinglabels,
      },
      {
        path: "form-validation",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Formvalidation,
      },
      {
        path: "form-select2",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Formselect2,
      },
      {
        path: "form-wizard",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Formwizard,
      },
    ],
  },
  {
    path: "/call",

    meta: {
      requiresAuth: true,
      permissionsRequired: [],
    },
    component: Call,
    children: [
      { path: "", redirect: "/call/video-call" },
      {
        path: "video-call",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: VideoCall,
      },
      {
        path: "audio-call",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: AudioCall,
      },
      {
        path: "call-history",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: CallHistory,
      },
    ],
  },
  {
    path: "/dashboard",

    meta: {
      requiresAuth: true,
      permissionsRequired: [],
    },
    component: Dashboard,
    children: [
      { path: "", redirect: "/dashboard/admin-dashboard" },
      {
        path: "admin-dashboard",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: AdminDashboard,
      },
      {
        path: "sales-dashboard",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: SalesDashboard,
      },
    ],
  },
  {
    path: "/stock",

    meta: {
      requiresAuth: true,
      permissionsRequired: [],
    },
    component: Stock,
    children: [
      { path: "", redirect: "/stock/manage-stocks" },
      {
        path: "manage-stocks",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: ManageStocks,
      },
      {
        path: "stock-adjustment",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: StockAdjustment,
      },
      {
        path: "stock-transfer",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: StockTransfer,
      },
    ],
  },
  {
    path: "/leaves",

    meta: {
      requiresAuth: true,
      permissionsRequired: [],
    },
    component: Leaves,
    children: [
      { path: "", redirect: "/leaves/leaves-admin" },
      {
        path: "leaves-admin",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Leaves_Admin,
      },
      {
        path: "leaves-employee",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Leaves_Employee,
      },
      {
        path: "leave-types",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Leave_Types,
      },
    ],
  },
  {
    path: "/payroll",

    meta: {
      requiresAuth: true,
      permissionsRequired: [],
    },
    component: Payroll,
    children: [
      { path: "", redirect: "/payroll/payroll-list" },
      {
        path: "payroll-list",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Payroll_List,
      },
      {
        path: "payslip",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Payslip,
      },
    ],
  },
  {
    path: "/hrm",

    meta: {
      requiresAuth: true,
      permissionsRequired: [],
    },
    component: HRM,
    children: [
      { path: "", redirect: "/hrm/employees-grid" },
      {
        path: "employees-grid",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Employees_Grid,
      },
      {
        path: "employees-list",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Employees_List,
      },
      {
        path: "add-employee",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Add_Employee,
      },
      {
        path: "edit-employee",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Edit_Employee,
      },
      {
        path: "department-grid",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Department_Grid,
      },
      {
        path: "department-list",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Department_List,
      },
      {
        path: "designation",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Designation,
      },
      {
        path: "shift",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Shift,
      },
      {
        path: "attendance-employee",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Attendance_Employee,
      },
      {
        path: "attendance-admin",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Attendance_Admin,
      },
      {
        path: "holidays",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Holidays,
      },
    ],
  },

  {
    path: "/purchases",

    meta: {
      requiresAuth: true,
      permissionsRequired: [],
    },
    component: Purchases,
    children: [
      { path: "", redirect: "/purchases/purchase-list" },
      {
        path: "purchase-list",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: PurchaseList,
      },
      {
        path: "purchase-order-report",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: PurchaseOrderReport,
      },
      {
        path: "purchase-returns",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: PurchaseReturns,
      },
      {
        path: "cancelled-order",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: CancelledOrder,
      },
    ],
  },
  {
    path: "/sales",

    meta: {
      requiresAuth: true,
      permissionsRequired: [],
    },
    component: Sales,
    children: [
      { path: "", redirect: "/sales/sales-list" },
      {
        path: "sales-list",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: SalesList,
      },
      {
        path: "invoice-report",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: InvoiceReport,
      },
      {
        path: "sales-returns",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: SalesReturns,
      },
      {
        path: "pos",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: POS,
      },
      {
        path: "quotation-list",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: Quotation_List,
      },
    ],
  },

  // my routes

  {
    path: "/sales-notification",
    meta: {
      requiresAuth: true,
      permissionsRequired: [],
    },
    component: Sales_Notification,
  },

  {
    path: "/Widthrawal",
    meta: {
      requiresAuth: true,
      permissionsRequired: [],
    },
    component: Widthrawal,
  },
  {
    path: "/widthrawal-details",
    meta: {
      requiresAuth: true,
      permissionsRequired: [],
    },
    component: Widthrawal_Details,
  },
];

export const router = createRouter({
  history: createWebHistory("/"),
  linkActiveClass: "active",
  routes,
});

router.beforeEach((to, from, next) => {
  // alert(isAuth());
  if (to.meta.requiresAuth) {
    if (isAuth()) {
      if (to.name == "") {
        next("/dashboard/sales-dashboard");
      }
      next();
    } else {
      next("/");
    }
    return;
  }

  // Scroll to the top of the page
  window.scrollTo({ top: 0, behavior: "smooth" });
  // Continue with the navigation
  next();
});
