import axios from "axios";
import { router } from "@/router";
import { getAccessToken } from "@/utils";
import Swal from "sweetalert2";
import { adminUrl } from "@/constants/index";

const api = axios.create({
  baseURL: adminUrl,
  validateStatus: function (status) {
    if (status == 401) {
      if (getAccessToken() == null) {
        Swal.fire("Access Denied", "", "error");
        return false;
      }
      return false;
    } else if (status == 200 || status == 201) {
      return true;
    }
    return false;
  },
});

/**
 * This Handles the Authentication Properly
 */
api.interceptors.response.use(
  function (config) {
    Swal.close();
    return config;
  },
  function (error) {
    if (error.response.data.message.match(/authenticated/)) {
      Swal.fire({
        title: "Admin not Logged in",
        icon: "error",
      });
      router.push({ name: "signin" });
      localStorage.clear();
    } else {
      Swal.fire({
        title: error.response.data.message,
        icon: "error",
      });
      throw error;
    }
  }
);

export { api };
