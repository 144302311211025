<template>
  <!-- Main Wrapper -->
  
    <layout-header></layout-header>
    <layout-sidebar></layout-sidebar>

    <!-- Page Wrapper -->
    <div class="page-wrapper">
      <div class="content container-fluid"> 
        <div class="row">
          <!-- Chart -->
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">Fontawesome Icon</h5>
              </div>
              <div class="card-body">
                <div class="icons-items">
                  <ul class="icons-list">
                    <li>
                      <i
                        class="fa fa-address-book"
                        data-bs-toggle="tooltip"
                        title="fa fa-address-book"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-address-card"
                        data-bs-toggle="tooltip"
                        title="fa fa-address-card"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-align-center"
                        data-bs-toggle="tooltip"
                        title="fa fa-align-center"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-align-justify"
                        data-bs-toggle="tooltip"
                        title="fa fa-align-justify"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-align-left"
                        data-bs-toggle="tooltip"
                        title="fa fa-align-left"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-align-right"
                        data-bs-toggle="tooltip"
                        title="fa fa-align-right"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-ambulance"
                        data-bs-toggle="tooltip"
                        title="fa fa-ambulance"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-american-sign-language-interpreting"
                        data-bs-toggle="tooltip"
                        title="fa fa-american-sign-language-interpreting"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-anchor"
                        data-bs-toggle="tooltip"
                        title="fa fa-anchor"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-angle-double-down"
                        data-bs-toggle="tooltip"
                        title="fa fa-angle-double-down"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-angle-double-left"
                        data-bs-toggle="tooltip"
                        title="fa fa-angle-double-left"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-angle-double-right"
                        data-bs-toggle="tooltip"
                        title="fa fa-angle-double-right"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-angle-double-up"
                        data-bs-toggle="tooltip"
                        title="fa fa-angle-double-up"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-angle-down"
                        data-bs-toggle="tooltip"
                        title="fa fa-angle-down"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-angle-left"
                        data-bs-toggle="tooltip"
                        title="fa fa-angle-left"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-angle-right"
                        data-bs-toggle="tooltip"
                        title="fa fa-angle-right"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-angle-up"
                        data-bs-toggle="tooltip"
                        title="fa fa-angle-up"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fab fa-apple"
                        data-bs-toggle="tooltip"
                        title="fab fa-apple"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-archive"
                        data-bs-toggle="tooltip"
                        title="fa fa-archive"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fas fa-chart-area"
                        data-bs-toggle="tooltip"
                        title="fas fa-chart-area"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-arrow-circle-down"
                        data-bs-toggle="tooltip"
                        title="fa fa-arrow-circle-down"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-arrow-circle-left"
                        data-bs-toggle="tooltip"
                        title="fa fa-arrow-circle-left"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-arrow-circle-right"
                        data-bs-toggle="tooltip"
                        title="fa fa-arrow-circle-right"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-arrow-circle-up"
                        data-bs-toggle="tooltip"
                        title="fa fa-arrow-circle-up"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-arrow-down"
                        data-bs-toggle="tooltip"
                        title="fa fa-arrow-down"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-arrow-left"
                        data-bs-toggle="tooltip"
                        title="fa fa-arrow-left"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-arrow-right"
                        data-bs-toggle="tooltip"
                        title="fa fa-arrow-right"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-arrow-up"
                        data-bs-toggle="tooltip"
                        title="fa fa-arrow-up"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-arrows-alt"
                        data-bs-toggle="tooltip"
                        title="fa fa-arrows-alt"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-assistive-listening-systems"
                        data-bs-toggle="tooltip"
                        title="fa fa-assistive-listening-systems"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-asterisk"
                        data-bs-toggle="tooltip"
                        title="fa fa-asterisk"
                      ></i>
                    </li>
                    <li>
                      <i class="fa fa-at" data-bs-toggle="tooltip" title="fa fa-at"></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-audio-description"
                        data-bs-toggle="tooltip"
                        title="fa fa-audio-description"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-backward"
                        data-bs-toggle="tooltip"
                        title="fa fa-backward"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-balance-scale"
                        data-bs-toggle="tooltip"
                        title="fa fa-balance-scale"
                      ></i>
                    </li>
                    <li>
                      <i class="fa fa-ban" data-bs-toggle="tooltip" title="fa fa-ban"></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-barcode"
                        data-bs-toggle="tooltip"
                        title="fa fa-barcode"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-bars"
                        data-bs-toggle="tooltip"
                        title="fa fa-bars"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-bath"
                        data-bs-toggle="tooltip"
                        title="fa fa-bath"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-battery-empty"
                        data-bs-toggle="tooltip"
                        title="fa fa-battery-empty"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-battery-full"
                        data-bs-toggle="tooltip"
                        title="fa fa-battery-full"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-battery-half"
                        data-bs-toggle="tooltip"
                        title="fa fa-battery-half"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-battery-quarter"
                        data-bs-toggle="tooltip"
                        title="fa fa-battery-quarter"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-battery-three-quarters"
                        data-bs-toggle="tooltip"
                        title="fa fa-battery-three-quarters"
                      ></i>
                    </li>
                    <li>
                      <i class="fa fa-bed" data-bs-toggle="tooltip" title="fa fa-bed"></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-beer"
                        data-bs-toggle="tooltip"
                        title="fa fa-beer"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-bell"
                        data-bs-toggle="tooltip"
                        title="fa fa-bell"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-bell-slash"
                        data-bs-toggle="tooltip"
                        title="fa fa-bell-slash"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-bicycle"
                        data-bs-toggle="tooltip"
                        title="fa fa-bicycle"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-binoculars"
                        data-bs-toggle="tooltip"
                        title="fa fa-binoculars"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-birthday-cake"
                        data-bs-toggle="tooltip"
                        title="fa fa-birthday-cake"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-blind"
                        data-bs-toggle="tooltip"
                        title="fa fa-blind"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-bold"
                        data-bs-toggle="tooltip"
                        title="fa fa-bold"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-bolt"
                        data-bs-toggle="tooltip"
                        title="fa fa-bolt"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-bomb"
                        data-bs-toggle="tooltip"
                        title="fa fa-bomb"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-book"
                        data-bs-toggle="tooltip"
                        title="fa fa-book"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-bookmark"
                        data-bs-toggle="tooltip"
                        title="fa fa-bookmark"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-braille"
                        data-bs-toggle="tooltip"
                        title="fa fa-braille"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-briefcase"
                        data-bs-toggle="tooltip"
                        title="fa fa-briefcase"
                      ></i>
                    </li>
                    <li>
                      <i class="fa fa-bug" data-bs-toggle="tooltip" title="fa fa-bug"></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-building"
                        data-bs-toggle="tooltip"
                        title="fa fa-building"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-bullhorn"
                        data-bs-toggle="tooltip"
                        title="fa fa-bullhorn"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-bullseye"
                        data-bs-toggle="tooltip"
                        title="fa fa-bullseye"
                      ></i>
                    </li>
                    <li>
                      <i class="fa fa-bus" data-bs-toggle="tooltip" title="fa fa-bus"></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-calculator"
                        data-bs-toggle="tooltip"
                        title="fa fa-calculator"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-calendar"
                        data-bs-toggle="tooltip"
                        title="fa fa-calendar"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-camera"
                        data-bs-toggle="tooltip"
                        title="fa fa-camera"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-camera-retro"
                        data-bs-toggle="tooltip"
                        title="fa fa-camera-retro"
                      ></i>
                    </li>
                    <li>
                      <i class="fa fa-car" data-bs-toggle="tooltip" title="fa fa-car"></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-caret-down"
                        data-bs-toggle="tooltip"
                        title="fa fa-caret-down"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-caret-left"
                        data-bs-toggle="tooltip"
                        title="fa fa-caret-left"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-caret-right"
                        data-bs-toggle="tooltip"
                        title="fa fa-caret-right"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-cart-arrow-down"
                        data-bs-toggle="tooltip"
                        title="fa fa-cart-arrow-down"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-cart-plus"
                        data-bs-toggle="tooltip"
                        title="fa fa-cart-plus"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-certificate"
                        data-bs-toggle="tooltip"
                        title="fa fa-certificate"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-check"
                        data-bs-toggle="tooltip"
                        title="fa fa-check"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-check-circle"
                        data-bs-toggle="tooltip"
                        title="fa fa-check-circle"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-chevron-circle-left"
                        data-bs-toggle="tooltip"
                        title="fa fa-chevron-circle-left"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-chevron-circle-right"
                        data-bs-toggle="tooltip"
                        title="fa fa-chevron-circle-right"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-chevron-circle-up"
                        data-bs-toggle="tooltip"
                        title="fa fa-chevron-circle-up"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-chevron-down"
                        data-bs-toggle="tooltip"
                        title="fa fa-chevron-down"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-chevron-left"
                        data-bs-toggle="tooltip"
                        title="fa fa-chevron-left"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-chevron-right"
                        data-bs-toggle="tooltip"
                        title="fa fa-chevron-right"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-chevron-up"
                        data-bs-toggle="tooltip"
                        title="fa fa-chevron-up"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-child"
                        data-bs-toggle="tooltip"
                        title="fa fa-child"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-circle"
                        data-bs-toggle="tooltip"
                        title="fa fa-circle"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-clipboard"
                        data-bs-toggle="tooltip"
                        title="fa fa-clipboard"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-clone"
                        data-bs-toggle="tooltip"
                        title="fa fa-clone"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-cloud"
                        data-bs-toggle="tooltip"
                        title="fa fa-cloud"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-code"
                        data-bs-toggle="tooltip"
                        title="fa fa-code"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-coffee"
                        data-bs-toggle="tooltip"
                        title="fa fa-coffee"
                      ></i>
                    </li>
                    <li>
                      <i class="fa fa-cog" data-bs-toggle="tooltip" title="fa fa-cog"></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-cogs"
                        data-bs-toggle="tooltip"
                        title="fa fa-cogs"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-columns"
                        data-bs-toggle="tooltip"
                        title="fa fa-columns"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-comment"
                        data-bs-toggle="tooltip"
                        title="fa fa-comment"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-compress"
                        data-bs-toggle="tooltip"
                        title="fa fa-compress"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-copyright"
                        data-bs-toggle="tooltip"
                        title="fa fa-copyright"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-credit-card"
                        data-bs-toggle="tooltip"
                        title="fa fa-credit-card"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-desktop"
                        data-bs-toggle="tooltip"
                        title="fa fa-desktop"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-edit"
                        data-bs-toggle="tooltip"
                        title="fa fa-edit"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-eject"
                        data-bs-toggle="tooltip"
                        title="fa fa-eject"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-ellipsis-h"
                        data-bs-toggle="tooltip"
                        title="fa fa-ellipsis-h"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-ellipsis-v"
                        data-bs-toggle="tooltip"
                        title="fa fa-ellipsis-v"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-envelope"
                        data-bs-toggle="tooltip"
                        title="fa fa-envelope"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-envelope-open"
                        data-bs-toggle="tooltip"
                        title="fa fa-envelope-open"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-envelope-square"
                        data-bs-toggle="tooltip"
                        title="fa fa-envelope-square"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-eraser"
                        data-bs-toggle="tooltip"
                        title="fa fa-eraser"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-exclamation"
                        data-bs-toggle="tooltip"
                        title="fa fa-exclamation"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-exclamation-circle"
                        data-bs-toggle="tooltip"
                        title="fa fa-exclamation-circle"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-exclamation-triangle"
                        data-bs-toggle="tooltip"
                        title="fa fa-exclamation-triangle"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-expand"
                        data-bs-toggle="tooltip"
                        title="fa fa-expand"
                      ></i>
                    </li>
                    <li>
                      <i class="fa fa-eye" data-bs-toggle="tooltip" title="fa fa-eye"></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-eye-slash"
                        data-bs-toggle="tooltip"
                        title="fa fa-eye-slash"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-fast-backward"
                        data-bs-toggle="tooltip"
                        title="fa fa-fast-backward"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-fast-forward"
                        data-bs-toggle="tooltip"
                        title="fa fa-fast-forward"
                      ></i>
                    </li>
                    <li>
                      <i class="fa fa-fax" data-bs-toggle="tooltip" title="fa fa-fax"></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-female"
                        data-bs-toggle="tooltip"
                        title="fa fa-female"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-fighter-jet"
                        data-bs-toggle="tooltip"
                        title="fa fa-fighter-jet"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-file"
                        data-bs-toggle="tooltip"
                        title="fa fa-file"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-fire"
                        data-bs-toggle="tooltip"
                        title="fa fa-fire"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-fire-extinguisher"
                        data-bs-toggle="tooltip"
                        title="fa fa-fire-extinguisher"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-flag"
                        data-bs-toggle="tooltip"
                        title="fa fa-flag"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-flag-checkered"
                        data-bs-toggle="tooltip"
                        title="fa fa-flag-checkered"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-road"
                        data-bs-toggle="tooltip"
                        title="fa fa-road"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-rocket"
                        data-bs-toggle="tooltip"
                        title="fa fa-rocket"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-save"
                        data-bs-toggle="tooltip"
                        title="fa fa-save"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-search"
                        data-bs-toggle="tooltip"
                        title="fa fa-search"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-search-minus"
                        data-bs-toggle="tooltip"
                        title="fa fa-search-minus"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-search-plus"
                        data-bs-toggle="tooltip"
                        title="fa fa-search-plus"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-server"
                        data-bs-toggle="tooltip"
                        title="fa fa-server"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-share"
                        data-bs-toggle="tooltip"
                        title="fa fa-share"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-share-alt"
                        data-bs-toggle="tooltip"
                        title="fa fa-share-alt"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-share-alt-square"
                        data-bs-toggle="tooltip"
                        title="fa fa-share-alt-square"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-share-square"
                        data-bs-toggle="tooltip"
                        title="fa fa-share-square"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-ship"
                        data-bs-toggle="tooltip"
                        title="fa fa-ship"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-shopping-bag"
                        data-bs-toggle="tooltip"
                        title="fa fa-shopping-bag"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-shopping-basket"
                        data-bs-toggle="tooltip"
                        title="fa fa-shopping-basket"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-shopping-cart"
                        data-bs-toggle="tooltip"
                        title="fa fa-shopping-cart"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-shower"
                        data-bs-toggle="tooltip"
                        title="fa fa-shower"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-sign-language"
                        data-bs-toggle="tooltip"
                        title="fa fa-sign-language"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-signal"
                        data-bs-toggle="tooltip"
                        title="fa fa-signal"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-sitemap"
                        data-bs-toggle="tooltip"
                        title="fa fa-sitemap"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-sort"
                        data-bs-toggle="tooltip"
                        title="fa fa-sort"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-sort-down"
                        data-bs-toggle="tooltip"
                        title="fa fa-sort-down"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-square"
                        data-bs-toggle="tooltip"
                        title="fa fa-square"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-star"
                        data-bs-toggle="tooltip"
                        title="fa fa-star"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-star-half"
                        data-bs-toggle="tooltip"
                        title="fa fa-star-half"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-step-backward"
                        data-bs-toggle="tooltip"
                        title="fa fa-step-backward"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-step-forward"
                        data-bs-toggle="tooltip"
                        title="fa fa-step-forward"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-stethoscope"
                        data-bs-toggle="tooltip"
                        title="fa fa-stethoscope"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-sticky-note"
                        data-bs-toggle="tooltip"
                        title="fa fa-sticky-note"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-stop"
                        data-bs-toggle="tooltip"
                        title="fa fa-stop"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-stop-circle"
                        data-bs-toggle="tooltip"
                        title="fa fa-stop-circle"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-street-view"
                        data-bs-toggle="tooltip"
                        title="fa fa-street-view"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-subscript"
                        data-bs-toggle="tooltip"
                        title="fa fa-subscript"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-suitcase"
                        data-bs-toggle="tooltip"
                        title="fa fa-suitcase"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-superscript"
                        data-bs-toggle="tooltip"
                        title="fa fa-superscript"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-table"
                        data-bs-toggle="tooltip"
                        title="fa fa-table"
                      ></i>
                    </li>
                    <li>
                      <i class="fa fa-tag" data-bs-toggle="tooltip" title="fa fa-tag"></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-tags"
                        data-bs-toggle="tooltip"
                        title="fa fa-tags"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-tasks"
                        data-bs-toggle="tooltip"
                        title="fa fa-tasks"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-taxi"
                        data-bs-toggle="tooltip"
                        title="fa fa-taxi"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-terminal"
                        data-bs-toggle="tooltip"
                        title="fa fa-terminal"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-text-height"
                        data-bs-toggle="tooltip"
                        title="fa fa-text-height"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-text-width"
                        data-bs-toggle="tooltip"
                        title="fa fa-text-width"
                      ></i>
                    </li>
                    <li>
                      <i class="fa fa-th" data-bs-toggle="tooltip" title="fa fa-th"></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-th-large"
                        data-bs-toggle="tooltip"
                        title="fa fa-th-large"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-th-list"
                        data-bs-toggle="tooltip"
                        title="fa fa-th-list"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-thermometer"
                        data-bs-toggle="tooltip"
                        title="fa fa-thermometer"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-thermometer-empty"
                        data-bs-toggle="tooltip"
                        title="fa fa-thermometer-empty"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-thermometer-full"
                        data-bs-toggle="tooltip"
                        title="fa fa-thermometer-full"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-thermometer-half"
                        data-bs-toggle="tooltip"
                        title="fa fa-thermometer-half"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-thermometer-quarter"
                        data-bs-toggle="tooltip"
                        title="fa fa-thermometer-quarter"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-thermometer-three-quarters"
                        data-bs-toggle="tooltip"
                        title="fa fa-thermometer-three-quarters"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-thumbs-down"
                        data-bs-toggle="tooltip"
                        title="fa fa-thumbs-down"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-thumbs-up"
                        data-bs-toggle="tooltip"
                        title="fa fa-thumbs-up"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-times"
                        data-bs-toggle="tooltip"
                        title="fa fa-times"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-times-circle"
                        data-bs-toggle="tooltip"
                        title="fa fa-times-circle"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-tint"
                        data-bs-toggle="tooltip"
                        title="fa fa-tint"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-toggle-off"
                        data-bs-toggle="tooltip"
                        title="fa fa-toggle-off"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-toggle-on"
                        data-bs-toggle="tooltip"
                        title="fa fa-toggle-on"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-trademark"
                        data-bs-toggle="tooltip"
                        title="fa fa-trademark"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-train"
                        data-bs-toggle="tooltip"
                        title="fa fa-train"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-transgender"
                        data-bs-toggle="tooltip"
                        title="fa fa-transgender"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-transgender-alt"
                        data-bs-toggle="tooltip"
                        title="fa fa-transgender-alt"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-trash"
                        data-bs-toggle="tooltip"
                        title="fa fa-trash"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-tree"
                        data-bs-toggle="tooltip"
                        title="fa fa-tree"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-trophy"
                        data-bs-toggle="tooltip"
                        title="fa fa-trophy"
                      ></i>
                    </li>
                    <li>
                      <i class="fa fa-tty" data-bs-toggle="tooltip" title="fa fa-tty"></i>
                    </li>
                    <li>
                      <i class="fa fa-tv" data-bs-toggle="tooltip" title="fa fa-tv"></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-umbrella"
                        data-bs-toggle="tooltip"
                        title="fa fa-umbrella"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-underline"
                        data-bs-toggle="tooltip"
                        title="fa fa-underline"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-undo"
                        data-bs-toggle="tooltip"
                        title="fa fa-undo"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-universal-access"
                        data-bs-toggle="tooltip"
                        title="fa fa-universal-access"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-university"
                        data-bs-toggle="tooltip"
                        title="fa fa-university"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-unlink"
                        data-bs-toggle="tooltip"
                        title="fa fa-unlink"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-unlock"
                        data-bs-toggle="tooltip"
                        title="fa fa-unlock"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-unlock-alt"
                        data-bs-toggle="tooltip"
                        title="fa fa-unlock-alt"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-upload"
                        data-bs-toggle="tooltip"
                        title="fa fa-upload"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-user-circle"
                        data-bs-toggle="tooltip"
                        title="fa fa-user-circle"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-user-md"
                        data-bs-toggle="tooltip"
                        title="fa fa-user-md"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-user-plus"
                        data-bs-toggle="tooltip"
                        title="fa fa-user-plus"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-user-secret"
                        data-bs-toggle="tooltip"
                        title="fa fa-user-secret"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-user-times"
                        data-bs-toggle="tooltip"
                        title="fa fa-user-times"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-users"
                        data-bs-toggle="tooltip"
                        title="fa fa-users"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-venus"
                        data-bs-toggle="tooltip"
                        title="fa fa-venus"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-venus-double"
                        data-bs-toggle="tooltip"
                        title="fa fa-venus-double"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-venus-mars"
                        data-bs-toggle="tooltip"
                        title="fa fa-venus-mars"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-volume-down"
                        data-bs-toggle="tooltip"
                        title="fa fa-volume-down"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-volume-off"
                        data-bs-toggle="tooltip"
                        title="fa fa-volume-off"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-volume-up"
                        data-bs-toggle="tooltip"
                        title="fa fa-volume-up"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-wheelchair"
                        data-bs-toggle="tooltip"
                        title="fa fa-wheelchair"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-wifi"
                        data-bs-toggle="tooltip"
                        title="fa fa-wifi"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-window-close"
                        data-bs-toggle="tooltip"
                        title="fa fa-window-close"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-window-maximize"
                        data-bs-toggle="tooltip"
                        title="fa fa-window-maximize"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-window-minimize"
                        data-bs-toggle="tooltip"
                        title="fa fa-window-minimize"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-window-restore"
                        data-bs-toggle="tooltip"
                        title="fa fa-window-restore"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="fa fa-wrench"
                        data-bs-toggle="tooltip"
                        title="fa fa-wrench"
                      ></i>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!-- /Chart -->
        </div>
      </div>
    </div>
    <!-- /Page Wrapper -->
 
  <!-- /Main Wrapper -->
</template>
<script>
export default {
  data() {
    return {
      title: "Fontawesome Icon",
    };
  },
};
</script>
