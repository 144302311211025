<template>
  <!-- Add User -->
  <div class="modal fade" id="add-units">
    <div class="modal-dialog modal-dialog-centered custom-modal-two">
      <div class="modal-content">
        <div class="page-wrapper-new p-0">
          <div class="content">
            <div class="modal-header border-0 custom-modal-header">
              <div class="page-title">
                <h4>Add User</h4>
              </div>
              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body custom-modal-body">
              <form @submit.prevent="submitForm">
                <div class="row">
                  <div class="col-lg-12" hidden>
                    <div class="new-employee-field">
                      <span>Avatar</span>
                      <div class="profile-pic-upload mb-2">
                        <div class="profile-pic">
                          <span
                            ><i
                              data-feather="plus-circle"
                              class="plus-down-add"
                            ></i>
                            Profile Photo</span
                          >
                        </div>
                        <div class="input-blocks mb-0">
                          <div class="image-upload mb-0">
                            <input type="file" />
                            <div class="image-uploads">
                              <h4>Change Image</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="input-blocks">
                      <label>Sur Name</label>
                      <input type="text" class="form-control" v-model="fname" />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="input-blocks">
                      <label>Other Name(s)</label>
                      <input type="text" class="form-control" v-model="lname" />
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="input-blocks">
                      <label>Email</label>
                      <input
                        type="email"
                        class="form-control"
                        v-model="email"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="input-blocks">
                      <label>Mobile Number</label>
                      <input
                        type="mobile_number"
                        class="form-control"
                        v-model="mobile_number"
                      />
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="input-blocks">
                      <label>Password</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="password"
                      />
                    </div>
                  </div>
                </div>

                <div class="modal-footer-btn">
                  <button
                    type="button"
                    class="btn btn-cancel me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <div class="btn btn-submit" @click="add_user()">Submit</div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add User -->
</template>
<script>
import { mapActions } from "vuex";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
export default {
  data() {
    return {
      fname: "",
      lname: "",
      email: "",
      password: "",
      role: "",
      tag: "",
      otp: "",
      sent: false,
      mobile_number:''
    };
  },
  methods: {
    // submitForm() {
    //   this.$router.push("/users/users-list");
    // },
    ...mapActions({
      _add_user: "admin/add_user",
    }),

    add_user() {
      $(".inloader").show();
      this._add_user({
        surname: this.fname,
        other_names: this.lname,
        email: this.email,
        password: this.password,
        mobile_number: this.mobile_number,
      })
        .then((response) => {
          $(".inloader").hide();
          $("#add-units").modal("hide");

          toast.success("User Added", {
            position: toast.POSITION.TOP_RIGHT,
          });
          this.response = response.data;
          //   this.response
          if (this.response["status"] == true) {
            var data = this.response.data;
            // this.sent = true;
            window.location.reload();
          }
        })
        .catch((error) => {
          $(".inloader").hide();

          console.log("Error logging in", error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;
            if (
              status == 400 ||
              status == 422 ||
              status == 401 ||
              status == 404
            ) {
              toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            } else if (status === 500) {
              toast.error("Network Error, Check your internet conectivity.", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          }
        });
    },
  },
};
</script>
<style scoped>
.in_message {
  font-size: 12px;
}
</style>
