<template>
  <div>
    <div class="loader-wrapper">
        <div class="loader">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
        </div>
        <!-- <p>Loading...</p> -->
    </div>

  </div>
</template>


<script>
import { mapActions } from "vuex";
export default {
  name: "TwiiterOauth",
  data() {
    return {
      // fullPage: true,
      // loader: "spinner",
      // isLoading: true,
    };
  },
  methods: {
    ...mapActions("authentication", ["authTwitter"])
  },
  mounted() {
       const codeToken = this.$route.query.code;
       const codeEmail = localStorage.getItem("twitterEmail");
       const tokenDetails = {
        code: codeToken,
        email: codeEmail,
        account_type: "Individual",
        otp: 1,
        news: 1,
        signup_platform: "Web",
      };

    this.authTwitter(tokenDetails);
   
  },
};
</script>
<style>
.loader-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f0f0; /* Background color can be adjusted */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dot {
  width: 15px;
  height: 15px;
  background-color: #3498db; /* Color of the loader dots */
  border-radius: 50%;
  margin: 0 5px;
  animation: bounce 1.5s infinite;
}

.dot:nth-child(2) {
  animation-delay: 0.3s;
}

.dot:nth-child(3) {
  animation-delay: 0.6s;
}

.dot:nth-child(4) {
  animation-delay: 0.9s;
}

.dot:nth-child(5) {
  animation-delay: 1.2s;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}

p {
  margin-top: 15px;
  font-size: 18px;
  color: #555; /* Text color can be adjusted */
}
</style>
