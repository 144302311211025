<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  <div class="page-wrapper">
    <div class="content">
      <div class="page-header">
        <div class="page-title">
          <h4>Product Details</h4>
          <h6>Full details of a product</h6>
        </div>
      </div>
      <!-- /add -->
      <div class="row">
        <div class="col-lg-8 col-sm-12">
          <div class="card">
            <div class="card-body">
              <div class="bar-code-view">
                <img src="@/assets/img/barcode/barcode1.png" alt="barcode" />
                <a class="printimg">
                  <img src="@/assets/img/icons/printer.svg" alt="print" />
                </a>
              </div>
              <div class="productdetails">
                <ul class="product-bar">
                  <li>
                    <h4>Product</h4>
                    <h6>{{ data.name }}</h6>
                  </li>
                  <li>
                    <h4>Slug</h4>
                    <h6>{{ data.slug }}</h6>
                  </li>
                  <li>
                    <h4>Title</h4>
                    <h6>{{ data.title }}</h6>
                  </li>
                  <li>
                    <h4>Bost Type</h4>
                    <h6>{{ data.boost_type }}</h6>
                  </li>

                  <li>
                    <h4>Original Price</h4>
                    <h6>{{ data.original_price }}</h6>
                  </li>
                  <li>
                    <h4>Selling Price</h4>
                    <h6>{{ data.selling_price }}</h6>
                  </li>

                  <li>
                    <h4>Quantity</h4>
                    <h6>{{ data.quantity }}</h6>
                  </li>
                  <li>
                    <h4>Condition</h4>
                    <h6>{{ data.condition }}</h6>
                  </li>
                  <li>
                    <h4>Total Stars</h4>
                    <h6>{{ data.total_stars }}</h6>
                  </li>
                  <li>
                    <h4>Status</h4>
                    <h6>{{ data.status }}</h6>
                  </li>
                  <li>
                    <h4>Description</h4>
                    <h6>{{ data.description }}</h6>
                  </li>
                  <li>
                    <h4>Created By</h4>
                    <h6>{{ data.vendor.name }}</h6>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-sm-12">
          <div class="card">
            <div class="card-body">
              <div class="slider-product-details">
                <div class="owl-carousel owl-theme product-slide">
                  <div class="slider-product">
                    <img :src="data.thumbnail" alt="img" class="detail-img" />
                    <!-- <h4>macbookpro.jpg</h4>
                    <h6>581kb</h6> -->

                    <div class="color-option">
                      <img
                        :src="data.url"
                        v-for="(data, index) in data?.images"
                        :key="index"
                        @click="change_varaint_product(data)"
                        class=""
                        style="margin: 10px"
                      />
                      <!-- <p>{{ data?.values?.Color?.value }}</p> -->
                    </div>
                  </div>
                  <!-- <div class="slider-product">
                    <img src="@/assets/img/products/product69.jpg" alt="img" />
                    <h4>macbookpro.jpg</h4>
                    <h6>581kb</h6>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- /add -->
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      data: JSON.parse(localStorage.getItem("product_data")),
    };
  },
};
</script>

<style>
.detail-img {
  height: 300px;
  width: 600px;
}

.color-option {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}

.color-option img {
  width: 60px;
  height: 60px;
  display: block;
  /* margin: auto; */
  /* margin-bottom: 5px; */
  display: inline-block;
  margin: 20px;
  margin-left: 20px;
  margin-right: 20px;
}
</style>
