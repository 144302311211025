// Function to set the access token
export function setAccessToken(token) {
  localStorage.setItem("token", token);
}

// Function to get the access token
export function getAccessToken() {
  return localStorage.getItem("accessToken");
}

/** Determines if the admin is logged in or not */
export function isAuth() {
  return !!getAccessToken();
}
