<template>
  <div class="account-page">
    <div class="account-content">
      <div class="login-wrapper">
        <div class="login-content">
          <div class="login-userset">
            <div class="logo-section">
              <router-link to="/">
                <img src="@/assets/img/logo.png" alt="img" class="logo" />
              </router-link>
              <h1>Naija Kobo Market</h1>
              <p>BUY | SELL | FIND ALMOST ANYTHING</p>
            </div>

            <div class="form-login">
              <!-- <div
                type="submit"
                class="btn btn-login"
                @click="openAuth(redirectUrl_account())"
              >
                <span id="btntext">Sign Up</span>
                <div id="samall_loader"></div>
              </div> -->
              <!-- <br />
              <br /> -->
              <div
                type="submit"
                class="btn btn-login"
                @click="openAuth(redirectUrl_account())"
              >
                <span id="btntext">Login</span>
                <div id="samall_loader"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="login-img">
          <img src="@/assets/img/authentication/login02.svg" alt="img" />
        </div>
      </div>
    </div>
  </div>
  <OTP />
</template>
<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

// import $ from 'jquery'
import { mapActions } from "vuex";
import { redirectUrl_account } from "@/api";
import { handleMessage } from "@/api/popup";
export default {
  components: {
    // Form,
    // Field,
  },
  data() {
    return {
      showPassword: false,
      password: null,
      email: null,
      emailError: "",
      passwordError: "",
      redirectUrl_account,
    };
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },
  methods: {
    openAuth(route) {
      const windowWidth = 700;
      const windowHeight = 600;
      const left = (window.screen.width - windowWidth) / 2 + window.screenX;
      const top = (window.screen.height - windowHeight) / 2;
      // const url = `${window.location.origin}${route}`;
      const url = `${route}?from=vendor`;

      let popup = window.open(
        url, // URL to open
        "Naija Kobo Market", // Window name
        `width=${windowWidth},height=${windowHeight},left=${left},top=${top},resizable=no`
      );
      window.addEventListener("message", handleMessage, false);
      // popup.opened = 'aaa';
    },
    ...mapActions({
      _login: "general/login",
      _loginGoogle: "authentication/loginGoogle",
      _loginTwitter: "authentication/loginTwitter",
      _loginApple: "authentication/loginApple",
      // _signUpApple: "authentication/signUpApple",
    }),
    loginGoogle() {
      this._loginGoogle();
    },
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    updateTimerDisplay(timeInSeconds) {
      // Get the timer element
      const timerElement = document.getElementById("count-down");

      const minutes = Math.floor(timeInSeconds / 60);
      const seconds = timeInSeconds % 60;
      const formattedTime = `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
      timerElement.textContent = formattedTime;
    },
    performAction() {
      $("#otp_modal").modal("hide");
      $(".xvx").show();
    },
    startCountdown() {
      const countdownTime = 5 * 60; // 5 minutes * 60 seconds
      let timeRemaining = countdownTime;
      // Update the display initially
      this.updateTimerDisplay(timeRemaining);

      let _vm = this;
      // Set an interval to update the countdown every second
      const countdownInterval = setInterval(function () {
        timeRemaining--;

        // Update the display
        _vm.updateTimerDisplay(timeRemaining);

        // Check if the countdown reached 0
        if (timeRemaining <= 0) {
          // Clear the interval
          clearInterval(countdownInterval);

          // Perform the action when the countdown reaches 0
          _vm.performAction();
        }
      }, 3000); // Update every 1000 milliseconds (1 second)
    },
    login() {
      // $("#otp_modal").modal("show");
      //     this.startCountdown();

      document.getElementById("samall_loader").style.display = "inline-block";
      $("#btntext").hide();

      this._login({
        email: this.email,
        password: this.password,
        platform: "Web",
      })
        .then((response) => {
          document.getElementById("samall_loader").style.display = "none";
          $("#btntext").show();

          this.response = response.data;
          //   this.response
          if (this.response["status"] == true) {
            var data = this.response.data;
            var token = this.response.data.token;
            console.log(data);
            localStorage.setItem("accessToken", token);
            $("#otp_modal").modal("show");
            this.startCountdown();

            // toast.success("Login successful", {
            //   position: toast.POSITION.TOP_RIGHT,
            // });
            // window.location = "authentication/lock-screen";
          }
        })
        .catch((error) => {
          document.getElementById("samall_loader").style.display = "none";
          $("#btntext").show();

          console.log("Error logging in", error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (
              status == 400 ||
              status == 422 ||
              status == 401 ||
              status == 404
            ) {
              toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            } else if (status === 500) {
              toast.error("Network Error, Check your internet conectivity.", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          }
        });
    },

    // async appleSignUp() {
    //   const response = await window.AppleID.auth.signIn();
    //   const appleMail = localStorage.getItem("appleEmail");

    //   const appleDetails = {
    //     account_type: "Individual",
    //     news: true,
    //     id_token: response.authorization.id_token,
    //     code: response.authorization.code,
    //     user: response.user,
    //     otp: true,
    //     signup_platform: "Web",
    //     email: appleMail,
    //   };
    //   this._signUpApple(appleDetails);
    // },

    // onSubmitApple(event) {
    //     event.preventDefault();
    //     const appleEmail = this.formApple.email;
    //     localStorage.setItem("appleEmail", appleEmail);
    //     this.appleSignUp();
    //     // Hide the modal manually
    //     this.$nextTick(() => {
    //       this.$bvModal.hide("modal-prevent-closing");
    //     });
    //   },
    async appleLogin() {
      const res = await window.AppleID.auth.signIn();
      const res2 = await window.AppleID.auth;
      const appleLoginDetails = {
        account_type: "Individual",
        news: 1,
        id_token: res.authorization.id_token,
        code: res.authorization.code,
        otp: 1,
        signup_platform: "Web",
        first_name: res.user.name.firstName,
        last_name: res.user.name.lastName,
        email: res.user.email,
      };
      console.log(res);
      if (res.consentRequired == true) {
        alert("go back");
      } else {
        this._loginApple(appleLoginDetails);
      }
    },
  },
  mounted() {
    $(".modal").modal("hide");
  },
};
</script>
<style>
.form-control {
  height: 50px;
}
#samall_loader {
  display: none;
  border: 6px solid #f3f3f3;
  border-top: 4px solid #00afef;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

.btn-login,
.form-login {
  width: 400px;
  text-transform: uppercase;
}
/* 
original - #FE9F43
replacement - #00afefee
*/

.logo-section {
  text-align: center;
  /* padding-left: 15%; */
  margin-bottom: 40px;
}

.logo {
  width: 200px;
  height: auto;
  margin-bottom: 10px;
}
.logo-section h1 {
  font-size: 20px;
  display: none;
}

@media only screen and (max-width: 490px) {
  .btn-login,
  .form-login {
    width: 80%;
    margin-left: 5%;
    margin-top: 10px;
  }
}
</style>
