<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  <InLoader class="inloader" />

  <div class="page-wrapper">
    <div class="content">
      <div class="page-header">
        <div class="add-item d-flex">
          <div class="page-title">
            <h4>Coupons</h4>
            <h6>Manage Your Coupons</h6>
          </div>
        </div>
        <ul class="table-top-head">
          <li>
            <a data-bs-toggle="tooltip" data-bs-placement="top" title="Pdf"><img src="@/assets/img/icons/pdf.svg"
                alt="img" /></a>
          </li>
          <li>
            <a data-bs-toggle="tooltip" data-bs-placement="top" title="Excel"><img src="@/assets/img/icons/excel.svg"
                alt="img" /></a>
          </li>
          <li>
            <a data-bs-toggle="tooltip" data-bs-placement="top" title="Print"><i data-feather="printer"
                class="feather-printer"></i></a>
          </li>
          <li>
            <a data-bs-toggle="tooltip" data-bs-placement="top" title="Refresh"><i data-feather="rotate-ccw"
                class="feather-rotate-ccw"></i></a>
          </li>
          <li>
            <a ref="collapseHeader" data-bs-toggle="tooltip" data-bs-placement="top" title="Collapse"
              @click="toggleCollapse">
              <i data-feather="chevron-up" class="feather-chevron-up"></i>
            </a>
          </li>
        </ul>
        <div class="page-btn">
          <a href="#" class="btn btn-added" data-bs-toggle="modal" data-bs-target="#add-units"><vue-feather
              type="plus-circle" class="me-2"></vue-feather>Add New
            Coupons</a>
        </div>
      </div>
      <!-- /product list -->
      <div class="card table-list-card">
        <div class="card-body">
          <div class="table-top">
            <div class="search-set">
              <div class="search-input">
                <input type="text" placeholder="Search" class="dark-input" v-model="search" @input="searchtrigger()" />
                <a href="" class="btn btn-searchset" @click="searchtrigger()"><i data-feather="search"
                    class="feather-search"></i></a>
              </div>
            </div>
            <div class="search-path" hidden>
              <div class="d-flex align-items-center">
                <a class="btn btn-filter" id="filter_search" v-on:click="filter = !filter"
                  :class="{ setclose: filter }">
                  <vue-feather type="filter" class="filter-icon"></vue-feather>
                  <span><img src="@/assets/img/icons/closes.svg" alt="img" /></span>
                </a>
                <div class="layout-hide-box">
                  <a href="javascript:void(0);" class="me-3 layout-box" @click="toggleLayoutBox"><vue-feather
                      type="layout" class="layout"></vue-feather></a>
                  <div class="layout-drop-item card">
                    <div class="drop-item-head">
                      <h5>Want to manage datatable?</h5>
                      <p>
                        Please drag and drop your column to reorder your table
                        and enable see option as you want.
                      </p>
                    </div>
                    <ul>
                      <li>
                        <div class="status-toggle modal-status d-flex justify-content-between align-items-center">
                          <span class="status-label"><i data-feather="menu" class="feather-menu"></i>Shop</span>
                          <input type="checkbox" id="option1" class="check" checked />
                          <label for="option1" class="checktoggle"></label>
                        </div>
                      </li>
                      <li>
                        <div class="status-toggle modal-status d-flex justify-content-between align-items-center">
                          <span class="status-label"><i data-feather="menu" class="feather-menu"></i>Product</span>
                          <input type="checkbox" id="option2" class="check" checked />
                          <label for="option2" class="checktoggle"> </label>
                        </div>
                      </li>
                      <li>
                        <div class="status-toggle modal-status d-flex justify-content-between align-items-center">
                          <span class="status-label"><i data-feather="menu" class="feather-menu"></i>Reference No</span>
                          <input type="checkbox" id="option3" class="check" checked />
                          <label for="option3" class="checktoggle"> </label>
                        </div>
                      </li>
                      <li>
                        <div class="status-toggle modal-status d-flex justify-content-between align-items-center">
                          <span class="status-label"><i data-feather="menu" class="feather-menu"></i>Date</span>
                          <input type="checkbox" id="option4" class="check" checked />
                          <label for="option4" class="checktoggle"> </label>
                        </div>
                      </li>
                      <li>
                        <div class="status-toggle modal-status d-flex justify-content-between align-items-center">
                          <span class="status-label"><i data-feather="menu" class="feather-menu"></i>Responsible
                            Person</span>
                          <input type="checkbox" id="option5" class="check" checked />
                          <label for="option5" class="checktoggle"> </label>
                        </div>
                      </li>
                      <li>
                        <div class="status-toggle modal-status d-flex justify-content-between align-items-center">
                          <span class="status-label"><i data-feather="menu" class="feather-menu"></i>Notes</span>
                          <input type="checkbox" id="option6" class="check" checked />
                          <label for="option6" class="checktoggle"> </label>
                        </div>
                      </li>
                      <li>
                        <div class="status-toggle modal-status d-flex justify-content-between align-items-center">
                          <span class="status-label"><i data-feather="menu" class="feather-menu"></i>Quantity</span>
                          <input type="checkbox" id="option7" class="check" checked />
                          <label for="option7" class="checktoggle"> </label>
                        </div>
                      </li>
                      <li>
                        <div class="status-toggle modal-status d-flex justify-content-between align-items-center">
                          <span class="status-label"><i data-feather="menu" class="feather-menu"></i>Actions</span>
                          <input type="checkbox" id="option8" class="check" checked />
                          <label for="option8" class="checktoggle"> </label>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-sort" hidden>
              <vue-feather type="sliders" class="info-img"></vue-feather>
              <vue-select :options="CouponsSort" id="couponssort" placeholder="Sort by Date" />
            </div>
          </div>
          <!-- /Filter -->
          <div hidden class="card" :style="{ display: filter ? 'block' : 'none' }" id="filter_inputs">
            <div class="card-body pb-0">
              <div class="row">
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="input-blocks">
                    <vue-feather type="zap" class="info-img"></vue-feather>
                    <vue-select :options="Couponsname" id="couponsname" placeholder="Choose Name" />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="input-blocks">
                    <vue-feather type="box" class="info-img"></vue-feather>
                    <vue-select :options="Couponstype" id="couponstype" placeholder="Sort by Date" />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="input-blocks">
                    <vue-feather type="calendar" class="info-img"></vue-feather>
                    <div class="input-groupicon">
                      <datepicker v-model="startdate" placeholder="Choose Date" class="datetimepicker" :editable="true"
                        :clearable="false" :input-format="dateFormat" />
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12 ms-auto">
                  <div class="input-blocks">
                    <a class="btn btn-filters ms-auto">
                      <i data-feather="search" class="feather-search"></i>
                      Search
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Filter -->
          <div class="table-responsive">
            <a-table :columns="columns" :data-source="data" :row-selection="{}">
              <template #bodyCell="{ column, record }">
                <template v-if="column.key === 'Code'">
                  <div>
                    <span :class="record.Code_class">{{ record.Code }}</span>
                  </div>
                </template>
                <template v-else-if="column.key === 'status'">
                  <div>
                    <span class="badge bg-outline-success" v-if="isDateGreaterThanCurrent(record.ends)">Active</span>
                    <span class="badge bg-outline-danger" v-else>Ended</span>
                  </div>
                </template>
                <template v-else-if="column.key === 'created_at'">
                  {{ getDatetime(record.created_at) }}
                </template>
                <template v-else-if="column.key === 'action'">
                  <td class="action-table-data">
                    <div class="edit-delete-action">
                      <a class="me-2 edit-icon p-2" to="/inventory/product-details">
                        <i data-feather="eye" class="feather-eye"></i>
                      </a>
                      <a class="me-2 p-2" href="javascript:void(0);" data-bs-toggle="modal"
                        data-bs-target="#edit-units">
                        <i data-feather="edit" class="feather-edit"></i>
                      </a>
                      <a class="confirm-text p-2" @click="showConfirmation" href="javascript:void(0);">
                        <i data-feather="trash-2" class="feather-trash-2"></i>
                      </a>
                    </div>
                  </td>
                </template>
              </template>
            </a-table>
          </div>
        </div>
      </div>
      <!-- /product list -->

      <div class="modal fade" id="add-units">
        <div class="modal-dialog modal-lg">
          <!-- modal-dialog-centered custom-modal-two -->
          <div class="modal-content">
            <div class="page-wrapper-new p-0">
              <div class="content">
                <div class="modal-header border-0 custom-modal-header">
                  <div class="page-title">
                    <h4>Add Coupons</h4>
                  </div>
                  <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div class="modal-body custom-modal-body">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label class="form-label">Name</label>
                        <input type="text" class="form-control" v-model="name" />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label class="form-label">Code</label>
                        <input type="text" class="form-control" v-model="code" />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label class="form-label">Minimum Spend</label>
                        <input type="text" class="form-control" v-model="minimum_spend" @keydown="preventNonNumeric" />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label class="form-label">Maximum Spend</label>
                        <input type="text" class="form-control" v-model="maximum_spend" @keydown="preventNonNumeric" />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label class="form-label">Type</label>

                        <vue-select :options="Type" id="Type" placeholder="Choose" v-model="type"
                          @keydown="preventNonNumeric" />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label class="form-label">Amount</label>
                        <input type="text" class="form-control" v-model="amount" @keydown="preventNonNumeric" />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label class="form-label"> Quantity</label>
                        <input type="text" class="form-control" v-model="quantity" @keydown="preventNonNumeric" />
                        <span class="unlimited-text"></span>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label class="form-label"> Limit Per User</label>
                        <input type="text" class="form-control" title="0 for Unlimited" data-bs-toggle="tooltip"
                          data-bs-custom-class="tooltip-primary" v-model="usage_per_user"
                          @keydown="preventNonNumeric" />
                        <!-- <span class="unlimited-text"></span> -->
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="input-blocks">
                        <label>Start Date</label>
                        <div class="input-groupicon calender-input">
                          <input type="date" class="form-control" v-model="start_timestamp" />
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="input-blocks">
                        <label>End Date</label>
                        <div class="input-groupicon calender-input">
                          <input type="date" class="form-control" v-model="end_timestamp" />
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-12">
                      <div class="input-blocks summer-description-box transfer mb-3">
                        <label>Description</label>
                        <textarea class="form-control" rows="3" v-model="description"></textarea>
                        <p class="mt-1">Maximum 60 Characters</p>
                      </div>
                    </div>

                    <div class="input-blocks">
                      <!-- <div
                          class="status-toggle modal-status d-flex justify-content-between align-items-center mb-2"
                        >
                          <span class="status-label">All Products</span>
                          <div class="d-flex align-items-center">
                            <input type="checkbox" id="user4" class="check" />
                            <label
                              for="user4"
                              class="checktoggle mb-0 me-1"
                            ></label>
                            <span class="customer-toggle"
                              >Once Per Customer</span
                            >
                          </div>
                        </div> -->
                      <!-- select goes here -->
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="input-blocks">
                            <label>Apply</label>
                            <div class="input-groupicon calender-input">
                              <vue-select :options="Apply" id="Apply" placeholder="Choose" v-model="apply_to"
                                @select="change_category()" />
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-6" v-if="not_cart">
                          <div class="input-blocks">
                            <!-- this select is dynamic depending on what you choose in the previous select input -->
                            <label class="dynamic_label">Select Target</label>
                            <vue-select :options="Target" id="chooseState" placeholder="Choose" v-model="spec_id" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="input-blocks m-0">
                      <div class="status-toggle modal-status d-flex justify-content-between align-items-center">
                        <span class="status-label">Status</span>
                        <input type="checkbox" id="user3" class="check" checked="" />
                        <label for="user3" class="checktoggle"> </label>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer-btn">
                    <button type="button" class="btn btn-cancel me-2" data-bs-dismiss="modal">
                      Cancel
                    </button>
                    <div @click="add_coupon()" class="btn btn-submit">
                      Create Coupon
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--  edit add units  -->
      <div class="modal fade" id="edit-units">
        <div class="modal-dialog modal-dialog-centered custom-modal-two">
          <div class="modal-content">
            <div class="page-wrapper-new p-0">
              <div class="content">
                <div class="modal-header border-0 custom-modal-header">
                  <div class="page-title">
                    <h4>Edit Coupons</h4>
                  </div>
                  <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div class="modal-body custom-modal-body">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label class="form-label">Name</label>
                        <input type="text" class="form-control" v-model="name" />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label class="form-label">Code</label>
                        <input type="text" class="form-control" v-model="code" />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label class="form-label">Minimum Spend</label>
                        <input type="number" class="form-control" v-model="minimum_spend" />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label class="form-label">Maximum Spend</label>
                        <input type="number" class="form-control" v-model="maximum_spend" />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <div class="input-blocks">
                          <label class="form-label">Type</label>
                          <select class="form-control" tabindex="-1" aria-hidden="true">
                            <option>Choose Type</option>
                            <option>Fixed</option>
                            <option>Percentage</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label class="form-label">Discount</label>
                        <input type="text" class="form-control" />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label class="form-label"> Quantity</label>
                        <input type="text" class="form-control" v-model="quantity" />
                        <span class="unlimited-text"></span>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label class="form-label"> Limit Per User</label>
                        <input type="text" class="form-control" title="0 for Unlimited" data-bs-toggle="tooltip"
                          data-bs-custom-class="tooltip-primary" v-model="usage_per_user" />
                        <!-- <span class="unlimited-text"></span> -->
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="input-blocks">
                        <label>Start Date</label>
                        <div class="input-groupicon calender-input">
                          <vue-feather type="calendar" class="info-img"></vue-feather>
                          <datepicker v-model="start_timestamp" placeholder="Choose" class="datetimepicker"
                            :editable="true" :clearable="false" :input-format="dateFormat" />
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="input-blocks">
                        <label>End Date</label>
                        <div class="input-groupicon calender-input">
                          <vue-feather type="calendar" class="info-img"></vue-feather>
                          <datepicker v-model="end_timestamp" placeholder="Choose" class="datetimepicker"
                            :editable="true" :clearable="false" :input-format="dateFormat" />
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-12">
                      <div class="input-blocks summer-description-box transfer mb-3">
                        <label>Description</label>
                        <textarea class="form-control" rows="3" v-model="description"></textarea>
                        <p class="mt-1">Maximum 60 Characters</p>
                      </div>
                    </div>

                    <div class="input-blocks">
                      <!-- <div
                          class="status-toggle modal-status d-flex justify-content-between align-items-center mb-2"
                        >
                          <span class="status-label">All Products</span>
                          <div class="d-flex align-items-center">
                            <input type="checkbox" id="user4" class="check" />
                            <label
                              for="user4"
                              class="checktoggle mb-0 me-1"
                            ></label>
                            <span class="customer-toggle"
                              >Once Per Customer</span
                            >
                          </div>
                        </div> -->
                      <!-- select goes here -->
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="input-blocks">
                            <label>Apply to Category</label>
                            <div class="input-groupicon calender-input">
                              <vue-select :options="Category" id="chooseCategory" placeholder="Choose"
                                v-model="edit_apply_to" disabled />
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-6">
                          <div class="input-blocks">
                            <label>Apply to Product</label>
                            <vue-select :options="products" id="chooseProduct" placeholder="Choose" v-model="apply_to"
                              disabled />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="input-blocks m-0">
                      <div class="status-toggle modal-status d-flex justify-content-between align-items-center">
                        <span class="status-label">Status</span>
                        <input type="checkbox" id="user3" class="check" checked="" />
                        <label for="user3" class="checktoggle"> </label>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer-btn">
                    <button type="button" class="btn btn-cancel me-2" data-bs-dismiss="modal">
                      Cancel
                    </button>
                    <div @click="add_coupon()" class="btn btn-submit">
                      Create Coupon
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
// import Vue3TagsInput from "vue3-tags-input";
import { searchList } from "../../../module/search_module.js";

const columns = [
  {
    sorter: true,
  },
  // {
  //   title: "Name",
  //   dataIndex: "Name",
  //   sorter: {
  //     compare: (a, b) => {
  //       a = a.Name.toLowerCase();
  //       b = b.Name.toLowerCase();
  //       return a > b ? -1 : b > a ? 1 : 0;
  //     },
  //   },
  // },
  {
    title: "Code",
    dataIndex: "code",
    key: "code",
    sorter: {
      compare: (a, b) => {
        a = a.code.toLowerCase();
        b = b.code.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Type",
    dataIndex: "type",
    sorter: {
      compare: (a, b) => {
        a = a.type.toLowerCase();
        b = b.type.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Apply To",
    dataIndex: "apply_to",
    sorter: {
      compare: (a, b) => {
        a = a.apply_to.toLowerCase();
        b = b.apply_to.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Amount",
    dataIndex: "amount",
    sorter: {
      compare: (a, b) => {
        a = a.amount.toLowerCase();
        b = b.amount.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
    sorter: {
      compare: (a, b) => {
        a = a.quantity.toLowerCase();
        b = b.quantity.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Quantity Available",
    dataIndex: "quantity_available",
    sorter: {
      compare: (a, b) => {
        a = a.quantity_available.toLowerCase();
        b = b.quantity_available.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Usage Per User",
    dataIndex: "usage_per_user",
    sorter: {
      compare: (a, b) => {
        a = a.usage_per_user.toLowerCase();
        b = b.usage_per_user.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Min Spend",
    dataIndex: "minimum_spend",
    sorter: {
      compare: (a, b) => {
        a = a.minimum_spend.toLowerCase();
        b = b.minimum_spend.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Max Spend",
    dataIndex: "maximum_spend",
    sorter: {
      compare: (a, b) => {
        a = a.maximum_spend.toLowerCase();
        b = b.maximum_spend.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Status",
    key: "status",
    sorter: {
      compare: (a, b) => {
        a = a.status.toLowerCase();
        b = b.status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Date Created",
    key: "created_at",
    sorter: {
      compare: (a, b) => {
        a = a.created_at.toLowerCase();
        b = b.created_at.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },

  // {
  //   title: "Valid",
  //   dataIndex: "Valid",
  //   sorter: {
  //     compare: (a, b) => {
  //       a = a.Valid.toLowerCase();
  //       b = b.Valid.toLowerCase();
  //       return a > b ? -1 : b > a ? 1 : 0;
  //     },
  //   },
  // },
  // {
  //   title: "Status",
  //   dataIndex: "Status",
  //   key: "Status",
  //   sorter: {
  //     compare: (a, b) => {
  //       a = a.Status.toLowerCase();
  //       b = b.Status.toLowerCase();
  //       return a > b ? -1 : b > a ? 1 : 0;
  //     },
  //   },
  // },
  // {
  //   title: "Action",
  //   key: "action",
  //   sorter: true,
  // },
];

import { ref } from "vue";

const currentDate = ref(new Date());
export default {
  data() {
    return {
      columns,
      data: [],
      data_reserve: [],
      search: null,
      isLayoutBoxVisible: false,
      filter: false,
      CouponsSort: ["Sort by Date", "Newest", "Oldest"],
      Couponsname: [
        "Choose Name",
        "Coupons 21",
        "First Offer",
        "Offer 40",
        "Subscription",
      ],
      Type: ["Choose Type", "Price", "Percentage"],
      Apply: ["Cart", "Product", "Category", "Subcategory"],
      startdate: currentDate,
      dateFormat: "dd-MM-yyyy",

      // my data
      code: "",
      description: null,
      quantity: null,
      amount: null,
      platform: null,
      usage_per_user: null,
      start_timestamp: null,
      end_timestamp: null,
      minimum_spend: null,
      maximum_spend: null,
      apply_to: null,
      target_id: null,
      Category: ["Choose"],
      type: "",
      Target: [],
      not_cart: false,
    };
  },
  methods: {
    change_category() {
      if (this.apply_to == "Category") {
        this.get_categories();
        this.not_cart = true;
      } else if (this.apply_to == "Subcategory") {
        this.get_subcategories();
        this.not_cart = true;
      } else if (this.apply_to == "Product") {
        this.get_products();
        this.not_cart = true;
      } else if (this.apply_to == "Cart") {
        this.not_cart = false;
      }
    },
    ...mapActions({
      _add_coupon: "coupon/add_coupon",
      _get_coupons: "coupon/get_coupons",
      _get_categories: "product/get_subcategories",
      _get_subcategories: "product/get_subcategories",
      _get_products: "product/get_subproducts",
    }),
    showConfirmation() {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          Swal.fire({
            icon: "success",
            title: "Deleted!",
            text: "Your file has been deleted.",
            confirmButtonClass: "btn btn-success",
          });
        }
      });
    },
    toggleCollapse() {
      const collapseHeader = this.$refs.collapseHeader;

      if (collapseHeader) {
        collapseHeader.classList.toggle("active");
        document.body.classList.toggle("header-collapse");
      }
    },
    toggleLayoutBox() {
      this.isLayoutBoxVisible = !this.isLayoutBoxVisible;
    },

    // my methods
    add_coupon() {
      var k = false;
      // var s = false;
      if (this.code.length < 5 || this.code.length > 10) {
        toast.error("Coupon code less than 5 or more than 10.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        k = false;
      } else {
        k = true;
      }
      // if (this.minimum_spend > this.maximum_spend) {
      //   alert(this.minimum_spend);
      //   alert(this.maximum_spend);
      //   toast.error("Minimum spend cannot be greater than maximum spend.", {
      //     position: toast.POSITION.TOP_RIGHT,
      //   });
      //   s = false;
      // } else {
      //   s = true;
      // }
      // if (k == true && s == true) {
      if (k == true) {
        $(".inloader").show();

        this._add_coupon({
          code: this.code,
          description: this.description,
          quantity: this.quantity,
          amount: this.amount,
          platform: "Web",
          usage_per_user: this.usage_per_user,
          start_timestamp: this.start_timestamp,
          end_timestamp: this.end_timestamp,
          minimum_spend: this.minimum_spend,
          maximum_discount: this.maximum_spend,
          apply_to: this.apply_to,
          target_id: this.target_id,
          type: this.type,
        })
          .then((response) => {
            $(".inloader").hide();
            $("#add-units").modal("hide");
            toast.success("Coupon Added", {
              position: toast.POSITION.TOP_RIGHT,
            });
            this.response = response.data;
            //   this.response
            if (this.response["status"] == true) {
              var data = this.response.data;

              window.location.reload();
            }
          })
          .catch((error) => {
            $(".inloader").hide();

            console.log("Error logging in", error);
            if (error.response) {
              const status = error.response.status;
              const data = error.response.data;
              if (
                status == 400 ||
                status == 422 ||
                status == 401 ||
                status == 404
              ) {
                toast.error(error.response.data.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              } else if (status === 500) {
                toast.error("Network Error, Check your internet conectivity.", {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }
            }
          });
      }
    },

    get_coupons() {
      this._get_coupons()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.data = this.response.data;
            this.data_reserve = this.data
            console.log(data);
          }
        })
        .catch((error) => {
          console.log("Error logging in", error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;
            if (
              status == 400 ||
              status == 422 ||
              status == 401 ||
              status == 404
            ) {
            } else if (status === 500) {
            }
          }
        });
    },
    isDateGreaterThanCurrent(inputDate) {
      // Parse the input date string into a Date object
      const currentDate = new Date();
      const givenDate = new Date(inputDate);

      // Compare the parsed dates
      return givenDate > currentDate;
    },
    getDatetime(date) {
      let datetime = new Date(date);
      return `${datetime.toDateString()} ${datetime.toLocaleTimeString()}`;
    },

    preventNonNumeric(event) {
      // Allow only number keys (0-9) and special keys (Backspace, Delete, Arrow keys)
      const allowedKeys = [
        "Backspace",
        "Delete",
        "ArrowLeft",
        "ArrowRight",
        "Tab",
      ];

      if (
        (event.key < "0" || event.key > "9") &&
        !allowedKeys.includes(event.key)
      ) {
        event.preventDefault(); // Block non-numeric keys
      }
    },

    get_categories() {
      $(".inloader").show();
      this._get_subcategories()
        .then((response) => {
          $(".inloader").hide();

          this.response = response.data;
          if (this.response["status"] == true) {
            const data = this.response.data;
            console.log(data);
            this.Target = data.map((item) => ({
              id: item.id,
              text: item.title,
            }));
            console.log(this.Target, "lm");
          }
        })
        .catch((error) => {
          $(".inloader").hide();

          console.log("Error logging in", error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;
            if (
              status == 400 ||
              status == 422 ||
              status == 401 ||
              status == 404
            ) {
            } else if (status === 500) {
            }
          }
        });
    },

    get_subcategories() {
      $(".inloader").show();

      this._get_subcategories()
        .then((response) => {
          $(".inloader").hide();

          this.response = response.data;
          if (this.response["status"] == true) {
            const ddata = this.response.data;
            console.log(this.data);

            const mappedSubcategories = ddata.flatMap((category) => {
              return category.subcategories.map((subcategory) => ({
                id: subcategory.id,
                text: subcategory.title,
              }));
            });
            this.Target = mappedSubcategories;
            // console.log(mappedSubcategories,'11')
          }
        })
        .catch((error) => {
          $(".inloader").hide();

          console.log("Error logging in", error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;
            if (
              status == 400 ||
              status == 422 ||
              status == 401 ||
              status == 404
            ) {
            } else if (status === 500) {
            }
          }
        });
    },

    get_products() {
      $(".inloader").show();

      this._get_products()
        .then((response) => {
          $(".inloader").hide();

          this.response = response.data;
          if (this.response["status"] == true) {
            this.Target = this.response.data;
          }
        })
        .catch((error) => {
          $(".inloader").hide();

          console.log("Error logging in", error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;
            if (
              status == 400 ||
              status == 422 ||
              status == 401 ||
              status == 404
            ) {
            } else if (status === 500) {
            }
          }
        });
    },

    searchtrigger() {
      const searchFields = ["code", "description", "type", "amount"];
      this.data = searchList(this.data_reserve, this.search, searchFields);
      console.log(this.data);
    },
  },
  mounted() {
    // this.get_subcategories();
    this.get_coupons();
  },
};
</script>
<style>
input[type="text"],
select {
  height: 35px;
}

/* textarea{height: 60px;} */
</style>
