<template>
  <div class="main-chat-blk">
    <div class="main-wrapper">
      <layout-header></layout-header>
      <layout-sidebar></layout-sidebar>
      <InLoader class="inloader" />

      <div class="page-wrapper chat-page-wrapper">
        <div class="content">
          <!-- sidebar group -->
          <div class="sidebar-group left-sidebar chat_sidebar">
            <!-- Chats sidebar -->
            <div id="chats" class="left-sidebar-wrap sidebar active slimscroll">
              <perfect-scrollbar
                class="scroll-area-one"
                :settings="settings"
                @ps-scroll-y="scrollHanle"
              >
                <div class="slimscroll-active-sidebar">
                  <!-- Left Chat Title -->
                  <div
                    class="left-chat-title all-chats d-flex justify-content-between align-items-center"
                  >
                    <div class="setting-title-head">
                      <h4>All messages</h4>
                    </div>
                    <div class="add-section">
                      <ul>
                        <li>
                          <a href="javascript:void(0);" class="user-chat-search-btn"
                            ><i class="bx bx-search"></i
                          ></a>
                        </li>
                        <li hidden>
                          <div class="chat-action-btns">
                            <div class="chat-action-col">
                              <a
                                class="#"
                                href="javascript:void(0);"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i class="bx bx-dots-vertical-rounded"></i>
                              </a>
                              <div class="dropdown-menu dropdown-menu-end">
                                <a href="javascript:void(0);" class="dropdown-item"
                                  ><span><i class="bx bx-message-rounded-add"></i></span
                                  >Delete All
                                </a>
                               
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                      <!-- Chat Search -->
                      <div class="user-chat-search">
                        <form>
                          <span class="form-control-feedback"
                            ><i class="bx bx-search"></i
                          ></span>
                          <input
                            type="text"
                            name="chat-search"
                            placeholder="Search"
                            class="form-control"
                          />
                          <div class="user-close-btn-chat">
                            <span class="material-icons">close</span>
                          </div>
                        </form>
                      </div>
                      <!-- /Chat Search -->
                    </div>
                  </div>
                  <!-- /Left Chat Title -->

                 

                  <div class="sidebar-body chat-body" id="chatsidebar">
          <router-link to="/crm/product-question">
                    <ul class="inbox-menu">
            <li>
              <a href="javascript:void(0);"><i class="far fa-star"></i> Read</a>
            </li>
            <li class="active">
              <a href="javascript:void(0);"
                ><i class="fas fa-download"></i> Unread
                <span class="mail-count">(5)</span></a
              >
            </li>

            <li>
              <a href="javascript:void(0);"
                ><i class="far fa-file-alt"></i> Achived
                <span class="mail-count">(13)</span></a
              >
            </li>
            <li>
              <a href="javascript:void(0);"
                ><i class="far fa-trash-alt"></i> Trash</a
              >
            </li>
          </ul>
        </router-link>
                  </div>
                </div>
              </perfect-scrollbar>
            </div>
            <!-- / Chats sidebar -->
          </div>
          <!-- /Sidebar group -->

          <!-- Chat -->
          <Msg></Msg>
          <!-- /Chat -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
export default {
  components: {
    PerfectScrollbar,
  },
  data() {
    return {
      settings: {
        suppressScrollX: true,
      },
    };
  },
  methods: {
    scrollHanle(evt) {},
  },
};
</script>
