<template>
  <div id="app">
    <div class="main-wrapper">
      <Loader class="loadercont"/>
      <router-view />
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      // data:JSON.parse(localStorage.getItem("data"))
    };
  },
  watch: {
    $route: {
      handler() {
        $(".loadercont").show();
        setTimeout(() => {
      $(".loadercont").hide();
    }, 2000);
      },
    },
  }
};
</script>
<!-- #FF9F43; - old
  #00afef - new
 -->

 <!-- #ff8d1f -old
black - new -->


<!-- 
  btn shadow
  rgba(255, 159, 67, 0.5) - old 

#00afef8e - new
-->