function handleMessage(event) {
  console.log(event);
  //   alert(event.data.popupValue);
  // alert(event.data.token);
  //............. not needed....................
  // loginData = event.data.popupValue;
  // localStorage.setItem("email", loginData.email);
  // localStorage.setItem("password", loginData.password);
  // localStorage.setItem("platform", loginData.platform);

  // from otp screen
  // localStorage.setItem("profiledata", JSON.stringify(event.data.profiledata)); //user data
  // localStorage.setItem("logToken", event.data.token); //user data

  localStorage.setItem("accessToken", event.data.token);
  localStorage.setItem("authorize", event.data.authorize);
  localStorage.setItem("data", JSON.stringify(event.data.profiledata));

}

export { handleMessage };
