<template>
  <!-- imag edit modal -->

  <!-- Modal -->
  <div
    class="modal fade"
    id="EditMultiImage"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="EditMultiImageLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-fullscreen">
      <!-- modal-xl -->
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="EditMultiImageLabel">
            Upload product Image(s)
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="clear_cropper()"
          ></button>
        </div>
        <div class="modal-body">
          <div>
            <hr />
            <input
              type="file"
              id="upload_images"
              class="upload_images"
              multiple
              accept="image/*"
              k="multiple"
              ref="input"
              name="image"
              @change="setImage"
            />

            <!-- style="box-shadow: 2px 2px 2px 2px #cccccc93;" -->
            <div class="content">
              <div class="row">
                <div
                  class="col-lg-8 col-sm-8 col-md-12 col-12 ps-0"
                  id="cropright"
                >
                  <!-- uploaded image row goes here -->
                  <div class="flex-container" v-if="crop_state == 'multi'">
                    <img
                      v-for="(image, index) in imagePreviews"
                      :key="index"
                      :src="image"
                      alt="product image"
                      style="cursor: grab"
                      @click="imageselector($event, index)"
                    />
                  </div>
                  <br />
                  <section class="cropper-area">
                    <div class="img-cropper">
                      <vue-cropper
                        id="cropper"
                        ref="cropper"
                        :aspect-ratio="600 / 600"
                        :src="imgSrc"
                        preview=".preview_multi"
                      />
                    </div>

                    <br />
                    <!-- rought.............................. -->

                    <div class="actions_rough1 r1_cont text-center">
                      <span
                        role="button"
                        @click.prevent="showFileChooser"
                        class="fas fa-upload faicon faicon2"
                        title="Upload another image"
                      ></span>
                    </div>
                    <div class="actions_rough1 btncont_rough">
                      <span class="button_tag b11">Upload</span>
                    </div>
                    <!-- rought.............................. -->

                    <div class="actions_multi text-center">
                      <span
                        role="button"
                        @click.prevent="showFileChooser"
                        class="fas fa-upload faicon"
                        title="Upload another image"
                      ></span>
                      <span
                        role="button"
                        @click.prevent="cropImage"
                        class="fas fa-crop-alt faicon"
                        title="Crop Image"
                        id="crop"
                      ></span>
                    </div>
                    <div class="actions_multi btncont">
                      <span class="button_tag b1">Upload</span>
                      <span class="button_tag b2">Crop</span>
                    </div>
                    <!-- <textarea v-model="data" /> -->
                  </section>

                  <div class="upload_shadow_multi" v-if="imgSrc == null">
                    <a
                      href="#"
                      role="button"
                      @click.prevent="showFileChooser"
                      class="fa fa-upload upload-icon"
                      title="Upload image"
                    ></a>
                  </div>
                </div>
                <div
                  class="col-lg-4 col-sm-4 col-md-12 col-12 ps-0"
                  id="prevleft"
                >
                  <section class="preview-area">
                    <p>Preview</p>
                    <div class="preview_multi" />
                    <br />
                    <br />
                    <p v-if="cropImg">Cropped Image</p>

                    <div class="cropped-image" v-if="crop_state == 'multi'">
                      <div
                        class="slider-container"
                        v-if="cropped_image_array.length >= 1"
                      >
                        <div class="slider">
                          <img
                            :src="i.url"
                            alt="Cropped Image"
                            v-for="(i, index) in cropped_image_array"
                            :key="i"
                            @click="imageselector2($event, index)"
                          />
                        </div>
                      </div>

                      <!-- buttons -->
                      <div
                        class="slider_buttons"
                        v-if="cropped_image_array.length >= 2"
                      >
                        <span
                          class="fa fa-angle-left controls controls-left"
                          @click="moveCroppedImg(-1)"
                        ></span>
                        <span
                          class="fa fa-angle-right controls controls-right"
                          @click="moveCroppedImg(1)"
                        ></span>
                      </div>
                    </div>
                    <div class="cropped-image" v-else>
                      <img
                        v-if="cropImg"
                        :src="cropImg"
                        alt="Cropped Image"
                        id="cropedimgmulti"
                      />
                      <div v-else class="crop-placeholder" />
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer align-items-right">
          <button
            type="button"
            class="btn btn-cancel bg-danger"
            data-bs-dismiss="modal"
            @click="clear_cropper()"
          >
            Close
          </button>
          <button type="button" class="btn btn-primary" @click="save_image()">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- /Static Backdrop -->
</template>

<script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  components: {
    VueCropper,
  },
  data() {
    return {
      imgSrc: null,
      cropImg: "",
      data: null,
      images: [],
      imagePreviews: [],
      cropped_image_array: [],
      currentIndex: 0,
      existingIndex: 0,
      blob_image_array: [],
      full_img: [],
    };
  },
  props: {
    images_length: {
      type: Number,
      required: false,
    },
    crop_state: {
      type: String,
      required: false,
    },
    single_image_to_edit: {
      type: String,
      required: false,
    },
  },
  methods: {
    b64toBlob(b64Data, contentType = "", sliceSize = 512) {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (
        let offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });
      blob.name = "image.png";
      return blob;
    },

    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();

      if (this.crop_state == "multi") {
        let newId = this.currentIndex;
        let newImg = this.cropImg;

        // Create the new object
        let newObject = { id: newId, url: newImg };

        const base64Data = newImg.replace(/^data:image\/\w+;base64,/, ""); // Ensure this is a valid base64 string
        const contentType = "image/png"; // Adjust content type as needed
        const blob = this.b64toBlob(base64Data, contentType);
        let newObject2 = { id: newId, url: newImg, blob };

        // Find the index of the object with the same ID
        let existingIndex =
          this.cropped_image_array.length === 0
            ? -1
            : this.cropped_image_array.findIndex(
                (obj) => obj && obj.id === newId
              );

        if (existingIndex !== -1) {
          // Replace the existing object with newObject
          this.cropped_image_array.splice(existingIndex, 1, newObject); // Replace at existingIndex
          this.blob_image_array.splice(existingIndex, 1, blob);
          console.log("Object replaced:", newObject);
          console.log("blob replaced:", blob);
        } else {
          // If the array length is 5, remove the first element
          if (this.cropped_image_array.length >= 5) {
            this.cropped_image_array.shift();
            this.blob_image_array.shift();
          }

          this.cropped_image_array.push(newObject);
          this.blob_image_array.push(blob);
          this.full_img.push(newObject2);

          console.log("Updated array:", this.cropped_image_array);
          console.log("Updated backendimage array:", this.blob_image_array);
          console.log("full_img222:", this.full_img);
        }

        this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
      } else if (this.crop_state == "single") {
        var preview = document.getElementById("preview_thumbnail");
        preview.src = this.cropImg;
        var dk = document.getElementById("uploadd");
        dk.style = "display:block";
      }

      // general
      document.getElementById("crop").classList.remove("blinking-background");
    },
    save_image() {
      if (this.crop_state == "multi") {
        this.$emit("CroppedImageMulti", {
          croppedImg: this.cropped_image_array,
          blobImg: this.blob_image_array,
          full_img: this.full_img,
        });
        $("#EditMultiImage").modal("hide");

        // this.cropImg = "";
        // this.$refs.cropper.replace("");
        this.imgSrc = null;
        this.imagePreviews = [];
        this.cropped_image_array = [];
        this.images = [];
        this.cropImg = "";
        const previewElement = document.querySelector(".preview_multi");

        if (previewElement) {
          previewElement.innerHTML = "";
        }
      } else if (this.crop_state == "single") {
        var preview = document.getElementById("preview_thumbnail");
        preview.src = this.cropImg;
        var dk = document.getElementById("uploadd");
        dk.style = "display:block";
        $("#EditMultiImage").modal("hide");

        // alert(typeof this.cropImg);

        const base64Data = this.cropImg.replace(/^data:image\/\w+;base64,/, ""); // Ensure this is a valid base64 string
        const contentType = "image/png"; // Adjust content type as needed
        const blob = this.b64toBlob(base64Data, contentType);

        this.$emit("CroppedImage", { image: this.cropImg, imageBlob: blob });
        console.log(blob);
        this.cropImg = "";
        this.imgSrc = null;

        // for precaution
        this.imagePreviews = [];
        this.cropped_image_array = [];
        this.images = [];
        const previewElement = document.querySelector(".preview_multi");

        if (previewElement) {
          previewElement.innerHTML = "";
        }
      }
      document.querySelector(".cropper-canvas img").src = null;
      document.querySelector(".cropper-view-box img").src = null;
    },
    clear_cropper() {
      if (this.crop_state == "multi") {
        // this.$refs.cropper.replace("");
        this.imagePreviews = [];
        this.cropped_image_array = [];
        this.images = [];
      } else if (this.crop_state == "single") {
        var dk = document.getElementById("uploadd");
        dk.style = "display:none";
        // general
        this.imagePreviews = [];
        this.cropped_image_array = [];
        this.images = [];
      }
      this.cropImg = "";
      this.imgSrc = null;
      const previewElement = document.querySelector(".preview_multi");
      if (previewElement) {
        previewElement.innerHTML = "";
      }
      document.querySelector(".cropper-canvas img").src = null;
      document.querySelector(".cropper-view-box img").src = null;
    },
    flipX() {
      const dom = this.$refs.flipX;
      let scale = dom.getAttribute("data-scale");
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleX(scale);
      dom.setAttribute("data-scale", scale);
    },
    flipY() {
      const dom = this.$refs.flipY;
      let scale = dom.getAttribute("data-scale");
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleY(scale);
      dom.setAttribute("data-scale", scale);
    },
    getCropBoxData() {
      this.data = JSON.stringify(this.$refs.cropper.getCropBoxData(), null, 4);
    },
    getData() {
      this.data = JSON.stringify(this.$refs.cropper.getData(), null, 4);
    },
    move(offsetX, offsetY) {
      this.$refs.cropper.move(offsetX, offsetY);
    },
    reset() {
      this.$refs.cropper.reset();
    },
    rotate(deg) {
      this.$refs.cropper.rotate(deg);
    },
    setCropBoxData() {
      if (!this.data) return;

      this.$refs.cropper.setCropBoxData(JSON.parse(this.data));
    },
    setData() {
      if (!this.data) return;

      this.$refs.cropper.setData(JSON.parse(this.data));
    },
    setImage(e) {
      const file = e.target.files[0];

      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);

          $(".actions_multi").show();
          $(".actions_rough1").hide();

          document.getElementById("crop").classList.add("blinking-background");
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    showFileChooser() {
      this.$refs.input.click();
    },
    zoom(percent) {
      this.$refs.cropper.relativeZoom(percent);
    },

    // traditional image methods
    // for multiple image upload
    // multi croopper functions...................................
    loadFileInputs() {
      const vm = this;
      $("input[k=multiple]").change(function () {
        let files = $(this).prop("files");
        if (vm.imagePreviews.length > 0) {
          vm.images = [];
          vm.imagePreviews = [];
        }
        // console.log(files);
        if (vm.images_length > 1) {
          if (vm.images_length <= 5) {
            for (let index = 0; index < files.length; index++) {
              const file = files[index];
              vm.images.push(file);
            }
          } else {
            toast.error("You can not select more than 5 images.", {
              position: toast.POSITION.TOP_RIGHT,
            });
            vm.$refs.cropper.replace("");
            vm.imgSrc = "";
          }
        } else {
          if (files.length <= 5) {
            for (let index = 0; index < files.length; index++) {
              const file = files[index];
              vm.images.push(file);
            }
          } else {
            toast.error("You can not select more than 5 images.", {
              position: toast.POSITION.TOP_RIGHT,
            });
            vm.$refs.cropper.replace("");
            vm.imgSrc = "";
          }
        }
        console.log(vm.images);
        vm.updateImagePreviews();
      });
    },

    updateImagePreviews() {
      // Images for that type
      for (let index = 0; index < this.images.length; index++) {
        const img = this.images[index];

        const reader = new FileReader();
        reader.onload = (e) => {
          const imageUrl = e.target.result;
          this.imagePreviews.push(imageUrl); // Add The Image Url to the List of that type
        };
        console.log(this.imagePreviews, "pre");
        reader.readAsDataURL(img);
      }
    },

    // deleteAvatar(index) {
    //   this.imagePreviews.splice(index, 1);
    //   // this.avatars[index].splice(index, 1);
    //   for (let i = 0; i < this.images.length; i++) {
    //     if (i === index) {
    //       this.images.splice(i, 1);
    //       break;
    //     }
    //   }
    // },

    imageselector(event, index) {
      var img = event.target.src;
      this.imgSrc = img;
      // rebuild cropperjs with the updated source
      this.$refs.cropper.replace(img);
      this.currentIndex = index;
      document.getElementById("crop").classList.add("blinking-background");

      if (this.cropped_image_array.length >= 1) {
        let currentIndex = index;
        const slider = document.querySelector(".slider");
        const images = document.querySelectorAll(".slider img");
        const imageWidth = images[0].clientWidth;

        // currentIndex = index;
        slider.style.transform = `translateX(${-imageWidth * currentIndex}px)`;

        // window.addEventListener("resize", () => {
        //   // Adjust the slider position on window resize
        //   showImage(currentIndex);
        // });
      }
    },

    imageselector2(event, index) {
      var img = event.target.src;
      this.imgSrc = img;
      this.$refs.cropper.replace(img);
      this.currentIndex = index;
      document.getElementById("crop").classList.add("blinking-background");
    },

    moveCroppedImg(val) {
      if (this.cropped_image_array.length >= 1) {
        let currentIndex = this.currentIndex;
        const slider = document.querySelector(".slider");
        const images = document.querySelectorAll(".slider img");
        const imageWidth = images[0].clientWidth;

        if (val == 1) {
          currentIndex = +1;
          //  currentIndex = (currentIndex === images.length - 1) ? 0 : currentIndex + 1;
        } else if (val == -1 && currentIndex != 0) {
          currentIndex = -1;

          //  currentIndex = (currentIndex === 0) ? images.length - 1 : currentIndex - 1;
        }

        // currentIndex = index;
        slider.style.transform = `translateX(${-imageWidth * currentIndex}px)`;

        // window.addEventListener("resize", () => {
        //   // Adjust the slider position on window resize
        //   this.showImage(currentIndex);
        // });
      }
    },

    // Edit croopper functions...................................
    edit_returned_image() {
      this.imgSrc = this.single_image_to_edit.base64String;
    },
  },
  watch: {
    single_image_to_edit: {
      handler() {
        if (this.crop_state == "edit") {
          this.edit_returned_image();
        }
      },
      immidiate: true,
    },
  },
  mounted() {
    this.loadFileInputs();

    if (this.imgSrc == null) {
      $(".actions_multi").hide();
      $(".actions_rough1").show();
    } else {
      $(".actions_multi").show();
      $(".actions_rough1").hide();
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@keyframes blinkBackground {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.blinking-background {
  animation: blinkBackground 2s infinite; /* 1s duration, repeat infinitely */
}

input[type="file"] {
  display: none;
}

/* .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 5px 0;
} */

.header h2 {
  margin: 0;
}

.header a {
  text-decoration: none;
  color: black;
}

/* .content {
  display: flex;
  justify-content: space-between;
} */

.cropper-area {
  width: 614px;
}

.actions_multi,
.actions_rough1 {
  display: flex;
  justify-content: center; /* Align items horizontally */
  align-items: center; /* Align items vertically */
  margin-top: 20px;
  height: 60px;
  padding: 10px;
  /* background-color: black; */
  border-radius: 10px;
}

.btncont {
  margin-top: 0px;
  text-align: center;
}
.faicon {
  display: inline-block;
  font-size: 40px;
  margin: 6px;
  margin-right: 20px;
  color: #00afef;
  background-color: black;
  border-radius: 10px;
  padding: 10px;
}
.button_tag {
  margin: 6px;
  margin-right: 20px;
  color: black;
  display: inline-block;
  margin-top: 0px;
  margin-left: 0px;
  font-weight: bolder;
}
.b1 {
  margin-right: 50px;
}
.b11 {
  margin-left: 74px;
}
.faicon2 {
  margin: 0px;
  margin-right: 0px;
  margin-left: 60px;
  font-size: 45px;
  margin-top: 50px;
}
/* .b1 {
  margin-left: 50px;
} */
/* .actions_multi a {
  display: inline-block;
  padding: 5px 15px;
  background: #0062cc;
  color: white;
  text-decoration: none;
  border-radius: 3px;
  margin-right: 1rem;
  margin-bottom: 1rem;
} */

.preview-area {
  width: 60%;
  margin-top: 20px;
  /* box-shadow: 2px 2px 2px 2px #cccccc93; */
  align-content: center;
  align-items: center;
}

.preview-area p {
  font-size: 1.25rem;
  margin: 0;
  margin-bottom: 20px;
}

.preview-area p:last-of-type {
  margin-top: 1rem;
}

.preview_multi {
  width: 100%;
  height: calc(372px * (600 / 600));
  overflow: hidden;
}

/* .crop-placeholder {
  width: 100%;
  height: 200px;
}

.cropped-image img {
  max-width: 100%;
} */

.upload_shadow_multi {
  width: 60%;
  height: 500px;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.418);
  top: 5%;
  align-items: center;
  align-content: center;
  text-align: center;
}
.upload-icon {
  font-size: 60px;
  color: black;
}
#cropper {
  height: 450px;
  width: 100%;
}

.right-image-conts {
  width: 100%;
  height: 200px;
}
/* vue-cropper{} */
@media (max-width: 1500px) {
  .cropper-area {
    width: 90%;
  }
}
@media (max-width: 1200px) {
  #cropright {
    width: 100%;
  }
  #prevleft {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
  }
  .upload_shadow_multi {
    width: 100%;
  }
}
@media (max-width: 950px) {
  #cropright {
    width: 100%;
  }
  #prevleft {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
  }
  /* .upload_shadow_multi {
    width: 100%;
  } */
  .cropper-area {
    width: 100%;
  }

  .upload_shadow_multi {
    width: 100%;
    height: 350px;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.418);
    top: 6%;
    align-items: center;
    align-content: center;
    text-align: center;
    margin-left: -5px;
  }

  .preview-area {
    width: 100%;
    margin-top: 20px;
    align-content: center;
    align-items: center;
    text-align: center;
  }

  .faicon {
    font-size: 28px;
    margin: 6px;
    margin-right: 6px;
  }

  .b1 {
    margin-right: 35px;
  }

  .b11 {
    margin-left: 55px;
    margin-top: -55px;
  }
  .faicon2 {
    margin: 0px;
    margin-right: 0px;
    margin-left: 40px;
    font-size: 45px;
    margin-top: -40px;
  }
}

.flex-container {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
  gap: 20px; /* Space between images */
  /* position: bottom; */
  margin-top: 10px;
}

.flex-container img {
  width: 80px; /* Adjust the size as needed */
  height: 60px; /* Adjust the size as needed */
  object-fit: cover; /* Ensure the image covers the entire area without distortion */
  border-radius: 8px; /* Optional: round the corners of the images */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: add a subtle shadow for a nicer look */
}

.slider-container {
  overflow: hidden;
  width: 100%;
  max-width: 200px;
  margin: 20px auto;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.slider {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 100%;
}

.slider img {
  width: 100%;
  height: auto;
  flex-shrink: 0;
}

.thumbnails {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.thumbnails img {
  width: 100px;
  height: 60px;
  cursor: pointer;
  margin: 0 5px;
  border: 2px solid transparent;
}

.thumbnails img:hover {
  border: 2px solid #000;
}
a {
  cursor: grab;
}

.controls {
  font-size: 30px;
  color: black;
}
.controls-left {
  float: left;
}
.controls-right {
  float: right;
}
</style>
