const baseUrl = "http://localhost:8000/api/"
const adminUrl = `${baseUrl}admin/v1/`
const superAdminUrl = `${baseUrl}admin/v1/super-admin`
const ctrlPanelUrl = `${adminUrl}control-panel`
const userUrl = `${baseUrl}v1/`

// 
const socketUrl = "wss://bqgwrfnx8h.execute-api.eu-west-2.amazonaws.com/development";

const endpoints = {
    auth: {
        login: `${adminUrl}login`,
        logout: `${adminUrl}logout`,
        register: `${adminUrl}register`,
        email: `${adminUrl}email`,
        verifyEmail: `${adminUrl}verify-email`
    },
    user: {
        register: `${userUrl}register`,
        login: `${userUrl}login`,
        forgotPassword: `${userUrl}forgot-password`,
        requestActivation: `${userUrl}user/request-activation`,
        activate: `${userUrl}user/activate`,
        resetPassword: `${userUrl}reset-password`,
        changePassword: `${userUrl}change-password`,
        product: `${userUrl}product`,
        category: `${userUrl}category`,
        orderReceipt: (orderId) => `${userUrl}order/${orderId}/receipt`,
        orderSummary: `${userUrl}order/summary`,
        transactions: `${userUrl}transactions`,
        order: `${userUrl}order`,
        emailOTP: `${userUrl}email/otp`,
        verifyEmail: `${userUrl}email/verify`,
        addressDefault: `${userUrl}user/address/default`,
        address: `${userUrl}user/address`,
        setDefaultAddress: (id) => `${userUrl}user/address/${id}/default`,
        dashboard: `${userUrl}user/dashboard`,
        profile: `${userUrl}user/profile`,
        updateProfile: `${userUrl}user/update-profile`,
        notifications: `${userUrl}user/notifications`,
        deactivate: `${userUrl}user/deactivate`,
        requestDelete: `${userUrl}user/request-delete`,
        deleteUser: `${userUrl}user/delete`
    },
    admin: {
        states: `${adminUrl}states`,
        storeName: `${adminUrl}store_name`,
        auth: {
            register: `${adminUrl}register`,
            login: `${adminUrl}login`,
            email: `${adminUrl}email`,
            verifyEmail: `${adminUrl}verify-email`
        },
        chat: {
            connection: `${adminUrl}chat/connection`,
            users: `${adminUrl}chat/users`
        },
        admin: {
            updateProfile: `${adminUrl}admin`,
            getProfile: `${adminUrl}admin`,
            deposit: `${adminUrl}admin/deposit`,
            history: `${adminUrl}admin/history`,
            spotCheck: `${adminUrl}admin/spot-check`
        },
        sale: {
            report: `${adminUrl}sales/report`,
            pending: `${adminUrl}sales/pending`,
            summary: `${adminUrl}sales/summary`,
            completed: `${adminUrl}sales/completed`
        },
        productStock: `${adminUrl}product/stock`,
        paymentReport: `${adminUrl}payment-report`,
        purchaseReport: `${adminUrl}purchase-report`,
        supplierReport: `${adminUrl}supplier-report`,
        vendorReport: `${adminUrl}vendor-report`
    },
    controlPanel: {
        chat: `${ctrlPanelUrl}/chat`,
        admin: `${ctrlPanelUrl}/admin`,
        accessCode: `${ctrlPanelUrl}/access-code`,
        productImage: `${ctrlPanelUrl}/product-image`,
        accountType: `${ctrlPanelUrl}/account-type`,
        printTransactions: (accountNo) => `${ctrlPanelUrl}/print/${accountNo}/transactions`,
        crateType: `${ctrlPanelUrl}/crate-type`,
        receipts: `${ctrlPanelUrl}/receipts`,
        advertsMobileSplash: `${ctrlPanelUrl}/adverts/mobile/splash`,
        bankDetails: `${ctrlPanelUrl}/bank-details`,
        crates: `${ctrlPanelUrl}/crates`,
        approveSalesReturn: `${ctrlPanelUrl}/sales/return/approve`,
        salesReturn: `${ctrlPanelUrl}/sales/return`,
        adminRole: `${ctrlPanelUrl}/admin-role`,
        adminPermission: `${ctrlPanelUrl}/admin-permission`,
        confirmTransit: (transitId) => `${ctrlPanelUrl}/product/transit/${transitId}/confirm`,
        productTransit: `${ctrlPanelUrl}/product/transit`,
        updateSubcategory: (id) => `${ctrlPanelUrl}/subcategories/${id}`,
        updateBrand: (id) => `${ctrlPanelUrl}/brands/${id}`,
        updateProduct: (id) => `${ctrlPanelUrl}/product/${id}`,
        updateCategory: (id) => `${ctrlPanelUrl}/categories/${id}`,
        users: {
            creditUser: (accountNo) => `${ctrlPanelUrl}/users/${accountNo}/credit`,
            clearDues: (accountNo) => `${ctrlPanelUrl}/users/${accountNo}/clear-dues`,
            transactions: (accountNo) => `${ctrlPanelUrl}/users/${accountNo}/transactions`,
            outstanding: (accountNo) => `${ctrlPanelUrl}/users/${accountNo}/outstanding`,
            accounts: `${ctrlPanelUrl}/users/accounts`,
            accountDetails: (accountNo) => `${ctrlPanelUrl}/users/accounts/${accountNo}`
        },
        sales: `${ctrlPanelUrl}/sales`,
        orders: {
            index: `${ctrlPanelUrl}/orders`,
            destroy: (orderId) => `${ctrlPanelUrl}/orders/${orderId}`,
            show: (orderId) => `${ctrlPanelUrl}/orders/${orderId}`,
            payments: (orderId) => `${ctrlPanelUrl}/orders/${orderId}/payments`
        },
        archive: {
            orders: `${ctrlPanelUrl}/archive/orders`
        }
    },
    superAdmin: {
        pendingVendors: `${superAdminUrl}/pending-vendors`,
        rejectedVendors: `${superAdminUrl}/rejected-vendors`,
        vendors: (status, page) => `${superAdminUrl}/vendors?status=${status}&page=${page}`,
        updateVendorRequest: `${superAdminUrl}/update-vendor-request`
    }
}

export { endpoints, socketUrl, adminUrl }