<template>
  <div>
    <layout-header></layout-header>
    <layout-sidebar></layout-sidebar>
    <InLoader class="inloader" />
    <OTP id="OTP_modal" />
    <OTP2 id="OTP_modal2" />

    <div class="page-wrapper">
      <div class="content">
        <div class="page-header transfer">
          <div class="add-item d-flex">
            <div class="page-title">
              <h4>Withdrawals List</h4>
              <!-- <h6>Manage your purchases</h6> -->
            </div>
          </div>
          <ul class="table-top-head">
            <li>
              <a data-bs-toggle="tooltip" data-bs-placement="top" title="Pdf"
                ><img src="@/assets/img/icons/pdf.svg" alt="img"
              /></a>
            </li>
            <li>
              <a data-bs-toggle="tooltip" data-bs-placement="top" title="Excel"
                ><img src="@/assets/img/icons/excel.svg" alt="img"
              /></a>
            </li>
            <li>
              <a data-bs-toggle="tooltip" data-bs-placement="top" title="print"
                ><img src="@/assets/img/icons/printer.svg" alt="img"
              /></a>
            </li>
            <li>
              <a
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Refresh"
                ><i data-feather="rotate-ccw" class="feather-rotate-ccw"></i
              ></a>
            </li>
            <li>
              <a
                ref="collapseHeader"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Collapse"
                @click="toggleCollapse"
              >
                <i data-feather="chevron-up" class="feather-chevron-up"></i>
              </a>
            </li>
          </ul>
          <div class="d-flex purchase-pg-btn">
            <div class="page-btn">
              <a
                class="btn btn-added"
                data-bs-toggle="modal"
                href="#exampleModalToggle"
                ><vue-feather type="plus-circle" class="me-2"></vue-feather>Add
                New Withdrawals</a
              >
            </div>
          </div>

          <div class="page-btn import">
            <a
              class="btn btn-added color"
              data-bs-toggle="modal"
              href="#international_withdraw"
              ><vue-feather type="plus-circle" class="me-2"></vue-feather
              >International Withdrawals</a
            >
          </div>
        </div>

        <!-- /product list -->
        <div class="card table-list-card">
          <div class="card-body">
            <div class="table-top">
              <div class="search-set">
                <div class="search-input">
                  <input type="text" placeholder="Search" class="dark-input" />
                  <a href="" class="btn btn-searchset"
                    ><i data-feather="search" class="feather-search"></i
                  ></a>
                </div>
              </div>
              <div class="search-path" hidden>
                <a
                  class="btn btn-filter"
                  id="filter_search"
                  v-on:click="filter = !filter"
                  :class="{ setclose: filter }"
                >
                  <vue-feather type="filter" class="filter-icon"></vue-feather>
                  <span
                    ><img src="@/assets/img/icons/closes.svg" alt="img"
                  /></span>
                </a>
              </div>
              <div class="form-sort">
                <vue-feather type="sliders" class="info-img"></vue-feather>
                <vue-select
                  :options="Sortby"
                  id="Sortby"
                  placeholder="Sort by Date"
                />
              </div>
            </div>
            <!-- /Filter -->
            <div
              class="card"
              id="filter_inputs"
              :style="{ display: filter ? 'block' : 'none' }"
            >
              <div class="card-body pb-0">
                <div class="row">
                  <div class="col-lg-2 col-sm-6 col-12">
                    <div class="input-blocks">
                      <i data-feather="user" class="info-img"></i>
                      <vue-select
                        :options="Best"
                        id="best"
                        placeholder="Choose Supplier Name"
                      />
                    </div>
                  </div>
                  <div class="col-lg-2 col-sm-6 col-12">
                    <div class="input-blocks">
                      <i data-feather="stop-circle" class="info-img"></i>
                      <vue-select
                        :options="Status"
                        id="status"
                        placeholder="Choose Status"
                      />
                    </div>
                  </div>
                  <div class="col-lg-2 col-sm-6 col-12">
                    <div class="input-blocks">
                      <i data-feather="file" class="info-img"></i>
                      <vue-select
                        :options="Enter"
                        id="enter"
                        placeholder="Enter Reference"
                      />
                    </div>
                  </div>
                  <div class="col-lg-2 col-sm-6 col-12">
                    <div class="input-blocks">
                      <i class="fas fa-money-bill info-img"></i>
                      <vue-select
                        :options="Paid"
                        id="paid"
                        placeholder="Choose Payment Status"
                      />
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6 col-12 ms-auto">
                    <div class="input-blocks">
                      <a class="btn btn-filters ms-auto">
                        <i data-feather="search" class="feather-search"></i>
                        Search
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Filter -->
            <div class="table-responsive product-list">
              <a-table
                :columns="columns"
                :data-source="data"
                :row-selection="{}"
              >
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key === 'amount'">
                    <td>
                      ₦ {{ splitNumbersWithComma(record.amount) }}
                    </td>
                  </template>
                  <template v-if="column.key === 'name'">
                    <td>
                      {{ record.bank_details.surname }}
                      {{ record.bank_details.other_names }}
                    </td>
                  </template>
                  <template v-else-if="column.key === 'account'">
                    <td>
                      {{ record.bank_details.account_number }}
                    </td>
                  </template>
                  <template v-else-if="column.key === 'status'">
                    <span
                      class="badges status-badge"
                      v-if="record.status == 'Success'"
                      >{{ record.status }}</span
                    >
                    <span
                      class="badge badge-warning"
                      v-if="record.status == 'Pending'"
                      >{{ record.status }}</span
                    >
                  </template>

                  <template v-else-if="column.key === 'action'">
                    <td class="action-table-data">
                      <div class="edit-delete-action">
                        <a
                          class="me-2 p-2"
                          href="javascript:void(0);"
                          @click="view_withdrawal(record)"
                        >
                        <i data-feather="eye" class="feather-eye"></i>
                        </a>
                      </div>
                    </td>
                  </template>
                </template>
              </a-table>
            </div>
          </div>
        </div>
        <!-- /product list -->
      </div>
    </div>
  </div>

  <!-- withdrawal modal -->
  <!-- Toggle Between Modals -->
  <div class="col-xl-4">
    <!-- Modal -->
    <div
      class="modal fade"
      id="exampleModalToggle"
      aria-hidden="true"
      aria-labelledby="exampleModalToggleLabel"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="exampleModalToggleLabel">
              Request Payout
            </h4>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div>
              <div class="balance-card">
                <div class="bal-content">
                  <div style="display: inline-block" class="icon_cont">
                    <span class="fa fa-wallet wallet_icon"></span>
                  </div>
                  <div style="display: inline-block">
                    <h5 class="bal">
                      ₦
                      <vue3-autocounter
                        class="counters"
                        ref="counter"
                        :startAmount="0"
                        :delay="1"
                        :endAmount="returned_data.available_balance"
                        :duration="5"
                        :autoinit="true"
                      />
                    </h5>
                    <p style="color: rgb(255, 255, 255)">Available Balance</p>
                  </div>
                </div>
              </div>
            </div>
            <center v-if="returned_data.available_balance < withdraw_amount">
              <span style="color: red"
                >Your balance is less than your request!</span
              >
            </center>
            <form class="">
              <div id="input-group-1" role="group" class="form-group">
                <label id="" for="input-1" class="d-block"
                  >Enter the amount you want to withdraw:</label
                >
                <div>
                  <input
                    id="input-1"
                    type="number"
                    placeholder="Enter Amount"
                    required="required"
                    aria-required="true"
                    class="form-control"
                    v-model="withdraw_amount"
                  /><!----><!----><!---->
                </div>
              </div>
            </form>
          </div>
          <!-- :disabled="withdraw_amount < 1000 && withdraw_amount === ''" -->

          <div class="modal-footer">
            <button
              class="btn btn-primary"
              data-bs-target="#exampleModalToggle2"
              data-bs-toggle="modal"
              data-bs-dismiss="modal"
              style="width: 100%"
              :disabled="withdraw_amount < 1000"
              @click="get_banks_accounts()"
            >
              CONTINUE
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- /Modal -->

    <!-- Modal -->
    <div
      class="modal fade"
      id="exampleModalToggle2"
      aria-hidden="true"
      aria-labelledby="exampleModalToggleLabel2"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="exampleModalToggleLabel2">
              Request Payout
            </h4>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div>
              <div class="balance-card">
                <div class="bal-content">
                  <div style="display: inline-block" class="icon_cont">
                    <span class="fa fa-wallet wallet_icon"></span>
                  </div>
                  <div style="display: inline-block">
                    <h5 class="bal">
                      ₦
                      <vue3-autocounter
                        class="counters"
                        ref="counter"
                        :startAmount="0"
                        :delay="1"
                        :endAmount="returned_data.available_balance"
                        :duration="5"
                        :autoinit="true"
                      />
                    </h5>
                    <p style="color: rgb(255, 255, 255)">Available Balance</p>
                  </div>
                </div>
              </div>
            </div>

            <form class="" v-if="bank_list.length <= 0 || bank_list == null">
              <div id="input-group-1" role="group" class="form-group fg2">
                <label id="" for="input-1" class="d-block"
                  >You have not added a bank yet.
                  <router-link
                    to="/settings/bank-settings-grid"
                    style="color: #00afef"
                  >
                    Add Bank Details</router-link
                  ></label
                >
              </div>
            </form>

            <div class="list_accts" v-else>
              <div class="mt-4">
                <p style="color: rgb(0, 0, 0); margin-bottom: -20px">
                  Select Bank Account:
                </p>

                <div>
                  <div v-for="(bank, index) in bank_list" :key="index" class="">
                    <div
                      :class="{
                        'bank-box': true,
                      }"
                    >
                      <div class="bank-header">
                        <div class="bank-name">
                          <h6>{{ bank.bank }}</h6>
                          <p>{{ bank.account_number }}</p>
                        </div>
                      </div>
                      <div
                        class="d-flex align-items-center justify-content-between"
                      >
                        <div class="bank-info">
                          <span>Holder Name</span>
                          <h6>{{ bank.surname }} {{ bank.other_names }}</h6>
                        </div>
                        <div class="edit-delete-action bank-action-btn">
                          <a
                            class="me-2 p-2 text-light"
                            href="javascript:void(0);"
                            data-bs-target="#confirmModalToggle"
                            data-bs-toggle="modal"
                            data-bs-dismiss="modal"
                            @click="
                              get_selected(
                                bank.bank,
                                bank.surname,
                                bank.other_names,
                                bank.id
                              )
                            "
                          >
                            Select
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              v-if="bank_list.length <= 0 || bank_list == null"
              class="btn btn-primary"
              data-bs-target="#confirmModalToggle"
              data-bs-toggle="modal"
              data-bs-dismiss="modal"
              style="width: 100%"
              disabled
            >
              CONTINUE
            </button>
            <!-- <button
              v-else
              class="btn btn-primary"
              data-bs-target="#confirmModalToggle"
              data-bs-toggle="modal"
              data-bs-dismiss="modal"
              style="width: 100%"
            >
              CONTINUE
            </button> -->
          </div>
        </div>
      </div>
    </div>
    <!-- /Modal -->
  </div>
  <!-- /Toggle Between Modals -->

  <!-- confirm modal -->
  <!-- Toggle Between Modals -->
  <div class="col-xl-4">
    <!-- Modal -->
    <div
      class="modal fade"
      id="confirmModalToggle"
      aria-hidden="true"
      aria-labelledby="confirmModalToggleLabel"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="confirmModalToggleLabel">
              Request Payout
            </h4>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <!--   verify paymentcontnerts goes here -->
            <div>
              <p>Confirm withrawal details below:</p>
              <div class="py-2">
                <div class="confirm-desc py-2">
                  <div class="confrim-content">
                    <h4 style="font-size: 28px; font-weight: 600">
                      ₦
                      <!-- <vue3-autocounter
                        class="counters"
                        ref="counter"
                        :startAmount="0"
                        :delay="1"
                        :endAmount="withdraw_amount"
                        :duration="5"
                        :autoinit="true"
                      /> -->
                      {{ splitNumbersWithComma(withdraw_amount) }}
                    </h4>
                    <p>Withdrawal Amount</p>
                  </div>
                </div>
              </div>
              <div class="py-2">
                <div class="confirm-desc py-2">
                  <div class="confrim-content">
                    <h4 style="font-size: 28px; font-weight: 600">
                      {{ returned_bank_name }}
                    </h4>
                    <p>{{ returned_bank }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-primary"
              data-bs-target="#confirmModalToggle2"
              data-bs-toggle="modal"
              data-bs-dismiss="modal"
              style="width: 100%"
            >
              CONTINUE
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- /Modal -->

    <!-- Modal -->
    <div
      class="modal fade"
      id="confirmModalToggle2"
      aria-hidden="true"
      aria-labelledby="confirmModalToggleLabel2"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="confirmModalToggleLabel2">
              Request Payout
            </h4>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="mt-1">
              <p>You’ll need to enter password to continue.</p>
              <div class="py-2 mt-0">
                <form class="">
                  <div id="input-group-1" role="group" class="form-group">
                    <label id="input-group-1" for="input-1" class="d-block"
                      >Enter your password below:</label
                    >
                    <div>
                      <input
                        id="input-1"
                        type="password"
                        placeholder="Enter Password"
                        class="form-control"
                        v-model="withdraw_password"
                      /><!----><!----><!---->
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <!-- the main action goes here (withdraw)-->
            <div
              class="btn btn-primary"
              style="width: 100%"
              @click="confirm_withdrawal()"
            >
              CONFIRM
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Modal -->
  </div>
  <!-- /Toggle Between Modals -->

  <div class="col-xl-4">
    <!-- Modal -->
    <div
      class="modal fade"
      id="viewWithdrawal"
      aria-hidden="true"
      aria-labelledby="viewWithdrawal_Label"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="viewWithdrawal_Label">
              Withdrawal Details
            </h4>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="modal-container">
              <h4>Name:</h4>
              <div class="name-block">
                <p>{{ mutated_item?.bank_details?.surname }}</p>
                <p>{{ mutated_item?.bank_details?.other_names }}</p>
              </div>
            </div>
            <div class="modal-container">
              <h4>Bank Name:</h4>
              <p>{{ mutated_item?.bank_details?.bank }}</p>
            </div>
            <div class="modal-container">
              <h4>Account Number:</h4>
              <p>{{ mutated_item?.bank_details?.account_number }}</p>
            </div>
            <div class="modal-container">
              <h4>Amount:</h4>
              <p>₦ {{ splitNumbersWithComma(mutated_item?.amount) }}</p>
            </div>
            <div class="modal-container">
              <h4>Status:</h4>
              <!----><span class="order-status pending">{{
                mutated_item?.status
              }}</span
              ><!----><!----><!---->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- withdral modal -->
  <div class="modal fade" id="international_withdraw">
    <div class="modal-dialog modal-dialog-centered custom-modal-two">
      <div class="modal-content">
        <div class="page-wrapper-new p-0">
          <div class="content">
            <div class="modal-header border-0 custom-modal-header">
              <div class="page-title">
                <h4>International Withdrawal Form</h4>
              </div>
              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div class="modal-body custom-modal-body">
              <div class="row">
                <div class="col-lg-6">
                  <div class="mb-3">
                    <label class="form-label">Company Name</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="company_name"
                    />
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="mb-3">
                    <label class="form-label">Company Address</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="company_address"
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="mb-3">
                    <label class="form-label">City</label>
                    <input type="text" class="form-control" v-model="city" />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="mb-3">
                    <label class="form-label"> State</label>
                    <input type="text" class="form-control" v-model="state" />
                    <span class="unlimited-text"></span>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="mb-3">
                    <label class="form-label">Full Name</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="full_name"
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="mb-3">
                    <label class="form-label">Email Address</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="email_address"
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="mb-3">
                    <label class="form-label">Bank Account Number</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="bank_ccount_number"
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="mb-3">
                    <label class="form-label">Bank Name</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="bank_name"
                    />
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="mb-3">
                    <label class="form-label">Bank Sort Code</label>

                    <input
                      type="text"
                      class="form-control"
                      v-model="bank_name"
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="mb-3">
                    <label class="form-label">Amount</label>
                    <input type="text" class="form-control" v-model="amount" />
                  </div>
                </div>
              </div>
              <div class="modal-footer-btn">
                <button
                  type="button"
                  class="btn btn-cancel me-2"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <div class="btn btn-submit" disabled >
                  REQUEST PAYOUT
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

// can remove
import { ref } from "vue";
import { onMounted } from "vue";
const columns = [
  {
    sorter: true,
  },
  {
    title: "Amount",
    key: "amount",
    sorter: {
      compare: (a, b) => {
        a = a.amount.toLowerCase();
        b = b.amount.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Name",
    key: "name",
    sorter: {
      compare: (a, b) => {
        a = a.name.toLowerCase();
        b = b.name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Account",
    key: "account",
    sorter: {
      compare: (a, b) => {
        a = a.account.toLowerCase();
        b = b.account.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Status",
    key: "status",
    sorter: {
      compare: (a, b) => {
        a = a.status.toLowerCase();
        b = b.status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },

  {
    title: "Action",
    key: "action",
    sorter: true,
  },
];

export default {
  data() {
    return {
      returned_data: "",
      data: [],
      columns,
      // can remove
      withdraw_amount: "",
      k: false,
      bank_list: "",
      returned_bank: "",
      returned_bank_name: "",
      bank_details_id: "",
      withdraw_password: "",
      response: "",
      mutated_item: "",
    };
  },
  methods: {
    ...mapActions({
      _get_withdrawls: "general/get_withdrawls",
      _get_banks_accounts: "general/get_banks_accounts",
      _confirm_withdrawal: "general/confirm_withdrawal",
    }),

    get_withdrawls() {
      this._get_withdrawls()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.data = this.response.data;
            this.data = this.data.reverse();
            console.log(this.data);
          }
        })
        .catch((error) => {
          console.log("Error logging in", error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;
            if (
              status == 400 ||
              status == 422 ||
              status == 401 ||
              status == 404
            ) {
            } else if (status === 500) {
            }
          }
        });
    },
    get_banks_accounts() {
      $(".inloader").show();
      this._get_banks_accounts()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            $(".inloader").hide();

            this.bank_list = this.response.data;
          }
        })
        .catch((error) => {
          $(".inloader").hide();

          console.log("Error logging in", error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;
            if (
              status == 400 ||
              status == 422 ||
              status == 401 ||
              status == 404
            ) {
            } else if (status === 500) {
            }
          }
        });
    },
    confirm_withdrawal() {
      $(".inloader").show();
      this._confirm_withdrawal({
        amount: this.withdraw_amount,
        bank_details_id: this.bank_details_id,
        password: this.withdraw_password,
      })
        .then((response) => {
          $(".inloader").hide();
          toast.success("OTP SENT", {
            position: toast.POSITION.TOP_RIGHT,
          });
          this.response = response.data;
          //   this.response
          if (this.response["status"] == true) {
            var data = this.response.data;
            // alert(data.id);
            localStorage.setItem("withdrawal_id", data.id);
            localStorage.setItem("withdraw_amount", this.withdraw_amount);

            // SHOW OTP MODAL
            $("#confirmModalToggle2").modal("hide");
            $("#OTP_modal2").modal("show");
            this.startCountdown();
          }
        })
        .catch((error) => {
          $(".inloader").hide();

          console.log("Error logging in", error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;
            if (
              status == 400 ||
              status == 422 ||
              status == 401 ||
              status == 404
            ) {
              toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            } else if (status === 500) {
              toast.error("Network Error, Check your internet conectivity.", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          }
        });
    },

    get_selected(bank, bank_name1, bank_name2, id) {
      this.returned_bank = bank;
      this.returned_bank_name = bank_name1 + " " + bank_name2;
      this.bank_details_id = id;
    },
    view_withdrawal(item) {
      this.mutated_item = item;
      // $("#viewWithdrawal").modal("show");
      window.location='/widthrawal-details'
      localStorage.setItem('Wdata',JSON.stringify(item))
    },
    showConfirmation() {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          Swal.fire({
            icon: "success",
            title: "Deleted!",
            text: "Your file has been deleted.",
            confirmButtonClass: "btn btn-success",
          });
        }
      });
    },
    logout() {
      window.location = "/";
    },

    toggleCollapse() {
      const collapseHeader = this.$refs.collapseHeader;

      if (collapseHeader) {
        collapseHeader.classList.toggle("active");
        document.body.classList.toggle("header-collapse");
      }
    },

    updateTimerDisplay(timeInSeconds) {
      // Get the timer element
      const timerElement = document.getElementById("count-down");

      const minutes = Math.floor(timeInSeconds / 60);
      const seconds = timeInSeconds % 60;
      const formattedTime = `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
      timerElement.textContent = formattedTime;
    },
    performAction() {
      $("#OTP_modal").modal("hide");
      $(".xvx").show();
    },
    startCountdown() {
      const countdownTime = 5 * 60; // 5 minutes * 60 seconds
      let timeRemaining = countdownTime;
      // Update the display initially
      this.updateTimerDisplay(timeRemaining);

      let _vm = this;
      // Set an interval to update the countdown every second
      const countdownInterval = setInterval(function () {
        timeRemaining--;

        // Update the display
        _vm.updateTimerDisplay(timeRemaining);

        // Check if the countdown reached 0
        if (timeRemaining <= 0) {
          // Clear the interval
          clearInterval(countdownInterval);

          // Perform the action when the countdown reaches 0
          _vm.performAction();
        }
      }, 3000); // Update every 1000 milliseconds (1 second)
    },
    splitNumbersWithComma(number) {
      // Convert number to string to handle cases like decimal numbers
      if (number) {
        let numStr = number.toString();

        // Split the integer and decimal parts if it's a decimal number
        let parts = numStr.split(".");
        let integerPart = parts[0];
        let decimalPart = parts.length > 1 ? "." + parts[1] : "";

        // Insert commas for thousands separator in the integer part
        let integerWithCommas = integerPart.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );

        // Combine integer part with decimal part (if any)
        let formattedNumber = integerWithCommas + decimalPart;

        return formattedNumber;
      }
    },
  },
  mounted() {
    this.get_withdrawls();
    // this.get_banks_accounts();
  },

  // can remove if it doesnt work
  setup() {
    const dateRangeInput = ref(null);
    onMounted(() => {
      if (dateRangeInput.value) {
        const start = moment().subtract(6, "days");
        const end = moment();

        function booking_range(start, end) {
          start.format("M/D/YYYY") + " - " + end.format("M/D/YYYY");
        }

        new DateRangePicker(
          dateRangeInput.value,
          {
            startDate: start,
            endDate: end,
            ranges: {},
          },
          booking_range
        );

        booking_range(start, end);
      }
    });
    return {
      dateRangeInput,
    };
  },
};
</script>

<style scoped>
.c {
  color: #00afef34;
}
#item_code {
  z-index: 5;
  margin-right: 43%;
  width: 200px;
  cursor: pointer;
  margin-top: -45%;
  float: right;
}

.balance-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px;
  height: 96px;
  color: #fff;
  background: #00afef;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
}

.form-control {
  outline: none;
  font-size: 14px;
  padding: 14px 20px;
  border: none;
  font-style: italic;
  color: #000;
  height: 50px;
  font-weight: 400;
  border-radius: 0;
  border: 1px solid #ddd;
  transition: all 0.4s ease;
  box-shadow: 0 0 0 transparent;
  background-color: transparent;
}

.form-group > label {
  margin-bottom: 1.5rem;
  font-weight: 400;
  color: #000000;
  line-height: 1em;
  font-size: 16px;
  margin-top: 20px;
}
.fg2 > label {
  margin-bottom: 0px;
}
.bal {
  font-family: "Roboto";
  color: #fff;
  font-weight: 600;
  font-size: 28px;
  line-height: 33px;
}
.wallet_icon {
  font-size: 25px;
  color: #00afef;
  background-color: white;
  border-radius: 25%;
  display: inline-block;
  width: 40px;
  height: 40px;
  padding: 8px;
}
.icon_cont {
  margin-right: 10px;
  float: left;
}
#input-1 {
  height: 50px;
}
center {
  margin-top: 20px;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.confirm-desc {
  width: 100%;
  height: 82px;
  border-radius: 16px;
  background: #eaeaea;
  padding: 12px;
}
.confrim-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
element.style {
  font-size: 28px;
  font-weight: 600;
}

.modal-container {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  padding: 0px 20px;
}

.modal-container h4 {
  font-size: 16px;
  font-weight: bolder;
}
.modal-container {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  padding: 0px 20px;
}
p {
  font-size: 14px;
  line-height: 1.6em;
  font-weight: 500;
}
.name-block {
  display: flex;
  gap: 10px;
  margin-bottom: -20px;
}
</style>
