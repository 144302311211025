<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  <InLoader class="inloader" />

  <div class="page-wrapper sales-list">
    <div class="content">
      <div class="page-header">
        <div class="add-item d-flex">
          <div class="page-title">
            <h4>Sales List</h4>
            <h6>Manage Your Sales</h6>
          </div>
        </div>
        <ul class="table-top-head">
          <li>
            <a data-bs-toggle="tooltip" data-bs-placement="top" title="Pdf"
              ><img src="@/assets/img/icons/pdf.svg" alt="img"
            /></a>
          </li>
          <li>
            <a data-bs-toggle="tooltip" data-bs-placement="top" title="Excel"
              ><img src="@/assets/img/icons/excel.svg" alt="img"
            /></a>
          </li>
          <li>
            <a data-bs-toggle="tooltip" data-bs-placement="top" title="Print"
              ><vue-feather type="printer" class="printer"></vue-feather
            ></a>
          </li>
          <li>
            <a data-bs-toggle="tooltip" data-bs-placement="top" title="Refresh"
              ><vue-feather type="rotate-ccw" class="rotate-ccw"></vue-feather
            ></a>
          </li>
          <li>
            <a
              ref="collapseHeader"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Collapse"
              @click="toggleCollapse"
            >
              <i data-feather="chevron-up" class="feather-chevron-up"></i>
            </a>
          </li>
        </ul>
        <div class="page-btn">
          <a
            href="javascript:void(0);"
            class="btn btn-added"
            data-bs-toggle="modal"
            data-bs-target="#add-sales-new"
            ><vue-feather type="plus-circle" class="me-2"></vue-feather> Add New
            Sales</a
          >
        </div>
      </div>

      <!-- /product list -->
      <div class="card table-list-card">
        <div class="card-body">
          <div class="table-top">
            <div class="search-set">
              <div class="search-input">
                <input
                  type="text"
                  placeholder="Search"
                  class="dark-input"
                  v-model="search"
                  @input="searchtrigger()"
                />
                <span class="btn btn-searchset" @click="searchtrigger()"
                  ><i data-feather="search" class="feather-search"></i
                ></span>
              </div>
            </div>
            <div class="search-path" hidden>
              <div class="d-flex align-items-center">
                <a
                  class="btn btn-filter"
                  id="filter_search"
                  v-on:click="filter = !filter"
                  :class="{ setclose: filter }"
                >
                  <vue-feather type="filter" class="filter-icon"></vue-feather>
                  <span
                    ><img src="@/assets/img/icons/closes.svg" alt="img"
                  /></span>
                </a>
              </div>
            </div>
            <div class="form-sort" hidden>
              <vue-feather type="sliders" class="info-img"></vue-feather>
              <vue-select
                :options="Sortdate"
                id="sortdate"
                placeholder="Sort by Date"
              />
            </div>
          </div>
          <!-- /Filter -->
          <div
            hidden
            class="card"
            id="filter_inputs"
            :style="{ display: filter ? 'block' : 'none' }"
          >
            <div class="card-body pb-0">
              <div class="row">
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="input-blocks">
                    <i data-feather="user" class="info-img"></i>
                    <vue-select
                      :options="Macbook"
                      id="macbook"
                      placeholder="Choose Customer Name"
                    />
                  </div>
                </div>
                <div class="col-lg-2 col-sm-6 col-12">
                  <div class="input-blocks">
                    <i data-feather="stop-circle" class="info-img"></i>
                    <vue-select
                      :options="Fruits"
                      id="fruits"
                      placeholder="Choose Status"
                    />
                  </div>
                </div>
                <div class="col-lg-2 col-sm-6 col-12">
                  <div class="input-blocks">
                    <i data-feather="file-text" class="info-img"></i>
                    <input
                      type="text"
                      placeholder="Enter Reference"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="input-blocks">
                    <i data-feather="stop-circle" class="info-img"></i>
                    <vue-select
                      :options="Computers"
                      id="computer"
                      placeholder="Choose Payment Status"
                    />
                  </div>
                </div>
                <div class="col-lg-2 col-sm-6 col-12">
                  <div class="input-blocks">
                    <a class="btn btn-filters ms-auto">
                      <i data-feather="search" class="feather-search"></i>
                      Search
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Filter -->
          <div class="table-responsive">
            <a-table :columns="columns" :data-source="data" :row-selection="{}">
              <template #bodyCell="{ column, record }">
                <template v-if="column.key === 'Status'">
                  <div>
                    <span :class="record.Status_class">{{
                      record.Status
                    }}</span>
                  </div>
                </template>
                <template v-if="column.key === 'timestamp'">
                  <div>
                    <span>{{ getDatetime(record.timestamp) }}</span>
                  </div>
                </template>
                <template v-if="column.key === 'shipping_status'">
                  <div>
                    <span
                      class="badges status-badge"
                      v-if="record.shipping_status == 'Delivered'"
                      >{{ record.shipping_status }}</span
                    >
                    <span
                      class="badges status-badge"
                      v-if="record.shipping_status == 'Paid'"
                      >{{ record.shipping_status }}</span
                    >
                    <span
                      class="badges bg-lightyellow"
                      v-if="record.shipping_status == 'Fulfilled'"
                      >{{ record.shipping_status }}</span
                    >
                    <span
                      class="badges bg-lightyellow"
                      v-if="record.shipping_status == 'Shipped'"
                      >{{ record.shipping_status }}</span
                    >
                    <span
                      class="badges unstatus-badge"
                      v-if="record.shipping_status == 'Cancelled'"
                      >{{ record.shipping_status }}</span
                    >
                    <span
                      class="badges unstatus-badge"
                      v-if="record.shipping_status == 'Cancelling'"
                      >{{ record.shipping_status }}</span
                    >
                    <span
                      class="badges bg-lightyellow"
                      v-if="record.shipping_status == 'Pending'"
                      >{{ record.shipping_status }}</span
                    >
                  </div>
                </template>
                <template v-if="column.key === 'order_status'">
                  <div>
                    <span
                      class="badges status-badge"
                      v-if="record.order_status == 'Paid'"
                      >{{ record.order_status }}</span
                    >
                    <span
                      class="badges status-badge"
                      v-if="record.order_status == 'Delivered'"
                      >{{ record.order_status }}</span
                    >
                    <span
                      class="badges unstatus-badge"
                      v-if="record.order_status == 'Unpaid'"
                      >{{ record.order_status }}</span
                    >
                    <span
                      class="badges unstatus-badge"
                      v-if="record.order_status == 'Cancelled'"
                      >{{ record.order_status }}</span
                    >
                    <span
                      class="badges status-badge"
                      v-if="record.order_status == 'Fulfilled'"
                      >{{ record.order_status }}</span
                    >
                  </div></template
                >
                <template v-if="column.key === 'amount'">
                  <div>
                    <span v-if="record.amount">
                      ₦ {{ splitNumbersWithComma(record.amount) }}</span
                    >
                  </div>
                </template>
                <template v-if="column.key === 'total_payment'">
                  <div>
                    <span v-if="record.amount">
                      ₦ {{ splitNumbersWithComma(record.total_payment) }}</span
                    >
                  </div>
                </template>
                <template v-if="column.key === 'due'">
                  <div>
                    <span v-if="record.amount">
                      ₦ {{ splitNumbersWithComma(record.due) }}</span
                    >
                  </div>
                </template>
                <template v-if="column.key === 'type'">
                  <div>
                    <span
                      v-if="record.is_offline == false"
                      class="badges status-badge"
                    >
                      Online</span
                    >
                    <span
                      v-if="record.is_offline == true"
                      class="badges unstatus-badge"
                    >
                      Offline</span
                    >
                  </div>
                </template>
                <template v-if="column.key === 'action'">
                  <div class="text-center" v-if="record.amount">
                    <a
                      class="action-set"
                      href="javascript:void(0);"
                      data-bs-toggle="dropdown"
                      aria-expanded="true"
                    >
                      <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                    </a>
                    <ul class="dropdown-menu sales-list-icon">
                      <li>
                        <a
                          href="javascript:void(0);"
                          class="dropdown-item"
                          @click="view_purchase_details(record.id)"
                          v-if="record.is_offline == false"
                          ><vue-feather
                            type="eye"
                            class="info-img"
                          ></vue-feather
                          >Sale Detail</a
                        >
                        <a
                          href="javascript:void(0);"
                          class="dropdown-item"
                          @click="view_purchase_details_offline(record.id)"
                          v-if="record.is_offline == true"
                          ><vue-feather
                            type="eye"
                            class="info-img"
                          ></vue-feather
                          >Sale Detail</a
                        >
                      </li>
                      <li hidden>
                        <a
                          hidden
                          href="javascript:void(0);"
                          class="dropdown-item"
                          data-bs-toggle="modal"
                          data-bs-target="#edit-sales-new"
                          ><vue-feather
                            type="edit"
                            class="info-img"
                          ></vue-feather
                          >Edit Sale</a
                        >
                      </li>
                      <li hidden>
                        <a
                          href="javascript:void(0);"
                          class="dropdown-item"
                          data-bs-toggle="modal"
                          data-bs-target="#showpayment"
                        >
                          <!-- <vue-feather
                            type="dollar-sign"
                            class="info-img"
                          ></vue-feather
                          > -->

                          ₦ Show Payments</a
                        >
                      </li>
                      <li v-if="record.is_offline == true">
                        <a
                          href="javascript:void(0);"
                          class="dropdown-item"
                          @click="open_create_payment(record)"
                          ><vue-feather
                            type="plus-circle"
                            class="info-img"
                          ></vue-feather
                          >Create Payment</a
                        >
                      </li>
                      <li hidden>
                        <a href="javascript:void(0);" class="dropdown-item"
                          ><vue-feather
                            type="download"
                            class="info-img"
                          ></vue-feather
                          >Download pdf</a
                        >
                      </li>
                      <li hidden>
                        <a
                          hidden
                          href="javascript:void(0);"
                          class="dropdown-item confirm-text mb-0"
                          ><vue-feather
                            type="trash-2"
                            class="info-img"
                          ></vue-feather
                          >Delete Sale</a
                        >
                      </li>
                    </ul>
                  </div>
                </template>
              </template>
            </a-table>
          </div>
        </div>
      </div>
      <!-- /product list -->
    </div>
  </div>

  <sales-list-modal :sales_data="sales_data"></sales-list-modal>
</template>

<script>
import { mapActions } from "vuex";
import { searchList } from "../../../module/search_module.js";

const columns = [
  {
    sorter: true,
  },
  {
    title: "Product",
    dataIndex: "products",
    sorter: {
      compare: (a, b) => {
        a = a.products.toLowerCase();
        b = b.products.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Reference",
    dataIndex: "public_ref",
    sorter: {
      compare: (a, b) => {
        a = a.public_ref.toLowerCase();
        b = b.public_ref.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Date",
    key: "timestamp",
    sorter: {
      compare: (a, b) => {
        a = a.timestamp.toLowerCase();
        b = b.timestamp.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Shipping Status",
    dataIndex: "shipping_status",
    key: "shipping_status",
    sorter: {
      compare: (a, b) => {
        a = a.shipping_status.toLowerCase();
        b = b.shipping_status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Order Status",
    dataIndex: "order_status",
    key: "order_status",
    sorter: {
      compare: (a, b) => {
        a = a.shipping_status.toLowerCase();
        b = b.shipping_status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Amount",
    // dataIndex: "amount",
    key: "amount",
    sorter: {
      compare: (a, b) => {
        a = a.amount.toLowerCase();
        b = b.amount.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Total Payment",
    // dataIndex: "amount",
    key: "total_payment",
    sorter: {
      compare: (a, b) => {
        a = a.total_payment.toLowerCase();
        b = b.total_payment.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Amount Due",
    key: "due",
    sorter: {
      compare: (a, b) => {
        a = a.due.toLowerCase();
        b = b.due.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Sales Type",
    key: "type",
    sorter: {
      compare: (a, b) => {
        a = a.Grand_Total.toLowerCase();
        b = b.Grand_Total.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "action",
    sorter: true,
  },
];

const columnsold = [
  {
    sorter: true,
  },
  {
    title: "Customer Name",
    dataIndex: "Customer_Name",
    sorter: {
      compare: (a, b) => {
        a = a.Customer_Name.toLowerCase();
        b = b.Customer_Name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Reference",
    dataIndex: "Reference",
    sorter: {
      compare: (a, b) => {
        a = a.Reference.toLowerCase();
        b = b.Reference.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Date",
    dataIndex: "Date",
    sorter: {
      compare: (a, b) => {
        a = a.Date.toLowerCase();
        b = b.Date.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status",
    sorter: {
      compare: (a, b) => {
        a = a.Status.toLowerCase();
        b = b.Status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Type",
    dataIndex: "type",
    sorter: {
      compare: (a, b) => {
        a = a.Grand_Total.toLowerCase();
        b = b.Grand_Total.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  // {
  //   title: "Paid",
  //   dataIndex: "Paid",
  //   sorter: {
  //     compare: (a, b) => {
  //       a = a.Paid.toLowerCase();
  //       b = b.Paid.toLowerCase();
  //       return a > b ? -1 : b > a ? 1 : 0;
  //     },
  //   },
  // },
  // {
  //   title: "Due",
  //   dataIndex: "Due",
  //   sorter: {
  //     compare: (a, b) => {
  //       a = a.Due.toLowerCase();
  //       b = b.Due.toLowerCase();
  //       return a > b ? -1 : b > a ? 1 : 0;
  //     },
  //   },
  // },
  // {
  //   title: "Payment Status",
  //   dataIndex: "Payment_Status",
  //   key: "Payment_Status",
  //   sorter: {
  //     compare: (a, b) => {
  //       a = a.Payment_Status.toLowerCase();
  //       b = b.Payment_Status.toLowerCase();
  //       return a > b ? -1 : b > a ? 1 : 0;
  //     },
  //   },
  // },
  // {
  //   title: "Biller",
  //   dataIndex: "Biller",
  //   sorter: {
  //     compare: (a, b) => {
  //       a = a.Biller.toLowerCase();
  //       b = b.Biller.toLowerCase();
  //       return a > b ? -1 : b > a ? 1 : 0;
  //     },
  //   },
  // },
  {
    title: "Action",
    key: "action",
    sorter: true,
  },
];

const data = [
  {
    Customer_Name: "Thomas",
    Reference: "SL0101",
    Date: "19 Jan 2023",
    Status: "Completed",
    Status_class: "badge badge-bgsuccess",
    Grand_Total: "$550",
    Paid: "$550",
    Due: "$0.00",
    Payment_Status: "Paid",
    Payment_Class: "badge badge-linesuccess",
    Biller: "Admin",
  },
  {
    Customer_Name: "Rose",
    Reference: "SL0102",
    Date: "26 Jan 2023",
    Status: "Completed",
    Status_class: "badge badge-bgsuccess",
    Grand_Total: "$250",
    Paid: "$250",
    Due: "$0.00",
    Payment_Status: "Paid",
    Payment_Class: "badge badge-linesuccess",
    Biller: "Admin",
  },
  {
    Customer_Name: "Benjamin",
    Reference: "SL0103",
    Date: "08 Feb 2023",
    Status: "Completed",
    Status_class: "badge badge-bgsuccess",
    Grand_Total: "$570",
    Paid: "$570",
    Due: "$0.00",
    Payment_Status: "Paid",
    Payment_Class: "badge badge-linesuccess",
    Biller: "Admin",
  },
  {
    Customer_Name: "Lilly",
    Reference: "SL0104",
    Date: "12 Feb 2023",
    Status: "Pending",
    Status_class: "badge badge-bgdanger",
    Grand_Total: "$300",
    Paid: "$0.00",
    Due: "$300",
    Payment_Status: "Due",
    Payment_Class: "badge badge-linedanger",
    Biller: "Admin",
  },
  {
    Customer_Name: "Freda",
    Reference: "SL0105",
    Date: "17 Mar 2023",
    Status: "Pending",
    Status_class: "badge badge-bgdanger",
    Grand_Total: "$700",
    Paid: "$0.00",
    Due: "$700",
    Payment_Status: "Due",
    Payment_Class: "badge badge-linedanger",
    Biller: "Admin",
  },
  {
    Customer_Name: "Alwin",
    Reference: "SL0106",
    Date: "24 Mar 2023",
    Status: "Completed",
    Status_class: "badge badge-bgsuccess",
    Grand_Total: "$400",
    Paid: "$400",
    Due: "$0.00",
    Payment_Status: "Paid",
    Payment_Class: "badge badge-linesuccess",
    Biller: "Admin",
  },
  {
    Customer_Name: "Maybelle",
    Reference: "SL0107",
    Date: "06 Apr 2023",
    Status: "Pending",
    Status_class: "badge badge-bgdanger",
    Grand_Total: "$120",
    Paid: "$0.00",
    Due: "$120",
    Payment_Status: "Due",
    Payment_Class: "badge badge-linedanger",
    Biller: "Admin",
  },
  {
    Customer_Name: "Ellen",
    Reference: "SL0108",
    Date: "16 Apr 2023",
    Status: "Completed",
    Status_class: "badge badge-bgsuccess",
    Grand_Total: "$830",
    Paid: "$830",
    Due: "$0.00",
    Payment_Status: "Paid",
    Payment_Class: "badge badge-linesuccess",
    Biller: "Admin",
  },
  {
    Customer_Name: "Kaitlin",
    Reference: "SL0109",
    Date: "04 May 2023",
    Status: "Pending",
    Status_class: "badge badge-bgdanger",
    Grand_Total: "$800",
    Paid: "$0.00",
    Due: "$800",
    Payment_Status: "Due",
    Payment_Class: "badge badge-linedanger",
    Biller: "Admin",
  },
  {
    Customer_Name: "Grace",
    Reference: "SL0110",
    Date: "29 May 2023",
    Status: "Completed",
    Status_class: "badge badge-bgsuccess",
    Grand_Total: "$460",
    Paid: "$460",
    Due: "$0.00",
    Payment_Status: "Paid",
    Payment_Class: "badge badge-linesuccess",
    Biller: "Admin",
  },
];
export default {
  data() {
    return {
      filter: false,
      Sortdate: ["Sort by Date", "07 09 23", "21 09 23"],
      Macbook: ["Choose Customer Name", "Macbook pro", "Orange"],
      Fruits: ["Choose Status", "Computers", "Fruits"],
      Computers: ["Choose Payment Status", "Computers", "Fruits"],
      columns,
      // data,
      data: [],
      data_reserve: [],
      search: null,
      sales_data: null,
    };
  },
  methods: {
    open_create_payment(data) {
      $("#createpayment").modal("show");
      this.sales_data = data;
    },
    ...mapActions({
      _get_invoices: "general/get_invoices",
    }),
    get_invoices() {
      this._get_invoices()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.data = this.response.data;
            this.data_reserve = this.data;
          }
        })
        .catch((error) => {
          console.log("Error logging in", error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;
            if (
              status == 400 ||
              status == 422 ||
              status == 401 ||
              status == 404
            ) {
            } else if (status === 500) {
            }
          }
        });
    },
    getDatetime(date) {
      if (date) {
        let datetime = new Date(date);
        return `${datetime.toDateString()} ${datetime.toLocaleTimeString()}`;
      } else {
        return "";
      }
    },
    splitNumbersWithComma(number) {
      // Convert number to string to handle cases like decimal numbers
      if (number) {
        let numStr = number.toString();

        // Split the integer and decimal parts if it's a decimal number
        let parts = numStr.split(".");
        let integerPart = parts[0];
        let decimalPart = parts.length > 1 ? "." + parts[1] : "";

        // Insert commas for thousands separator in the integer part
        let integerWithCommas = integerPart.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );

        // Combine integer part with decimal part (if any)
        let formattedNumber = integerWithCommas + decimalPart;

        return formattedNumber;
      }
    },
    view_purchase_details(id) {
      localStorage.setItem("invoice_id", id);
      window.location = "/inventory/purchase-details";
    },
    view_purchase_details_offline(id) {
      localStorage.setItem("invoice_id", id);
      window.location = "/inventory/purchase-details-offline";
    },
    showConfirmation() {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          Swal.fire({
            icon: "success",
            title: "Deleted!",
            text: "Your file has been deleted.",
            confirmButtonClass: "btn btn-success",
          });
        }
      });
    },
    toggleCollapse() {
      const collapseHeader = this.$refs.collapseHeader;

      if (collapseHeader) {
        collapseHeader.classList.toggle("active");
        document.body.classList.toggle("header-collapse");
      }
    },
    searchtrigger() {
      const searchFields = [
        "products",
        // "amount",
        // "order_id",
        // "shipping_status",
        "public_ref",
      ];
      this.data = searchList(this.data_reserve, this.search, searchFields);
      console.log(this.data);
    },
  },
  mounted() {
    this.get_invoices();
  },
};
</script>
