<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  <div class="page-wrapper">
    <div class="content">
      <div class="page-header">
        <div class="page-title">
          <h4>Withdrawal Details</h4>
          <h6>Full details of a product</h6>
        </div>
      </div>
      <!-- /add -->
      <div class="row">
        <div class="col-lg-8 col-sm-12">
          <div class="card">
            <div class="card-body">
              <div class="bar-code-view" hidden>
                <img src="@/assets/img/barcode/barcode1.png" alt="barcode" />
                <a class="printimg">
                  <img src="@/assets/img/icons/printer.svg" alt="print" />
                </a>
              </div>
              <div class="productdetails">
                <ul class="product-bar">
                  <li>
                    <h4>Withdrawal Amount</h4>
                    <h6>₦ {{splitNumbersWithComma(data?.amount)}}</h6>
                  </li>
                  <li>
                    <h4>Name </h4>
                    <h6>{{ data?.bank_details?.surname}} {{ data?.bank_details?.other_names }}</h6>
                  </li>
                  <li>
                    <h4>Bank</h4>
                    <h6>{{ data?.bank_details?.bank}}</h6>
                  </li>
                  <li>
                    <h4>Account Number</h4>
                    <h6>{{ data?.bank_details?.account_number}}</h6>
                  </li>
                 
                  <li>
                    <h4>Status</h4>
                    <h6> <span
                      class="badges status-badge"
                      v-if="data.status == 'Success'"
                      >{{ data.status }}</span
                    >
                    <span
                      class="badge badge-warning"
                      v-if="data.status == 'Pending'"
                      >{{ data.status }}</span
                    ></h6>
                  </li>
                  <li>
                    <h4>Date</h4>
                    <h6>{{ getDatetime(data?.bank_details?.created_at)}}</h6>
                  </li>
                  
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-sm-12">
          <div class="card">
            <div class="card-body">
              <div class="slider-product-details">
                <div class="owl-carousel owl-theme product-slide">
                  <div class="slider-product">
                    <img :src="data.image" alt="img" />
                    <h4>Transaction Reciept</h4>
                  </div>
                  <!-- <div class="slider-product">
                    <img src="@/assets/img/products/product69.jpg" alt="img" />
                    <h4>macbookpro.jpg</h4>
                    <h6>581kb</h6>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- /add -->
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
data:JSON.parse(localStorage.getItem('Wdata'))
    }},
  methods:{
    splitNumbersWithComma(number) {
      // Convert number to string to handle cases like decimal numbers
      if (number) {
        let numStr = number.toString();

        // Split the integer and decimal parts if it's a decimal number
        let parts = numStr.split(".");
        let integerPart = parts[0];
        let decimalPart = parts.length > 1 ? "." + parts[1] : "";

        // Insert commas for thousands separator in the integer part
        let integerWithCommas = integerPart.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );

        // Combine integer part with decimal part (if any)
        let formattedNumber = integerWithCommas + decimalPart;

        return formattedNumber;
      }
    },
    getDatetime(date) {
      let datetime = new Date(date);
      return `${datetime.toDateString()} ${datetime.toLocaleTimeString()}`;
    },
  }
  }
</script>
