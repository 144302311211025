/* eslint-disable */
import { createStore } from "vuex";

import {
  general,
  product,
  coupon,
  dashboard,
  purchase,
  notification,
  admin,
  message,
  authentication,
} from "./adminStore";

import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

const store = createStore({
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions,
  modules: {
    general,
    product,
    coupon,
    dashboard,
    purchase,
    notification,
    admin,
    message,
    authentication,
  },
});
export default store;
