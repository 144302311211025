<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>

  <div class="page-wrapper">
    <div class="content">
      <div class="page-header">
        <div class="page-title">
          <h4>All Notifications</h4>
          <h6>View your all activities</h6>
        </div>
      </div>
      <!-- /product list -->
      <div class="activity">
        <div class="activity-box">
          <ul
            class="activity-list"
            v-for="(noti, id) in returned_data"
            v-bind:key="id"
          >
            <li @click="view_purchase_details(noti.id, noti.target_id)">
              <div class="activity-use" v-if="noti.seen == true">
                <div class="ribbone ribbone-top-left">
                  <span class="bg-success">Read</span>
                </div>
              </div>

              <div class="activity-use" v-else-if="noti.seen == false">
                <div class="ribbone ribbone-top-left text-primary">
                  <span class="bg-primary">Unread</span>
                </div>
              </div>
              <div class="activity-content">
                <div class="timeline-content">
                  <h4>
                    {{ noti.target }}
                    <span
                      class="badge badge-success"
                      @click="view_purchase_details(noti.target_id)"
                      style="float: right"
                      >{{ noti.tag }}</span
                    >
                  </h4>
                  <p class="noti-details">{{ noti.description }}</p>
                  <p class="noti-time">
                    <span class="notification-time">{{
                      getDatetime(noti.created_at)
                    }}</span>
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- /product list -->
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      returned_data: [],
      response: "",
    };
  },
  mounted() {
    this.get_notifications();
  },
  methods: {
    ...mapActions({
      _get_notifications: "notification/get_notifications",
      _read_notification: "notification/read_notification",
    }),
    get_notifications() {
      this._get_notifications()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.returned_data = this.response.data;
          }
        })
        .catch((error) => {
          console.log("Error logging in", error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;
            if (
              status == 400 ||
              status == 422 ||
              status == 401 ||
              status == 404
            ) {
            } else if (status === 500) {
            }
          }
        });
    },
    getDatetime(date) {
      let datetime = new Date(date);
      return `${datetime.toDateString()} ${datetime.toLocaleTimeString()}`;
    },
    view_purchase_details(id, t_id) {
      this._read_notification(id)
        .then((response) => {
          localStorage.setItem("notification_id", t_id);
          window.location = "/sales-notification";
        })
        .catch((error) => {
          console.log("Error logging in", error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;
            if (
              status == 400 ||
              status == 422 ||
              status == 401 ||
              status == 404
            ) {
            } else if (status === 500) {
            }
          }
        });
    },
  },
};
</script>
<style>
.activity-list {
  cursor: pointer;
}
</style>
