<template>
  <div class="chat chat-messages" id="middle">
    <div class="slimscroll">
      <perfect-scrollbar
        class="scroll-area-two"
        :settings="settings"
        @ps-scroll-y="scrollHanle"
      >
        <div class="chat-header">
          <div class="user-details">
            <div class="d-lg-none">
              <ul class="list-inline mt-2 me-2">
                <li class="list-inline-item">
                  <a
                    class="text-muted px-0 left_sides"
                    href="javascript:void(0);"
                    data-chat="open"
                  >
                    <i class="fas fa-arrow-left"></i>
                  </a>
                </li>
              </ul>
            </div>
            <figure class="avatar ms-1">
              <img :src="QA?.user?.avatar" class="rounded-circle" alt="image" />
            </figure>
            <div class="mt-1">
              <h5>{{ QA?.user?.name }}</h5>
              <!-- <small class="last-seen">
                                Last Seen at 07:15 PM
                            </small> -->
            </div>
          </div>
          <div class="chat-options" hidden>
            <ul class="list-inline">
              <li class="list-inline-item">
                <a
                  href="javascript:void(0)"
                  @click="showChat"
                  class="btn btn-outline-light chat-search-btn"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  title="Search"
                >
                  <i class="bx bx-search"></i>
                </a>
              </li>

              <li class="list-inline-item">
                <a
                  class="btn btn-outline-light no-bg"
                  href="javascript:void(0);"
                  data-bs-toggle="dropdown"
                >
                  <i class="bx bx-dots-vertical-rounded"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-end">
                  <a href="javascript:void(0);" class="dropdown-item"
                    ><span><i class="bx bx-x"></i></span>Close Chat
                  </a>

                  <a href="javascript:void(0);" class="dropdown-item"
                    ><span><i class="bx bx-trash-alt"></i></span>Delete Chat</a
                  >
                </div>
              </li>
            </ul>
          </div>
          <!-- Chat Search -->
          <div class="chat-search" :class="{ 'visible-chat': chatVisible }">
            <form>
              <span class="form-control-feedback"
                ><i class="bx bx-search"></i
              ></span>
              <input
                type="text"
                name="chat-search"
                placeholder="Search Chats"
                class="form-control"
              />
              <div class="close-btn-chat" @click="hideChat">
                <span class="material-icons">close</span>
              </div>
            </form>
          </div>
          <!-- /Chat Search -->
        </div>
        <div class="chat-body">
          <div class="messages">
            <div class="chats" v-if="QA?.question != null">
              <div class="chat-avatar">
                <img
                  :src="QA?.user?.avatar"
                  class="rounded-circle dreams_chat"
                  alt="image"
                />
              </div>
              <div class="chat-content">
                <div class="chat-profile-name">
                  <h6>{{ QA?.user?.name }}<span>8:16 PM</span></h6>
                </div>
                <div class="message-content">
                  {{ QA?.question }}
                </div>
              </div>
            </div>
            <div class="chats chats-right" v-if="QA?.answer != null">
              <div class="chat-avatar">
                <img
                  src="@/assets/img/avatar/avatar-2.jpg"
                  class="rounded-circle dreams_chat"
                  alt="image"
                />
              </div>
              <div class="chat-content">
                <div class="chat-profile-name" hidden>
                  <h6>Me<span>8:16 PM</span></h6>
                </div>
                <div class="message-content">
                  {{ QA?.answer }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
    <div class="chat-footer">
     <div class="stat_msg text-center" v-if="QA?.answer != null">You have replied this message</div> 
      <form v-else>
        <input
          type="text"
          class="form-control chat_form"
          placeholder="Type your message here..."
          style="height: auto"
          v-model="answer"
        />
        <!-- oninput="autoResize()"
          id="autoResizeTextArea" -->
        <div class="form-buttons">
          <div class="btn send-btn" type="submit" @click="answer_question()">
            <i class="bx bx-paper-plane"></i>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
import { myClickHandler } from "@/views/pages/crm/chat/myFunctions.js";
import { mapActions } from "vuex";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  components: {
    PerfectScrollbar,
  },
  data() {
    return {
      QA: JSON.parse(localStorage.getItem("QA")),
      answer: "",
    };
  },
  methods: {
    ...mapActions({
      _answer_question: "message/answer_question",
    }),
    answer_question() {
      if ((answer = "")) {
        toast.error("You must submit an answer!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        $(".inloader").show();
        this._answer_question({
          id: this.QA.id,
          payload: {
            answer: this.answer,
          },
        })
          .then((response) => {
            $(".inloader").hide();

            toast.success("Answer Updated", {
              position: toast.POSITION.TOP_RIGHT,
            });
            this.response = response.data;
            //   this.response
            if (this.response["status"] == true) {
              var data = this.response.data;
              // this.sent = true;
              // this.get_user();
              window.location = "/crm/product-question";
            }
          })
          .catch((error) => {
            $(".inloader").hide();

            console.log("Error logging in", error);
            if (error.response) {
              const status = error.response.status;
              const data = error.response.data;
              if (
                status == 400 ||
                status == 422 ||
                status == 401 ||
                status == 404
              ) {
                toast.error(error.response.data.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              } else if (status === 500) {
                toast.error("Network Error, Check your internet conectivity.", {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }
            }
          });
      }
    },

    //     autoResize() {
    //   const textArea = document.getElementById('autoResizeTextArea');
    //   textArea.style.height = 'auto';
    //   textArea.style.height = textArea.scrollHeight + 'px';
    // }
  },
};
</script>
