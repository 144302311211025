<template>
  <!-- Add Adjustment -->
  <div class="modal fade" id="add-units">
    <div class="modal-dialog modal-dialog-centered stock-adjust-modal">
      <div class="modal-content">
        <div class="page-wrapper-new p-0">
          <div class="content">
            <div class="modal-header border-0 custom-modal-header">
              <div class="page-title">
                <h4>Add Variation Attribute</h4>
              </div>
              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body custom-modal-body">
              <div class="row">
                <div class="col-lg-12" v-if="variant_key.length > 1">
                  <div class="input-blocks">
                    <label
                      >Variant Attribute
                      <!-- {{ variant_object }} -->
                    </label>
                    <div class="btn-list">
                      <button
                        type="button"
                        class="btn btn-outline-primary rounded-pill"
                        :class="[
                          this.keys[0]?.name === 'Color' ? 'add_bg_color' : '',
                          this.keys[0]?.name === 'Color' ? 'text-light' : '',
                        ]"
                        :id="
                          this.keys[0]?.name === 'Color' ? 'add_bg_color' : ''
                        "
                      >
                        Color
                      </button>
                      <button
                        type="button"
                        class="btn btn-outline-primary rounded-pill"
                        :class="[
                          this.keys[1]?.name === 'Size' ? 'add_bg_color' : '',
                          this.keys[1]?.name === 'Size' ? 'text-light' : '',
                        ]"
                        :id="
                          this.keys[1]?.name === 'Size' ? 'add_bg_color' : ''
                        "
                      >
                        Size
                      </button>
                      <button
                        hidden
                        type="button"
                        class="btn btn-outline-primary rounded-pill"
                      >
                        Custom
                      </button>
                    </div>

                    <div class="checkcont">
                      <span class="checkbox-cont">
                        <input
                          v-for="v in variant_key"
                          :key="v"
                          type="checkbox"
                          v-model="keys"
                          :value="{ name: v.name, id: v.id }"
                          id="size-checkbox"
                        />
                      </span>

                      <!-- <span class="checkbox-cont">
                        <input
                          type="checkbox"
                          v-model="keys"
                          :value="{ name: 'Color', id: 2 }"
                          id="color-checkbox"
                        />
                       
                      </span>-->

                      <span class="checkbox-cont">
                        <input type="checkbox" id="custom-checkbox" />
                        <!-- v-model="keys"
                          :value="{ name: 'Custom', id: null }" -->
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- size attribute options -->
              <!-- {{ prep_variant() }} -->
              <div class="row" id="size_options">
                <div class="col-lg-12" v-for="key in keys" :key="key">
                  <!-- {{ keys }} -->
                  <div class="input-blocks">
                    <label>Attribute Value ({{ key.name }})</label>

                    <!-- this place is api dependent, depending on the avraint key the vendor chooses the aalue of this input will make an aoi call and change -->
                    <div class="input-blocks">
                      <vue-select
                        :options="VariantValueSize"
                        v-if="key.name == 'Size'"
                        id="VariantValueSize"
                        @select="setVariantData(key.name, $event.id)"
                      />
                    </div>
                    <div class="input-blocks">
                      <vue-select
                        :options="VariantValueColor"
                        v-if="key.name == 'Color'"
                        id="VariantValueColor"
                        @select="setVariantData(key.name, $event.id)"
                      />
                      <!-- {{ (key_color = "Color") }} -->
                    </div>
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="input-blocks">
                    <label>Price</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="variant_object.original_price"
                      @keydown="preventNonNumeric"
                    />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="input-blocks">
                    <label>Selling Price</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="variant_object.selling_price"
                      @keydown="preventNonNumeric"
                    />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="input-blocks">
                    <label>Quantity Available</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="variant_object.quantity"
                      @keydown="preventNonNumeric"
                    />
                  </div>
                </div>

                <!-- <div class="col-lg-12">
                  <div class="add-choosen">
                    <div class="input-blocks">
                      <button
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#EditVariantImage"
                        id="btnnn"
                      >
                        <div class="image-upload">
                         
                          <div class="image-uploads">
                            <i
                              data-feather="plus-circle"
                              class="plus-down-add me-0"
                            ></i>
                            <h4>Add thumbnail</h4>
                          </div>
                        </div>
                      </button>
                    </div>

                    <div class="phone-img" id="varaint_uploadd">
                      <img
                        :src="variant_imgs_child?.image"
                        alt="image"
                        id="preview_variant"
                        style="height: 100%; width: 100%"
                      />
                      <a href="javascript:void(0);" @click="clearImageInput()"
                        ><vue-feather
                          type="x"
                          class="x-square-add remove-product"
                        ></vue-feather
                      ></a>
                    </div>
                  </div>
                </div> -->
              </div>
              <!-- {{ returnedVariants }} -->
              <div class="row">
                <div class="col-lg-12">
                  <div class="modal-footer-btn popup">
                    <a
                      href="javascript:void(0);"
                      class="btn btn-cancel me-2"
                      data-bs-dismiss="modal"
                      >Cancel</a
                    >
                    <div @click="submit_variant()" class="btn btn-submit">
                      Create Attribute
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Adjustment -->

  <!-- Add Category -->
  <div class="modal fade" id="add-units-category">
    <div class="modal-dialog modal-dialog-centered custom-modal-two">
      <div class="modal-content">
        <div class="page-wrapper-new p-0">
          <div class="content">
            <div class="modal-header border-0 custom-modal-header">
              <div class="page-title">
                <h4>Add New Category</h4>
              </div>
              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body custom-modal-body">
              <div class="mb-3">
                <label class="form-label">Name</label>
                <input type="text" class="form-control" />
              </div>
              <div class="modal-footer-btn">
                <a
                  href="javascript:void(0);"
                  class="btn btn-cancel me-2"
                  data-bs-dismiss="modal"
                  >Cancel</a
                >
                <router-link to="/inventory/add-product" class="btn btn-submit"
                  >Submit</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Category -->

  <!-- Add Brand -->
  <div class="modal fade" id="add-units-brand">
    <div class="modal-dialog modal-dialog-centered custom-modal-two">
      <div class="modal-content">
        <div class="page-wrapper-new p-0">
          <div class="content">
            <div class="modal-header border-0 custom-modal-header">
              <div class="page-title">
                <h4>Add New Brand</h4>
              </div>
              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body custom-modal-body">
              <div class="mb-3">
                <label class="form-label">Brand</label>
                <input type="text" class="form-control" />
              </div>
              <div class="modal-footer-btn">
                <a
                  href="javascript:void(0);"
                  class="btn btn-cancel me-2"
                  data-bs-dismiss="modal"
                  >Cancel</a
                >
                <router-link to="/inventory/add-product" class="btn btn-submit"
                  >Submit</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Brand -->

  <!-- Add Unit -->
  <div class="modal fade" id="add-unit">
    <div class="modal-dialog modal-dialog-centered custom-modal-two">
      <div class="modal-content">
        <div class="page-wrapper-new p-0">
          <div class="content">
            <div class="modal-header border-0 custom-modal-header">
              <div class="page-title">
                <h4>Add Unit</h4>
              </div>
              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body custom-modal-body">
              <div class="mb-3">
                <label class="form-label">Unit</label>
                <input type="text" class="form-control" />
              </div>
              <div class="modal-footer-btn">
                <a
                  href="javascript:void(0);"
                  class="btn btn-cancel me-2"
                  data-bs-dismiss="modal"
                  >Cancel</a
                >
                <router-link to="/inventory/add-product" class="btn btn-submit"
                  >Submit</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Unit -->

  <!-- Add Variatent -->
  <div class="modal fade" id="add-variation">
    <div class="modal-dialog modal-dialog-centered custom-modal-two">
      <div class="modal-content">
        <div class="page-wrapper-new p-0">
          <div class="content">
            <div class="modal-header border-0 custom-modal-header">
              <div class="page-title">
                <h4>Add Variation</h4>
              </div>
              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body custom-modal-body">
              <div class="modal-title-head people-cust-avatar">
                <h6>Variant Thumbnail</h6>
              </div>
              <div class="new-employee-field">
                <div class="profile-pic-upload">
                  <div class="profile-pic">
                    <span
                      ><vue-feather
                        type="plus-circle"
                        class="plus-down-add"
                      ></vue-feather>
                      Add Image</span
                    >
                  </div>
                  <div class="mb-3">
                    <div class="image-upload mb-0">
                      <input type="file" />
                      <div class="image-uploads">
                        <h4>Change Image</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6 pe-0">
                  <div class="mb-3">
                    <label class="form-label">Barcode Symbology</label>
                    <vue-select
                      :options="BarCodeSym"
                      id="barcodesym"
                      placeholder="Choose"
                    />
                  </div>
                </div>
                <div class="col-lg-6 pe-0">
                  <div class="mb-3">
                    <div class="input-blocks add-product list">
                      <label>Item Code</label>
                      <input
                        type="text"
                        class="form-control list"
                        value="455454478844"
                      />
                      <button type="submit" class="btn btn-primaryadd">
                        Generate Code
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="input-blocks image-upload-down">
                    <div class="image-upload download">
                      <input type="file" />
                      <div class="image-uploads">
                        <img src="@/assets/img/download-img.png" alt="img" />
                        <h4>Drag and drop a <span>file to upload</span></h4>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-body">
                    <div class="text-editor add-list add">
                      <div class="col-lg-12">
                        <div class="add-choosen mb-3">
                          <div class="phone-img ms-0">
                            <img
                              src="@/assets/img/products/phone-add-2.png"
                              alt="image"
                            />
                            <a href="javascript:void(0);"
                              ><vue-feather
                                type="x"
                                class="x-square-add remove-product"
                              ></vue-feather
                            ></a>
                          </div>

                          <div class="phone-img">
                            <img
                              src="@/assets/img/products/phone-add-1.png"
                              alt="image"
                            />
                            <a href="javascript:void(0);"
                              ><vue-feather
                                type="x"
                                class="x-square-add remove-product"
                              ></vue-feather
                            ></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 pe-0">
                  <div class="mb-3">
                    <label class="form-label">Quantity</label>
                    <input type="text" class="form-control" />
                  </div>
                </div>
                <div class="col-lg-6 pe-0">
                  <div class="mb-3">
                    <label class="form-label">Quantity Alert</label>
                    <input type="text" class="form-control" />
                  </div>
                </div>
                <div class="col-lg-6 pe-0">
                  <div class="mb-3">
                    <label class="form-label">Tax Type</label>
                    <vue-select
                      :options="Typetax"
                      id="typeta"
                      placeholder="Choose"
                    />
                  </div>
                </div>
                <div class="col-lg-6 pe-0">
                  <div class="mb-3">
                    <label class="form-label">Tax </label>
                    <vue-select
                      :options="Incometax"
                      id="incometax"
                      placeholder="Choose"
                    />
                  </div>
                </div>
                <div class="col-lg-12 pe-0">
                  <div class="mb-3">
                    <label class="form-label">Discount Type </label>
                    <vue-select
                      :options="TypeDiscount"
                      id="typediscount"
                      placeholder="Choose"
                    />
                  </div>
                </div>
                <div class="col-lg-12 pe-0">
                  <div>
                    <label class="form-label">Discount Value</label>
                    <input type="text" class="form-control" />
                  </div>
                </div>
              </div>

              <div class="modal-footer-btn">
                <a
                  href="javascript:void(0);"
                  class="btn btn-cancel me-2"
                  data-bs-dismiss="modal"
                  >Cancel</a
                >
                <router-link to="/inventory/add-product" class="btn btn-submit"
                  >Submit</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Variatent -->
</template>
<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      key_color: "",
      key_size: "",
      TypeDiscount: ["Choose", "Percentage", "Early Payment"],
      Incometax: ["Choose", "Income Tax", "Service Tax"],
      Typetax: ["Choose", "Direct", "Indirect"],
      BarCodeSym: ["Choose", "Code34"],
      VariantValueSize: [
        "Choose Size",
        { id: "L", text: "L" },
        { id: "XL", text: "XL" },
        { id: "XXL", text: "XXL" },
      ],
      VariantValueColor: [
        "Choose Size",
        { id: "red", text: "red" },
        { id: "blue", text: "blue" },
        { id: "yellow", text: "yellow" },
        { id: "green", text: "green" },
        { id: "purple", text: "purple" },
        { id: "orange", text: "orange" },
      ],
      VariantQuantity: "",
      VariantPrice: "",
      variant_object: {
        id: ``,
        values: {},
        original_price: "",
        selling_price: "",
        quantity: "",
        thumbnail: null,
      },
      variant_array: [],

      keys: [],
      variant_key: [],
      variant_file: "",
      processedVariant: null,
    };
  },
  props: {
    variant_imgs_child: {
      type: Object,
      required: true,
    },
    returnedVariants: {
      type: Array,
      // required: true,
    },
  },
  watch: {
    variant_object: {
      handler() {
        this.processedVariant = this.prep_variant();
        console.log(this.processedVariant);
      },
      deep: true,
    },
    keys: {
      handler() {
        this.keys.forEach((key) => {
          this.variant_object.values[key.name] = {
            key: key.name,
            key_id: key.id,
            value: null,
          };
        });
        this.key_color = this.keys[0]?.name;
        this.key_size = this.keys[1]?.name;
      },
    },
  },
  methods: {
    assignThumbnailsByColor(products) {
      // Loop through each product
      products.forEach((product, index) => {
        const colorValue = product.values.find(
          (value) => value.key === "Color"
        )?.value;

        // value.key === "Color" || value.key === "Color"
        // If the product has no thumbnail and has a color
        if (!product.thumbnail && colorValue) {
          // Find a product with the same color and a thumbnail
          const matchingProduct = products.find(
            (p, i) =>
              i !== index &&
              p.values.find((value) => value.key === "Color")?.value ===
                colorValue &&
              p.thumbnail
          );

          // If a matching product is found, assign its thumbnail
          if (matchingProduct) {
            product.thumbnail = matchingProduct.thumbnail;
          }
        }
      });
    },
    setVariantData(key, newValue) {
      console.log(newValue);
      // if (!this.variant_object.values[key]) {
      //   this.variant_object.values[key] = { value: newValue };
      // }
      this.variant_object.values[key].value = newValue;
      console.log(this.variant_object.values);
    },
    ...mapActions({
      _get_variant_keys: "general/get_variant_keys",
    }),
    // methods from modal

    prep_variant() {
      let variant = {
        id: `${Date.now()}-${Math.random().toString(36).substring(2, 9)}`,
        original_price: this.variant_object.original_price,
        selling_price: this.variant_object.selling_price,
        quantity: this.variant_object.quantity,
        values: [],
        thumbnail: null,
      };
      Object.values(this.variant_object.values).map((value) =>
        variant.values.push(value)
      );
      return variant;
    },
    prep_variant2() {
      let variant = {
        id: `${Date.now()}-${Math.random().toString(36).substring(2, 9)}`,
        original_price: this.variant_object.original_price,
        selling_price: this.variant_object.selling_price,
        quantity: this.variant_object.quantity,
        values: [],
        thumbnail: null,
        is_new: 0,
      };
      Object.values(this.variant_object.values).map((value) =>
        variant.values.push(value)
      );
      return variant;
    },
    submit_variant() {
      if (this.returnedVariants) {
        console.log(this.prep_variant2(), "tester");

        this.returnedVariants.push(...this.prep_variant2());
        // sanity code
        // this.returnedVariants.forEach((product) => {
        //   if (product.values.Color.value === null) {
        //     delete product.values.Color; // Remove `Color` if its value is null
        //   }
        //   if (product.values.Size.value === null) {
        //     delete product.values.Size; // Remove `Size` if its value is null
        //   }
        // });

        this.assignThumbnailsByColor(this.returnedVariants);
        this.$emit("Emitter", this.returnedVariants);
        $("#add-units").modal("hide");
        this.keys = [];

        this.variant_object = {
          values: {},
          original_price: "",
          selling_price: "",
          quantity: "",
          // image: null,
        };
      } else {
        this.variant_array = [
          ...this.variant_array,
          { ...this.variant_object },
        ];

        this.$emit("Emitter", this.variant_array);

        $("#add-units").modal("hide");
        this.keys = [];
        this.variant_object = {
          values: {},
          original_price: "",
          selling_price: "",
          quantity: "",
          // image: null,
        };
      }
    },

    get_variant_keys() {
      this._get_variant_keys()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.variant_key = this.response.data;
            console.log(this.variant_key);
          }
        })
        .catch((error) => {
          console.log("Error logging in", error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;
            if (
              status == 400 ||
              status == 422 ||
              status == 401 ||
              status == 404
            ) {
            } else if (status === 500) {
            }
          }
        });
    },
    clearImageInput() {
      var input = document.getElementById("varaint_img_upload");
      var file = input.files[0];
      this.variant_file = file;
      this.variant_file = null;
      var dk = document.getElementById("varaint_uploadd");
      dk.style = "display:none";
    },
    preventNonNumeric(event) {
      // Allow only number keys (0-9) and special keys (Backspace, Delete, Arrow keys)
      const allowedKeys = [
        "Backspace",
        "Delete",
        "ArrowLeft",
        "ArrowRight",
        "Tab",
      ];

      if (
        (event.key < "0" || event.key > "9") &&
        !allowedKeys.includes(event.key)
      ) {
        event.preventDefault(); // Block non-numeric keys
      }
    },
    // for variant
  },
  mounted() {
    // for variant
    this.get_variant_keys();
    //     $("input[k=variant]").change(function () {
    //       var input = document.getElementById("varaint_img_upload");
    //       var dk = document.getElementById("varaint_uploadd");
    //       var preview = document.getElementById("preview_variant");
    //       var file = input.files[0];
    //       // this.thumbnail = file

    //       var reader = new FileReader();

    //       reader.onload = function (e) {
    //         preview.src = e.target.result;
    //         dk.style = "display:block";
    //         // alert(this.previewer);
    //       };

    //       if (file) {
    //         reader.readAsDataURL(file);
    //       }
    //     });
    //   },
    // };
  },
};
</script>
<style scoped>
/* .checkbox-cont {
  margin-right: 10px;
} */

#varaint_uploadd {
  display: none;
}
.checkcont {
  position: absolute;
  margin-top: -25px;
  z-index: 999;
  height: 50px;
  opacity: 0;
}
#size-checkbox {
  width: 40px;
  cursor: pointer;
  zoom: 1.5;
}
#color-checkbox {
  width: 40px;
  cursor: pointer;
  zoom: 1.5;
}
#custom-checkbox {
  width: 50px;
  cursor: pointer;
  zoom: 1.5;
}

#btnnn {
  width: 120px;
  height: 120px;
}
#add_bg_color {
  background-color: #00afef;
  color: white;
}
</style>
