import qs from "qs";
import axios from "axios";
// demo.gtcomnet.com or front.dev
let REDIRECT_URL;

if (process.env.NODE_ENV === "production") {
  REDIRECT_URL =
    "https://accounts.google.com/o/oauth2/v2/auth?scope=https%3A//www.googleapis.com/auth/userinfo.email%20https%3A//www.googleapis.com/auth/userinfo.profile&include_granted_scopes=true&response_type=token&state=statestuff&redirect_uri=https%3A//vendor.naijakobomarket.com/google_signup&client_id=166382894003-os75q3o04qtgrbv2quqajda95m26grsv.apps.googleusercontent.com";
} else if (process.env.NODE_ENV === "demo") {
  REDIRECT_URL =
    "https://accounts.google.com/o/oauth2/v2/auth?scope=https%3A//www.googleapis.com/auth/userinfo.email%20https%3A//www.googleapis.com/auth/userinfo.profile&include_granted_scopes=true&response_type=token&state=statestuff&redirect_uri=https%3A//nkmvendor.gtcomnet.com/google_signup&client_id=166382894003-os75q3o04qtgrbv2quqajda95m26grsv.apps.googleusercontent.com";
} else {
  REDIRECT_URL =
    "https://accounts.google.com/o/oauth2/v2/auth?scope=https%3A//www.googleapis.com/auth/userinfo.email%20https%3A//www.googleapis.com/auth/userinfo.profile&include_granted_scopes=true&response_type=token&state=statestuff&redirect_uri=https%3A//vendor.dev/google_signup&client_id=166382894003-os75q3o04qtgrbv2quqajda95m26grsv.apps.googleusercontent.com";
}

function loginWithGoogle() {
  window.location = REDIRECT_URL;
}

async function postGoogleCode(code) {
  const data = {
    code,
    client_id:
      "566382894003-os75q3o04qtgrbv2quqajda95m26grsv.apps.googleusercontent.com",
    // "166382894003-os75q3o04qtgrbv2quqajda95m26grsv.apps.googleusercontent.com",
    // client_secret: "",
    grant_type: "authorization_code",
    // access_type: "offline",
    redirect_uri: REDIRECT_URL,
    ux_mode: "redirect",
    prompt: "none",
  };
  const options = {
    method: "POST",
    headers: { "content-type": "application/x-www-form-urlencoded" },
    data: decodeURIComponent(qs.stringify(data)),
    url: "https://www.googleapis.com/oauth2/v4/token",
  };
  try {
    return await axios(options);
  } catch (error) {
    console.clear();
  }
}
async function requestToken(code) {
  try {
    return await postGoogleCode(code);
  } catch (error) {
    console.clear();
  }
}

async function getUserInfo(token) {
  try {
    return await axios.get(
      `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${token}`
    );
  } catch (error) {
    console.clear();
  }
}

export default { loginWithGoogle, requestToken, getUserInfo };
