import { api } from "@/configs";
import { endpoints } from "@/constants";
import { router } from "@/router";
import { setAccessToken } from "@/utils";

export default {
  async login({ context }, credentials) {
    try {
      const response = await api.post(endpoints.auth.login, credentials);
      const data = response.data.data;
      setAccessToken(data.bearer_token);
      context.commit("profile/setProfile", data);
      context.commit("profile/setPermissions", data.permissions);
    } catch (error) {
      throw error;
    }
  },
};
