<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  <div class="page-wrapper">
    <div class="content">
      <div class="page-header">
        <div class="page-title">
          <h4>{{ returned_data?.public_ref }}</h4>
          <h6>Order detail of {{ returned_data?.public_ref }}</h6>
        </div>
      </div>
      <!-- /add -->

      <div class="row">
        <div class="col-lg-8 col-sm-12">
          <div class="row">
            <div class="col-lg-4 col-sm-12">
              <div class="card">
                <div class="card-body">
                  <h2 class="shipping_info">Shipping Information</h2>
                  <br />
                  <p class="name">
                    Customer Name: {{ returned_data?.customer?.name }}
                  </p>
                  <p class="address">
                    Customer Address:
                    {{ returned_data?.deliveryAddress?.address }},
                    {{ returned_data?.deliveryAddress?.city }}
                    {{ returned_data?.deliveryAddress?.state }}
                  </p>
                  <p class="phone">
                    Phone No: {{ returned_data?.customer?.phone }}
                  </p>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-sm-12">
              <div class="card">
                <div class="card-body">
                  <h2 class="shipping_info">Billing Information</h2>
                  <br />
                  <p class="name">Payment Type:: -</p>
                  <p class="address">Provider: **** **** **** ****</p>
                  <p class="phone">Valid Date: **-****</p>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-sm-12">
              <div class="card">
                <div class="card-body">
                  <h2 class="shipping_info">Shipping Information</h2>
                  <br />
                  <p class="name">{{ returned_data?.customer?.name }}</p>
                  <p class="address">
                    Address: {{ returned_data?.deliveryAddress?.address }},
                    {{ returned_data?.deliveryAddress?.city }}
                    {{ returned_data?.deliveryAddress?.state }}
                  </p>
                  <p class="phone">
                    Phone No: {{ returned_data?.customer?.phone }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- other rows -->

          <div class="tcont">
            <h4>{{ returned_data?.public_ref }}</h4>
            <div class="card-body">
              <div class="table-responsive dataview table-stripped table">
                <table class="table dashboard-expired-products">
                  <thead>
                    <tr>
                      <th>Product</th>
                      <th>Quantity</th>
                      <th>Price</th>
                      <th>Total</th>
                      <!-- <th class="no-sort">Action</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="p in returned_data.products" :key="p.id">
                      <td>
                        {{ p?.name }}
                      </td>
                      <td>{{ p?.quantity }}</td>
                      <td>{{ p?.price }}</td>
                      <td>{{ (getprice = p.price) }}</td>
                    </tr>

                    <tr class="s_tr">
                      <td>Sub Total</td>
                      <td>-</td>
                      <td>-</td>
                      <td>{{ returned_data.amount }}</td>
                    </tr>
                    <tr class="s_tr">
                      <td>Commission</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-{{ commission }}</td>
                    </tr>
                    <tr class="s_tr">
                      <td>Bonus</td>
                      <td>-</td>
                      <td>-</td>
                      <td>0.00</td>
                    </tr>
                    <tr class="s_tr">
                      <td>Total amount receivable</td>
                      <td>-</td>
                      <td>-</td>
                      <td>{{ returned_data.amount_receivable }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!-- table2 -->

          <div class="tcont">
            <h4>Shipping Details</h4>
            <div class="card-body">
              <div class="table-responsive dataview">
                <table class="table dashboard-expired-products">
                  <thead>
                    <tr>
                      <th>Product</th>
                      <th>Order ID</th>
                      <th>Buyer</th>
                      <th>Delivery Method</th>
                      <th>Order Status</th>
                      <th>Tracking Number</th>
                      <th>Delivery Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="p in returned_data.products" :key="p.id">
                      <td>
                        {{ p?.name }}
                      </td>
                      <td>{{ returned_data?.public_ref }}</td>
                      <td>{{ returned_data?.customer?.name }}</td>
                      <td>{{ returned_data?.company }}</td>
                      <td>{{ p?.status }}</td>
                      <td>-</td>
                      <td>{{ returned_data?.delivery_type }}</td>
                      <td hidden>
                        {{ (commission = p?.vendor_commission_fee) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-sm-12 trackcont">
          <div class="innertrackcont">
            <h1 class="order_header">Track Order</h1>
            <div class="my_prgress cont">
              <div
                class="my_progress"
                style="display: inline-block"
              >
                <!-- <div class="in_my_progress"></div> -->
                <div class="in_my_progress in_my_progress1" style="height:20%;"></div>
                <div class="in_my_progress in_my_progress2" v-if="returned_data?.packed_at !== null && returned_data?.packed_at !==undefined" style="height:34%;"></div>
                <div class="in_my_progress in_my_progress3" v-if="returned_data?.shipped_at !== null && returned_data?.shipped_at !==undefined" style="height:17%;"></div>
                <div class="in_my_progress in_my_progress1" v-if="returned_data?.delivered_at !== null && returned_data?.delivered_at !==undefined" style="height:16%;"></div>
                <div class="in_my_progress in_my_progress1" v-if="returned_data?.fulfilled_at !== null && returned_data?.fulfilled_at !==undefined" style="height:13%;"></div>

                <!-- circles -->
                <div class="circles circle0" style="background-color: #0095c78e"></div>
                <div class="circles circle1" style="background-color: #0095c78e"></div>
                <div class="circles circle2" :style="{ backgroundColor: returned_data?.packed_at !== null && returned_data?.packed_at !==undefined ? '#0095c78e' : '' }"></div>
                <div class="circles circle3" :style="{ backgroundColor: returned_data?.shipped_at !== null && returned_data?.shipped_at !==undefined ? '#0095c78e' : '' }"></div>
                <div class="circles circle4" :style="{ backgroundColor: returned_data?.delivered_at !== null && returned_data?.delivered_at !==undefined ? '#0095c78e' : '' }"></div>
                <div class="circles circle5" :style="{ backgroundColor: returned_data?.fulfilled_at!== null && returned_data?.fulfilled_at !==undefined ? '#0095c78e' : '' }"></div>
              </div>

              <div style="display: inline-block;margin-left: 5%">
                <div class="track_info">
                  <span style="float: left">
                    <span
                      style="font-weight: bolder; font-size: 20px; color: black"
                      ><b>Order ID</b></span
                    ><br />
                    #0CABNMKCB3E</span
                  >

                  <span style="float: right">
                    <span
                      style="font-weight: bolder; font-size: 20px; color: black"
                      ><b>Tracking ID</b></span
                    ><br />
                    <!-- #0CABNMKCB3E -->
                    </span
                  >
                </div>
                <br /><br />
                <div class="track" style="clear: both; margin-top: 50px;">
                  <figure class="active">
                    <figcaption>Order Placed</figcaption>
                    <p>{{ getDatetime(returned_data?.timestamp) }}</p>
                  </figure>
                  <figure class="">
                    <figcaption>Packed</figcaption>
                    <!---->
                    <p v-if="returned_data?.packed_at == null">
                      Waiting for the order to be packed
                    </p>
                    <p v-else>{{ returned_data?.packed_at }}</p>
                  </figure>
                  <figure class="">
                    <figcaption>Shipped</figcaption>
                    <!---->
                    <p v-if="returned_data?.shipped_at == null">
                      Waiting for the order to be shipped
                    </p>
                    <p v-else>{{ returned_data?.shipped_at }}</p>
                  </figure>
                  <figure class="">
                    <figcaption>Delivered</figcaption>
                    <!---->
                    <p v-if="returned_data?.delivered_at == null">
                      Estimated delivery within 1 day
                    </p>
                    <p v-else>{{ returned_data?.delivered_at }}</p>
                  </figure>
                  <figure class="">
                    <figcaption>Completed</figcaption>
                    <!----><!---->
                    <p v-if="returned_data?.fulfilled_at == null">
                      Waiting for user to confirm Recipient of goods
                    </p>
                    <p v-else>{{ returned_data?.fulfilled_at }}</p>
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- /add -->
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      data: JSON.parse(localStorage.getItem("product_data")),
      id: localStorage.getItem("notification_id"),
      returned_data: "",
      getprice: "",
      commission: "",
    };
  },
  methods: {
    ...mapActions({
      _get_notification_details: "notification/get_notification_details",
    }),
    get_notification_details() {
      // let id = (this.id !== null) ? this.id : localStorage.getItem("purchase_id");
      this._get_notification_details(this.id)
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.returned_data = this.response.data;
            console.log(this.returned_data);
          }
        })
        .catch((error) => {
          console.log("Error logging in", error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;
            if (
              status == 400 ||
              status == 422 ||
              status == 401 ||
              status == 404
            ) {
            } else if (status === 500) {
            }
          }
        });
    },
    getDatetime(date) {
      let datetime = new Date(date);
      return `${datetime.toDateString()} ${datetime.toLocaleTimeString()}`;
    },
  },
  mounted() {
    this.get_notification_details();
  },
};
</script>
<style scoped>
.card {
  border: none;
  box-shadow: 2px 2px 2px 2px #0095c78e;
  height: 220px;
}
.tcont {
  margin-top: 40px;
}
.s_tr {
  font-weight: bolder;
  background-color: rgba(128, 128, 128, 0.329);
}
.trackcont {
  padding-left: 1%;
}
.innertrackcont {
  height: auto;
  border-left: 1px solid rgba(0, 0, 0, 0.082);
  padding-left: 20px;
  padding-bottom: 40px;
}
figcaption {
  font-weight: bolder;
  margin-bottom: 10px;
  color: black;
}
figure p {
  margin-bottom: 30px;
}
.order_header {
  margin-bottom: 30px;
}
@media (max-width: 1800px) {
  .card {
    height: auto;
  }
}
@media (max-width: 853px) {
  .trackcont {
    margin-top: 80px;
  }
}

/* my progress bar */

.my_progress{
  height: 500px;
  width: 3px;
  background-color: rgba(102, 102, 102, 0.404);
  display: inline-block;
  position: absolute;
}
.circles{width: 12px;height: 12px; background-color: rgba(102, 102, 102, 0.938);border-radius: 50%;position: absolute;left: -150%;}
.circle0{top: -1%;}
.circle1{top: 20%;}
.circle2{top: 36%;}
.circle3{top: 52%;}
.circle4{top: 69%;}
.circle5{top: 85%;}
.in_my_progress{background-color: #0095c78e;}
</style>
