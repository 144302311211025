<template>
  <div
    id="otp_modal"
    class="modal modal-md animate__animated animate__zoomIn animate__faster"
    
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
  <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          Enter Phone OTP
          <!-- <button
type="button"
class="btn-close"
data-bs-dismiss="modal"
aria-label="Close"
></button> -->
        </div>

        <div class="modal-body p-4">
          <div class="form-header">
            <!-- <h2>Enter OTP</h2> -->
            <h4>
              To continue, we'll require you to input your OTP to authorize this
              transaction.
            </h4>
            <br>
            <p>
              Enter the six-digit OTP sent to your phone number asssociated with your account, to proceed.
            </p>
          </div>
          <!-- body starts here -->
          <div class="row">
            <div class="col-3"></div>
            <div class="col-6">
              <div
                id="otp-container2"
                style="
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  justify-content: center;
                "
              >
                <!-- OTP input fields will be dynamically added here using JavaScript -->
              </div>
            </div>
            <div class="col-3"></div>
          </div>
          <br />
          <br />

          <div class="row" style="align-items: center; justify-content: center">
            <div class="col-4">
              <span>
                Didn't get code?
                <span class="special-color xvx">Resend</span></span
              >
            </div>
            <div class="col-4 text-center" style="text-align: center">
              <span id="errormsg"></span>
            </div>
            <div class="col-4 text-right" style="text-align: right">
              <!-- <span class="special-color"
                >Expires in
                <span class="count-down" id="count-down">5:00</span></span
              > -->
            </div>
          </div>

          <!-- /.modal -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { store } from "../../store";
// import { mapActions } from "vuex";

export default {
  name: "Settings_menu",
  data() {
    return {
      // patient_id: localStorage.getItem("v_patient_id"),
      // hospital_id: localStorage.getItem("v_hospital_id"),
      phone_otp: null,
    };
  },
  methods: {
    // ...mapActions({
    //   _verify_withdraw: "general/verify_withdraw",
    // }),
  },

  mounted() {
    const otpInputContainer = document.getElementById("otp-container2");

    // Function to create OTP input fields
    function createOTPInputFields(length) {
      otpInputContainer.innerHTML = ""; // Clear previous input fields

      for (let i = 0; i < length; i++) {
        const input = document.createElement("input");
        input.type = "text";
        input.maxLength = 1;
        input.classList.add("otp-input");
        input.addEventListener("input", handleInput);
        input.placeholder = "-";
        input.pattern = "[0-9]*";
        input.style =
          "height:60px; width:60px;margin:0 5px;font-size:19px;text-align:center";
        // input.style:focus="border-color: #007bff;outline: none;"
        input.addEventListener("input", handleInput);
        input.addEventListener("keydown", handleKeyDown);

        otpInputContainer.appendChild(input);
      }
    }

    // Handle input in OTP fields
    function handleInput() {
      const inputs = Array.from(
        otpInputContainer.getElementsByTagName("input")
      );

      for (let i = 0; i < inputs.length; i++) {
        if (inputs[i].value && i < inputs.length - 1) {
          inputs[i + 1].focus();
        }
      }
    }

    // Set the length of OTP (customize this according to your needs)
    const otpLength = 6;
    createOTPInputFields(otpLength);

    // Focus on the first OTP input field
    const firstInput = otpInputContainer.getElementsByTagName("input")[0];
    if (firstInput) {
      firstInput.focus();
    }

    const _vm = this;

    function handleInput() {
      const inputs = Array.from(
        otpInputContainer.getElementsByTagName("input")
      );
      const lastInput = inputs[inputs.length - 1];

      // Check if the last input is filled
      if (lastInput.value) {
        // Perform the action when the last input is filled
        this.phone_otp = Array.from(otpInputContainer.getElementsByTagName("input"))
          .map((input) => input.value)
          .join("");

        // alert(this.otp);
        // alert(_vm.patient_id);
        // alert();
        // api call
        // $(".inloader").show();

        localStorage.setItem("phone_otp",this.phone_otp)
        // alert(this.phone_otp)
         $("#OTP_modal").modal("show");
         $("#OTP_modal2").modal("hide");
         $("#otp_modal").modal("hide");
        // _vm
        //   ._verify_withdraw({
        //     id: localStorage.getItem("withdrawal_id"),
        //     payload: {
        //       hospital_id: localStorage.getItem('withdraw_amount'),
        //       email_otp: this.otp,
        //     },
        //   })
        //   .then((response) => {
        //     $(".inloader").hide();

        //     _vm.response = response.data;
        //     if (_vm.response["status"] == true) {
        //       _vm.$toast.success(_vm.response.message);
        //       $("#otp_modal").modal("hide");
        //       window.location = "Patients";
        //     }
        //   })
        //   .catch((error) => {
        //     $(".inloader").hide();
        //     console.log(error);
        //     // _vm.$toast.error();
        //     $("#errormsg").text(error.response.data.message);
        //     $(".inloader").hide();
        //     // $("#otp_modal").modal("hide")
        //     _vm.otp = "";
        //   });
      } else {
        // If the last input is not filled, focus on the next input
        for (let i = 0; i < inputs.length; i++) {
          if (inputs[i].value && i < inputs.length - 1) {
            inputs[i + 1].focus();
          }
        }
      }
    }

    // Handle keydown events (e.g., backspace)
    function handleKeyDown(event) {
      const inputs = Array.from(
        otpInputContainer.getElementsByTagName("input")
      );
      const currentIndex = inputs.indexOf(event.target);

      if (
        event.key === "Backspace" &&
        currentIndex > 0 &&
        !event.target.value
      ) {
        // Focus on the previous input field if backspace is pressed and the current field is empty
        inputs[currentIndex - 1].focus();
      }
    }

    // #######################################not working but still here##########################################################

    // Set the countdown time in seconds
    const countdownTime = 5 * 60; // 5 minutes * 60 seconds

    // Get the timer element
    const timerElement = document.getElementById("count-down");

    // Function to update the timer display
    function updateTimerDisplay(timeInSeconds) {
      const minutes = Math.floor(timeInSeconds / 60);
      const seconds = timeInSeconds % 60;
      const formattedTime = `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
      timerElement.textContent = formattedTime;
    }

    // Function to perform an action when the countdown reaches 0
    function performAction() {
      $("#otp_modal").modal("hide");
    }

    // Function to start the countdown
    function startCountdown() {
      let timeRemaining = countdownTime;

      // Update the display initially
      updateTimerDisplay(timeRemaining);

      // Set an interval to update the countdown every second
      const countdownInterval = setInterval(function () {
        timeRemaining--;

        // Update the display
        updateTimerDisplay(timeRemaining);

        // Check if the countdown reached 0
        if (timeRemaining <= 0) {
          // Clear the interval
          clearInterval(countdownInterval);

          // Perform the action when the countdown reaches 0
          performAction();
        }
      }, 1000); // Update every 1000 milliseconds (1 second)
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.otp_cont {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.otp-container2 {
  width: 100%;
}
.otp-input {
  border-radius: 6px;
  border: 1px solid #00afef;
}

.otp-input:focus {
  outline: none;
  border: 3px solid #00afef;
}
.modal-dialog {
  width: 40%;
}
.special-color {
  color: #00afef;
}
p,
.count-down {
  font-weight: bold;
}
.xvx {
  display: none;
}
#errormsg {
  color: red;
  text-align: center;
  font-weight: bold;
  font-size: 17px;
}
</style>
