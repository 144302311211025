<template>
  <div
    class="modal fade"
    id="List_images"
    tabindex="-1"
    aria-labelledby="List_imagesLabel"
    aria-hidden="true"
    style="display: none"
  >
    <div class="modal-dialog modal-fullscreen">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="List_imagesLabel">
            Select Image(s) {{ added_images.current_colorValue }}
          </h4>

          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div>
            <div class="thumbnail-images">
              <!-- {{ added_images }} -->
              <img
                v-for="(image, index) in added_images.full_img"
                :key="index"
                :src="image.url"
                @click="select_image(image)"
              />

              <!-- <span
                class="addImg"
                @click="select_img_input(added_images.current_colorValue)"
              >
                <span class="fa fa-plus"></span>
              </span>

              <input
                type="file"
                :id="`upload_variant${added_images.current_colorValue}`"
                class="upload_variant"
                @change="
                  push_into_array($event, added_images.current_colorValue)
                "
                accept="image/*"
                style="display: none"
              /> -->
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  components: {},

  props: {
    added_images: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      remainingBlobs: [],
      remainingimgpreview: [],
    };
  },
  methods: {
    select_image(specific_array) {
      this.remainingBlobs = [];
      this.remainingimgpreview = [];

      // 1. seelect image - add to variant images array
      // 2. add to variant image preview
      // 3. remove from image blob list
      // 4. remove from image preview list
      this.added_images.full_img.forEach((item, index) => {
        // Check if the item's id matches the idToRemove
        if (item.id === specific_array.id) {
        // removedBlobs.push(item.blob); // Add the blob to the removedBlobs array
        this.added_images.color_images.push({
          color: this.added_images.current_colorValue,
          image: item.blob,
        });

        // var variant_preview = document.getElementById(
        //   `variant_thumbnail${this.added_images.current_colorValue}`
        // );
        // variant_preview.src = item.url;
        // variant_preview.style = "display:inline-block";

        var variant_previews = document.querySelectorAll(
          `.variant_thumbnail${this.added_images.current_colorValue}`
        );
        // console.log(variant_previews, "ks");

        // Loop through each element and apply the changes
        variant_previews.forEach((variant_preview) => {
          // alert(this.added_images.current_colorValue)
          // alert(variant_preview)
          variant_preview.src = item.url;
          variant_preview.style.display = "inline-block";
        });

        // hiding image selector
        var selector = document.getElementById(
          `selector${this.added_images.current_colorValue}`
        );
        if (selector) {
          selector.style = "display:none";
        }
        // Remove the entire object containing the selected blob
        this.added_images.full_img.splice(index, 1);
        }
      });

      // After removing the specified blob, extract the remaining blobs
      this.added_images.full_img.forEach((item) => {
        if (item.blob) {
          this.remainingBlobs.push(item.blob);
          this.remainingimgpreview.push({ id: item.id, url: item.url });
        }
      });

      // console.log(this.added_images.color_images, "color_img");
      // console.log(this.remainingBlobs, "remaining blobs");
      // console.log(this.remainingimgpreview, "remaining imgs");

      this.$emit("list_images", {
        remainingBlobs: this.remainingBlobs,
        remainingimgpreview: this.remainingimgpreview,
        added_color_images: this.added_images.color_images,
      });
      $("#List_images").modal("hide");
    },

    image_procesor(image) {
      if (image) {
        const imageURL = URL.createObjectURL(image);
        // console.log(image);
        return imageURL;
      }
    },

    // manual upload
    push_into_array(event, colorValue) {
      var variant_previews = document.querySelectorAll(
        `.variant_thumbnail${this.added_images.current_colorValue}`
      );

      // var variant_preview = document.getElementById(
      //   `variant_thumbnail${colorValue}`
      // );
      var selector = document.getElementById(`selector${colorValue}`);
      var file = event.target.files[0];
      var reader = new FileReader();

      reader.onload = function (e) {
        // Loop through each element and apply the changes
        variant_previews.forEach((variant_preview) => {
          variant_preview.src = e.target.result;
          variant_preview.style = "display:inline-block";
        });

        selector.style = "display:none";
      };

      if (file) {
        reader.readAsDataURL(file);
      }
      this.added_images.color_images.push({
        color: colorValue,
        image: file,
      });
      console.log(this.added_images.color_images, "added vrm");
      $("#List_images").modal("hide");
    },
    select_img_input(colorValue) {
      document.getElementById(`upload_variant${colorValue}`).click();
    },
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.thumbnail-images {
  display: flex;
  justify-content: left;
  margin-top: 10px;
}

.thumbnail-images img {
  max-width: 150px;
  width: 150px;
  height: 150px;
  margin: 0 8px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.addImg {
  max-width: 150px;
  width: 150px;
  height: 150px;
  border: 2px dotted black;
  border-radius: 5px;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
  align-items: center;
  padding-top: 50px;
}

.fa-plus {
  font-size: 52px;
}
</style>
