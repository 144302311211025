<template>
  <!-- Header -->
  <div class="header">
    <!-- Logo -->
    <div class="header-left active">
      <router-link to="/dashboard/sales-dashboard" class="logo logo-normal">
        <img src="@/assets/img/logo.png" alt="" />
      </router-link>
      <router-link to="/dashboard/sales-dashboard" class="logo logo-white">
        <img src="@/assets/img/logo-white.png" alt="" />
      </router-link>
      <router-link to="/dashboard/sales-dashboard" class="logo-small">
        <img src="@/assets/img/logo-small.png" alt="" />
      </router-link>
      <a id="toggle_btn" href="javascript:void(0);" @click="toggleSidebar">
        <vue-feather type="chevrons-left"></vue-feather>
      </a>
    </div>
    <!-- /Logo -->

    <a
      id="mobile_btn"
      class="mobile_btn"
      href="javascript:void(0);"
      @click="toggleSidebar1"
    >
      <span class="bar-icon">
        <span></span>
        <span></span>
        <span></span>
      </span>
    </a>

    <!-- Header Menu -->
    <ul class="nav user-menu">
      <!-- Search -->
      <li class="nav-item nav-searchinputs">
        <div class="top-nav-search">
          <a href="javascript:void(0);" class="responsive-search">
            <i class="fa fa-search"></i>
          </a>
          <form action="javascript:void(0);" class="dropdown">
            <div
              class="searchinputs dropdown-toggle"
              id="dropdownMenuClickable"
              data-bs-toggle="dropdown"
              data-bs-auto-close="false"
            >
              <input type="text" placeholder="Search" />
              <div class="search-addon">
                <span
                  ><vue-feather type="x-circle" class="feather-14"></vue-feather
                ></span>
              </div>
            </div>
            <div
              hidden
              class="dropdown-menu search-dropdown"
              aria-labelledby="dropdownMenuClickable"
            >
              <div class="search-info">
                <h6>
                  <span
                    ><vue-feather
                      type="search"
                      class="feather-16"
                    ></vue-feather></span
                  >Recent Searches
                </h6>
                <ul class="search-tags">
                  <li><a href="javascript:void(0);">notifications</a></li>
                  <li><a href="javascript:void(0);">Sales</a></li>
                  <li><a href="javascript:void(0);">Applications</a></li>
                </ul>
              </div>
              <div class="search-info">
                <h6>
                  <span
                    ><vue-feather
                      type="help-circle"
                      class="feather-16"
                    ></vue-feather></span
                  >Help
                </h6>
                <p>
                  How to Change Product Volume from 0 to 200 on Inventory
                  management
                </p>
                <p>Change Product Name</p>
              </div>
              <div class="search-info">
                <h6>
                  <span
                    ><vue-feather
                      type="user"
                      class="feather-16"
                    ></vue-feather></span
                  >Customers
                </h6>
                <ul class="customers">
                  <li>
                    <a href="javascript:void(0);"
                      >Aron Varu<img
                        src="@/assets/img/profiles/avator1.jpg"
                        alt=""
                        class="img-fluid"
                    /></a>
                  </li>
                  <li>
                    <a href="javascript:void(0);"
                      >Jonita<img
                        src="@/assets/img/profiles/avatar-01.jpg"
                        alt=""
                        class="img-fluid"
                    /></a>
                  </li>
                  <li>
                    <a href="javascript:void(0);"
                      >Aaron<img
                        src="@/assets/img/profiles/avatar-10.jpg"
                        alt=""
                        class="img-fluid"
                    /></a>
                  </li>
                </ul>
              </div>
            </div>
          </form>
        </div>
      </li>
      <!-- /Search -->

      <!-- Select Store -->
      <li
        class="nav-item dropdown has-arrow main-drop select-store-dropdown"
        hidden
      >
        <a
          href="javascript:void(0);"
          class="dropdown-toggle nav-link select-store"
          data-bs-toggle="dropdown"
        >
          <span class="user-info">
            <span class="user-letter">
              <img
                src="@/assets/img/store/store-01.png"
                alt="Store Logo"
                class="img-fluid"
              />
            </span>
            <span class="user-detail">
              <span class="user-name">Select Store</span>
            </span>
          </span>
        </a>
        <div class="dropdown-menu dropdown-menu-right">
          <a href="javascript:void(0);" class="dropdown-item">
            <img
              src="@/assets/img/store/store-01.png"
              alt="Store Logo"
              class="img-fluid"
            />
            Grocery Alpha
          </a>
          <a href="javascript:void(0);" class="dropdown-item">
            <img
              src="@/assets/img/store/store-02.png"
              alt="Store Logo"
              class="img-fluid"
            />
            Grocery Apex
          </a>
          <a href="javascript:void(0);" class="dropdown-item">
            <img
              src="@/assets/img/store/store-03.png"
              alt="Store Logo"
              class="img-fluid"
            />
            Grocery Bevy
          </a>
          <a href="javascript:void(0);" class="dropdown-item">
            <img
              src="@/assets/img/store/store-04.png"
              alt="Store Logo"
              class="img-fluid"
            />
            Grocery Eden
          </a>
        </div>
      </li>
      <!-- /Select Store -->

      <!-- Flag -->
      <li class="nav-item dropdown has-arrow flag-nav nav-item-box" hidden>
        <a
          class="nav-link dropdown-toggle"
          data-bs-toggle="dropdown"
          href="javascript:void(0);"
          role="button"
        >
          <img
            src="@/assets/img/flags/us.png"
            alt="Language"
            class="img-fluid"
          />
        </a>
        <div class="dropdown-menu dropdown-menu-right">
          <a href="javascript:void(0);" class="dropdown-item active">
            <img src="@/assets/img/flags/us.png" alt="" height="16" /> English
          </a>
          <a href="javascript:void(0);" class="dropdown-item">
            <img src="@/assets/img/flags/fr.png" alt="" height="16" /> French
          </a>
          <a href="javascript:void(0);" class="dropdown-item">
            <img src="@/assets/img/flags/es.png" alt="" height="16" /> Spanish
          </a>
          <a href="javascript:void(0);" class="dropdown-item">
            <img src="@/assets/img/flags/de.png" alt="" height="16" /> German
          </a>
        </div>
      </li>
      <!-- /Flag -->

      <li class="nav-item nav-item-box">
        <a
          href="javascript:void(0);"
          id="btnFullscreen"
          @click="initFullScreen"
        >
          <vue-feather type="maximize"></vue-feather>
        </a>
      </li>
      <li class="nav-item nav-item-box" hidden>
        <router-link to="/crm/product-question">
          <vue-feather type="mail"></vue-feather>
          <!-- <span class="badge rounded-pill">1</span> -->
        </router-link>
      </li>
      <!-- Notifications -->
      <li class="nav-item dropdown nav-item-box">
        <a
          href="javascript:void(0);"
          class="dropdown-toggle nav-link"
          data-bs-toggle="dropdown"
        >
          <vue-feather type="bell"></vue-feather
          ><span class="badge rounded-pill" v-if="no_of_notifications > 0">{{
            no_of_notifications
          }}</span>
        </a>
        <div class="dropdown-menu notifications">
          <div class="topnav-dropdown-header">
            <span class="notification-title">Notifications</span>
            <a href="javascript:void(0)" class="clear-noti"> Clear All </a>
          </div>
          <div class="noti-content">
            <ul
              class="notification-list"
              v-for="(noti, id) in not_data"
              v-bind:key="id"
            >
              <li
                class="notification-message"
                style="cursor: pointer"
                @click="view_purchase_details(noti.id, noti.target_id)"
              >
                <div class="media d-flex">
                  <span class="avatar flex-shrink-0">
                    <img alt="" src="@/assets/img/profiles/avatar-02.jpg" />
                  </span>
                  <div class="media-body flex-grow-1">
                    <h4>
                      {{ noti.target }}
                      <span class="badge badge-success" style="float: right">{{
                        noti.tag
                      }}</span>
                    </h4>
                    <p class="noti-details">{{ noti.description }}</p>
                    <p class="noti-time">
                      <span class="notification-time">{{
                        getDatetime(noti.created_at)
                      }}</span>
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="topnav-dropdown-footer">
            <router-link to="/activities">View all Notifications</router-link>
          </div>
        </div>
      </li>
      <!-- /Notifications -->

      <li class="nav-item nav-item-box" hidden>
        <router-link to="/settings/general-settings"
          ><vue-feather type="settings"></vue-feather
        ></router-link>
      </li>
      <li class="nav-item dropdown has-arrow main-drop">
        <a
          href="javascript:void(0);"
          class="dropdown-toggle nav-link userset"
          data-bs-toggle="dropdown"
        >
          <span class="user-info">
            <span class="user-letter">
              <img :src="data.avatar" alt="" class="img-fluid" />
            </span>
            <span class="user-detail">
              <span class="user-name"
                >{{ data?.surname }} {{ data?.other_names }}</span
              >
              <span class="user-role">{{ data?.user_type }} Dashboard</span>
            </span>
          </span>
        </a>
        <div class="dropdown-menu menu-drop-user">
          <div class="profilename">
            <div class="profileset">
              <span class="user-img"
                ><img :src="data.avatar" alt="" />
                <span class="status online"></span
              ></span>
              <div class="profilesets">
                <h6>{{ data?.surname }} {{ data?.other_names }}</h6>
                <h5>{{ data?.user_type }} Dashboard</h5>
              </div>
            </div>
            <hr class="m-0" />
            <router-link class="dropdown-item" to="/pages/profile">
              <vue-feather class="me-2" type="user"></vue-feather> My
              Profile</router-link
            >
            <router-link
              class="dropdown-item"
              to="/settings/general-settings"
              hidden
              ><vue-feather class="me-2" type="settings"></vue-feather
              >Settings</router-link
            >
            <hr class="m-0" />
            <span class="dropdown-item logout pb-0" @click="logout()"
              ><img
                src="@/assets/img/icons/log-out.svg"
                class="me-2"
                alt="img"
              />Logout</span
            >
          </div>
        </div>
      </li>
    </ul>
    <!-- /Header Menu -->

    <!-- Mobile Menu -->
    <div class="dropdown mobile-user-menu">
      <a
        href="javascript:void(0);"
        class="nav-link dropdown-toggle"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        ><i class="fa fa-ellipsis-v"></i
      ></a>
      <div class="dropdown-menu dropdown-menu-right">
        <router-link class="dropdown-item" to="/pages/profile"
          >My Profile</router-link
        >
        <router-link
          class="dropdown-item"
          to="/settings/general-settings"
          hidden
          >Settings</router-link
        >
        <span class="dropdown-item" @click="logout()">Logout</span>
      </div>
    </div>
    <!-- /Mobile Menu -->
  </div>
  <!-- /Header -->

  <side-settings></side-settings>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      data: JSON.parse(localStorage.getItem("data")),
      returned_data: [],
      response: "",
      no_of_notifications: 0,
      not_data: [],
    };
  },
  mounted() {
    this.initMouseoverListener();
    console.log(this.data);
    this.get_notifications();
  },
  methods: {
    logout() {
      window.location = "/";
      localStorage.clear();
    },
    getDatetime(date) {
      let datetime = new Date(date);
      return `${datetime.toDateString()} ${datetime.toLocaleTimeString()}`;
    },
    ...mapActions({
      _get_notifications: "notification/get_notifications",
      _read_notification: "notification/read_notification",
    }),
    get_notifications() {
      this._get_notifications()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.returned_data = this.response.data;
            // this.no_of_notifications
            const unread_msgs = this.returned_data.filter(
              (item) => item.seen == false
            );
            this.no_of_notifications = unread_msgs.length;
            this.not_data = unread_msgs.slice(0, 5);
            //   console.log(unread_msgs)
          }
        })
        .catch((error) => {
          console.log("Error logging in", error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;
            if (
              status == 400 ||
              status == 422 ||
              status == 401 ||
              status == 404
            ) {
            } else if (status === 500) {
            }
          }
        });
    },
    view_purchase_details(id, t_id) {
      this._read_notification(id)
        .then((response) => {
          localStorage.setItem("notification_id", t_id);
          window.location = "/sales-notification";
        })
        .catch((error) => {
          console.log("Error logging in", error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;
            if (
              status == 400 ||
              status == 422 ||
              status == 401 ||
              status == 404
            ) {
            } else if (status === 500) {
            }
          }
        });
    },

    toggleSidebar1() {
      const body = document.body;
      body.classList.toggle("slide-nav");
    },
    toggleSidebar() {
      const body = document.body;
      body.classList.toggle("mini-sidebar");
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */
        !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },

    initMouseoverListener() {
      document.addEventListener("mouseover", this.handleMouseover);
    },
    handleMouseover(e) {
      e.stopPropagation();

      const body = document.body;
      const toggleBtn = document.getElementById("toggle_btn");

      if (
        body.classList.contains("mini-sidebar") &&
        this.isElementVisible(toggleBtn)
      ) {
        const target = e.target.closest(".sidebar, .header-left");

        if (target) {
          body.classList.add("expand-menu");
          this.slideDownSubmenu();
        } else {
          body.classList.remove("expand-menu");
          this.slideUpSubmenu();
        }

        e.preventDefault();
      }
    },
    isElementVisible(element) {
      return element.offsetWidth > 0 || element.offsetHeight > 0;
    },
    slideDownSubmenu() {
      const subdropPlusUl = document.getElementsByClassName("subdrop");
      for (let i = 0; i < subdropPlusUl.length; i++) {
        const submenu = subdropPlusUl[i].nextElementSibling;
        if (submenu && submenu.tagName.toLowerCase() === "ul") {
          submenu.style.display = "block";
        }
      }
    },
    slideUpSubmenu() {
      const subdropPlusUl = document.getElementsByClassName("subdrop");
      for (let i = 0; i < subdropPlusUl.length; i++) {
        const submenu = subdropPlusUl[i].nextElementSibling;
        if (submenu && submenu.tagName.toLowerCase() === "ul") {
          submenu.style.display = "none";
        }
      }
    },
  },
  beforeUnmount() {
    document.removeEventListener("mouseover", this.handleMouseover);
  },
};
</script>
