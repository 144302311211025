<template>
  <!-- Add User -->
  <div class="modal fade" id="add-units">
    <div class="modal-dialog modal-dialog-centered custom-modal-two">
      <div class="modal-content">
        <div class="page-wrapper-new p-0">
          <div class="content">
            <div class="modal-header border-0 custom-modal-header">
              <div class="page-title">
                <h4>Add Admin</h4>
              </div>
              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body custom-modal-body">
              <form @submit.prevent="submitForm">
                <div class="row">
                  <div class="col-lg-12" hidden>
                    <div class="new-employee-field">
                      <span>Avatar</span>
                      <div class="profile-pic-upload mb-2">
                        <div class="profile-pic">
                          <span
                            ><i
                              data-feather="plus-circle"
                              class="plus-down-add"
                            ></i>
                            Profile Photo</span
                          >
                        </div>
                        <div class="input-blocks mb-0">
                          <div class="image-upload mb-0">
                            <input type="file" />
                            <div class="image-uploads">
                              <h4>Change Image</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="input-blocks">
                      <label>First Name</label>
                      <input type="text" class="form-control" v-model="fname" />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="input-blocks">
                      <label>Last Name</label>
                      <input type="text" class="form-control" v-model="lname" />
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="input-blocks">
                      <label>Email</label>
                      <input
                        type="email"
                        class="form-control"
                        v-model="email"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="input-blocks">
                      <label>Phone</label>
                      <input type="text" class="form-control" v-model="phone" />
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="input-blocks">
                      <label>Role</label>
                      <vue-select
                        :options="ListUs"
                        id="listus"
                        placeholder="Choose"
                        v-model="role"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="input-blocks">
                      <label>Tag</label>
                      <input type="text" class="form-control" v-model="tag" />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="input-blocks">
                      <label>Password</label>
                      <input type="text" class="form-control" v-model="password" />
                    </div>
                  </div>
                  <div class="col-lg-6" v-if="sent">
                    <div class="input-blocks">
                      <label>OTP</label>
                      <input type="text" class="form-control" v-model="otp" />
                      <span class="in_message">OTP sent to the above email address</span>
                    </div>
                  </div>


                  <div
                          class="status-toggle modal-status d-flex justify-content-between align-items-center mb-2"
                        >
                          <span class="status-label"></span>
                          <div class="d-flex align-items-center">
                            <input type="checkbox" id="user4" class="check" />
                            <label
                              for="user4"
                              class="checktoggle mb-0 me-1"
                            ></label>
                            <span class="customer-toggle"
                              >Make Admin Public</span
                            >
                          </div>
                        </div>
                </div>
                <div class="modal-footer-btn">
                  <button
                    type="button"
                    class="btn btn-cancel me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <div class="btn btn-submit" @click="get_code()" v-if="sent">Resend OTP</div>
                  <div class="btn btn-submit" @click="add_vendor()"  v-if="sent">Submit</div>
                  <div class="btn btn-submit" @click="get_code()" v-if="!sent">Request OTP</div>

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add User -->

  
</template>
<script>
import { mapActions } from "vuex";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
export default {
  data() {
    return {
      ListUs: [
        "Choose",
        "Finance and Account Manager",
        "Web store Operations Manager",
        "Sales Representative",
        "Business Developer Manager",
        "Web store assistance",
        "Finance and Account Manager Assistance",
        "Order Dispatcher",
        "Customer Service",
      ],
      ListUsOne: ["Admin", "Manager", "Store Keeper"],

      fname: "",
      lname: "",
      email: "",
      phone: "",
      role: "",
      tag: "",
      otp: "",
      sent: false,
      password:''
    };
  },
  methods: {
    // submitForm() {
    //   this.$router.push("/users/users-list");
    // },
    ...mapActions({
      _add_vendor: "admin/add_vendor",
      _get_code: "admin/get_code",
    }),

    get_code() {
      $(".inloader").show();
      this._get_code({
        surname: this.fname,
        other_names: this.lname,
        email: this.email,
        phone: this.phone,
        role: this.role,
        tag: this.tag,
      })
        .then((response) => {
          $(".inloader").hide();
          toast.success("Verification Code Sent", {
            position: toast.POSITION.TOP_RIGHT,
          });
          this.response = response.data;
          //   this.response
          if (this.response["status"] == true) {
            var data = this.response.data;
            this.sent = true;
          }
        })
        .catch((error) => {
          $(".inloader").hide();

          console.log("Error logging in", error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;
            if (
              status == 400 ||
              status == 422 ||
              status == 401 ||
              status == 404
            ) {
              toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            } else if (status === 500) {
              toast.error("Network Error, Check your internet conectivity.", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          }
        });
    },
    add_vendor() {
      $(".inloader").show();
      this._add_vendor({
        surname: this.fname,
        other_names: this.lname,
        email: this.email,
        phone: this.phone,
        role: this.role,
        tag: this.tag,
        verification_code: this.otp,
        password: this.password,
        is_public:0
      })
        .then((response) => {
          $(".inloader").hide();
          $("#add-units").modal('hide');

          toast.success("User Added", {
            position: toast.POSITION.TOP_RIGHT,
          });
          this.response = response.data;
          //   this.response
          if (this.response["status"] == true) {
            var data = this.response.data;
            // this.sent = true;
            window.location.reload()
          }
        })
        .catch((error) => {
          $(".inloader").hide();

          console.log("Error logging in", error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;
            if (
              status == 400 ||
              status == 422 ||
              status == 401 ||
              status == 404
            ) {
              toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            } else if (status === 500) {
              toast.error("Network Error, Check your internet conectivity.", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          }
        });
    },

   
  },
};
</script>
<style scoped>
.in_message{font-size: 12px;}
</style>