<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>

  <div class="page-wrapper">
    <div class="content">
      <div class="page-header">
        <div class="row">
          <div class="col">
            <h3 class="page-title">Questions and Answers</h3>
            <span>Naija Kobo Market Q and A listings</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <!-- <div class="compose-btn">
                        <a href="javascript:void(0);" class="btn btn-primary btn-block w-100">
                            Compose
                        </a>
                    </div> -->
          <ul class="inbox-menu">
            <li>
              <a href="javascript:void(0);"><i class="far fa-star"></i> Read</a>
            </li>
            <li class="active">
              <a href="javascript:void(0);"
                ><i class="fas fa-download"></i> Unread
                <span class="mail-count">(5)</span></a
              >
            </li>

            <li>
              <a href="javascript:void(0);"
                ><i class="far fa-file-alt"></i> Achived
                <span class="mail-count">(13)</span></a
              >
            </li>
            <li>
              <a href="javascript:void(0);"
                ><i class="far fa-trash-alt"></i> Trash</a
              >
            </li>
          </ul>
        </div>

        <div class="col-lg-9 col-md-12">
          <div class="card bg-white">
            <div class="card-body">
              <div class="email-header">
                <div class="row">
                  <div class="col-lg-9 top-action-left col-sm-12">
                    <div class="float-left">
                      <div class="btn-group dropdown-action me-1"  hidden>
                        <button
                          type="button"
                          class="btn btn-white dropdown-toggle"
                          data-bs-toggle="dropdown"
                        >
                          Select <i class="fas fa-angle-down"></i>
                        </button>
                        <div class="dropdown-menu">
                          <a class="dropdown-item" href="javascript:void(0);"
                            >All</a
                          >
                          <a class="dropdown-item" href="javascript:void(0);"
                            >None</a
                          >
                          <div class="dropdown-divider"></div>
                          <a class="dropdown-item" href="javascript:void(0);"
                            >Read</a
                          >
                          <a class="dropdown-item" href="javascript:void(0);"
                            >Unread</a
                          >
                        </div>
                      </div>
                      <div class="btn-group dropdown-action me-1"  hidden>
                        <button
                          type="button"
                          class="btn btn-white dropdown-toggle"
                          data-bs-toggle="dropdown"
                        >
                          Actions <i class="fas fa-angle-down"></i>
                        </button>
                        <div class="dropdown-menu">
                          <a class="dropdown-item" href="javascript:void(0);"
                            >Reply</a
                          >
                          <a class="dropdown-item" href="javascript:void(0);"
                            >Forward</a
                          >
                          <a class="dropdown-item" href="javascript:void(0);"
                            >Archive</a
                          >
                          <div class="dropdown-divider"></div>
                          <a class="dropdown-item" href="javascript:void(0);"
                            >Mark As Read</a
                          >
                          <a class="dropdown-item" href="javascript:void(0);"
                            >Mark As Unread</a
                          >
                          <div class="dropdown-divider"></div>
                          <a class="dropdown-item" href="javascript:void(0);"
                            >Delete</a
                          >
                        </div>
                      </div>

                      <div class="btn-group dropdown-action mail-search">
                        <input
                          type="text"
                          placeholder="Search Messages"
                          class="form-control search-message"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 top-action-right col-sm-12">
                    <div class="text-end">
                      <button
                        type="button"
                        title="Refresh"
                        data-toggle="tooltip"
                        class="btn btn-white d-none d-md-inline-block me-1"
                      >
                        <i class="fas fa-sync-alt"></i>
                      </button>
                      <div class="btn-group">
                        <a class="btn btn-white"
                          ><i class="fas fa-angle-left"></i
                        ></a>
                        <a class="btn btn-white"
                          ><i class="fas fa-angle-right"></i
                        ></a>
                      </div>
                    </div>
                    <div class="text-end">
                      <span class="text-muted d-none d-md-inline-block"
                        >You have {{questions.length}} messages
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="email-content">
                <div class="table-responsive">
                  <table class="table table-inbox table-hover">
                    <thead>
                      <tr>
                        <th>
                          <label class="checkboxs">
                            <input type="checkbox" id="select-all" />
                            <span class="checkmarks"></span>
                          </label>
                        </th>
                        <th>Product Name</th>
                        <th>Customer Name</th>
                        <th>Slug</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        class="unread clickable-row"
                        v-for="Q in questions"
                        :key="Q.id"
                        @click="viewmessage(Q)"
                      >
                        <td>
                          <label class="checkboxs">
                            <input type="checkbox" />
                            <span class="checkmarks"></span>
                          </label>
                        </td>
                        <!-- <td><span class="mail-important"><i class="fas fa-star starred"></i></span></td> -->
                        <td class="product_name">{{ Q?.product?.name }}</td>
                        <td class="name">{{ Q?.user?.name }}</td>
                        <td class="subject">{{ Q?.slug }}</td>
                        <td class="action-table-data">
                    <div class="edit-action">
                          <a
                            class="confirm-text p-2"
                            @click="viewmessage(Q)"
                            href="javascript:void(0);"
                          >
                           View Question
                          </a>
                          </div>
                        </td>

                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import { ref } from "vue";

// const currentDate = ref(new Date());
export default {
  data() {
    return {
      questions: [],
    };
  },

  methods: {
    ...mapActions({
      _get_messages: "message/get_messages",
    }),

    get_messages() {
      this._get_messages()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.questions = this.response.data;
            console.log(this.questions);
          }
        })
        .catch((error) => {
          console.log("Error logging in", error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;
            if (
              status == 400 ||
              status == 422 ||
              status == 401 ||
              status == 404
            ) {
            } else if (status === 500) {
            }
          }
        });
    },

    getDatetime(date) {
      let datetime = new Date(date);
      return `${datetime.toDateString()} ${datetime.toLocaleTimeString()}`;
    },
    viewmessage(Q) {
        localStorage.setItem('QA',JSON.stringify(Q))
      window.location = "/crm/product-question-chat";
    },
  },
  mounted() {
    this.get_messages();
  },
};
</script>
