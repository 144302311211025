<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  <InLoader class="inloader" />

  <div class="page-wrapper">
    <div class="content">
      <product-header></product-header>

      <!-- /product list -->
      <div class="card table-list-card">
        <div class="card-body">
          <div class="table-top">
            <div class="search-set">
              <div class="search-input">
                <input
                  type="text"
                  placeholder="Search"
                  class="dark-input"
                  v-model="search"
                  @input="searchtrigger()"
                />
                <a
                  href="javascript:void(0);"
                  class="btn btn-searchset"
                  @click="searchtrigger()"
                  ><i data-feather="search" class="feather-search"></i
                ></a>
              </div>
            </div>
            <div class="search-path" hidden>
              <a
                class="btn btn-filter"
                id="filter_search"
                v-on:click="filter = !filter"
                :class="{ setclose: filter }"
              >
                <vue-feather type="filter" class="filter-icon"></vue-feather>
                <span
                  ><img src="@/assets/img/icons/closes.svg" alt="img"
                /></span>
              </a>
            </div>
            <div class="form-sort" hidden>
              <vue-feather type="sliders" class="info-img"></vue-feather>
              <vue-select
                :options="Sortby"
                id="sortby"
                placeholder="Sort by Date"
              />
            </div>
          </div>
          <!-- /Filter -->
          <div
            hidden
            class="card mb-0"
            :style="{ display: filter ? 'block' : 'none' }"
            id="filter_inputs"
          >
            <div class="card-body pb-0">
              <div class="row">
                <div class="col-lg-12 col-sm-12">
                  <div class="row">
                    <div class="col-lg-2 col-sm-6 col-12">
                      <div class="input-blocks">
                        <vue-feather type="box" class="info-img"></vue-feather>
                        <vue-select
                          :options="ChooseFilter"
                          id="choosefilter"
                          placeholder="Choose Product"
                        />
                      </div>
                    </div>
                    <div class="col-lg-2 col-sm-6 col-12">
                      <div class="input-blocks">
                        <vue-feather
                          type="stop-circle"
                          class="info-img"
                        ></vue-feather>
                        <vue-select
                          :options="CategroyFilter"
                          id="categroyfilter"
                          placeholder="Choose Categroy"
                        />
                      </div>
                    </div>

                    <div class="col-lg-2 col-sm-6 col-12">
                      <div class="input-blocks">
                        <vue-feather
                          type="git-merge"
                          class="info-img"
                        ></vue-feather>
                        <vue-select
                          :options="CategroySubFilter"
                          id="categroysubfilter"
                          placeholder="Choose Sub Categroy"
                        />
                      </div>
                    </div>

                    <div class="col-lg-2 col-sm-6 col-12">
                      <div class="input-blocks">
                        <vue-feather
                          type="stop-circle"
                          class="info-img"
                        ></vue-feather>
                        <vue-select
                          :options="BrandFilter"
                          id="brandfilter"
                          placeholder="All Brand"
                        />
                      </div>
                    </div>

                    <div class="col-lg-2 col-sm-6 col-12">
                      <div class="input-blocks">
                        <i class="fas fa-money-bill info-img"></i>
                        <vue-select
                          :options="PriceFilter"
                          id="Pricefilter"
                          placeholder="Price"
                        />
                      </div>
                    </div>
                    <div class="col-lg-2 col-sm-6 col-12">
                      <div class="input-blocks">
                        <a class="btn btn-filters ms-auto">
                          <i data-feather="search" class="feather-search"></i>
                          Search
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Filter -->
          <div class="table-responsive product-list">
            <a-table
              class="table datanew table-hover table-center mb-0"
              :columns="columns"
              :data-source="data"
              :row-selection="{}"
            >
              <template #bodyCell="{ column, record }">
                <template v-if="column.key === 'image'">
                  <a href="javascript:void(0);" class="product-img stock-img">
                    <img :src="record.thumbnail" alt="product" />
                  </a>
                </template>
                <template v-else-if="column.key === 'slug'">
                  <a :href="record.slug" target="_blank">{{ record.slug }}</a>
                </template>
                <template v-else-if="column.key === 'status'">
                  <span
                    class="badge bg-outline-success"
                    v-if="record.status == 'Active'"
                    >{{ record.status }}</span
                  >
                  <span
                    class="badge bg-outline-danger"
                    v-if="record.status == 'Inactive'"
                    >{{ record.status }}</span
                  >
                  <span
                    class="badge bg-outline-warning"
                    v-if="record.status == 'Pending'"
                    >{{ record.status }}</span
                  >
                </template>
                <template v-else-if="column.key === 'vendor'">
                  <span>{{ record.vendor.name }}</span>
                </template>
                <template v-else-if="column.key === 'quantity'">
                  <span v-if="record.quantity == null"> Variant Product</span>
                  <span v-else>{{ record.quantity }}</span>
                </template>
                <template v-else-if="column.key === 'created_at'">
                  <td class="action-table-data">
                    <div class="edit-delete-action">
                      <a
                        class="me-2 edit-icon p-2"
                        @click="view_product_details(record)"
                        href="javascript:void(0);"
                      >
                        <i data-feather="eye" class="feather-eye"></i>
                      </a>
                      <a
                        class="me-2 p-2"
                        @click="edit_product(record)"
                        href="javascript:void(0);"
                      >
                        <i data-feather="edit" class="feather-edit"></i>
                      </a>
                      <a
                        class="confirm-text p-2"
                        @click="showConfirmation(record.id)"
                        href="javascript:void(0);"
                        style="margin-right: 10px"
                      >
                        <vue-feather
                          type="trash-2"
                          class="trash-2"
                        ></vue-feather>
                      </a>

                      <div
                        class="status-toggle modal-status d-flex justify-content-between align-items-center"
                        v-if="record.status == 'Active'"
                      >
                        <input
                          type="checkbox"
                          :id="'user' + record.id"
                          class="check"
                          checked=""
                        />
                        <label
                          :for="'user' + record.id"
                          class="checktoggle"
                          @click="change_status('Active', record.id)"
                        >
                        </label>
                      </div>

                      <div
                        class="status-toggle modal-status d-flex justify-content-between align-items-center"
                        v-else-if="record.status == 'Inactive'"
                      >
                        <input
                          type="checkbox"
                          :id="'user' + record.id"
                          class="check"
                        />
                        <label
                          :for="'user' + record.id"
                          class="checktoggle"
                          @click="change_status('Inactive', record.id)"
                        >
                        </label>
                      </div>
                    </div>
                  </td>
                </template>
              </template>
            </a-table>
          </div>
        </div>
      </div>
      <!-- /product list -->
    </div>
  </div>
  <product-list-modal></product-list-modal>
</template>
<script>
import { mapActions } from "vuex";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { searchList } from "../../../../module/search_module.js";
const columns = [
  {
    title: "",
    dataIndex: "image",
    key: "image",
    sorter: {
      compare: (a, b) => {
        a = a.image.toLowerCase();
        b = b.image.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Product",
    dataIndex: "title",
    key: "title",
    sorter: {
      compare: (a, b) => {
        a = a.title.toLowerCase();
        b = b.title.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Slug",
    key: "slug",
    sorter: {
      compare: (a, b) => {
        a = a.slug.toLowerCase();
        b = b.slug.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Boost Type",
    dataIndex: "boost_type",
    sorter: {
      compare: (a, b) => {
        a = a.boost_type.toLowerCase();
        b = b.boost_type.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Original price",
    dataIndex: "original_price",
    sorter: {
      compare: (a, b) => {
        a = a.original_price.toLowerCase();
        b = b.original_price.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Selling price",
    dataIndex: "selling_price",
    sorter: {
      compare: (a, b) => {
        a = a.selling_price.toLowerCase();
        b = b.selling_price.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
    key: "quantity",
    sorter: {
      compare: (a, b) => {
        a = a.quantity.toLowerCase();
        b = b.quantity.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Status",
    key: "status",
    dataIndex: "status",
    sorter: {
      compare: (a, b) => {
        a = a.status.toLowerCase();
        b = b.status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Created By",
    // dataIndex: "vendor",
    key: "vendor",
    sorter: true,
  },
  {
    title: "Actions",
    key: "created_at",
    // sorter: {
    //   compare: (a, b) => {
    //     a = a.created_at.toLowerCase();
    //     b = b.created_at.toLowerCase();
    //     return a > b ? -1 : b > a ? 1 : 0;
    //   },
    // },
  },
  // {
  //   title: "Qty",
  //   dataIndex: "Qty",
  //   sorter: {
  //     compare: (a, b) => {
  //       a = a.Qty.toLowerCase();
  //       b = b.Qty.toLowerCase();
  //       return a > b ? -1 : b > a ? 1 : 0;
  //     },
  //   },
  // },
  // {
  //   title: "Created by",
  //   dataIndex: "CreatedBy",
  //   key: "CreatedBy",
  //   sorter: {
  //     compare: (a, b) => {
  //       a = a.CreatedBy.toLowerCase();
  //       b = b.CreatedBy.toLowerCase();
  //       return a > b ? -1 : b > a ? 1 : 0;
  //     },
  //   },
  // },
  // {
  //   title: "Action",
  //   key: "action",
  //   sorter: true,
  // },
];

export default {
  data() {
    return {
      filter: false,
      Sortby: ["Sort by Date", "14 09 23", "11 09 23"],
      ChooseFilter: ["Choose Product", "Lenovo 3rd Generation", "Nike Jordan"],
      CategroyFilter: ["Laptop", "Shoe"],
      CategroySubFilter: ["Choose Sub Categroy", "Computers", "Fruits"],
      BrandFilter: ["Lenovo", "Nike"],
      PriceFilter: ["Price", "₦12500.00", "₦12500.00"],
      data: [],
      data_reserve: [],
      search: null,
      columns,
      products_id_array: [],
    };
  },
  methods: {
    ...mapActions({
      _get_products: "product/get_subproducts",
      _delete_product: "product/delete_product",
      _change_status: "product/change_status",
    }),
    // my functions

    get_products() {
      this._get_products()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.data = this.response.data;
            this.data_reserve = this.data;
            console.log(data);
          }
        })
        .catch((error) => {
          console.log("Error logging in", error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;
            if (
              status == 400 ||
              status == 422 ||
              status == 401 ||
              status == 404
            ) {
            } else if (status === 500) {
            }
          }
        });
    },

    showConfirmation(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value == true) {
          this._delete_product(id)
            .then((response) => {
              $(".inloader").hide();
              // toast.success("Product Edited", {
              //   position: toast.POSITION.TOP_RIGHT,
              // });
              this.response = response.data;
              //   this.response
              if (this.response["status"] == true) {
                var data = this.response.data;

                window.location.reload();
              }
            })
            .catch((error) => {
              $(".inloader").hide();

              console.log("Error logging in", error);
              if (error.response) {
                const status = error.response.status;
                const data = error.response.data;
                if (
                  status == 400 ||
                  status == 422 ||
                  status == 401 ||
                  status == 404
                ) {
                  toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                } else if (status === 500) {
                  toast.error(
                    "Network Error, Check your internet conectivity.",
                    {
                      position: toast.POSITION.TOP_RIGHT,
                    }
                  );
                }
              }
            });

          Swal.fire({
            icon: "success",
            title: "Deleted!",
            text: "Your product has been deleted.",
            confirmButtonClass: "btn btn-success",
          });
        }
      });
    },
    view_product_details(data) {
      localStorage.setItem("product_data", JSON.stringify(data));
      window.location = "/inventory/product-details";
    },
    edit_product(data) {
      localStorage.setItem("edit_data", JSON.stringify(data));
      window.location = "/inventory/edit-product";
    },

    change_status(product_status, id) {
      this.products_id_array.push(id);

      if (product_status === "Inactive") {
        $(".inloader").show();
        this._change_status({
          status: "Active",
          products_id: this.products_id_array,
        })
          .then((response) => {
            $(".inloader").hide();
            toast.success("Product is now Active", {
              position: toast.POSITION.TOP_RIGHT,
            });
            window.location.reload();
          })
          .catch((error) => {
            $(".inloader").hide();
            $(`#user${id}`).attr("checked", "");
            console.log("Error logging in", error);
            if (error.response) {
              const status = error.response.status;
              const data = error.response.data;
              if (
                status == 400 ||
                status == 422 ||
                status == 401 ||
                status == 404
              ) {
                toast.error(error.response.data.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              } else if (status === 500) {
                toast.error("Network Error, Check your internet conectivity.", {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }
            }
          });
      }
      if (product_status == "Active") {
        $(".inloader").show();
        this._change_status({
          status: "Inactive",
          products_id: this.products_id_array,
        })
          .then((response) => {
            // alert(`#user${id}`)
            $(".inloader").hide();
            toast.success("Product is now Inactive", {
              position: toast.POSITION.TOP_RIGHT,
            });
            window.location.reload();
          })
          .catch((error) => {
            $(".inloader").hide();
            $(`#user${id}`).attr("checked", "");
            console.log("Error logging in", error);
            if (error.response) {
              const status = error.response.status;
              const data = error.response.data;
              if (
                status == 400 ||
                status == 422 ||
                status == 401 ||
                status == 404
              ) {
                toast.error(error.response.data.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              } else if (status === 500) {
                toast.error("Network Error, Check your internet conectivity.", {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }
            }
          });
      }
    },

    searchtrigger() {
      const searchFields = [
        "original_price",
        "selling_price",
        "id",
        "title",
        "description"
      ];
      this.data = searchList(this.data_reserve, this.search, searchFields);
      console.log(this.data);
    },
  },
  mounted() {
    this.get_products();
  },
};
</script>
<style scoped></style>
