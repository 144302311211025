<script>
const columns = [
  {
    sorter: true,
  },
  {
    title: "Product",
    key: "productname",
    sorter: {
      compare: (a, b) => {
        a = a.product.toLowerCase();
        b = b.product.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Quantity",
    key: "productq",
    sorter: {
      compare: (a, b) => {
        a = a.product.toLowerCase();
        b = b.product.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Date of Refund",
    key: "decision_at",
    sorter: {
      compare: (a, b) => {
        a = a.decision_at.toLowerCase();
        b = b.decision_at.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Refund Status",
    dataIndex: "status",
    key: "status",
    sorter: {
      compare: (a, b) => {
        a = a.status.toLowerCase();
        b = b.status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Amount",
    // dataIndex: "amount",
    key: "amount",
    sorter: {
      compare: (a, b) => {
        a = a.amount.toLowerCase();
        b = b.amount.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "User",
    // dataIndex: "amount",
    key: "buyer",
    sorter: {
      compare: (a, b) => {
        a = a.buyer.toLowerCase();
        b = b.buyer.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },

  {
    title: "Action",
    key: "action",
    sorter: true,
  },
];

import { mapActions } from "vuex";
import { orderStatus } from "../../../constants/enums";

export default {
  data() {
    return {
      filter: false,
      columns,
      data: [],
      Sortby: ["Sort by Date", "Newest", "Oldest"],
      Best: [
        "Choose Supplier Name",
        "Apex Computers",
        "Beats Headphones",
        "Dazzle Shoes",
        "Best Accessories",
      ],
      Status: ["Choose Status", "Received", "Ordered", "Pending"],
      Enter: ["Enter Reference", "PT001", "PT002", "PT003"],
      Paid: ["Choose Payment Status", "Paid", "Partial", "Unpaid"],
      comment: null,
      temp_id: null,
    };
  },
  methods: {
    ...mapActions({
      _refund_action: "general/refund_action",
      _get_refund: "general/get_refund",
    }),
    get_refund() {
      const status = orderStatus.cancelled;
      this._get_refund()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.data = this.response.data;
          }
        })
        .catch((error) => {
          console.log("Error logging in", error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;
            if (
              status == 400 ||
              status == 422 ||
              status == 401 ||
              status == 404
            ) {
            } else if (status === 500) {
            }
          }
        });
    },
    getDatetime(date) {
      if (date) {
        let datetime = new Date(date);
        return `${datetime.toDateString()} ${datetime.toLocaleTimeString()}`;
      } else {
        return "";
      }
    },
    showConfirmation(id, type) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Aprrove",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value == true) {
          this.refund_action(id, type);

          Swal.fire({
            icon: "success",
            title: "Aprroved",
            text: "Request Sent.",
            confirmButtonClass: "btn btn-success",
          });
        }
      });
    },
    refund_action(id, type) {
      this._refund_action({
        refundRequestID: id,
        payload: {
          // request_id: id,
          status: type,
          comment: this.comment,
        },
      })
        .then((response) => {
          $(".inloader").hide();
          this.response = response.data;
          //   this.response
          if (this.response["status"] == true) {
            var data = this.response.data;
            // window.location.reload();
            this.get_refund();
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((error) => {
          $(".inloader").hide();
          console.log("Error logging in", error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;
            if (
              status == 400 ||
              status == 422 ||
              status == 401 ||
              status == 404
            ) {
              toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            } else if (status === 500) {
              toast.error("Network Error, Check your internet conectivity.", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          }
        });
    },
    splitNumbersWithComma(number) {
      // Convert number to string to handle cases like decimal numbers
      if (number) {
        let numStr = number.toString();

        // Split the integer and decimal parts if it's a decimal number
        let parts = numStr.split(".");
        let integerPart = parts[0];
        let decimalPart = parts.length > 1 ? "." + parts[1] : "";

        // Insert commas for thousands separator in the integer part
        let integerWithCommas = integerPart.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );

        // Combine integer part with decimal part (if any)
        let formattedNumber = integerWithCommas + decimalPart;

        return formattedNumber;
      }
    },
    view_purchase_details(id) {
      localStorage.setItem("invoice_id", id);
      window.location = "/inventory/purchase-details";
    },
    toggleCollapse() {
      const collapseHeader = this.$refs.collapseHeader;

      if (collapseHeader) {
        collapseHeader.classList.toggle("active");
        document.body.classList.toggle("header-collapse");
      }
    },
  },
  mounted() {
    this.get_refund();
  },
};
</script>

<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>

  <div class="page-wrapper">
    <div class="content">
      <div class="page-header transfer">
        <div class="add-item d-flex">
          <div class="page-title">
            <h4>Cancelled Order List</h4>
            <h6>Manage cancelled orders</h6>
          </div>
        </div>
        <ul class="table-top-head">
          <li>
            <a data-bs-toggle="tooltip" data-bs-placement="top" title="Pdf"
              ><img src="@/assets/img/icons/pdf.svg" alt="img"
            /></a>
          </li>
          <li>
            <a data-bs-toggle="tooltip" data-bs-placement="top" title="Excel"
              ><img src="@/assets/img/icons/excel.svg" alt="img"
            /></a>
          </li>
          <li>
            <a data-bs-toggle="tooltip" data-bs-placement="top" title="print"
              ><img src="@/assets/img/icons/printer.svg" alt="img"
            /></a>
          </li>
          <li>
            <a data-bs-toggle="tooltip" data-bs-placement="top" title="Refresh"
              ><i data-feather="rotate-ccw" class="feather-rotate-ccw"></i
            ></a>
          </li>
          <li>
            <a
              ref="collapseHeader"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Collapse"
              @click="toggleCollapse"
            >
              <i data-feather="chevron-up" class="feather-chevron-up"></i>
            </a>
          </li>
        </ul>
        <div class="d-flex purchase-pg-btn">
          <div class="page-btn">
            <a
              href="javascript:void(0);"
              class="btn btn-added"
              data-bs-toggle="modal"
              data-bs-target="#add-units"
              ><vue-feather type="plus-circle" class="me-2"></vue-feather>Add
              New Purchase</a
            >
          </div>
          <div class="page-btn import">
            <a
              href="javascript:void(0);"
              class="btn btn-added color"
              data-bs-toggle="modal"
              data-bs-target="#view-notes"
              ><vue-feather type="download" class="me-2"></vue-feather>Import
              Purchase</a
            >
          </div>
        </div>
      </div>

      <!-- /product list -->
      <div class="card table-list-card">
        <div class="card-body">
          <div class="table-top">
            <div class="search-set">
              <div class="search-input">
                <input type="text" placeholder="Search" class="dark-input" />
                <a href="" class="btn btn-searchset"
                  ><i data-feather="search" class="feather-search"></i
                ></a>
              </div>
            </div>
            <div class="search-path">
              <a
                class="btn btn-filter"
                id="filter_search"
                v-on:click="filter = !filter"
                :class="{ setclose: filter }"
              >
                <vue-feather type="filter" class="filter-icon"></vue-feather>
                <span
                  ><img src="@/assets/img/icons/closes.svg" alt="img"
                /></span>
              </a>
            </div>
            <div class="form-sort">
              <vue-feather type="sliders" class="info-img"></vue-feather>
              <vue-select
                :options="Sortby"
                id="Sortby"
                placeholder="Sort by Date"
              />
            </div>
          </div>
          <!-- /Filter -->
          <div
            class="card"
            id="filter_inputs"
            :style="{ display: filter ? 'block' : 'none' }"
          >
            <div class="card-body pb-0">
              <div class="row">
                <div class="col-lg-2 col-sm-6 col-12">
                  <div class="input-blocks">
                    <i data-feather="user" class="info-img"></i>
                    <vue-select
                      :options="Best"
                      id="best"
                      placeholder="Choose Supplier Name"
                    />
                  </div>
                </div>
                <div class="col-lg-2 col-sm-6 col-12">
                  <div class="input-blocks">
                    <i data-feather="stop-circle" class="info-img"></i>
                    <vue-select
                      :options="Status"
                      id="status"
                      placeholder="Choose Status"
                    />
                  </div>
                </div>
                <div class="col-lg-2 col-sm-6 col-12">
                  <div class="input-blocks">
                    <i data-feather="file" class="info-img"></i>
                    <vue-select
                      :options="Enter"
                      id="enter"
                      placeholder="Enter Reference"
                    />
                  </div>
                </div>
                <div class="col-lg-2 col-sm-6 col-12">
                  <div class="input-blocks">
                    <i class="fas fa-money-bill info-img"></i>
                    <vue-select
                      :options="Paid"
                      id="paid"
                      placeholder="Choose Payment Status"
                    />
                  </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12 ms-auto">
                  <div class="input-blocks">
                    <a class="btn btn-filters ms-auto">
                      <i data-feather="search" class="feather-search"></i>
                      Search
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Filter -->
          <div class="table-responsive product-list">
            <a-table :columns="columns" :data-source="data" :row-selection="{}">
              <template #bodyCell="{ column, record }">
                <template v-if="column.key === 'productname'">
                  <div class="productimgname">
                    <a href="javascript:void(0);" class="product-img">
                      <img :src="record.product.image" />
                    </a>

                    <a href="javascript:void(0);">{{ record.product.name }}</a>
                  </div>
                  <!-- <span>{{ record.product.name }}</span> -->
                </template>
                <template v-if="column.key === 'productq'">
                  <div>
                    <span>{{ record.product.quantity }}</span>
                  </div>
                </template>
                <template v-if="column.key === 'decision_at'">
                  <div>
                    <span>{{ getDatetime(record.decision_at) }}</span>
                  </div>
                </template>
               <template v-if="column.key === 'status'">
                  <div>
                    <span
                      class="badges bg-lightyellow"
                      v-if="record.status == 'Processing'"
                      >{{ record.status }}</span
                    >
                    <span
                      class="badges status-badge"
                      v-if="record.status == 'Approved'"
                      >{{ record.status }}</span
                    >
                    <span
                      class="badges unstatus-badge"
                      v-else-if="record.status == 'Rejected'"
                      >{{ record.status }}</span
                    >
                  </div>
                </template>
                <template v-if="column.key === 'amount'">
                  <div>
                    <span>
                      ₦ {{ splitNumbersWithComma(record.product.amount) }}</span
                    >
                  </div>
                </template>
                <template v-if="column.key === 'buyer'">
                  <div>
                    <span>{{ record.user.name }}</span>
                  </div>
                </template>
                <template v-else-if="column.key === 'action'">
                  <td class="action-table-data">
                     <span
                      v-if="record.status == 'Rejected'"
                      type="button"
                      class="btn btn-outline-danger"
                      data-bs-toggle="modal"
                      data-bs-target="#TrackingId"
                      @click="
                        {
                          {
                            temp_id = record.id;
                          }
                        }
                      "
                    >
                      Decline
                    </span>
                    <span
                    v-else
                      type="button"
                      class="btn btn-outline-success"
                      @click="showConfirmation(record.id, 'Processing')"
                    >
                      Accept
                    </span>
                   
                  </td>
                </template>
              </template>
            </a-table>
          </div>
        </div>
      </div>
      <!-- /product list -->
    </div>
  </div>

  <!-- Toggle Between Modals -->
  <div class="col-xl-4">
    <!-- Modal -->
    <div
      class="modal fade"
      id="TrackingId"
      aria-hidden="true"
      aria-labelledby="TrackingIdLabel"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="TrackingIdLabel">
              Why do you want to reject this Cancellation
            </h4>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <form class="">
              <div id="input-group-1" role="group" class="form-group">
                <!-- <label id="" for="input-1" class="d-block"
                  >Enter Tracking code</label
                > -->
                <div>
                  <textarea
                    id="textarea"
                    type="text"
                    placeholder="Comment"
                    required="required"
                    aria-required="true"
                    class="form-control"
                    v-model="comment"
                  ></textarea>

                  <!----><!----><!---->
                </div>
              </div>
            </form>
          </div>

          <div class="modal-footer">
            <button
              class="btn btn-primary"
              data-bs-dismiss="modal"
              style="width: 100%"
              @click="refund_action(temp_id, 'Declined')"
            >
              CONFIRM
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- /Modal -->
  </div>
  <!-- /Toggle Between Modals -->
  <InLoader class="inloader" />

  <purchase-list-modal></purchase-list-modal>
</template>
<style scoped>
#textarea {
  height: 100px;
}
</style>
