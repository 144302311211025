<template>
  <div class="main-wrapper login-body">
    <div class="login-wrapper">
      <div class="container">
        <img
          class="img-fluid logo-dark mb-5"
          src="@/assets/img/logo.png"
          alt="Logo"
        />
        <div class="loginbox">
          <div class="login-right">
            <div class="login-right-wrap">
              <div class="login-info">
                <!-- <p class="account-subtitle">Welcome back!</p>
                <img src="@/assets/img/login-user.png" class="img-fluid" alt="User-Img" />
                <h5>John Smilga</h5> -->
              </div>

              <form action="javascript:void(0);">
                <div class="input-blocks">
                  <div class="pass-group mb-3">
                    <input
                      type="text"
                      class="form-control pass-input"
                      placeholder="Enter your password"
                      v-model="otp"
                    />
                    <!-- <span class="fas toggle-password fa-eye-slash"></span> -->
                  </div>
                  <vue-select
                    v-if="needs2SelectStore"
                    type="text"
                    class="pass-input"
                    placeholder="Select Store"
                    :options="stores"
                    v-model="selectedStoreId"
                  />
                  <!-- <span class="fas toggle-password fa-eye-slash"></span> -->
                </div>
                <button
                  class="btn btn-lg btn-block btn-primary"
                  @click="viewDashboard()"
                  v-if="showDashboardBtn"
                  :disabled="!selectedStoreId"
                >
                  <span id="btntext">View Dashboard</span>
                </button>
                <div
                  class="btn btn-lg btn-block btn-primary"
                  @click="verify()"
                  v-else
                >
                  <span id="btntext">Login</span>
                  <div id="samall_loader"></div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="row">
          <ul class="terms d-flex">
            <li>Terms & Condition</li>
            <li>Privacy</li>
            <li>Help</li>
            <li class="has-submenu">
              <a href="javascript:void(0);"
                >English <i class="fas fa-chevron-down"></i
              ></a>
              <ul class="submenu dropdown-menu">
                <li><a href="javascript:">American</a></li>
                <li><a href="javascript:">British</a></li>
              </ul>
            </li>
          </ul>
          <div
            class="my-4 d-flex justify-content-center align-items-center copyright-text"
          >
            <p>
              Copyright &copy; {{ new Date().getFullYear() }} Naija Kobo Market.
              All rights reserved
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import $ from 'jquery'
import { mapActions } from "vuex";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { ApiResponseCodes } from "@/utils/response_codes";
export default {
  data() {
    return {
      otp: "",
      stores: [],
      needs2SelectStore: false,
      showDashboardBtn: false,
      selectedStoreId: null,
    };
  },
  methods: {
    ...mapActions({
      _verify: "general/verify",
    }),
    viewDashboard() {
      if (this.selectedStoreId) {
        localStorage.setItem("store_id", this.selectedStoreId);
      }
      window.location = "/dashboard/sales-dashboard";
    },
    verify() {
      document.getElementById("samall_loader").style.display = "inline-block";
      $("#btntext").hide();

      this._verify({
        platform: "Web",
        code: this.otp,
      })
        .then((response) => {
          toast.success("Login successful", {
            position: toast.POSITION.TOP_RIGHT,
          });
          document.getElementById("samall_loader").style.display = "none";
          $("#btntext").show();
          this.response = response.data;
          //   this.response
          if (this.response["status"] == true) {
            var data = this.response;
            var token = data.token;
            const profile = data.data;
            localStorage.setItem("accessToken", token);
            localStorage.setItem("data", JSON.stringify(profile));

            // this.$toast.success("verify successful");

            // window.location = "/dashboard/admin-dashboard";
            if (response.data.code == ApiResponseCodes.SEND_STORE_ID) {
              this.stores = profile.stores.map((store) => ({
                id: store.id,
                text: store.name,
              }));
              this.needs2SelectStore = true;
              this.showDashboardBtn = true;
            } else {
              this.viewDashboard();
            }
          }
        })
        .catch((error) => {
          document.getElementById("samall_loader").style.display = "none";
          $("#btntext").show();
          console.log("Error logging in", error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;
            // localStorage.setItem("email", data.email);
            if (
              status == 400 ||
              status == 422 ||
              status == 401 ||
              status == 404
            ) {
              toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            } else if (status === 500) {
              toast.error("Network Error, Check your internet conectivity.", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          }
        });
    },
  },
  mounted() {
    $(".modal").modal("hide");
  },
};
</script>

<style>
#samall_loader {
  display: none;
  border: 6px solid #f3f3f3;
  border-top: 4px solid #00afef;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}
</style>
