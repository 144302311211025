<template>
  <div class="cont">
    <layout-header></layout-header>
    <layout-sidebar></layout-sidebar>
    <InLoader class="inloader" />

    <div class="page-wrapper">
      <div class="content">
        <div class="page-header">
          <div class="add-item d-flex">
            <div class="page-title">
              <h4>Edit Product</h4>
              <!-- <h6>Create new product</h6> -->
            </div>
          </div>
          <ul class="table-top-head">
            <li>
              <div class="page-btn">
                <router-link
                  to="/inventory/product-list"
                  class="btn btn-secondary"
                  ><vue-feather type="arrow-left" class="me-2"></vue-feather
                  >Back to Product
                </router-link>
              </div>
            </li>

            <li>
              <a
                ref="collapseHeader"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Collapse"
                @click="toggleCollapse"
              >
                <i data-feather="chevron-up" class="feather-chevron-up"></i>
              </a>
            </li>
          </ul>
        </div>
        <!-- /add -->
        <form @submit.prevent="submitForm">
          <div class="card">
            <div class="card-body add-product pb-0">
              <div class="accordion-card-one accordion" id="accordionExample">
                <div class="accordion-item">
                  <div class="accordion-header" id="headingOne">
                    <div
                      class="accordion-button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-controls="collapseOne"
                    >
                      <div class="addproduct-icon">
                        <h5>
                          <vue-feather
                            type="info"
                            class="add-info"
                          ></vue-feather
                          ><span>Product Information</span>
                        </h5>
                        <a href="javascript:void(0);"
                          ><vue-feather
                            type="chevron-down"
                            class="chevron-down-add"
                          ></vue-feather
                        ></a>
                      </div>
                    </div>
                  </div>
                  <div
                    id="collapseOne"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <div class="row">
                        <div class="col-lg-4 col-sm-6 col-12" hidden>
                          <div class="mb-3 add-product">
                            <label class="form-label">Store</label>
                            <vue-select
                              :options="ThomasStore"
                              id="thomasstore"
                              placeholder="Choose"
                            />
                          </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-12" hidden>
                          <div class="mb-3 add-product">
                            <label class="form-label">Warehouse</label>
                            <vue-select
                              :options="WarhouseStore"
                              id="warehousestore"
                              placeholder="Choose"
                            />
                          </div>
                        </div>

                        <div class="col-lg-4 col-sm-6 col-12">
                          <div class="mb-3 add-product">
                            <label class="form-label">Product Name</label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="retrieved_data.title"
                              requ="yes"
                            />
                          </div>
                        </div>

                        <div class="col-lg-4 col-sm-6 col-12" hidden>
                          <div class="input-blocks add-product list">
                            <label>SKU</label>
                            <input
                              type="text"
                              class="form-control list"
                              placeholder="Enter SKU"
                            />
                            <button type="submit" class="btn btn-primaryadd">
                              Generate Code
                            </button>
                          </div>
                        </div>

                        <div class="col-lg-4 col-sm-6 col-12">
                          <div class="mb-3 add-product">
                            <div class="add-newplus">
                              <label class="form-label">Brand</label>
                            </div>
                            <vue-select
                              :options="brands"
                              id="brands"
                              placeholder="Choose"
                              v-model="brand_id"
                              requS="yes"
                            />
                          </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-12" hidden>
                          <div class="mb-3 add-product">
                            <label class="form-label">Selling Type</label>
                            <vue-select
                              :options="selling_type"
                              id="sellingtype"
                              placeholder="Choose"
                              requS="yes"
                            />
                          </div>
                        </div>

                        <div class="col-lg-4 col-sm-6 col-12">
                          <div class="mb-3 add-product">
                            <div class="add-newplus">
                              <label class="form-label">Category</label>
                            </div>
                            <vue-select
                              :options="Category"
                              id="Category"
                              placeholder="Choose"
                              v-model="category_id"
                              requS="yes"
                            />
                          </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-12">
                          <div class="mb-3 add-product">
                            <label class="form-label">Sub Category </label>
                            <vue-select
                              :options="SubCategory"
                              id="subcategory"
                              placeholder="Choose"
                              v-model="retrieved_data.subcategory.id"
                              requS="yes"
                            />
                          </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-12">
                          <div class="mb-3 add-product">
                            <label class="form-label">Condition</label>
                            <vue-select
                              :options="Choosecondition"
                              id="choosecode"
                              placeholder="Choose"
                              v-model="retrieved_data.condition"
                              requS="yes"
                            />
                          </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-12" hidden>
                          <div class="mb-3 add-product">
                            <label class="form-label">Negotiable</label>
                            <vue-select
                              :options="Negotiable"
                              id="subsubcategory"
                              placeholder="Choose"
                              v-model="retrieved_data.negotiable"
                            />
                          </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-12">
                          <div class="mb-3 add-product">
                            <label class="form-label"
                              >Are you shipping from abroad?</label
                            >
                            <vue-select
                              :options="Local"
                              id="local"
                              placeholder="Choose"
                              v-model="retrieved_data.is_local"
                              requS="yes"
                            />
                          </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-12">
                          <div class="mb-3 add-product">
                            <label class="form-label">Preorder</label>
                            <vue-select
                              :options="Preorder"
                              id="preorder"
                              placeholder="Choose"
                              v-model="retrieved_data.pre_order"
                              requS="yes"
                            />
                          </div>
                        </div>
                        <!--                        
                        <div class="col-lg-4 col-sm-6 col-12">
                          <div class="mb-3 add-product">
                            <div class="add-newplus">
                              <label class="form-label">Unit</label>
                              <a
                                href="javascript:void(0);"
                                data-bs-toggle="modal"
                                data-bs-target="#add-unit"
                                ><vue-feather
                                  type="plus-circle"
                                  class="plus-down-add"
                                ></vue-feather
                                ><span>Add New</span></a
                              >
                            </div>
                            <vue-select
                              :options="brandsNew"
                              id="brandsnew"
                              placeholder="Choose"
                            />
                          </div>
                        </div> -->
                      </div>
                    </div>
                    <!-- <div class="row"></div> -->

                    <!-- Editor -->
                    <div class="col-lg-12">
                      <div class="input-blocks add-product list" id="item_code">
                        <button type="submit" class="btn btn-primaryadd">
                          Generate with Stella
                        </button>
                      </div>

                      <div
                        class="input-blocks summer-description-box transfer mb-3"
                      >
                        <label>Description</label>
                        <textarea
                          class="form-control h-100"
                          rows="5"
                          v-model="retrieved_data.description"
                          requ="yes"
                        ></textarea>
                        <p class="mt-1">Maximum 60 Characters</p>
                      </div>
                    </div>
                    <!-- /Editor -->
                  </div>
                </div>
              </div>
              <div class="accordion-card-one accordion" id="accordionExample2">
                <div class="accordion-item">
                  <div class="accordion-header" id="headingTwo">
                    <div
                      class="accordion-button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-controls="collapseTwo"
                    >
                      <div class="text-editor add-list">
                        <div class="addproduct-icon list icon">
                          <h5>
                            <vue-feather
                              type="life-buoy"
                              class="add-info"
                            ></vue-feather
                            ><span>Pricing & Stocks</span>
                          </h5>
                          <a href="javascript:void(0);"
                            ><vue-feather
                              type="chevron-down"
                              class="chevron-down-add"
                            ></vue-feather
                          ></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="collapseTwo"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample2"
                  >
                    <div class="accordion-body">
                      <div class="input-blocks add-products">
                        <label class="d-block">Product Type</label>
                        <div class="single-pill-product">
                          <ul
                            class="nav nav-pills"
                            id="pills-tab1"
                            role="tablist"
                          >
                            <li class="nav-item" role="presentation">
                              <span
                                class="custom_radio me-4 mb-0 active"
                                id="pills-home-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-home"
                                role="tab"
                                aria-controls="pills-home"
                                aria-selected="true"
                              >
                                <input
                                  type="radio"
                                  class="form-control"
                                  name="payment"
                                  requ="yes"
                                />
                                <span class="checkmark"></span> Single
                                Product</span
                              >
                            </li>
                            <li class="nav-item" role="presentation">
                              <span
                                class="custom_radio me-2 mb-0"
                                id="pills-profile-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-profile"
                                role="tab"
                                aria-controls="pills-profile"
                                aria-selected="false"
                              >
                                <input
                                  type="radio"
                                  class="form-control"
                                  name="sign"
                                  requ="yes"
                                />
                                <span class="checkmark"></span> Variable
                                Product</span
                              >
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="tab-content" id="pills-tabContent">
                        <div
                          class="tab-pane fade show active"
                          id="pills-home"
                          role="tabpanel"
                          aria-labelledby="pills-home-tab"
                        >
                          <div class="row">
                            <div class="col-lg-4 col-sm-6 col-12">
                              <div class="input-blocks add-product">
                                <label>Price</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="retrieved_data.original_price"
                                  requ="yes"
                                  @keydown="preventNonNumeric"
                                />
                              </div>
                            </div>
                            <div class="col-lg-4 col-sm-6 col-12">
                              <div class="input-blocks add-product">
                                <label>Seling Price</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="retrieved_data.selling_price"
                                  requ="yes"
                                  @keydown="preventNonNumeric"
                                />
                              </div>
                            </div>
                            <div class="col-lg-4 col-sm-6 col-12">
                              <div class="input-blocks add-product">
                                <label>Quantity</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="retrieved_data.quantity"
                                  requ="yes"
                                  @keydown="preventNonNumeric"
                                />
                              </div>
                            </div>
                            <div class="col-lg-4 col-sm-6 col-12" hidden>
                              <div class="input-blocks add-product">
                                <label>Quantity Alert</label>
                                <input type="text" class="form-control" />
                              </div>
                            </div>

                            <div class="col-lg-4 col-sm-6 col-12" hidden>
                              <div class="input-blocks add-product">
                                <label>Product Voucher</label>
                                <vue-select
                                  :options="ChooseTypeDis"
                                  id="choosetypedis"
                                  placeholder="Choose"
                                />
                              </div>
                            </div>
                            <div class="col-lg-4 col-sm-6 col-12" hidden>
                              <div class="input-blocks add-product">
                                <label>Discount Value</label>
                                <input type="text" placeholder="Choose" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="tab-pane fade"
                          id="pills-profile"
                          role="tabpanel"
                          aria-labelledby="pills-profile-tab"
                        >
                          <div class="row select-color-add">
                            <div class="col-lg-6 col-sm-6 col-12">
                              <div class="input-blocks add-product">
                                <label>Variant Attribute</label>
                                <div class="row">
                                  <!-- <div class="col-lg-10 col-sm-10 col-10">
                                  <vue-select
                                    :options="VariantChoose"
                                    id="varianchoose"
                                    placeholder="Choose"
                                  />
                                </div> -->
                                  <div class="col-lg-2 col-sm-2 col-2 ps-0">
                                    <div class="add-icon tab">
                                      <a
                                        class="btn btn-filter"
                                        data-bs-toggle="modal"
                                        data-bs-target="#add-units"
                                        ><i
                                          class="feather feather-plus-circle"
                                        ></i
                                      ></a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- {{ color_images }}
                          {{ retrieved_data.variant.data }} -->
                          <div class="modal-body-table" id="variant-table">
                            <div class="table-responsive">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th></th>
                                    <th>Variation</th>
                                    <th>Variant Value</th>
                                    <!-- <th>SKU</th> -->
                                    <th>Quantity</th>
                                    <th>Price</th>
                                    <th>Selling Price</th>
                                    <th class="no-sort">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="added_variants in retrieved_data
                                      .variant.data"
                                    :key="added_variants"
                                  >
                                    <td>
                                      <div>
                                        <!-- {{ getVariantValue(added_variants.values, 'Color') }} -->
                                        <img
                                          :src="added_variants.thumbnail"
                                          alt=""
                                          :id="`variant_thumbnail${getVariantValue(
                                            added_variants.values,
                                            'Color'
                                          )}`"
                                          :class="`variant_thumbnail${getVariantValue(
                                            added_variants.values,
                                            'Color'
                                          )}`"
                                          class="variant_pre_img"
                                          @click="
                                            listimg(
                                              getVariantValue(
                                                added_variants.values,
                                                'Color'
                                              )
                                            )
                                          "
                                        />
                                        <span
                                          v-if="!added_variants.thumbnail"
                                          class="select_img_input"
                                          @click="
                                            listimg(
                                              getVariantValue(
                                                added_variants.values,
                                                'Color'
                                              )
                                            )
                                          "
                                          :id="`selector${getVariantValue(
                                            added_variants.values,
                                            'Color'
                                          )}`"
                                          >Add Image</span
                                        >
                                      </div>
                                    </td>
                                    <td>
                                      <div
                                        class="add-product"
                                        v-for="v in added_variants.values"
                                        :key="v"
                                      >
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="v.key"
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      <div
                                        class="add-product"
                                        v-for="v in added_variants.values"
                                        :key="v"
                                      >
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="v.value"
                                        />
                                      </div>
                                    </td>
                                    <!-- <td>
                                    <div class="add-product">
                                      <input
                                        type="text"
                                        class="form-control"
                                        value="1234"
                                      />
                                    </div>
                                  </td> -->
                                    <td>
                                      <div class="product-quantity">
                                        <span class="quantity-btn"
                                          ><i
                                            data-feather="minus-circle"
                                            class="feather-search"
                                          ></i
                                        ></span>
                                        <input
                                          type="text"
                                          class="quntity-input"
                                          v-model="added_variants.quantity"
                                          @keydown="preventNonNumeric"
                                        />
                                        <span class="quantity-btn"
                                          >+<i
                                            data-feather="plus-circle"
                                            class="plus-circle"
                                          ></i
                                        ></span>
                                      </div>
                                    </td>
                                    <td>
                                      <div class="add-product">
                                        <input
                                          type="text"
                                          class="form-control"
                                          @keydown="preventNonNumeric"
                                          v-model="added_variants.price"
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      <div class="add-product">
                                        <input
                                          type="text"
                                          class="form-control"
                                          @keydown="preventNonNumeric"
                                          v-model="added_variants.selling_price"
                                        />
                                      </div>
                                    </td>
                                    <td class="action-table-data">
                                      <div
                                        class="edit-delete-action add-product"
                                        style="
                                          padding-bottom: 40px;
                                          padding-top: 40px;
                                        "
                                      >
                                        <!-- <div class="input-block add-lists">
                                          <label class="checkboxs">
                                            <input type="checkbox" checked />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <a
                                          class="me-2 p-2"
                                          href="javascript:void(0);"
                                          data-bs-toggle="modal"
                                          data-bs-target="#add-variation"
                                        >
                                          <i
                                            data-feather="plus"
                                            class="feather-edit"
                                          ></i>
                                        </a> -->
                                        <a
                                          class="confirm-text p-2"
                                          @click="showConfirmation"
                                          href="javascript:void(0);"
                                        >
                                          <i
                                            data-feather="trash-2"
                                            class="feather-trash-2"
                                          ></i>
                                        </a>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="accordion-card-one accordion"
                        id="accordionExample3"
                      >
                        <div class="accordion-item">
                          <div class="accordion-header" id="headingThree">
                            <div
                              class="accordion-button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseThree"
                              aria-controls="collapseThree"
                            >
                              <div class="addproduct-icon list">
                                <h5>
                                  <vue-feather
                                    type="image"
                                    class="add-info"
                                  ></vue-feather
                                  ><span>Images</span>
                                </h5>
                                <a href="javascript:void(0);"
                                  ><vue-feather
                                    type="chevron-down"
                                    class="chevron-down-add"
                                  ></vue-feather
                                ></a>
                              </div>
                            </div>
                          </div>
                          <div
                            id="collapseThree"
                            class="accordion-collapse collapse show"
                            aria-labelledby="headingThree"
                            data-bs-parent="#accordionExample3"
                          >
                            <div class="accordion-body">
                              <div class="text-editor add-list add">
                                <div class="col-lg-12">
                                  <div class="add-choosen">
                                    <div class="input-blocks">
                                      <button
                                        type="button"
                                        @click="cropper_opener('single')"
                                      >
                                        <div class="image-upload">
                                          <!-- <input
                                            type="file"
                                            id="upload_Thumbnail"
                                            class="upload_Thumbnail"
                                            k="single"
                                          /> -->
                                          <div class="image-uploads">
                                            <i
                                              data-feather="plus-circle"
                                              class="plus-down-add me-0"
                                            ></i>
                                            <h4>Add thumbnail</h4>
                                          </div>
                                        </div>
                                      </button>
                                    </div>

                                    <div class="phone-img" id="uploadd">
                                      <img
                                        :src="retrieved_data.thumbnail"
                                        alt="image"
                                        id="preview_thumbnail"
                                        style="height: 100%; width: 100%"
                                      />
                                      <a
                                        href="javascript:void(0);"
                                        @click="clearImageInput1()"
                                        ><vue-feather
                                          type="x"
                                          class="x-square-add remove-product"
                                        ></vue-feather
                                      ></a>
                                    </div>
                                  </div>
                                </div>
                                <!-- {{images}} -->

                                <div class="col-lg-12">
                                  <div class="add-choosen">
                                    <div class="input-blocks">
                                      <button type="button">
                                        <div class="image-upload">
                                          <!-- <input
                                            type="file"
                                            id="upload_images"
                                            class="upload_images"
                                            @change="handleImagePick($event)"
                                            multiple
                                            accept="image/*"
                                            k="multiple"
                                          /> -->
                                          <div class="image-uploads">
                                            <i
                                              data-feather="plus-circle"
                                              class="plus-down-add me-0"
                                            ></i>
                                            <h4>uploaded Images</h4>
                                          </div>
                                        </div>
                                      </button>
                                    </div>
                                    <div
                                      class="phone-img"
                                      id="img_upload_prview"
                                      v-for="(image, index) in imagePreviews"
                                      :key="index"
                                    >
                                      <img
                                        :src="image.url"
                                        alt="image"
                                        :id="`preview${image.id}`"
                                        class="multi-image-display"
                                      />
                                      <span
                                        @click="cropper_opener('edit', image)"
                                        class="edit_img"
                                        v-if="image.id.length > 2"
                                        style="
                                          display: inline-block;
                                          margin-right: 20px;
                                        "
                                      >
                                        <span
                                          class="fa fa-edit"
                                          style="font-size: 20px"
                                        ></span>
                                      </span>
                                      <span
                                        class="edit_img"
                                        v-if="image.id.length > 2"
                                        title="upload this image"
                                        @click="edit_img(image.id)"
                                      >
                                        <span
                                          class="fa fa-check"
                                          style="font-size: 20px"
                                        ></span>
                                      </span>

                                      <a
                                        href="javascript:void(0);"
                                        @click="deleteAvatar(index, image.id)"
                                        ><vue-feather
                                          type="x"
                                          class="x-square-add remove-product"
                                        ></vue-feather
                                      ></a>
                                    </div>
                                  </div>
                                </div>

                                <!-- new uploaded images -->
                                <div class="col-lg-12">
                                  <div class="add-choosen">
                                    <div class="input-blocks">
                                      <button
                                        type="button"
                                        @click="cropper_opener('multi')"
                                      >
                                        <div class="image-upload">
                                          <!-- <input
                                            type="file"
                                            id="upload_images"
                                            class="upload_images"
                                            @change="handleImagePick($event)"
                                            multiple
                                            accept="image/*"
                                            k="multiple"
                                          /> -->
                                          <div class="image-uploads">
                                            <i
                                              data-feather="plus-circle"
                                              class="plus-down-add me-0"
                                            ></i>
                                            <h4>Add (5) Images</h4>
                                          </div>
                                        </div>
                                      </button>
                                    </div>
                                    <div
                                      class="phone-img"
                                      id="img_upload_prview"
                                      v-for="(image, index) in imagePreviews2"
                                      :key="index"
                                    >
                                      <img
                                        :src="image.url"
                                        alt="image"
                                        id="preview"
                                        class="multi-image-display"
                                      />

                                      <a
                                        href="javascript:void(0);"
                                        @click="deleteAvatar2(index)"
                                        ><vue-feather
                                          type="x"
                                          class="x-square-add remove-product"
                                        ></vue-feather
                                      ></a>
                                    </div>
                                  </div>
                                  <button
                                    type="button"
                                    class="btn btn-primary me-2"
                                    @click="send_new_img()"
                                  >
                                    Upload images
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- copied -->
              <div class="accordion-card-one accordion" id="accordionExample5">
                <div class="accordion-item">
                  <div class="accordion-header" id="headingFive">
                    <div
                      class="accordion-button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-controls="collapseFive"
                    >
                      <div class="text-editor add-list">
                        <div class="addproduct-icon list icon">
                          <h5>
                            <vue-feather
                              type="box"
                              class="add-info"
                            ></vue-feather
                            ><span>Package and Shipping</span>
                          </h5>
                          <a href="javascript:void(0);"
                            ><vue-feather
                              type="chevron-down"
                              class="chevron-down-add"
                            ></vue-feather
                          ></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="collapseFive"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingFive"
                    data-bs-parent="#accordionExample5"
                  >
                    <div class="accordion-body">
                      <div class="input-blocks add-products">
                        <label class="d-block">Shippment Type</label>
                        <div class="single-pill-product">
                          <ul
                            class="nav nav-pills"
                            id="pills-tab1"
                            role="tablist"
                          >
                            <li class="nav-item" role="presentation">
                              <span
                                class="custom_radio me-4 mb-0 active gig"
                                id="pills-home-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-home"
                                role="tab"
                                aria-controls="pills-home"
                                aria-selected="true"
                                @click="hide_shipping(false)"
                              >
                                <input
                                  type="radio"
                                  class="form-control"
                                  name="payment"
                                  requ="yes"
                                />
                                <span class="checkmark"></span> GIG</span
                              >
                            </li>
                            <li class="nav-item" role="presentation">
                              <span
                                class="custom_radio me-2 mb-0 bus_driver"
                                id="bus-driver-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#bus-driver"
                                role="tab"
                                aria-controls=""
                                aria-selected="true"
                                @click="hide_shipping(false)"
                              >
                                <input
                                  type="radio"
                                  class="form-control"
                                  name="sign"
                                  requ="yes"
                                />
                                <span class="checkmark"></span> Bus Driver</span
                              >
                            </li>
                            <li class="nav-item" role="presentation">
                              <span
                                class="custom_radio me-4 mb-0 shipping"
                                id="shipping-tab"
                                data-bs-toggle="pill"
                                role="tab"
                                @click="hide_shipping(true)"
                              >
                                <input
                                  type="radio"
                                  class="form-control"
                                  name="sign"
                                  v-model="retrieved_data.free_shipping"
                                  value="true"
                                  requ="yes"
                                />
                                <span class="checkmark"></span> Free
                                Shpping</span
                              >
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div
                        class="tab-content"
                        id="pills-tabContent"
                        v-if="!retrieved_data.free_shipping"
                      >
                        <div
                          class="tab-pane fade show active"
                          id="pills-home"
                          role="tabpanel"
                          aria-labelledby="pills-home-tab"
                          ref="pills-home"
                        >
                          <div class="row">
                            <div class="col-lg-4 col-sm-6 col-12">
                              <div class="input-blocks add-product">
                                <label>State</label>
                                <vue-select
                                  :options="state_list"
                                  id="chooseState"
                                  placeholder="Choose"
                                  v-model="stateId"
                                  @select="select_Sate_Station()"
                                />
                              </div>
                            </div>
                            <div class="col-lg-4 col-sm-6 col-12">
                              <div class="input-blocks add-product">
                                <label class="station_label">GIG Station</label>
                                <vue-select
                                  :options="New_Choose_GIG_station2"
                                  id="station"
                                  placeholder="Choose"
                                  class="station"
                                  v-model="retrieved_data.gigl_station_id"
                                />
                              </div>
                            </div>

                            <div class="col-lg-4 col-sm-6 col-12">
                              <div class="input-blocks add-product">
                                <label>Weight</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="retrieved_data.weight"
                                  @keydown="preventNonNumeric"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-4 col-sm-6 col-12">
                              <div class="input-blocks add-product">
                                <label>Height</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="retrieved_data.height"
                                  @keydown="preventNonNumeric"
                                />
                              </div>
                            </div>
                            <div class="col-lg-4 col-sm-6 col-12">
                              <div class="input-blocks add-product">
                                <label>Width</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="retrieved_data.width"
                                  @keydown="preventNonNumeric"
                                />
                              </div>
                            </div>
                            <div class="col-lg-4 col-sm-6 col-12">
                              <div class="input-blocks add-product">
                                <label>Length</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="retrieved_data.length"
                                  @keydown="preventNonNumeric"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- copied -->
              <div
                class="accordion-card-one accordion"
                id="accordionExample4"
                hidden
              >
                <div class="accordion-item">
                  <div class="accordion-header" id="headingFour">
                    <div
                      class="accordion-button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-controls="collapseFour"
                    >
                      <div class="text-editor add-list">
                        <div class="addproduct-icon list">
                          <h5>
                            <vue-feather
                              type="list"
                              class="add-info"
                            ></vue-feather
                            ><span>Specificattion</span>
                          </h5>
                          <a href="javascript:void(0);"
                            ><vue-feather
                              type="chevron-down"
                              class="chevron-down-add"
                            ></vue-feather
                          ></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="collapseFour"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample4"
                  >
                    <div class="accordion-body">
                      <div class="text-editor add-list add">
                        <div class="row rl">
                          <div class="col-lg-2 col-sm-2 col-2 ps-0">
                            <div class="add-icon tab">
                              <a
                                class="btn btn-filter"
                                data-bs-toggle="modal"
                                data-bs-target="#add-units"
                                ><i class="feather feather-plus-circle"></i
                              ></a>
                            </div>
                          </div>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="btn-addproduct mb-4">
              <button type="button" class="btn btn-cancel me-2">Cancel</button>

              <!-- <div class="btn btn-submit" @click="edit_product('Draft')">
                Save as Draft
              </div> -->
              <div
                class="btn btn-submit btn-success"
                @click="edit_product('Pending')"
                v-if="allFilled"
              >
                Edit Product
              </div>

              <div
                @click="preview()"
                class="btn btn-primary mb-1"
                v-if="imagePreviews.length > 0"
              >
                Preview
              </div>
            </div>
          </div>
        </form>
        <!-- /add -->
      </div>
    </div>
    <add-product-modal
      @Emitter="P_emmiter"
      :variant_imgs_child="variant_imgs"
      :returnedVariants="retrieved_data.variant.data"
    ></add-product-modal>

    <ImageCropper />
    <FreeCropper
      @CroppedImage="get_updated_img"
      :single_image_to_edit="image_to_be_edited"
    />
    <ImageCropperMulti
      @CroppedImageMulti="getMultiimg"
      @CroppedImage="getimg"
      :crop_state="crop_state"
      @CroppedImage_edit="get_updated_img"
      :single_image_to_edit="image_to_be_edited"
    />
    <!-- @images_length="imagePreviews.length" -->

    <!-- this should be a comnination of the two image .. retrieved and added -->
    <ProductPreview :preview="product_preview_object" />

    <ImageCropperVariant @CroppedImageVariant="getvariant" />

    <List_images
      :added_images="{ full_img, color_images, current_colorValue }"
      @list_images="get_list_images"
    />
  </div>
</template>
<script>
import { ref } from "vue";
const currentDate = ref(new Date());
const currentDateOne = ref(new Date());
import Vue3TagsInput from "vue3-tags-input";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import { mapActions } from "vuex";
import state from "@/store/state";
import axios from "axios";

export default {
  components: {
    Vue3TagsInput,
  },
  data() {
    return {
      isVisible: true,
      isVisible1: true,
      // Distype: ["Choose", "Percentage", "Cash"],
      VariantChoose: ["Choose", "Color", "Red", "Black"],
      ChooseTypeDis: ["Choose", "Free Shippment", "Discount"],
      ExclusiveType: ["Exclusive", "Sales Tax"],
      ChooseCode: ["Choose", "Code34", "Code35", "Code36"],
      SellingType: ["Choose", "Transactional selling", "Solution selling"],
      brandsNew: ["Choose", "Kg", "Pc"],
      SubSubCategory: ["Choose", "Fruits", "Computers", "Shoes"],
      WarhouseStore: ["Choose", "Legendary", "Determined", "Sincere"],
      ThomasStore: ["Choose", "Thomas", "Rasmussen", "Fred john"],
      tags: ["red", "black"],
      state_list: ["Choose"],
      Choose_GIG_station: ["Choose"],
      startdate: currentDate,
      startdateOne: currentDateOne,
      dateFormat: "dd-MM-yyyy",
      // my edited data set
      SubCategory: ["Choose"],
      Category: ["Choose"],
      Choosecondition: ["New", "Used", "None"],
      Negotiable: [
        { id: 1, text: "Yes" },
        { id: 0, text: "No" },
      ],
      Local: [
        { id: 1, text: "Yes" },
        { id: 0, text: "No" },
      ],
      Preorder: [
        { id: 1, text: "Yes" },
        { id: 0, text: "No" },
      ],
      current_colorValue: "",
      images: [],
      color_images: [],

      imagePreviews: [],
      imagePreviews2: [],
      allimg_previews: [],
      subcats: [],
      stateId: null,
      New_Choose_GIG_station: [],
      New_Choose_GIG_station2: [],
      variantArray: [],
      product_preview_object: {},
      variant_imgs: {},
      brands: [],
      category_id: "",
      brand_id: "",
      thumbnail: "",
      // is_local:'',
      retrieved_data: JSON.parse(localStorage.getItem("edit_data")),
      // returned_variant_array:[],
      image_to_be_edited: "",
      image_id_replace: "",
      crop_state: "",
    };
  },

  methods: {
    cropper_opener(state, img_obj) {
      this.crop_state = state;
      $("#EditMultiImage").modal("show");
      if (state == "edit") {
        this.edit_this_returned_img(img_obj);
      }
    },
    getVariantValue(valuesArray, key) {
      const valueObject = valuesArray.find((item) => item.key === key);
      return valueObject ? valueObject.value : "";
    },
    sync() {
      this.category_id = this.retrieved_data?.category.id;
      this.retrieved_data.pre_order = this.retrieved_data.pre_order ? 1 : 0;
      this.retrieved_data.is_local = this.retrieved_data ? 1 : 0;
      this.brand_id = this.retrieved_data?.brand?.id;
      this.retrieved_data.free_shipping = this.retrieved_data.free_shipping
        ? 1
        : 0;
      this.imagePreviews = this.retrieved_data.images;
      // processing retirved images here and converting it to blob
    },
    loadSubcat() {
      this.SubCategory = this.Category.find(
        (category) => category.id == this.category_id
      )?.subcategories;
    },
    modify_variant_array() {
      // Loop through each variant data object
      this.retrieved_data.variant.data.forEach((dataObj) => {
        // Find the color value in the values array
        const colorValue = dataObj.values.find(
          (value) => value.key === "Color"
        ).value;

        // Find the matching color image
        const matchingImage = this.retrieved_data.variant.color_images.find(
          (imageObj) => imageObj.color === colorValue
        );

        // If a matching image is found, set it as the thumbnail
        if (matchingImage) {
          dataObj.thumbnail = matchingImage.image;
        }
      });
    },

    get_list_images(message) {
      const { remainingBlobs, remainingimgpreview, added_color_images } =
        message;
      this.images = remainingBlobs;
      this.imagePreviews = remainingimgpreview;
      this.color_images = added_color_images;
    },
    validateForm() {
      const inputs = document.querySelectorAll('[requS="yes"],[requ="yes"]');
      this.allFilled = true;

      inputs.forEach((input) => {
        // console.log(inputs);
        if (input.type === "text") {
          if (input.value.trim() === "") {
            this.allFilled = false;
          }
        } else if (input.tagName === "SELECT") {
          if (input.value === "") {
            this.allFilled = false;
          }
        }
      });
    },

    // handleImagePick(event) {
    //   const files = event.target.files;
    //   for (const file of files) {
    //     this.images.push(file);
    //     const preview = URL.createObjectURL(file);
    //     this.imagePreviews.push({ img: preview });
    //   }
    // },
    image_procesor(image) {
      if (image) {
        const imageURL = URL.createObjectURL(image);
        return imageURL;
      }
    },
    preview() {
      const array = {
        description: this.retrieved_data.description,
        image: this.retrieved_data.thumbnail,
        imagePreviews: this.imagePreviews,
        name: this.retrieved_data.title,
        price: this.retrieved_data.original_price,
        selling_price: this.retrieved_data.selling_price,
        quantity: this.retrieved_data.quantity,
        specification: this.retrieved_data.specification,
        weight: this.retrieved_data.weight,
      };

      this.product_preview_object = {
        main_product: array,
        color_images: this.retrieved_data.color_images,
      };
      $("#previewModal").modal("show");
      console.log(this.product_preview_object);
    },
    listimg(colorValue) {
      // alert(colorValue);

      $("#List_images").modal("show");
      this.current_colorValue = colorValue;
    },
    getimg(img) {
      const { image, imageBlob } = img;
      this.thumbnail = imageBlob;
      this.retrieved_data.thumbnail = image;
    },
    getMultiimg(payload) {
      const { croppedImg, blobImg, full_img } = payload;
      // this.imagePreviews.push(...croppedImg);
      this.imagePreviews2 = croppedImg;
      this.images = blobImg;
      this.full_img = full_img;

      // console.log(this.imagePreviews);
    },
    getvariant(message) {
      // const { croppedImg, blobImg } = message;
      this.variant_imgs = message;
    },
    P_emmiter(message) {
      console.log(message);
      this.retrieved_data.variant.data = message;
    },
    ...mapActions({
      _edit_product: "product/edit_product",
      _get_subcategories: "product/get_subcategories",
      _get_states: "product/get_states",
      _get_brands: "product/get_brands",
      _get_gig: "product/get_gig",
      _get_variant_keys: "general/get_variant_keys",
      _delete_online_img: "product/delete_online_img",
      _send_new_img: "product/send_new_img",
      _edit_img: "product/edit_img",
    }),
    edit_product(status) {
      const slug = this.retrieved_data.slug;
      this.validateForm();
      if (this.allFilled == true) {
        $(".inloader").show();

        this._edit_product({
          slug: slug,
          payload: {
            condition: this.retrieved_data.condition,
            description: this.retrieved_data.description,
            hasVariants: this.retrieved_data.hasVariants,
            // images: this.images,
            name: this.retrieved_data.title,
            negotiable: this.retrieved_data.negotiable,
            pre_order: this.retrieved_data.pre_order,
            price: this.retrieved_data.original_price,
            selling_price: this.retrieved_data.selling_price,
            quantity: this.retrieved_data.quantity,
            specification: this.specification,
            status: status,
            subcategory_id: this.retrieved_data.subcategory.id,
            thumbnail: this.thumbnail,
            variants: this.retrieved_data.variants,
            weight: this.retrieved_data.weight,
            height: this.retrieved_data.height,
            width: this.retrieved_data.width,
            length: this.retrieved_data.length,
            video: this.retrieved_data.video,
            gigl_station_id: this.retrieved_data.gigl_station_id,
            variants: this.retrieved_data.variant.data,
            color_images: this.color_images,
            brand_id: this.brand_id,
            free_shipping: this.retrieved_data.free_shipping,
            preorder: this.retrieved_data.preorder,
            is_local: this.retrieved_data.is_local,
          },
        })
          .then((response) => {
            $(".inloader").hide();
            toast.success("Product Edited", {
              position: toast.POSITION.TOP_RIGHT,
            });
            this.response = response.data;
            //   this.response
            if (this.response["status"] == true) {
              var data = this.response.data;
              window.location = "/inventory/product-list";
            }
          })
          .catch((error) => {
            $(".inloader").hide();

            console.log("Error logging in", error);
            if (error.response) {
              const status = error.response.status;
              const data = error.response.data;
              if (
                status == 400 ||
                status == 422 ||
                status == 401 ||
                status == 404
              ) {
                toast.error(error.response, {
                  position: toast.POSITION.TOP_RIGHT,
                });
                // error.response.data.message
              } else if (status === 500) {
                toast.error("Network Error, Check your internet conectivity.", {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }
            }
          });
      } else {
        toast.error("Inputs cannot be empty", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    },

    get_subcategories() {
      this._get_subcategories()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            const data = this.response.data;
            console.log(data);
            this.Category = data.map((item) => ({
              id: item.id,
              text: item.title,
              subcategories: item.subcategories.map((subcat) => ({
                id: subcat.id,
                text: subcat.title,
              })),
            }));
            this.loadSubcat();
            console.log(this.Category, "lm");
          }
        })
        .catch((error) => {
          console.log("Error logging in", error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;
            if (
              status == 400 ||
              status == 422 ||
              status == 401 ||
              status == 404
            ) {
            } else if (status === 500) {
            }
          }
        });
    },
    get_states() {
      this._get_states()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            const data = this.response.data;
            console.log(data);
            this.state_list = data.map((item) => ({
              id: item.id,
              text: item.name,
            }));
            // console.log(this.state_list, "lm");
          }
        })
        .catch((error) => {
          console.log("Error logging in", error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;
            if (
              status == 400 ||
              status == 422 ||
              status == 401 ||
              status == 404
            ) {
            } else if (status === 500) {
            }
          }
        });
    },
    get_gig() {
      this._get_gig()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            const data = this.response.data;

            this.Choose_GIG_station = data;
            // this.state_list = data.map((item) => ({
            //   id: item.id,
            //   text: item.title,
            // }));
          }
        })
        .catch((error) => {
          console.log("Error logging in", error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;
            if (
              status == 400 ||
              status == 422 ||
              status == 401 ||
              status == 404
            ) {
            } else if (status === 500) {
            }
          }
        });
    },
    select_Sate_Station() {
      this.New_Choose_GIG_station = this.Choose_GIG_station.filter(
        (item) => item.station.state_id == this.stateId
      );
      // alert(this.New_Choose_GIG_station);
      this.New_Choose_GIG_station2 = this.New_Choose_GIG_station.map(
        (item) => ({
          id: item.station_id,
          text: item.address,
        })
      );
    },

    deleteAvatar(index, id) {
      this.retrieved_data.images.splice(index, 1);
      localStorage.setItem("edit_data", JSON.stringify(this.retrieved_data));
      // this.avatars[index].splice(index, 1);
      for (let i = 0; i < this.images.length; i++) {
        if (i === index) {
          this.images.splice(i, 1);
          break;
        }
      }
      // endpoint to delete image
      this.delete_online_img(id);
    },
    deleteAvatar2(index) {
      this.imagePreviews2.url.splice(index, 1);
      for (let i = 0; i < this.url.length; i++) {
        if (i === index) {
          this.url.splice(i, 1);
          break;
        }
      }
    },

    clearImageInput1() {
      // var input = document.getElementById("upload_Thumbnail");
      // var file = input.files[0];
      // this.thumbnail = file;
      // this.thumbnail = null;
      var dk = document.getElementById("uploadd");
      dk.style = "display:none";
    },

    hide_shipping(bo) {
      if (bo == true) {
        this.retrieved_data.free_shipping = 1;
      } else if (bo == false) {
        this.retrieved_data.free_shipping = 0;
      }
    },

    get_brands() {
      this._get_brands()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            const data = this.response.data;
            console.log(data);
            this.brands = data.map((item) => ({
              id: item.id,
              text: item.name,
            }));
            console.log(this.brands, "lm");
          }
        })
        .catch((error) => {
          console.log("Error logging in", error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;
            if (
              status == 400 ||
              status == 422 ||
              status == 401 ||
              status == 404
            ) {
            } else if (status === 500) {
            }
          }
        });
    },
    toggleCollapse() {
      const collapseHeader = this.$refs.collapseHeader;

      if (collapseHeader) {
        collapseHeader.classList.toggle("active");
        document.body.classList.toggle("header-collapse");
      }
    },

    // advanced functions

    async urlToBlob(url) {
      try {
        console.log(url);

        // Make a GET request to fetch the image as a blob
        const response = await axios.get(url, { responseType: "blob" });

        // The response data will be a Blob
        const blob = response.data;

        // Check if the response is a valid Blob
        if (blob instanceof Blob) {
          return blob;
        } else {
          throw new Error("Response is not a valid Blob.");
        }
      } catch (error) {
        console.error("Error converting URL to Blob:", error);
        return null;
      }
    },
    // editing returned image
    edit_this_returned_img(img_obj) {
      this.image_id_replace = img_obj.id;
      this.urlToBlob(img_obj.url).then((blob) => {
        if (blob) {
          console.log(blob);
          // Convert the Blob to a Base64 string
          this.blobToBase64(blob).then((base64String) => {
            console.log(base64String, "the base64 encoded string");
            this.image_to_be_edited = { img_obj, base64String };
          });
        }
      });
    },

    async blobToBase64(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.onerror = (error) => {
          reject("Error converting Blob to Base64:", error);
        };
        reader.readAsDataURL(blob); // Converts the blob to base64
      });
    },

    get_updated_img(img) {
      const { image, imageBlob } = img;
      this.image_edited = imageBlob;

      var preview = document.getElementById(`preview${this.image_id_replace}`);
      preview.src = image;
    },
    delete_online_img(imageId) {
      $(".inloader").show();
      this._delete_online_img({
        productId: this.retrieved_data.id,
        imageId: imageId,
      })
        .then((response) => {
          $(".inloader").hide();
          toast.success("Image Deleted", {
            position: toast.POSITION.TOP_RIGHT,
          });
          this.response = response.data;
          if (this.response["status"] == true) {
            this.data = this.response.data;
            console.log(data);
          }
        })
        .catch((error) => {
          $(".inloader").hide();
          console.log("Error logging in", error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;
            toast.success(data, {
              position: toast.POSITION.TOP_RIGHT,
            });
            if (
              status == 400 ||
              status == 422 ||
              status == 401 ||
              status == 404
            ) {
            } else if (status === 500) {
            }
          }
        });
    },
    send_new_img() {
      $(".inloader").show();
      this._send_new_img({
        productId: this.retrieved_data.id,
        payload: { images: this.images },
      })
        .then((response) => {
          $(".inloader").hide();
          this.imagePreviews.push(...this.imagePreviews2);
          this.imagePreviews2 = [];
          this.retrieved_data.images = this.imagePreviews;
          localStorage.setItem(
            "edit_data",
            JSON.stringify(this.retrieved_data)
          );

          toast.success("Image(s) Uploaded)", {
            position: toast.POSITION.TOP_RIGHT,
          });

          this.response = response.data;
          if (this.response["status"] == true) {
            this.data = this.response.data;
            console.log(data);
          }
        })
        .catch((error) => {
          $(".inloader").hide();

          console.log("Error logging in", error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;
            if (
              status == 400 ||
              status == 422 ||
              status == 401 ||
              status == 404
            ) {
              toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            } else if (status === 500) {
            }
          }
        });
    },
    edit_img(imageId) {
      $(".inloader").show();
      this._edit_img({
        productId: this.retrieved_data.id,
        imageId: imageId,
        payload: { image: this.image_edited },
      })
        .then((response) => {
          $(".inloader").hide();
          toast.success("Image(s) Uploaded)", {
            position: toast.POSITION.TOP_RIGHT,
          });
          this.response = response.data;
          if (this.response["status"] == true) {
            this.data = this.response.data;
            console.log(data);
          }
        })
        .catch((error) => {
          $(".inloader").hide();

          console.log("Error logging in", error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;
            if (
              status == 400 ||
              status == 422 ||
              status == 401 ||
              status == 404
            ) {
            } else if (status === 500) {
            }
          }
        });
    },

    preventNonNumeric(event) {
      // Allow only number keys (0-9) and special keys (Backspace, Delete, Arrow keys)
      const allowedKeys = [
        "Backspace",
        "Delete",
        "ArrowLeft",
        "ArrowRight",
        "Tab",
      ];

      if (
        (event.key < "0" || event.key > "9") &&
        !allowedKeys.includes(event.key)
      ) {
        event.preventDefault(); // Block non-numeric keys
      }
    },
  },

  mounted() {
    this.get_states();
    this.get_subcategories();
    this.sync();

    this.get_brands();
    // this.loadFileInputs();
    this.get_gig();
    this.modify_variant_array();

    $(".bus_driver").click(function () {
      $("#station").attr("disabled", "disabled");
      $(".station_label").text("Station");
    });
    $(".gig").click(function () {
      $("#station").removeAttr("disabled");
      $(".station_label").text("GIG Station");
    });

    $(".bus_driver").click(function () {
      $("#station").attr("disabled", "disabled");
    });

    const inputs = document.querySelectorAll("input, select");

    inputs.forEach((input) => {
      input.addEventListener("input", this.validateForm);
      input.addEventListener("change", this.validateForm);
    });

    // Initial validation check
    this.validateForm();
  },
  watch: {
    category_id: {
      handler(newId) {
        this.loadSubcat();
      },
      immediate: true,
    },
    //  stateId: {
    //   handler(newId) {
    //     this.select_Sate_Station() ;
    //   },
    //   immediate: true,
    // },
  },
};
</script>
<style>
#item_code {
  position: relative;
  z-index: 999;
  left: 87.2%;
  width: 200px;
  cursor: pointer;
}
.rl {
  padding-left: 10px;
}
/* #uploadd {
  display: none;
} */
.btn-submit {
  margin-right: 5px;
}
.add-icon {
  margin-left: 10%;
}

button {
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: white;
}
.multi-image-display {
  height: 100%;
  width: 100%;
}

.variant_pre_img {
  width: 80px; /* Adjust the size as needed */
  height: 60px; /* Adjust the size as needed */
  object-fit: cover; /* Ensure the image covers the entire area without distortion */
  border-radius: 8px; /* Optional: round the corners of the images */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: add a subtle shadow for a nicer look */
  /* display: none; */
  cursor: pointer;
}
/* #upload_variant {
  display: none;
} */
.select_img_input {
  cursor: pointer;
}
</style>
