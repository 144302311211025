<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  <div class="page-wrapper">
    <div class="content">
      <div class="page-header">
        <div class="page-title">
          <h4>Profile</h4>
          <h6>User Profile</h6>
        </div>
      </div>
      <!-- /product list -->
      <div class="card">
        <div class="card-body">
          <div class="profile-set">
            <div class="profile-head"></div>
            <div class="profile-top">
              <div class="profile-content">
                <div class="store_name">{{ data.profile?.store_name }}</div>

                <div class="profile-contentimg">
                  <img :src="data?.avatar" alt="img" id="blah" />
                  <div class="profileupload">
                    <input type="file" id="imgInp" />
                    <a href="javascript:void(0);"
                      ><img src="@/assets/img/icons/edit-set.svg" alt="img"
                    /></a>
                  </div>
                </div>
                <div class="profile-contentname">
                  <h2>
                    {{ data?.surname }} {{ data?.other_names }}
                  </h2>
                  <h4>Updates Your Photo and Personal Details.</h4>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-sm-12">
              <div class="input-blocks">
                <label class="form-label">First Name</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="data.surname"
                  disabled
                />
              </div>
            </div>
            <div class="col-lg-6 col-sm-12">
              <div class="input-blocks">
                <label class="form-label">Last Name</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="data.other_names"
                  disabled
                />
              </div>
            </div>
            <div class="col-lg-6 col-sm-12">
              <div class="input-blocks">
                <label>Email</label>
                <input
                  type="email"
                  class="form-control"
                  v-model="data.email"
                  disabled
                />
              </div>
            </div>
            <div class="col-lg-6 col-sm-12">
              <div class="input-blocks">
                <label class="form-label">Phone</label>
                <input
                  type="text"
                  v-model="data.phone_number"
                  disabled
                />
              </div>
            </div>
            <div class="col-lg-6 col-sm-12">
              <div class="input-blocks">
                <label class="form-label">Old Password</label>
                <input
                  type="password"
                  class="form-control"
                  v-model="old_password"
                  autocomplete="off"
                />
              </div>
            </div>
            <div class="col-lg-6 col-sm-12">
              <div class="input-blocks">
                <label class="form-label"
                  >New Password: {{ passwordStrength }}
                  <div class="instruction">
                    {{ password_msg }}
                  </div>
                </label>
                <div class="pass-group">
                  <input
                    :type="showPassword ? 'text' : 'password'"
                    class="pass-input form-control"
                    v-model="new_password"
                  />

                  <span @click="toggleShow" class="toggle-password">
                    <i
                      :class="{
                        'fas fa-eye': showPassword,
                        'fas fa-eye-slash': !showPassword,
                      }"
                    ></i>
                  </span>
                </div>
              </div>

              <div
                class="progress-stacked progress-xl mb-5"
                id="progresstab"
                style="height: 5px"
              >
                <div
                  class="progress-bar bg-danger"
                  role="progressbar"
                  style="width: 20%"
                  aria-valuenow="20"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  v-if="passwordStrength >= 20"
                >
                  <!-- 20% -->
                </div>
                <div
                  class="progress-bar bg-warning"
                  role="progressbar"
                  style="width: 20%"
                  aria-valuenow="40"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  v-if="passwordStrength >= 40"
                >
                  <!-- 40% -->
                </div>
                <div
                  class="progress-bar bg-warning"
                  role="progressbar"
                  style="width: 20%"
                  aria-valuenow="60"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  v-if="passwordStrength >= 60"
                >
                  <!-- 60% -->
                </div>
                <div
                  class="progress-bar bg-success"
                  role="progressbar"
                  style="width: 20%"
                  aria-valuenow="80"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  v-if="passwordStrength >= 80"
                >
                  <!-- 80% -->
                </div>
                <div
                  class="progress-bar bg-success"
                  role="progressbar"
                  style="width: 20%"
                  aria-valuenow="100"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  v-if="passwordStrength >= 100"
                >
                  <!-- 100% -->
                </div>
              </div>
            </div>
            <div class="col-12">
              <div
                class="btn btn-submit me-2 disabled"
                @click="change_password()"
                id="submit"
              >
                change password
              </div>
              <a href="javascript:void(0);" class="btn btn-cancel">Cancel</a>
            </div>
          </div>
        </div>
      </div>
      <!-- /product list -->
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      data: JSON.parse(localStorage.getItem("data")),
      old_password: null,
      new_password: null,
      level1: false,
      level2: false,
      level3: false,
      showPassword: false,
      password_msg: "",
      passwordStrength: 0,
    };
  },
  computed: {
    storeName() {
      return this.data.profile?.store_name === null
        ? "No store name yet"
        : this.data.profile?.store_name;
    },
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    ...mapActions({
      _change_password: "general/change_password",
    }),
    change_password() {
      this._change_password({
        current: this.old_password,
        new: this.new_password,
        confirm: this.new_password,
      })
        .then((response) => {
          this.response = response.data;
          //   this.response
          if (this.response["status"] == true) {
            var data = this.response.data;

            //   window.location = "authentication/lock-screen";
          }
        })
        .catch((error) => {
          console.log("Error logging in", error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;
            if (
              status == 400 ||
              status == 422 ||
              status == 401 ||
              status == 404
            ) {
            } else if (status === 500) {
              // this.$toast.error("Network Error, Check your internet conectivity.");
            }
          }
        });
    },
    validatePassword(value) {
      var uppercase = /^(?=.*[A-Z])/;
      var lowercase = /^(?=.*[a-z])/;
      var digit = /^(?=.*?[0-9])/;

      // var regex =/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#\$&*~]).{6,}$/;
      var specialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
      this.passwordStrength = 0;

      if (uppercase.test(value)) {
        this.passwordStrength += 20;
      }
      if (lowercase.test(value)) {
        this.passwordStrength += 20;
      }
      if (digit.test(value)) {
        this.passwordStrength += 20;
      }
      if (specialChar.test(value)) {
        this.passwordStrength += 20;
      }
      if (value.length >= 8) {
        this.passwordStrength += 20;
      }

      if (this.passwordStrength < 100) {
        $("#submit").addClass("disabled");
        this.password_msg = this.getVaildationMsg(value);
      } else {
        $("#submit").removeClass("disabled");
        this.password_msg = '';
      }
    },
    getVaildationMsg(value) {
      var uppercase = /^(?=.*[A-Z])/;
      var lowercase = /^(?=.*[a-z])/;
      var digit = /^(?=.*?[0-9])/;

      // var regex =/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#\$&*~]).{6,}$/;
      var specialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
      if (value == "") {
        return "Enter Password";
      }
      if (!uppercase.test(value)) {
        return "Password should contain at least one upper case";
      }
      if (!lowercase.test(value)) {
        return "Password should contain at least one lower case";
      }
      if (!digit.test(value)) {
        return "Password should contain at least one digit";
      }
      if (!specialChar.test(value)) {
        return "Password should contain at least one special character";
      }
      if (value.length < 8) {
        return "Password MIN(8)";
      }
    },
  },
  watch: {
    new_password: {
      handler() {
        this.validatePassword(this.new_password);
      },
    },
  },
};
</script>
<style scoped>
#blah {
  background-color: rgb(255, 255, 255);
}
.store_name {
  font-size: 44px;
  position: absolute;
  top: 7%;
  left: 45%;
  color: white;
  font-weight: bolder;
}
.instruction {
  float: right;
  font-size: 11px;
}
#progresstab {
  margin-top: -25px;
  height: 20px;
}
</style>
