<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  <InLoader class="inloader" />

  <div class="page-wrapper">
    <div class="content">
      <div class="page-header">
        <div class="add-item d-flex">
          <div class="page-title">
            <h4>Admin List</h4>
            <h6>Manage Your Admins</h6>
          </div>
        </div>
        <ul class="table-top-head">
          <li>
            <a data-bs-toggle="tooltip" data-bs-placement="top" title="Pdf"
              ><img src="@/assets/img/icons/pdf.svg" alt="img"
            /></a>
          </li>
          <li>
            <a data-bs-toggle="tooltip" data-bs-placement="top" title="Excel"
              ><img src="@/assets/img/icons/excel.svg" alt="img"
            /></a>
          </li>
          <li>
            <a data-bs-toggle="tooltip" data-bs-placement="top" title="Print"
              ><i data-feather="printer" class="printer"></i
            ></a>
          </li>
          <li>
            <a data-bs-toggle="tooltip" data-bs-placement="top" title="Refresh"
              ><i data-feather="rotate-ccw" class="feather-rotate-ccw"></i
            ></a>
          </li>
          <li>
            <a
              ref="collapseHeader"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Collapse"
              @click="toggleCollapse"
            >
              <i data-feather="chevron-up" class="feather-chevron-up"></i>
            </a>
          </li>
        </ul>
        <div class="page-btn">
          <a
            href="javascript:void(0);"
            class="btn btn-added"
            data-bs-toggle="modal"
            data-bs-target="#add-units"
            ><vue-feather type="plus-circle" class="me-2"></vue-feather>Add New
            Admin</a
          >
        </div>
      </div>

      <!-- /product list -->
      <div class="card table-list-card">
        <div class="card-body">
          <div class="table-top">
            <div class="search-set">
              <div class="search-input">
                <input type="text" placeholder="Search" class="dark-input"  v-model="search"
                @input="searchtrigger()"/>
                <a href="" class="btn btn-searchset" @click="searchtrigger()"
                  ><i data-feather="search" class="feather-search"></i
                ></a>
              </div>
            </div>
            <div class="search-path" hidden>
              <div class="d-flex align-items-center">
                <a
                  class="btn btn-filter"
                  id="filter_search"
                  v-on:click="filter = !filter"
                  :class="{ setclose: filter }"
                >
                  <vue-feather type="filter" class="filter-icon"></vue-feather>
                  <span
                    ><img src="@/assets/img/icons/closes.svg" alt="img"
                  /></span>
                </a>
              </div>
            </div>
            <div class="form-sort" hidden>
              <vue-feather type="sliders" class="info-img"></vue-feather>
              <vue-select
                :options="UserSort"
                id="usersort"
                placeholder="Sort by Date"
              />
            </div>
          </div>
          <!-- /Filter -->
          <div hidden
            class="card"
            :style="{ display: filter ? 'block' : 'none' }"
            id="filter_inputs"
          >
            <div class="card-body pb-0">
              <div class="row">
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="input-blocks">
                    <vue-feather type="user" class="info-img"></vue-feather>
                    <vue-select
                      :options="Choosename"
                      id="choosename"
                      placeholder="Choose Name"
                    />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="input-blocks">
                    <vue-feather
                      type="stop-circle"
                      class="info-img"
                    ></vue-feather>
                    <vue-select
                      :options="Choosestatususer"
                      id="choosestatususer"
                      placeholder="Choose Status"
                    />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="input-blocks">
                    <vue-feather type="zap" class="info-img"></vue-feather>
                    <vue-select
                      :options="Chooseroleuser"
                      id="chooseroleuser"
                      placeholder="Choose Role"
                    />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="input-blocks">
                    <a class="btn btn-filters ms-auto">
                      <i data-feather="search" class="feather-search"></i>
                      Search
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Filter -->
          <div class="table-responsive" >
            <a-table
              class="table datanew"
              :columns="columns"
              :data-source="data"
              :row-selection="{}"
            >
              <template #bodyCell="{ column, record }">
                <template v-if="column.key === 'UserName'">
                  <div class="userimgname">
                    <a href="javascript:void(0);" class="userslist-img bg-img">
                      <img
                        :src="require(`@/assets/img/users/${record.Image}`)"
                        alt="product"
                      />
                    </a>
                    <div>
                      <a href="javascript:void(0);">{{ record.UserName }}</a>
                    </div>
                  </div>
                </template>
                <template v-else-if="column.key === 'Status'">
                  <div>
                    <span :class="record.Class">{{ record.Status }}</span>
                  </div>
                </template>
                <template v-else-if="column.key === 'action'">
                  <td class="action-table-data">
                    <div class="edit-delete-action">
                      <a
                        class="me-2 p-2 mb-0"
                        data-bs-toggle="modal"
                        data-bs-target="#edit-units"
                        @click="assign_vals(record)"
                      >
                        <i data-feather="edit" class="feather-edit"></i>
                      </a>
                      <a
                        class="me-2 confirm-text p-2 mb-0"
                        href="javascript:void(0);"
                        @click="showConfirmation(record.id)"
                      >
                        <i data-feather="trash-2" class="feather-trash-2"></i>
                      </a>
                    </div>
                  </td>
                </template>
              </template>
            </a-table>
          </div>
        </div>
      </div>
      <!-- /product list -->

      <!-- Edit User -->
      <div class="modal fade" id="edit-units">
        <div class="modal-dialog modal-dialog-centered custom-modal-two">
          <div class="modal-content">
            <div class="page-wrapper-new p-0">
              <div class="content">
                <div class="modal-header border-0 custom-modal-header">
                  <div class="page-title">
                    <h4>Edit User</h4>
                  </div>
                  <button
                    type="button"
                    class="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body custom-modal-body">
                  <form @submit.prevent="submitForm">
                    <div class="row">
                      <div class="col-lg-12" hidden>
                        <div class="new-employee-field">
                          <span>Avatar</span>
                          <div class="profile-pic-upload mb-2">
                            <div class="profile-pic">
                              <span
                                ><i
                                  data-feather="plus-circle"
                                  class="plus-down-add"
                                ></i>
                                Profile Photo</span
                              >
                            </div>
                            <div class="input-blocks mb-0">
                              <div class="image-upload mb-0">
                                <input type="file" />
                                <div class="image-uploads">
                                  <h4>Change Image</h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="input-blocks">
                          <label>First Name</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="fname"
                          />
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="input-blocks">
                          <label>Last Name</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="lname"
                          />
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="input-blocks">
                          <label>Email</label>
                          <input
                            type="email"
                            class="form-control"
                            v-model="email"
                          />
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="input-blocks">
                          <label>Phone</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="phone"
                          />
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="input-blocks">
                          <label>Role</label>
                          <vue-select
                            :options="ListUs2"
                            id="listus2" 
                            placeholder="Choose"
                            v-model="role"
                          />
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="input-blocks">
                          <label>Tag</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="tag"
                          />
                        </div>
                      </div>
                      <div class="col-lg-6" v-if="sent">
                        <div class="input-blocks">
                          <label>OTP</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="otp"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer-btn">
                      <button
                        type="button"
                        class="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <div class="btn btn-submit" @click="edit_vendor()">
                        Submit
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Edit User -->
    </div>
  </div>
  <vendors-list-modal></vendors-list-modal>
</template>
<script>
import { mapActions } from "vuex";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { searchList } from "../../../module/search_module.js";

const columns = [
  {
    title: "Sur name",
    dataIndex: "surname",
    key: "surname",
    sorter: {
      compare: (a, b) => {
        a = a.surname.toLowerCase();
        b = b.surname.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Other Names",
    dataIndex: "other_names",
    sorter: {
      compare: (a, b) => {
        a = a.other_names.toLowerCase();
        b = b.other_names.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Phone Number",
    dataIndex: "phone",
    sorter: {
      compare: (a, b) => {
        a = a.phone.toLowerCase();
        b = b.phone.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Email",
    dataIndex: "email",
    sorter: {
      compare: (a, b) => {
        a = a.email.toLowerCase();
        b = b.email.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Role",
    dataIndex: "role",
    sorter: {
      compare: (a, b) => {
        a = a.role.toLowerCase();
        b = b.role.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  // {
  //   title: "Created On",
  //   dataIndex: "CreatedOn",
  //   sorter: {
  //     compare: (a, b) => {
  //       a = a.CreatedOn.toLowerCase();
  //       b = b.CreatedOn.toLowerCase();
  //       return a > b ? -1 : b > a ? 1 : 0;
  //     },
  //   },
  // },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    sorter: {
      compare: (a, b) => {
        a = a.status.toLowerCase();
        b = b.status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "action",
    sorter: true,
  },
];

export default {
  data() {
    return {
      filter: false,
      UserSort: ["Sort by Date", "Newest", "Oldest"],
      Chooseroleuser: ["Choose Role", "Store Keeper", "Salesman"],
      Choosestatususer: ["Choose Status", "Active", "Inactive"],
      Choosename: ["Choose Name", "Lilly", "Benjamin"],
      data: [],
      data_reserve:[],
      search:[],
      columns,
      fname: "",
      lname: "",
      email: "",
      phone: "",
      role: "",
      tag: "",
      id: "",
      ListUs2: [
        "Choose",
        "Finance and Account Manager",
        "Web store Operations Manager",
        "Sales Representative",
        "Business Developer Manager",
        "Web store assistance",
        "Finance and Account Manager Assistance",
        "Order Dispatcher",
        "Customer Service",
      ],
    };
  },
  methods: {
    ...mapActions({
      _get_vendor: "admin/get_vendor",
      _edit_vendor: "admin/edit_vendor",
      _delete_vendor: "admin/delete_vendor",
    }),
    toggleCollapse() {
      const collapseHeader = this.$refs.collapseHeader;

      if (collapseHeader) {
        collapseHeader.classList.toggle("active");
        document.body.classList.toggle("header-collapse");
      }
    },

    get_vendor() {
      this._get_vendor()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.data = this.response.data;
            this.data_reserve = this.data;
            // console.log(data);
          }
        })
        .catch((error) => {
          console.log("Error logging in", error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;
            if (
              status == 400 ||
              status == 422 ||
              status == 401 ||
              status == 404
            ) {
            } else if (status === 500) {
            }
          }
        });
    },
    assign_vals(record) {
      this.id = record.id;
      this.fname = record.surname;
      this.lname = record.other_names;
      this.email = record.email;
      this.phone = record.phone;
      this.role = record.role;
      this.tag = record.tag;
    },
    edit_vendor() {
      $(".inloader").show();
      this._edit_vendor({
        id: this.id,
        payload: {
          surname: this.fname,
          other_names: this.lname,
          email: this.email,
          phone: this.phone,
          role: this.role,
          tag: this.tag,
          verification_code: this.otp,
        },
      })
        .then((response) => {
          $(".inloader").hide();
          $("#edit-units").modal('hide');

          toast.success("Store Admin Updated", {
            position: toast.POSITION.TOP_RIGHT,
          });
          this.response = response.data;
          //   this.response
          if (this.response["status"] == true) {
            var data = this.response.data;
            // this.sent = true;
            this.get_vendor();
          }
        })
        .catch((error) => {
          $(".inloader").hide();

          console.log("Error logging in", error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;
            if (
              status == 400 ||
              status == 422 ||
              status == 401 ||
              status == 404
            ) {
              toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            } else if (status === 500) {
              toast.error("Network Error, Check your internet conectivity.", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          }
        });
    },
    showConfirmation(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: false,
      }).then((result) => {
        this._delete_vendor(id)
          .then((response) => {
            $(".inloader").hide();
            this.get_vendor();

            // toast.success("Product Edited", {
            //   position: toast.POSITION.TOP_RIGHT,
            // });
            this.response = response.data;
            //   this.response
            if (this.response["status"] == true) {
              var data = this.response.data;

              this.get_vendor();
            }
          })
          .catch((error) => {
            $(".inloader").hide();

            console.log("Error logging in", error);
            if (error.response) {
              const status = error.response.status;
              const data = error.response.data;
              if (
                status == 400 ||
                status == 422 ||
                status == 401 ||
                status == 404
              ) {
                toast.error(error.response.data.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              } else if (status === 500) {
                toast.error("Network Error, Check your internet conectivity.", {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }
            }
          });

        if (result.value) {
          Swal.fire({
            icon: "success",
            title: "Deleted!",
            text: "Admin has been deleted.",
            confirmButtonClass: "btn btn-success",
          });
        }
      });
    },
    searchtrigger() {
      const searchFields = ["email", "other_names", "phone", "role","surname"];
      this.data = searchList(this.data_reserve, this.search, searchFields);
      console.log(this.data);
    },
   
  },
  mounted() {
    this.get_vendor();
  },
};
</script>
